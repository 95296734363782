import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import commonStrings from '../strings/common';

const formatCoverDayTitle = (date: number | Date, timeZone: string): string => {
    const zonedDate = typeof date === 'number' ? utcToZonedTime(fromUnixTime(date), timeZone) : date;
    const zonedToday = utcToZonedTime(new Date(), timeZone);

    const diffDays = differenceInCalendarDays(zonedDate, zonedToday);

    if (diffDays === 0) {
        return commonStrings.today;
    }
    if (diffDays === 1) {
        return commonStrings.tomorrow;
    }
    return format(zonedDate, 'EEEE', { timeZone });
};

export default formatCoverDayTitle;
