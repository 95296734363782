import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import ClaimsService from '../../../services/ClaimsService';
import Routes from '../../../utils/Routes';
import Injuries from '../../../utils/constants/Injuries';
import Constants from '../../../utils/Constants';
import { LoadingSpinnerOverlay } from '../../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import Alert from '../../../components/alert/Alert';
import ErrorMessages from '../../../components/alert/error-messages/ErrorMessages';
import ImageButtonInput, { ImageButtonInputProps } from '../../../components/form/image-button-input/ImageButtonInput';
import Fieldset from '../../../components/form/fieldset/Fieldset';

import './SelectInjury.scss';

const SelectInjury: React.FC = () => {
    const { oktaAuth } = useOktaAuth();
    const { setClaimDetailsByAttr, setFileUploadTokens, claimDetails } = useClaim();
    const [isLoading, setIsLoading] = useState(false);
    const [didInitiatingClaimFail, setDidInitiatingClaimFail] = useState(false);
    const [selectedInjuries, setSelectedInjuries] = useState(claimDetails.injuries || []);

    const history = useHistory();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_INJURY, claimDetails);
    }, []);

    const { selectInjury } = claimsFlowStrings;

    const handleContinueClick = async () => {
        const accessToken = oktaAuth.getAccessToken();

        setIsLoading(true);

        await setClaimDetailsByAttr('injuries', selectedInjuries);

        // if claimId already exists, a claim is in progress so don't create another claim
        if (claimDetails.id !== '') {
            Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_INJURY, claimDetails);
            history.push(Routes.ACCIDENT_DESCRIPTION);
            return;
        }

        try {
            await ClaimsService.createClaim(accessToken).then((response: any) => {
                setFileUploadTokens(response.accessToken, response.claimId, response.folderIds);
                setClaimDetailsByAttr('id', response.claimId);
                Analytics.trackClaimCreated(response.claimId);
            });

            Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_INJURY, claimDetails);
            setIsLoading(false);

            history.push(Routes.ACCIDENT_DESCRIPTION);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setDidInitiatingClaimFail(true);
        }
    };

    const handleSelect = useCallback(
        (id: string, label?: string) => {
            if (!selectedInjuries.some((injury) => injury.id === id)) {
                setSelectedInjuries([...selectedInjuries, { id, label }]);
            } else {
                const newArr = selectedInjuries.filter((selected) => selected.id !== id);
                setSelectedInjuries(newArr);
            }
        },
        [selectedInjuries],
    );

    const injuryOptions: ImageButtonInputProps[] = useMemo(
        () =>
            Object.values(Injuries).map((injury) => ({
                id: injury.value,
                icon: injury.icon,
                label: injury.label,
                name: 'injury',
                type: 'checkbox',
                onChange: () => handleSelect(injury.value, injury.label),
                checked: selectedInjuries.some((selected) => selected.id === injury.value),
            })),
        [handleSelect, selectedInjuries],
    );

    return (
        <Layout>
            {isLoading && <LoadingSpinnerOverlay />}
            <ProgressBar completed={(Constants.CLAIMS_STEP_INJURY / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="select-injury__header">{selectInjury.title}</h1>
            <form onSubmit={(e) => e.preventDefault()}>
                <Fieldset legend={selectInjury.title} visuallyHideLegend className="select-injury__fieldset">
                    {injuryOptions.map((option) => (
                        <ImageButtonInput {...option} key={option.id} />
                    ))}
                </Fieldset>
                {didInitiatingClaimFail && (
                    <Alert className="select-injury__alert" message={ErrorMessages.refreshOrComebackWithApologies} />
                )}
                <Button
                    className={classNames('select-injury__btn-continue', {
                        'select-injury__btn-continue--error': didInitiatingClaimFail,
                    })}
                    label={commonStrings.continue}
                    width="full"
                    onClick={handleContinueClick}
                    type="submit"
                    disabled={selectedInjuries.length === 0}
                />
            </form>
        </Layout>
    );
};

export default SelectInjury;
