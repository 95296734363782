import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from '@tanstack/react-query';
import { useMachine } from '@xstate/react';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { Destination, Region } from '../../business-logic/models/RoamingDestinations';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import Layout from '../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import roamingStartDestinationContent from '../../content/ui/screens/roaming-start-destination/roamingStartDestination';
import withContent from '../../hoc/with-content/withContent';
import useOnboarding from '../../hooks/useOnboarding';
import CdnService from '../../services/cdn-service/CdnService';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import getPdsVersion from '../../utils/getPdsVersion';
import RoamingStartDestinationMachine from './roaming-start-destination-machine/roamingStartDestinationMachine';

import './RoamingStartDestination.scss';

const contentMap = {
    heading: 'ui.heading',
    cta: 'ui.cta',
};

interface RoamingStartProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingStartDestination: FC<RoamingStartProps> = ({ content }) => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const history = useHistory();
    const { setRoamingData, destinations, startingDestination, selectedCover } = useRoaming();
    const { authState } = useOktaAuth();

    const {
        isLoading: fetchingDestinationsLoading,
        isError: fetchingDestinationsError,
        data: fetchedDestinations,
        isSuccess: fetchingDestinationsSucceeded,
    } = useQuery({
        queryKey: ['destinations'],
        queryFn: () => {
            return CdnService.getCoverConfig({
                coverCode: selectedCover as Cover,
                PDSVersion: getPdsVersion(selectedCover as Cover),
            });
        },
    });

    const displayDestinations = () => {
        if (fetchingDestinationsError || !!fetchedDestinations === false) return [];
        return (fetchedDestinations as Destination[]).filter((item: Destination) =>
            destinations.includes(item.destination),
        );
    };

    const [state, send] = useMachine(RoamingStartDestinationMachine, {
        actions: {
            redirectToSelectDates: () => {
                // one region for chosen destination
                const { timezone, region } = (fetchedDestinations! as Destination[])
                    .find((item: Destination) => item.destination === startingDestination)!
                    .regions.find((item: Region) => item.region === startingDestination)!;

                setRoamingData('startingDestination', startingDestination);
                setRoamingData('startingRegion', region);
                setRoamingData('timezone', timezone);
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.ROAMING_DATES : Routes.ROAMING_DATES_GUEST,
                });
            },
            redirectToChooseStartingRegion: () => {
                // multiple regions
                setRoamingData('startingDestination', startingDestination);
                // Reset starting region on starting destination select
                setRoamingData('startingRegion', '');
                setRoamingData('timezone', '');
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.ROAMING_REGION : Routes.ROAMING_REGION_GUEST,
                });
            },
        },
    });

    const confirmStartingDestination = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (fetchingDestinationsSucceeded) {
            send({ type: 'SELECT_START_DESTINATION', data: startingDestination as string });
        }
    };

    useEffect(() => {
        if (fetchingDestinationsSucceeded) {
            // Ensure startingDestination is front of destinations when committing to server
            const fromPosition = destinations.indexOf(startingDestination);
            const toPosition = 0;
            destinations.splice(toPosition, 0, destinations.splice(fromPosition, 1)[0]);
            setRoamingData('destinations', destinations);
            send({ type: 'LOAD_DESTINATIONS', data: fetchedDestinations as Destination[] });
        }
    }, [fetchingDestinationsSucceeded, fetchedDestinations, send]);

    useEffect(() => {
        const currentPath = location.pathname;
        if (authState?.isAuthenticated && currentPath === Routes.ROAMING_START_GUEST) {
            history.push(Routes.HOME);
        }
    }, [location, authState, history]);

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            {(fetchingDestinationsLoading || state.hasTag('loading')) && <LoadingSpinnerOverlay />}

            {fetchingDestinationsError && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}

            <form onSubmit={confirmStartingDestination}>
                <Fieldset legend={content.heading} visuallyHideLegend>
                    {fetchingDestinationsSucceeded &&
                        !!displayDestinations() &&
                        displayDestinations().map((item: Destination) => (
                            <div key={item.destination}>
                                <RadioButton
                                    id={item.destination}
                                    name="destination__option"
                                    className="roaming__start-destination"
                                    label={item.destination}
                                    onChange={() => setRoamingData('startingDestination', item.destination as string)}
                                    checked={startingDestination === item.destination}
                                />
                            </div>
                        ))}
                    <Button
                        width="full"
                        type="submit"
                        variant="primary"
                        label={common.continue}
                        disabled={!!startingDestination === false}
                        className="roaming-destinations__cta"
                    />
                </Fieldset>
            </form>
        </Layout>
    );
};

export default withContent(RoamingStartDestination, contentMap, roamingStartDestinationContent);
