import { datadogRum } from '@datadog/browser-rum';
import asService, { ServiceArgs } from '../utils/asService';
import withRetriesAsync from '../utils/withRetriesAsync';
import isAxios409Error from '../utils/isAxios409Error';
import GuestCustomerResponse from '../../business-logic/models/GuestCustomerResponse';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const initialiseGuestCustomer = async ({
    email,
    axios,
}: {
    email: string;
} & ServiceArgs): Promise<{ accessToken: string; isNewCustomer: boolean }> => {
    const { data, status } = await withRetriesAsync(
        () => axios.post<GuestCustomerResponse>(`${baseApiPath}/api/v1/pub/guest/customer`, { email }),
        undefined,
        (error) => {
            // If 409, log error and retry fetch
            if (isAxios409Error(error)) {
                datadogRum.addError(error, {
                    serviceName: 'initialiseGuestCustomer',
                    description: 'conflict in guest customer creation call',
                });
                return true;
            }
            return false;
        },
    );

    return { accessToken: data.accessToken, isNewCustomer: status === 201 };
};

export default asService(initialiseGuestCustomer);
