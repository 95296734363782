import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { SuccessPage } from '../../components/success-page/SuccessPage';
import requireFlags from '../../hoc/require-flags/requireFlags';
import useLazyDependency from '../../hooks/lazy-dependency/useLazyDependency';
import commonStrings from '../../strings/common';
import coverFlow from '../../strings/coverFlow';
import Routes from '../../utils/Routes';
import StorageKeys from '../../utils/constants/StorageKeys';
import getMainCoverPurchaseSuccessNextPath from '../../utils/getMainCoverPurchaseSuccessNextPath';
import parseString from '../../utils/parseString';

const ScheduleAlwaysOnSuccess: React.FC = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();

    const { totalPurchases } = useUser();
    const { clearOpenCoversSelections } = useOpenCovers();
    const ldClient = useLDClient();
    const { value: totalPurchaseValue, fetch: getTotalPurchaseValue } = useLazyDependency(totalPurchases);
    const { clearRoamingState } = useRoaming();

    const [isOnboarding, setIsOnboarding] = useState(false);

    const { alwaysOnSuccess: contentStrings } = coverFlow;

    const nextPath = getMainCoverPurchaseSuccessNextPath(
        typeof totalPurchaseValue === 'number' && totalPurchaseValue === 1,
    );

    useEffect(() => {
        getTotalPurchaseValue();

        const locationStateString = sessionStorage.getItem(StorageKeys.LOCATION_STATE);
        let isOnboardingState = location.state ? location.state.isOnboarding : false;
        if (locationStateString) {
            isOnboardingState = !!JSON.parse(locationStateString).isOnboarding;
            sessionStorage.removeItem(StorageKeys.LOCATION_STATE);
        }
        setIsOnboarding(isOnboardingState);

        if (isOnboardingState) {
            ldClient?.waitUntilGoalsReady().then(() => {
                ldClient?.track('Onboarding Cover Purchased');
                ldClient?.flush();
            });
        }

        const isFromNoCover = sessionStorage.getItem(StorageKeys.FROM_NO_COVER) === 'true';
        // If came from no cover journey, log experiment metric
        if (isFromNoCover) {
            ldClient?.waitUntilGoalsReady().then(() => {
                ldClient.track('Cover Purchased - No Cover');
                ldClient.flush();
            });
            sessionStorage.removeItem(StorageKeys.FROM_NO_COVER);
        }
        clearRoamingState();
        clearOpenCoversSelections();
    }, []);

    const handleBtnClick = () => {
        history.push({
            pathname: nextPath,
            state: { isOnboarding },
        });
    };

    return (
        <SuccessPage
            header={parseString(contentStrings.title)}
            description={parseString(contentStrings.description)}
            buttonLabel={nextPath === Routes.HOME ? commonStrings.done : commonStrings.continue}
            buttonOnClick={handleBtnClick}
        />
    );
};

export default requireFlags(ScheduleAlwaysOnSuccess);
