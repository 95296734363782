const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

export default (onLoad: () => void, onError: () => void): void => {
    // Load Google Places API Client Library
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    scriptTag.async = true;
    scriptTag.onload = onLoad;
    // If error loading autocomplete library fallback to manual input
    scriptTag.onerror = onError;
    document.body.appendChild(scriptTag);
};
