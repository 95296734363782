import { Elements } from '@stripe/react-stripe-js';
import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';

import stripe from '../../../../utils/third-party-dependencies/stripe';
import paymentElementMachine, { PAYMENT_ELEMENT_MACHINE_TAGS } from '../payment-element-machine/paymentElementMachine';
import LoadingSpinner from '../../../loading-spinner/LoadingSpinner';

interface StripeElementProviderProps {
    paymentElementMachineRef: ActorRefFrom<typeof paymentElementMachine>;
}

const StripeElementProvider: FC<StripeElementProviderProps> = ({ paymentElementMachineRef, children }) => {
    const [state] = useActor(paymentElementMachineRef);

    return state.hasTag(PAYMENT_ELEMENT_MACHINE_TAGS.LOADING_SETUP_INTENT) ? (
        <LoadingSpinner />
    ) : (
        <Elements
            stripe={stripe.stripePromise}
            options={{
                ...stripe.elementsOptions,
                clientSecret: state.context.setupIntent?.stripeClientSecret,
                appearance: stripe.paymentElementAppearance,
            }}
        >
            {children}
        </Elements>
    );
};

export default StripeElementProvider;
