import Cover from './constants/Cover';

const isKidsCover = (cover: Cover): boolean => {
    switch (cover) {
        case Cover.FLIP_KIDS_DAILY:
            return true;
        case Cover.FLIP_KIDS_WEEKLY:
            return true;
        case Cover.FLIP_KIDS_SUB_MONTHLY:
            return true;
        case Cover.FLIP_ROAMING_KIDS_WEEKLY:
            return true;
        default:
            return false;
    }
};

export default isKidsCover;
