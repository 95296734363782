import { useMachine } from '@xstate/react';
import { FC, FormEvent } from 'react';
import { assign, spawn } from 'xstate';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import DisclosureWidget from '../../../../components/disclosure-widget/DisclosureWidget';
import KidDetailsForm from '../../../../components/kid-details-form/KidDetailsForm';
import kidDetailsFormMachine from '../../../../components/kid-details-form/kid-details-form-machine/kidDetailsFormMachine';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import guestCartContent from '../../../../content/ui/screens/guest-cart/guestCart';
import withContent from '../../../../hoc/with-content/withContent';
import common from '../../../../strings/common';
import kidsDetailsMachine from './kids-details-machine/kidsDetailsMachine';
import UserDetails from '../../../../business-logic/models/UserDetails';

import './KidsDetailsStep.scss';

const contentMap = {
    title: 'ui.kidsDetailsStep.title',
    disclosureSummary: 'ui.kidsDetailsStep.disclosureSummary',
};

interface KidsDetailsStepProps {
    content: Record<keyof typeof contentMap, string>;
    numKids: number;
    onDependantsUpdateComplete: (dependants: UserDetails[]) => void;
}

const KidsDetailsStep: FC<KidsDetailsStepProps> = ({ content, numKids, onDependantsUpdateComplete }) => {
    const { accessToken, setDependants } = useUser();

    const [state, send] = useMachine(kidsDetailsMachine, {
        context: {
            accessToken,
        },
        actions: {
            spawnKidDetailsFormMachines: assign({
                kidDetailsFormMachines: () =>
                    [...Array(numKids)].map(() => spawn(kidDetailsFormMachine, { sync: true })),
            }),
            notifyDependantsCreationComplete: (ctx, event) => onDependantsUpdateComplete(event.data.dependants),
            updateUserContext: (ctx, event) => {
                setDependants(event.data.dependants);
            },
        },
    });

    const displayGenericError = state.matches('ready.displayGenericError');
    const machinesSpawned = state.matches('ready');
    const anyKidDetailsFormInvalid = state.context.kidDetailsFormMachines.some((i) =>
        i.getSnapshot()?.hasTag('INVALID'),
    );
    const isCreatingDependants = state.matches('ready.createGuestDependants');

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('CONTINUE');
    };

    return (
        <>
            <h2 className="kids-details-step__title">{content.title}</h2>

            <form onSubmit={handleSubmit}>
                {machinesSpawned ? (
                    <div className="kids-details-step__kid-details-forms">
                        {state.context.kidDetailsFormMachines.map((i, index) => (
                            <DisclosureWidget
                                key={i.id}
                                className="kids-details-step__kid-details-form"
                                summary={
                                    <span className="kids-details-step__kid-details-form-summary">
                                        {guestCartContent.formatString(content.disclosureSummary, { index: index + 1 })}
                                    </span>
                                }
                                // Open the first form on render
                                open={index === 0}
                            >
                                <KidDetailsForm kidDetailsFormMachineRef={i} />
                            </DisclosureWidget>
                        ))}
                    </div>
                ) : null}

                {displayGenericError && <span className="kids-details-step__error">{state.context.genericError}</span>}

                <LoadingButton
                    status={isCreatingDependants ? 'loading' : 'idle'}
                    label={common.continue}
                    disabled={anyKidDetailsFormInvalid}
                    width="full"
                    type="submit"
                />
            </form>
        </>
    );
};

export default withContent(KidsDetailsStep, contentMap, guestCartContent);
