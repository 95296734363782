import LocalizedStrings from 'react-localization';

const coverFlow = new LocalizedStrings({
    en: {
        yourCover: {
            yourCover: 'Your cover',
            whichCoverTitle: 'Check out your options',
            mostPopular: 'Most Popular',
            dayPassSubtitle: "Based on your response, we thought you'd like our Day Pass.",
            alwaysOnSubtitle: 'Based on your response, we thought you’d like our Always On cover.',
            dayPassCard: {
                title: 'Day Pass',
                subtitle: 'Flexible cover for the days you want it',
                whatsCovered: [
                    'Pay by the day',
                    'Book ahead for upcoming activities, events, or trips',
                    'No ongoing commitment',
                ],
            },
            alwaysOnCard: {
                title: 'Always On',
                subtitle: 'Ongoing cover for peace of mind',
                whatsCovered: [
                    'Subscribe for full-time cover',
                    'Boost to double your payout for the day, for just $6 extra per day',
                    'Cancel any time',
                ],
            },
            commonCoverage: [
                'Instant activation',
                'Lightning-fast claims',
                'Get up to $20,000 depending on your injury',
            ],
            notReady: 'Not ready to get Flip cover yet?',
            skipCover: 'Yeah, skip for now',
        },
        yourCoverV2: {
            yourCover: 'Your cover',
            whichCoverTitle: 'Purchase options',
            mostPopular: 'Most Popular',
            dayPassCard: {
                title: 'Day Pass',
                subtitle: 'Flexible cover for when you feel at risk',
                whatsCovered: [
                    'Pay only for the days you need',
                    'Book ahead for upcoming activities, events, or trips',
                    'No ongoing commitment',
                ],
            },
            alwaysOnCard: {
                title: 'Always On',
                subtitle: 'Ongoing cover for peace of mind',
                whatsCovered: [
                    'Weekly subscription',
                    'Boost for $6 per day to double your payout for the day',
                    'Cancel anytime',
                ],
            },
            commonCoverage: ['Instant activation', 'Lightning-fast claims', 'Get up to $20k depending on your injury'],
            notReady: 'Not ready to get Flip cover yet?',
            skipCover: 'Yeah, skip for now',
        },
        scheduleCover: {
            title: 'What days do you want cover?',
            buttonLabel: 'Select date(s)',
            disclaimer: "We'll charge you when you confirm your cover days.",
            coverForToday: {
                title: 'Cover for today: ',
                description:
                    'it’ll start now and be active for less than 24hrs, ending at 11.59PM tonight. You can’t cancel once it’s confirmed',
            },
            coverForLater: {
                title: 'Cover for later: ',
                description:
                    "you'll be covered from 12.00AM until 11.59PM on those days, unless you cancel beforehand. If you do, we'll automatically reimburse your card",
            },
            maxDayPassesBookedPerSessionReachedError: "You've reached your limit of scheduling 10 Boosts at a time.",
            profileInfoDisclaimer:
                'We’re using your profile information. If your details are incorrect please reach out to {customerSupport}.',
        },
        alwaysOnSuccess: {
            title: 'You’re all set!',
            description:
                "Check your inbox for cover documents.<br /><br />Get out there, and remember we've always got your back!",
        },
        dayPassSuccess: {
            title: 'You’re all set!',
            description:
                "Check your inbox for cover documents.\n\nIf you decide to cancel your cover before your big day, we'll refund the money back to your card.",
        },
        cancelDayPassModalDescription: 'Are you sure you want to cancel your cover for <strong>{activeDate}</strong>?',
    },
});

export default coverFlow;
