import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Modifier } from 'react-day-picker/types/Modifiers';
import { DateUtils } from 'react-day-picker';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import commonStrings from '../../strings/common';
import DateFormat from '../../utils/constants/DateFormat';

import './DatePickerInput.scss';

export interface DatePickerInputProps {
    onChange: (selectedDate: string) => void;
    selectedDate?: Date;
    monthToShow?: Date;
    fromMonth?: Date;
    toMonth?: Date;
    disabledDays?: Modifier | Modifier[];
    showYearInCaption?: boolean;
}

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
    onChange,
    selectedDate,
    monthToShow,
    fromMonth,
    toMonth,
    disabledDays,
    showYearInCaption = false,
}) => {
    const parseDate = (str: string, dateFormat: string) => {
        const parsed = parse(str, dateFormat, new Date());
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    };

    const formatDate = (date: number | Date, dateFormat: string) => {
        return format(date, dateFormat);
    };

    return (
        <div>
            <p className="label">{commonStrings.date}</p>
            <DayPickerInput
                value={selectedDate}
                onDayChange={(value: Date) => onChange(formatDate(value, DateFormat.DEFAULT))}
                formatDate={formatDate}
                parseDate={parseDate}
                format={DateFormat.DEFAULT}
                inputProps={{
                    placeholder: '',
                    className: 'text-field__input date-picker__input',
                    readOnly: 'readonly',
                }}
                dayPickerProps={{
                    captionElement: ({ date }) => (
                        // class name from daypicker needs to be here for
                        // for it to be controlled by parent
                        <div className="DayPicker-Caption">
                            <p>{`${format(date, 'MMMM').toUpperCase()} ${
                                showYearInCaption ? format(date, 'Y') : ''
                            }`}</p>
                        </div>
                    ),
                    month: monthToShow,
                    fromMonth,
                    toMonth,
                    disabledDays,
                    fixedWeeks: true,
                }}
            />
        </div>
    );
};

export default DatePickerInput;
