import LocalizedStrings from 'react-localization';

const guestCartSuccessContent = new LocalizedStrings({
    en: {
        heading: 'Thank you {firstName}!',
        description:
            'Your Flip cover is purchased.\nCheck your inbox for your confirmation email and Certificate of Insurance.',
        accountCreation:
            '<p><strong>We&apos;ve created you an account.</strong></p><p>To manage your cover and claims, sign in to view your dashboard.</p>',
        accessAccountCTA: 'Sign in',
        accessAccountCTADescription: 'to manage your cover and claims',
        downloadTheAppCta: 'Download the app',
        productsCta: 'Continue browsing',
        appStoreActionText: 'Download on the',
        appStoreStoreName: 'App Store',
        playStoreActionText: 'Get it on',
        playStoreStoreName: 'Google Play',
    },
});

export default guestCartSuccessContent;
