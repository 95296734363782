/* eslint-disable @typescript-eslint/no-unused-vars */
import { useOktaAuth } from '@okta/okta-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import infoIcon from '../../assets/images/info.svg';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import Alert, { AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import ModalWithCTA from '../../components/modal-with-cta/ModalWithCTA';
import { ModalAlert } from '../../components/modal-with-cta/utils/ModalWithCTAAlertTypes';
import { IProductCategory, ProductCategories } from '../../content/product-categories/ProductCategories';
import selectCoverContent from '../../content/ui/screens/select-cover/selectCover';
import withContent from '../../hoc/with-content/withContent';
import useOnboarding from '../../hooks/useOnboarding';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import CoverInformation from '../../utils/constants/CoverInformation';
import parseString from '../../utils/parseString';

import './SelectCover.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface SelectCoverProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectCover: FC<SelectCoverProps> = ({ content }) => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const { initialised: userContextInitialised, dependants, userDetails } = useUser();
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const { setRoamingData, resetRoamingSelection, destinations, selectedCover: selectedRoamingCover } = useRoaming();
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const history = useHistory();
    const { enforceOverlappingRules } = useFlags();
    const { policies } = useOpenCovers();
    const [unavailableCoverModal, setUnavailableCoverModal] = useState<{
        status: boolean;
        textContent: ReactNode | null;
        title: string;
    }>({ status: false, textContent: null, title: '' });
    const { authState } = useOktaAuth();

    const availableCategories = useMemo(() => {
        if (!userContextInitialised || !!location.state.coverFor === false) return [];
        return Object.entries(ProductCategories)
            .filter(([_, value]) => value.coverFor === location.state.coverFor)
            .sort(([akey, acategory], [bkey, bcategory]) => acategory.position - bcategory.position);
    }, [userContextInitialised, location]);

    const getOtherCategoryChoice = (selectedCategory: string): string => {
        const category = availableCategories
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .find(([key, _]) => key !== selectedCategory)
            ?.flat()
            .shift();
        return ProductCategories[category as keyof IProductCategory].title;
    };

    const accountHolderCovers = policies.filter(
        (x) =>
            (x.mainCover.insuredPersonId === null || x.mainCover.insuredPersonId === userDetails.personId) &&
            x.mainCover.status !== 'Canceled',
    );

    const dependantsCovers = policies.filter(
        (x) =>
            !!dependants.find((y) => y.personId === x.mainCover.insuredPersonId) && x.mainCover.status !== 'Canceled',
    );

    const selectCategory = (category: string, coverCodes: string[]): void => {
        switch (category) {
            case 'FLIP_ROAMING':
            case 'FLIP_ROAMING_KIDS':
                setRoamingData('selectedCover', coverCodes[0]);
                if (location.state.dependantToCover) {
                    setRoamingData('selectedKidId', location.state.dependantToCover);
                }
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.ROAMING_SELECTED : Routes.ROAMING_SELECTED_GUEST,
                    state: {
                        ...(!authState?.isAuthenticated && {
                            numberOfGuestKids: location.state.numberOfGuestKids,
                        }),
                    },
                });
                break;
            case 'FLIP_ACTIVE':
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.SCHEDULE_COVER : Routes.SCHEDULE_COVER_GUEST,
                    state: {
                        coverFor: 'self',
                        ...(!authState?.isAuthenticated && {
                            guestSelectedCovers: coverCodes,
                        }),
                    },
                });
                break;
            case 'FLIP_KIDS':
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.SCHEDULE_COVER : Routes.SCHEDULE_COVER_GUEST,
                    state: {
                        coverFor: 'dependant',
                        ...(!!authState?.isAuthenticated && { dependantToCover: location.state.dependantToCover }),
                        ...(!authState?.isAuthenticated && {
                            numberOfGuestKids: location.state.numberOfGuestKids,
                            guestSelectedCovers: coverCodes,
                        }),
                    },
                });
                break;
            default:
                setCategoryError(true);
        }
    };

    // User can buy cover when
    // // 1) They have previously bought covers of the same category before OR
    // // 2) They have never bought any covers at all
    const canUserBuyCoverCategory = (key: string) => {
        if (location.state.coverFor === 'self') {
            if (accountHolderCovers.length === 0) {
                return true;
            }
            switch (key) {
                case 'FLIP_ACTIVE':
                    return !!accountHolderCovers.filter(
                        (x) =>
                            x.mainCover.coverCode === CoverInformation[Cover.FLIP_ACTIVE_DAILY].coverCode ||
                            x.mainCover.coverCode === CoverInformation[Cover.FLIP_ACTIVE_SUB_MONTHLY].coverCode ||
                            x.mainCover.coverCode === CoverInformation[Cover.FLIP_ACTIVE_WEEKLY].coverCode,
                    ).length;

                case 'FLIP_ROAMING':
                    return !!accountHolderCovers.filter(
                        (x) => x.mainCover.coverCode === CoverInformation[Cover.FLIP_ROAMING_WEEKLY].coverCode,
                    ).length;
                default:
                    return false;
            }
        }

        const selectedKid = dependantsCovers.filter(
            (x) => x.mainCover.insuredPersonId === location.state.dependantToCover,
        );
        // Newly Added child should have all options available
        if (selectedKid === undefined) {
            return true;
        }
        if (Object.values(selectedKid).every((item) => isEmpty(item))) {
            return true;
        }
        switch (key) {
            case 'FLIP_KIDS':
                return !!selectedKid.filter(
                    (x) =>
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_KIDS_DAILY].coverCode ||
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_KIDS_SUB_MONTHLY].coverCode ||
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_KIDS_WEEKLY].coverCode,
                ).length;
            case 'FLIP_ROAMING_KIDS':
                return !!selectedKid.filter(
                    (x) => x.mainCover.coverCode === CoverInformation[Cover.FLIP_ROAMING_KIDS_WEEKLY].coverCode,
                ).length;
            default:
                return false;
        }
    };

    const renderUnavailableModalContent = (existingCoverCategory: string, selectedCoverCategory: string) => {
        return {
            main: (
                <span>
                    Please cancel your {existingCoverCategory} cover or wait for the cover to end, before purchasing{' '}
                    {selectedCoverCategory} cover.
                </span>
            ),
            title: `You currently have ${existingCoverCategory} scheduled`,
        };
    };
    const unavailableCoverCategoryModal = (existingCoverCategory: string, selectedCoverCategory: string) => {
        const unavailableCoverCategoryContent = renderUnavailableModalContent(
            existingCoverCategory,
            selectedCoverCategory,
        );
        setUnavailableCoverModal({
            status: true,
            textContent: unavailableCoverCategoryContent.main,
            title: unavailableCoverCategoryContent.title,
        });
    };

    useEffect(() => {
        if (destinations.length && !!selectedRoamingCover) {
            resetRoamingSelection();
        }
    }, []);

    useEffect(() => {
        const currentPath = location.pathname;
        if (authState?.isAuthenticated && currentPath === Routes.SELECT_COVER_GUEST) {
            history.push(Routes.HOME);
        }
    }, [location, authState, history]);

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            <ModalWithCTA
                icon={ModalAlert.SYSTEM}
                isOpen={unavailableCoverModal.status}
                title={unavailableCoverModal.title}
                textContent={unavailableCoverModal?.textContent || <p>There was a problem</p>}
                arrangeButtons="column"
                primaryCTA={{
                    label: common.ok,
                    onClick: () => setUnavailableCoverModal({ status: false, textContent: null, title: '' }),
                }}
            />
            <div className="cover-selection">
                {(categoryError || availableCategories.length === 0) && (
                    <Alert type={AlertTypes.ERROR} message={ErrorMessages.refreshOrContactUs} />
                )}
                {availableCategories.length > 0 &&
                    availableCategories.map(([key, category]) => (
                        <div className="cover" key={category.title}>
                            <div className="cover__image-wrapper">
                                <div className={`cover__highlight cover__highlight--${category.highlightStyle}`}>
                                    <span className="cover__highlight__text">{category.highlightText}</span>
                                </div>
                                <img src={category.image} alt="" className="cover__image" />
                            </div>
                            <div className="cover__content">
                                <div className="cover__content__text">
                                    <div className="cover__content__title">
                                        <h3>{category.title}</h3>
                                        <div className="cover__content__title__price">
                                            {parseString(category.pricePerPeriod)}
                                        </div>
                                    </div>
                                    <span className="cover__content__text__blurb">
                                        {parseString(category.description)}
                                    </span>
                                </div>
                                {!enforceOverlappingRules || canUserBuyCoverCategory(key) ? (
                                    <Button
                                        type="button"
                                        width="full"
                                        size="small"
                                        onClick={() => selectCategory(key, category.coverCodes)}
                                        variant="primary"
                                        label={common.select}
                                        className="cover__content__cta"
                                    />
                                ) : (
                                    <div className="cover__unavailable">
                                        <span>Cover unavailable</span>
                                        <button
                                            type="button"
                                            className="button--link button cover__unavailable__btn"
                                            onClick={() =>
                                                unavailableCoverCategoryModal(
                                                    getOtherCategoryChoice(key),
                                                    category.title,
                                                )
                                            }
                                        >
                                            <img src={infoIcon} alt="Cover information" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </Layout>
    );
};

export default withContent(SelectCover, contentMap, selectCoverContent);
