import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import { UploadDocumentButton } from '../../../components/upload-document-button/UploadDocumentButton';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import Analytics from '../../../analytics/Analytics';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Item from './components/item/Item';
import Examples from './components/examples/Examples';
import './MedicalReport.scss';

const MedicalReport: React.FC = () => {
    const history = useHistory();
    const { fileUploadTokens, claimDetails, setClaimFilesByAtrr } = useClaim();

    const { medicalReport: contentString, allowedFilesLabel } = claimsFlowStrings;

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_MEDICAL_REPORT, claimDetails);
    }, []);

    const handleClickContinue = () => {
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_MEDICAL_REPORT, claimDetails);

        history.push(Routes.MEDICAL_CONTACT_CONSENT);
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_MEDICAL_REPORT / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="medical-report__header">{contentString.title}</h1>
            <p>{contentString.description}</p>
            <p className="medical-report__items-description">{contentString.itemsDescription}</p>
            <div className="medical-report__items">
                {contentString.itemsToInclude.map((item) => (
                    <Item icon={item.icon} text={item.title} key={item.title} />
                ))}
            </div>
            <Examples />

            <p className="medical-report__files-allowed">{allowedFilesLabel}</p>
            <UploadDocumentButton
                accessToken={fileUploadTokens.accessToken}
                folderId={fileUploadTokens.folderIds.medicalReport}
                onUploadComplete={(files: Array<any>) => setClaimFilesByAtrr('medicalReport', files)}
                uploadedFiles={claimDetails.medicalReport}
            />
            <Button
                className="medical-report__btn-continue"
                label={commonStrings.continue}
                width="full"
                disabled={claimDetails.medicalReport.length === 0}
                onClick={handleClickContinue}
            />
        </Layout>
    );
};

export default MedicalReport;
