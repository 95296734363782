import { assign } from 'xstate';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import { Destination } from '../../../../business-logic/models/RoamingDestinations';
import { RoamingDestinationsBaymaxMachineContextTypes } from '../context/roamingDestinationsBaymaxMachineContext';
import { Typegen0 } from '../roamingDestinationsBaymaxMachine.typegen';
import Analytics from '../../../../analytics/Analytics';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setChosenDestinations = assign<
    RoamingDestinationsBaymaxMachineContextTypes,
    { type: EventsCausingActions['setChosenDestinations']; data: string[] }
>({
    chosenDestinations: (_, event) => event.data,
});

export const updateMachineContextFromGlobalContext = assign<
    RoamingDestinationsBaymaxMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            selectedProductOption: ProductOption;
            destinations: Destination[];
        };
    }
>({
    availableDestinations: (ctx, event) => event.data.destinations,
    selectedProductOption: (ctx, event) => event.data.selectedProductOption,
});

export const trackDestinationsSelected = (ctx: RoamingDestinationsBaymaxMachineContextTypes): void => {
    Analytics.trackDestinationsSelected(ctx.chosenDestinations);
};
