import { Appearance, loadStripe } from '@stripe/stripe-js';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

const elementsOptions = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Mulish',
        },
    ],
};

const paymentElementAppearance: Appearance = {
    theme: 'flat',
    variables: {
        colorBackground: '#f8f2db',
        fontFamily: 'Mulish, sans-serif',
        fontSizeBase: '16px',
        fontLineHeight: '24px',
        colorText: '#070a3d',
        colorDanger: '#be2301',
        colorDangerText: '#be2301',
        borderRadius: '5px',
    },
    rules: {
        '.Input': {
            border: '2px solid #bab4ab',
            padding: '14.85px 16px',
        },
        '.Input:focus': {
            borderColor: '#070a3d',
        },
        '.Label': {
            fontSize: 'var(--fontSize3Xs)',
            fontWeight: 'var(--fontWeightBold)',
            lineHeight: '20px',
            textTransform: 'uppercase',
        },
        '.Tab--selected': {
            backgroundColor: '#070a3d',
        },
    },
};

export default { stripePromise, elementsOptions, paymentElementAppearance };
