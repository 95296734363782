import { FC } from 'react';
import { useHistory } from 'react-router';
import appStoreButton from '../../assets/images/app-store-button.svg';
import playStoreButton from '../../assets/images/play-store-button.svg';
import successIcon from '../../assets/images/success.svg';
import { useUser } from '../../business-logic/context-provider/user-context';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import LinkButton from '../../components/link-button/LinkButton';
import guestCartSuccessContent from '../../content/ui/screens/guest-cart-success/guestCartSuccess';
import withContent from '../../hoc/with-content/withContent';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import Routes from '../../utils/Routes';
import parseString from '../../utils/parseString';

import './GuestCartSuccess.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
    accessAccountCTA: 'ui.accessAccountCTA',
    accountCreation: 'ui.accountCreation',
    accessAccountCTADescription: 'ui.accessAccountCTADescription',
    downloadTheAppCta: 'ui.downloadTheAppCta',
    appStoreActionText: 'ui.appStoreActionText',
    appStoreStoreName: 'ui.appStoreStoreName',
    productsCta: 'ui.productsCta',
    playStoreActionText: 'ui.playStoreActionText',
    playStoreStoreName: 'ui.playStoreStoreName',
};

interface GuestCartSuccessProps {
    content: Record<keyof typeof contentMap, string>;
}

const GuestCartSuccess: FC<GuestCartSuccessProps> = ({ content }) => {
    const {
        userDetails: { firstName },
    } = useUser();
    const history = useHistory();

    const renderDownloadAppButton = (imageSrc: string, accessibleText: string) => {
        return (
            <>
                <img src={imageSrc} alt="" />
                <span className="guest-cart-success__download-button-hidden-text">{accessibleText}</span>
            </>
        );
    };

    return (
        <Layout className="guest-cart-success">
            <img className="guest-cart-success__success-icon" src={successIcon} alt="" />
            <h1 className="guest-cart-success__heading">
                {guestCartSuccessContent.formatString(content.heading, { firstName: firstName || '' })}
            </h1>
            <p className="guest-cart-success__description">{content.description}</p>
            <div className="guest-cart-success__acc-create">{parseString(content.accountCreation)}</div>
            <Button
                className="guest-cart-success__access-acc-cta"
                width="full"
                label={content.accessAccountCTA}
                onClick={() => history.push(Routes.LOGIN)}
            />
            <Button
                className="guest-cart-success-access-acc-cta guest-cart-success__product-cta"
                width="full"
                variant="secondary"
                label={content.productsCta}
                onClick={() => {
                    window.location.href = ExternalLinks.products;
                }}
            />
            <h2 className="guest-cart-success__download-app-heading">{content.downloadTheAppCta}</h2>
            <div className="guest-cart-success__download-app-button-row">
                <LinkButton
                    label={renderDownloadAppButton(
                        playStoreButton,
                        `${content.playStoreActionText} ${content.playStoreStoreName}`,
                    )}
                    href={ExternalLinks.mobileAppGooglePlayStore}
                    target="_blank"
                    variant="secondary"
                />
                <LinkButton
                    label={renderDownloadAppButton(
                        appStoreButton,
                        `${content.appStoreActionText} ${content.appStoreStoreName}`,
                    )}
                    href={ExternalLinks.mobileAppAppleAppStore}
                    target="_blank"
                    variant="secondary"
                />
            </div>
        </Layout>
    );
};

export default withContent(GuestCartSuccess, contentMap, guestCartSuccessContent);
