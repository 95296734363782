import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import claimsFlowStrings from '../../../strings/claimsFlow';
import Analytics from '../../../analytics/Analytics';
import { List } from '../../../components/list/List';
import medicalReportIcon from '../../../assets/images/claims/requirement-icons/medical-report.svg';
import witnessDetailsIcon from '../../../assets/images/claims/requirement-icons/witness-details.svg';
import activityProofIcon from '../../../assets/images/claims/requirement-icons/proof-of-activity.svg';
import thirdPartyConsentIcon from '../../../assets/images/claims/requirement-icons/third-party-consent.svg';
import healthConsentIcon from '../../../assets/images/claims/requirement-icons/health-consent.svg';
// import policeReportIcon from '../../../assets/images/claims/requirement-icons/police-report.svg';
import BulletPointList from '../../../components/bullet-point-list/BulletPointList';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';

import './ClaimRequirements.scss';

const ClaimRequirements: React.FC = () => {
    const { claimRequirements: contentStrings } = claimsFlowStrings;

    const history = useHistory();

    const { claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_REQUIREMENTS, claimDetails);
    }, []);

    const renderRequirementList = ({ title, requirements }: { title: string; requirements: string[] }) => (
        <div className="claim-requirements__requirement-list">
            <p>{title}</p>
            <BulletPointList list={requirements} />
        </div>
    );

    const renderDetails = ({ title, description }: { title: string; description: string }) => (
        <>
            <p className="claim-requirements__details-title">{title}</p>
            <p className="claim-requirements__details-description">{description}</p>
        </>
    );

    const mandatoryItems = useMemo(
        () => [
            {
                id: 'medical-report',
                icon: medicalReportIcon,
                data: contentStrings.mandatory.medicalReport.title,
                dataExpanded: (
                    <div>
                        {renderRequirementList(contentStrings.mandatory.medicalReport.reportRequirements)}
                        {renderRequirementList(contentStrings.mandatory.medicalReport.surgeryRequirements)}
                    </div>
                ),
            },
        ],
        [contentStrings.mandatory],
    );

    const speedUpYourClaimItems = useMemo(
        () => [
            {
                id: 'third-party-consent',
                icon: thirdPartyConsentIcon,
                data: contentStrings.speedUpYourClaim.thirdPartyConsent.title,
                dataExpanded: renderDetails(contentStrings.speedUpYourClaim.thirdPartyConsent.details),
            },
            {
                id: 'health-consent',
                icon: healthConsentIcon,
                data: contentStrings.speedUpYourClaim.healthProfessionalConsent.title,
                dataExpanded: renderDetails(contentStrings.speedUpYourClaim.healthProfessionalConsent.details),
            },
        ],
        [contentStrings.speedUpYourClaim],
    );

    const niceToHaveItems = useMemo(
        () => [
            // TODO: Uncomment when police report task is done
            // {
            //     id: 'police-report',
            //     icon: policeReportIcon,
            //     data: contentStrings.niceToHave.policeReport.title,
            //     dataExpanded: renderRequirementList(contentStrings.niceToHave.policeReport.requirements),
            // },
            {
                id: 'witness-details',
                icon: witnessDetailsIcon,
                data: contentStrings.niceToHave.witnessDetails.title,
                dataExpanded: renderRequirementList(contentStrings.niceToHave.witnessDetails.requirements),
            },
            {
                id: 'proof-of-activity',
                icon: activityProofIcon,
                data: contentStrings.niceToHave.activityProof.title,
                dataExpanded: renderRequirementList(contentStrings.niceToHave.activityProof.requirements),
            },
        ],
        [contentStrings.niceToHave],
    );

    const handleAcceptClick = () => {
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_REQUIREMENTS, claimDetails);
        history.push(Routes.CLAIM_AUTOGRAPH);
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_REQUIREMENTS / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="claim-requirements__header">{contentStrings.title}</h1>
            <List
                className="claim-requirements__list"
                innerHeader={contentStrings.mandatory.title}
                items={mandatoryItems}
            />
            <List
                className="claim-requirements__list"
                innerHeader={contentStrings.speedUpYourClaim.title}
                customInnerHeader={
                    <p className="claim-requirement__list-desc">{contentStrings.speedUpYourClaim.description}</p>
                }
                items={speedUpYourClaimItems}
            />
            <List
                className="claim-requirements__list"
                innerHeader={contentStrings.niceToHave.title}
                customInnerHeader={
                    <p className="claim-requirement__list-desc">{contentStrings.niceToHave.description}</p>
                }
                items={niceToHaveItems}
            />
            <p className="claim-requirement__file-requirement">{contentStrings.fileRequirements}</p>
            <Button
                className="claim-requirement__button"
                label={contentStrings.acceptButtonLabel}
                onClick={handleAcceptClick}
            />
        </Layout>
    );
};

export default ClaimRequirements;
