import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import { ReactComponent as WitnessQuestionnaireImage } from '../../../assets/images/claims/photo-of-accident-questionnaire.svg';
import SpeedUpYourClaim from '../../../components/speed-up-your-claim/SpeedUpYourClaim';
import { ClaimUploadExampleModal } from '../../../components/claim-upload-example-modal/ClaimUploadExampleModal';
import ClaimExamples from '../../../utils/constants/ClaimExamples';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';

import './PhotoOfAccidentQuestionnaire.scss';

const PhotoOfAccidentQuestionnaire: React.FC = () => {
    const { photoOfAccidentQuestionnaire: contentStrings } = claimsFlowStrings;

    const history = useHistory();
    const { claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_PHOTO_OF_ACCIDENT_QUESTIONNAIRE, claimDetails);
    }, []);

    const handleAnswerClick = (hasPhotoOfAccident: boolean) => {
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_PHOTO_OF_ACCIDENT_QUESTIONNAIRE, claimDetails);

        history.push(hasPhotoOfAccident ? Routes.PHOTO_OF_ACCIDENT : Routes.PROOF_OF_ACTIVITY_QUESTIONNAIRE);
    };

    return (
        <Layout>
            <ProgressBar
                completed={(Constants.CLAIMS_STEP_PHOTO_OF_ACCIDENT_QUESTIONNAIRE / Constants.CLAIMS_NO_OF_STEPS) * 100}
            />
            <h1 className="photo-of-accident-questionnaire__header">{contentStrings.title}</h1>
            <SpeedUpYourClaim />
            <div className="photo-of-accident-questionnaire__image-wrapper">
                <WitnessQuestionnaireImage />
            </div>
            <p className="photo-of-accident-questionnaire__example-description">{contentStrings.description}</p>
            <ClaimUploadExampleModal claimUploadExamples={ClaimExamples.photoOfAccident} />
            <div className="photo-of-accident-questionnaire__btn-group">
                <Button label={commonStrings.no} onClick={() => handleAnswerClick(false)} />
                <Button label={commonStrings.yes} onClick={() => handleAnswerClick(true)} />
            </div>
        </Layout>
    );
};

export default PhotoOfAccidentQuestionnaire;
