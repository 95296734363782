import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useClaim } from '../../business-logic/context-provider/ClaimContext';
import Routes from '../../utils/Routes';

/**
 * This HOC checks whether there is a valid claims context in the application
 * If there is no valid claims context for a specific route, then will redirect
 * to the beginning of the claims flow.
 *
 */
function requireClaimsContext<T extends JSX.IntrinsicAttributes>(WrappedComponent: React.FC<T>): React.FC<T> {
    return (props: T) => {
        const {
            claimDetails: { currentClaimStarted },
        } = useClaim();
        const history = useHistory();
        useEffect(() => {
            if (!currentClaimStarted) {
                history.push({
                    pathname: Routes.CLAIMS,
                });
            }
        }, [currentClaimStarted, history]);
        return <WrappedComponent {...props} />;
    };
}

export default requireClaimsContext;
