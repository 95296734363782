import Injuries from '../constants/Injuries';

const getClaimType = (injury: string[]): string => {
    // could not include injury if not in BE and claim in draft
    if (injury.length === 0) return 'Injury';
    if (injury.length > 1) return 'Multiple injuries';
    return Injuries[injury[0]]?.label;
};

export default getClaimType;
