import React from 'react';
import Routes from '../../../utils/Routes';
import { Layout } from '../../../components/layout/Layout';
import quizFlow from '../../../strings/quizFlow';
import QuizRadio from '../components/quiz-radio/QuizRadio';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Analytics from '../../../analytics/Analytics';
import useCustomHistory from '../hooks/useCustomHistory';

const ExistingInjury: React.FC = () => {
    const { navigate } = useCustomHistory();
    const { trackOnboardingQuizAnswer } = Analytics;
    const {
        setQuizAnswer,
        onboardingQuizAnswers: { existingInjury },
    } = useUser();

    const onChangeHandler = (value: boolean) => {
        setQuizAnswer({ existingInjury: value });
        trackOnboardingQuizAnswer('existing-injury', value ? 'yes' : 'no');
        setTimeout(() => {
            navigate(Routes.ONBOARDING_QUIZ_WORK);
        }, 300);
    };

    return (
        <Layout
            isContentCentered
            bgPattern={false}
            showPdsVersion={false}
            currentStepCount={1}
            totalStepCount={3}
            title={quizFlow.existingInjury.title}
        >
            <QuizRadio onChange={onChangeHandler} value={existingInjury} />
        </Layout>
    );
};

export default ExistingInjury;
