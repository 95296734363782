import { FC } from 'react';
import { WhatsNotCoveredV2 } from '../../../content/by-product/cover-terms/types';
import notCoveredTabV2 from '../../../content/ui/components/not-covered-tab-v2/notCoveredTabV2';
import withContent from '../../../hoc/with-content/withContent';
import parseString from '../../../utils/parseString';
import stringToReactKey from '../../../utils/stringToReactKey';
import { ListItemProps } from '../../list-item/ListItem';
import List from '../../list/List';
import PdsTmdDisclaimer from '../../pds-tmd-disclaimer/PdsTmdDisclaimer';

import './NotCoveredTabV2.scss';

const contentMap = {
    promise: 'ui.promise',
};

interface NotCoveredTabV2Props {
    content: Record<keyof typeof contentMap, string>;
    whatsNotCovered: WhatsNotCoveredV2;
}

const NotCoveredTabV2: FC<NotCoveredTabV2Props> = ({ content, whatsNotCovered }) => {
    const notCoveredList: ListItemProps[] = whatsNotCovered.exclusions.map((exclusion) => ({
        id: stringToReactKey(exclusion.name),
        icon: exclusion.icon,
        data: exclusion.name,
        dataExpanded: <span className="not-covered-tab-v2__item-data-expanded">{exclusion.description}</span>,
    }));

    return (
        <div>
            <h1>{whatsNotCovered.title}</h1>
            {parseString(whatsNotCovered.description)}
            <List items={notCoveredList} className="not-covered-tab-v2__list" />
            <p>{content.promise}</p>
            <PdsTmdDisclaimer />
        </div>
    );
};

export default withContent(NotCoveredTabV2, contentMap, notCoveredTabV2);
