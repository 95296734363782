import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import BulletPointList from '../../../components/bullet-point-list/BulletPointList';
import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import { TextArea } from '../../../components/text-area/TextArea';
import claimsFlowStrings from '../../../strings/claimsFlow';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import parseString from '../../../utils/parseString';

import './AccidentDescription.scss';

const AccidentDescription: React.FC = () => {
    const { claimDetails, setClaimDetailsByAttr } = useClaim();
    const [description, setDescription] = useState(claimDetails.accidentDescription || '');
    const history = useHistory();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_ACCIDENT_DESCRIPTION, claimDetails);
    }, []);

    const { accidentDescription } = claimsFlowStrings;

    const handleContinueClick = () => {
        // remove leading/trailing whitespace before setting value
        const descriptionTrimmed = description?.trim();

        setClaimDetailsByAttr('accidentDescription', descriptionTrimmed);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_ACCIDENT_DESCRIPTION, claimDetails);

        history.push(Routes.THIRD_PARTY_CONSENT);
    };

    return (
        <Layout>
            <ProgressBar
                completed={(Constants.CLAIMS_STEP_ACCIDENT_DESCRIPTION / Constants.CLAIMS_NO_OF_STEPS) * 100}
            />
            <h1 className="accident-description__header">{accidentDescription.title}</h1>
            <p>{accidentDescription.usefulThingsToIncludeTitle}</p>
            <BulletPointList list={accidentDescription.usefulThingsToInclude} />
            <p>{accidentDescription.usefulDetail}</p>
            <p className="accident-description__example">{parseString(accidentDescription.example)}</p>
            <TextArea
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength={300}
                showCharsRemaining
            />
            <Button
                className="accident-description__btn-continue"
                label="Continue"
                width="full"
                onClick={handleContinueClick}
                disabled={description?.trim().length === 0}
            />
        </Layout>
    );
};

export default AccidentDescription;
