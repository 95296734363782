import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import sub from 'date-fns/sub';
import { Modifier } from 'react-day-picker';
import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import ProductList from '../../../../analytics/ProductList';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import { Policy } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import Cover, { MainCover } from '../../../../utils/constants/Cover';
import CoverInformation from '../../../../utils/constants/CoverInformation';
import Limits from '../../../../utils/constants/Limits';
import getDayPassActiveDates from '../../../../utils/getDayPassActiveDates';
import getLastDayToSchedule from '../../../../utils/getLastDayToSchedule';
import { ScheduleCoverAuthenticatedMachineContextTypes } from '../context/scheduleCoverAuthenticatedMachineContext';
import { Typegen0 } from '../scheduleCoverAuthenticatedMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            policies: Policy[];
            userTimeZone: string;
            productSpecs: Record<MainCover, ProductResponse>;
        };
    }
>({
    policies: (ctx, event) => event.data.policies,
    userTimeZone: (ctx, event) => event.data.userTimeZone,
    productSpecs: (ctx, event) => event.data.productSpecs,
});

export const initialiseCoversAvailable = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['initialiseCoversAvailable'] }
>({
    coversAvailable: (ctx) => {
        let coversAvailable: MainCover[] = [
            Cover.FLIP_ACTIVE_DAILY,
            Cover.FLIP_ACTIVE_WEEKLY,
            Cover.FLIP_ACTIVE_SUB_MONTHLY,
        ];
        if (ctx.enforceOverlappingRules) {
            if (
                ctx.coverFor === 'self' &&
                ctx.policies.filter(
                    (x) =>
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_ACTIVE_DAILY].coverCode &&
                        x.mainCover.status !== 'Canceled' &&
                        x.mainCover.insuredPersonId === null,
                ).length > 0
            ) {
                coversAvailable = [Cover.FLIP_ACTIVE_DAILY];
            }

            if (
                ctx.coverFor === 'self' &&
                ctx.policies.filter(
                    (x) =>
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_ACTIVE_WEEKLY].coverCode &&
                        x.mainCover.status !== 'Canceled' &&
                        x.mainCover.insuredPersonId === null,
                ).length > 0
            ) {
                coversAvailable = [Cover.FLIP_ACTIVE_WEEKLY];
            }
        }
        return coversAvailable;
    },
});

export const initialiseCoversAvailableForDependants = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['initialiseCoversAvailableForDependants'] }
>({
    coversAvailable: (ctx) => {
        let coversAvailable: MainCover[] = [Cover.FLIP_KIDS_DAILY, Cover.FLIP_KIDS_WEEKLY, Cover.FLIP_KIDS_SUB_MONTHLY];
        if (ctx.enforceOverlappingRules) {
            if (
                ctx.coverFor === 'dependant' &&
                ctx.policies.filter(
                    (x) =>
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_KIDS_DAILY].coverCode &&
                        x.mainCover.status !== 'Canceled' &&
                        x.mainCover.insuredPersonId === ctx.dependantToCover,
                ).length > 0
            ) {
                coversAvailable = [Cover.FLIP_KIDS_DAILY];
            }

            if (
                ctx.coverFor === 'dependant' &&
                ctx.policies.filter(
                    (x) =>
                        x.mainCover.coverCode === CoverInformation[Cover.FLIP_KIDS_WEEKLY].coverCode &&
                        x.mainCover.status !== 'Canceled' &&
                        x.mainCover.insuredPersonId === ctx.dependantToCover,
                ).length > 0
            ) {
                coversAvailable = [Cover.FLIP_KIDS_WEEKLY];
            }
        }
        return coversAvailable;
    },
});

export const initialiseFixedPaymentModelSelectedCover = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['initialiseFixedPaymentModelSelectedCover'] }
>({
    selectedCover: (ctx) => {
        return ctx.coversAvailable[0];
    },
});

export const initialiseFixedPaymentModelDatePickerMode = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['initialiseFixedPaymentModelDatePickerMode'] }
>({
    datePickerMode: (ctx) => {
        if (
            ctx.coversAvailable[0] === Cover.FLIP_ACTIVE_SUB_MONTHLY ||
            ctx.coversAvailable[0] === Cover.FLIP_KIDS_SUB_MONTHLY
        ) {
            return 'single';
        }
        if (ctx.coversAvailable[0] === Cover.FLIP_ACTIVE_DAILY || ctx.coversAvailable[0] === Cover.FLIP_KIDS_DAILY) {
            return 'multiple';
        }
        if (ctx.coversAvailable[0] === Cover.FLIP_ACTIVE_WEEKLY || ctx.coversAvailable[0] === Cover.FLIP_KIDS_WEEKLY) {
            return 'week';
        }
        return 'single';
    },
});

export const setSelectedCover = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['setSelectedCover']; data: MainCover }
>({
    selectedCover: (ctx, event) => event.data,
});

export const setDatePickerMode = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['setDatePickerMode']; data: MainCover }
>({
    datePickerMode: (ctx, event) => {
        if (event.data === Cover.FLIP_ACTIVE_SUB_MONTHLY || event.data === Cover.FLIP_KIDS_SUB_MONTHLY) {
            return 'single';
        }
        if (event.data === Cover.FLIP_ACTIVE_DAILY || event.data === Cover.FLIP_KIDS_DAILY) {
            return 'multiple';
        }
        if (event.data === Cover.FLIP_ACTIVE_WEEKLY || event.data === Cover.FLIP_KIDS_WEEKLY) {
            return 'week';
        }
        return 'single';
    },
});

export const setDaysToSchedule = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['setDaysToSchedule']; data: Date[] }
>({
    daysToSchedule: (ctx, event) => event.data,
});

export const resetDaysToSchedule = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['resetDaysToSchedule']; data: MainCover }
>({
    daysToSchedule: () => [],
});

export const evaluateDisabledDays = assign<
    ScheduleCoverAuthenticatedMachineContextTypes,
    { type: EventsCausingActions['evaluateDisabledDays']; data: MainCover } | { type: '' }
>({
    disabledDays: (ctx) => {
        const zonedToday = utcToZonedTime(new Date(), ctx.userTimeZone);
        const lastDayToGetCover = getLastDayToSchedule(
            zonedToday,
            ctx.selectedCover
                ? ctx.productSpecs![ctx.selectedCover as MainCover].productSpec.mainCoverType.schedule
                      .scheduleLimitInHours
                : Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS,
        );

        const weekPassToDisabledModifier = (weekPass: Policy) => ({
            // Disable week pass dates as well as the 6 days before it
            from: sub(utcToZonedTime(fromUnixTime(weekPass.mainCover.activeFrom), ctx.userTimeZone), {
                days: 6,
            }),
            to: utcToZonedTime(fromUnixTime(weekPass.mainCover.activeTo), ctx.userTimeZone),
        });

        const dayCovers = ctx.policies.filter(
            (x) =>
                x.mainCover.status !== 'Canceled' &&
                (ctx.coverFor === 'dependant'
                    ? x.mainCover.insuredPersonId === ctx.dependantToCover
                    : x.mainCover.insuredPersonId === null),
        );

        const weeklyCovers = ctx.policies.filter(
            (x) =>
                x.mainCover.status !== 'Canceled' &&
                (ctx.coverFor === 'dependant'
                    ? x.mainCover.insuredPersonId === ctx.dependantToCover
                    : x.mainCover.insuredPersonId === null),
        );

        const dates: Modifier[] = [
            {
                before: zonedToday,
                after: lastDayToGetCover,
            },
            ...(ctx.enforceOverlappingRules ? getDayPassActiveDates([...dayCovers], ctx.userTimeZone) : []),
            ...(ctx.enforceOverlappingRules ? weeklyCovers.map(weekPassToDisabledModifier) : []),
        ];

        return dates;
    },
});

export const trackFlipActiveProductListViewed = (ctx: ScheduleCoverAuthenticatedMachineContextTypes): void => {
    Analytics.trackProductListViewed(
        'Flip Active',
        ctx.coversAvailable.map((c) => ProductList[c]),
    );
};

export const trackFlipKidsProductListViewed = (ctx: ScheduleCoverAuthenticatedMachineContextTypes): void => {
    Analytics.trackProductListViewed(
        'Flip Kids',
        ctx.coversAvailable.map((c) => ProductList[c]),
    );
};
