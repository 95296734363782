import LocalizedStrings from 'react-localization';

const scheduleCoverBaymaxContent = new LocalizedStrings({
    en: {
        paymentOptionLegend: 'Chose your payment option',
        heading: 'Select Cover',
        selectCoverDates: 'Select your cover dates',
        benefitsCTA: "Check what we pay out for injuries and the injuries we don't cover",
        injuriesCoveredTab: 'Injuries covered',
        injuriesNotCoveredTab: 'Injuries not covered',
        continueCTA: 'Continue to checkout',
    },
});

export default scheduleCoverBaymaxContent;
