import LocalizedStrings from 'react-localization';

const coveredTabV1 = new LocalizedStrings({
    en: {
        heading: 'What you get',
        includesHeading: 'This includes:',
        includesPermanentLossOfHeading: 'This includes permanent loss of:',
        additionalNotesHeading: 'Things to know:',
        more: 'more',
        less: 'less',
    },
});

export default coveredTabV1;
