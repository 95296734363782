enum GuestCartStep {
    PROFILE,
    KIDS_DETAILS,
    REVIEW_COVER,
    PAYMENT,
}

export const GuestCartStepsWithoutKids = [GuestCartStep.PROFILE, GuestCartStep.REVIEW_COVER, GuestCartStep.PAYMENT];

export const GuestCartStepsWithKids = [
    GuestCartStep.PROFILE,
    GuestCartStep.KIDS_DETAILS,
    GuestCartStep.REVIEW_COVER,
    GuestCartStep.PAYMENT,
];

export default GuestCartStep;
