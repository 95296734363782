import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import { ReactComponent as WitnessQuestionnaireImage } from '../../../assets/images/claims/witness-questionnaire.svg';
import { ReactComponent as WitnessQuestionnaireGround } from '../../../assets/images/claims/witness-questionnaire-ground.svg';
import SpeedUpYourClaim from '../../../components/speed-up-your-claim/SpeedUpYourClaim';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';

import './WitnessQuestionnaire.scss';

const WitnessQuestionnaire: React.FC = () => {
    const { witnessQuestionnaire: contentStrings } = claimsFlowStrings;

    const history = useHistory();
    const { claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_WITNESS_QUESTIONNAIRE, claimDetails);
    }, []);

    const handleAnswerClick = (hasWitness: boolean) => {
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_WITNESS_QUESTIONNAIRE, claimDetails);

        history.push(hasWitness ? Routes.WITNESS_INFO : Routes.PHOTO_OF_ACCIDENT_QUESTIONNAIRE);
    };

    return (
        <Layout>
            <ProgressBar
                completed={(Constants.CLAIMS_STEP_WITNESS_QUESTIONNAIRE / Constants.CLAIMS_NO_OF_STEPS) * 100}
            />
            <h1 className="witness-questionnaire__header">{contentStrings.title}</h1>
            <SpeedUpYourClaim />
            <div className="witness-questionnaire__image-wrapper">
                <WitnessQuestionnaireImage />
                <WitnessQuestionnaireGround className="witness-questionnaire__image-ground" />
            </div>
            <p className="witness-questionnaire__example-title">{contentStrings.description}</p>
            <div className="witness-questionnaire__btn-group">
                <Button label={commonStrings.no} onClick={() => handleAnswerClick(false)} />
                <Button label={commonStrings.yes} onClick={() => handleAnswerClick(true)} />
            </div>
        </Layout>
    );
};

export default WitnessQuestionnaire;
