import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import WitnessFormFields, { WitnessFormFieldError } from './witness-form-fields/WitnessFormFields';
import isValidEmail from '../../../validation/isValidEmail';

import './WitnessInfo.scss';

const WitnessInfo: React.FC = () => {
    const { witnessInfo: contentStrings } = claimsFlowStrings;

    const history = useHistory();
    const { claimDetails, setClaimDetailsByAttr } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_WITNESS_INFORMATION, claimDetails);
    }, []);

    // witnesses
    const [witnesses, setWitnesses] = useState(
        claimDetails.witnesses.length ? claimDetails.witnesses : [{ name: '', phone: '', email: '' }],
    );
    // errors
    const [errors, setErrors] = useState<Array<WitnessFormFieldError>>(
        new Array(witnesses.length).fill({ email: '', phone: '' }),
    );
    // permission checkbox checked state
    const [hasPermission, setHasPermission] = useState(claimDetails.hasWitnessesPermission);

    /**
     * Updates the error state with new error state
     *
     * @param index index of error to update
     * @param newError new errors
     */
    const updateError = (index: number, newError: WitnessFormFieldError) => {
        setErrors((prevState) => {
            return prevState.map((e, i) => {
                if (index === i) {
                    return newError;
                }
                return e;
            });
        });
    };

    /**
     * This function validates the email and phone number of a witness.
     * Called on email and phone input blur events
     *
     * @param index which witness to validate
     */
    const handleEmailPhoneInputBlur = (index: number) => {
        const witness = witnesses[index];

        // If name is filled in but either email or phone is not filled in after blur
        // prompt user to let them know email or phone is needed
        if (witness.name && !witness.email && !witness.phone) {
            updateError(index, {
                email: contentStrings.errors.eitherPhoneOrEmail,
                phone: contentStrings.errors.eitherPhoneOrEmail,
            });
            return;
        }

        const error = { email: '', phone: '' };

        if (witness.email && !isValidEmail(witness.email)) {
            error.email = contentStrings.errors.invalidEmail;
        }

        updateError(index, error);
    };

    /**
     * This function removes existing errors on the witness fields if the name
     * input was cleared and the email and phone inputs are empty.
     * This is mainly to clean up errors for potentially unneeded witnesses.
     *
     * @param index which witness to validate
     */
    const handleNameInputBlur = (index: number) => {
        const witness = witnesses[index];

        if (!witness.name && !witness.email && !witness.phone) {
            updateError(index, { email: '', phone: '' });
        }
    };

    /**
     * Updates the witness state with the new input values
     *
     * @param index index of witness to update
     * @param key name, email or phone
     * @param value new value
     */
    const handleChange = (index: number, key: string, value: string) => {
        setWitnesses((prevState) => {
            return prevState.map((witness, i) => {
                if (index === i) {
                    return { ...witness, [key]: value };
                }
                return witness;
            });
        });
    };

    /**
     * Handler for the add witness button
     */
    const handleAddWitness = () => {
        setWitnesses((prevState) => [...prevState, { name: '', phone: '', email: '' }]);
        setErrors((prevState) => [...prevState, { phone: '', email: '' }]);
    };

    /**
     * Handler for the continue button
     */
    const handleContinue = () => {
        setClaimDetailsByAttr(
            'witnesses',
            // filter out witnesses whose names are not filled in
            // ie if the user clicked on add witness but decides not to add another witness
            witnesses.filter((witness) => !!witness.name),
        );
        setClaimDetailsByAttr('hasWitnessesPermission', hasPermission);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_WITNESS_INFORMATION, claimDetails);

        history.push(Routes.PHOTO_OF_ACCIDENT_QUESTIONNAIRE);
    };

    /**
     * Used to set the continue button disabled state. Checks 2 things
     * 1. If the permission checkbox is checked
     * 2. if the witnesses that are filled in have either a valid email or phone
     */
    const canProceed = useMemo(() => {
        if (!hasPermission) {
            // no proceed if no permission
            return false;
        }

        return witnesses.reduce((acc, cur) => {
            if (cur.name) {
                if (isValidEmail(cur.email) || !!cur.phone) {
                    return acc || true;
                }
                return acc && false;
            }
            return acc && true;
        }, false);
    }, [witnesses, hasPermission]);

    /**
     * Used to set the add witness button disabled state. Checks if all the witness
     * fields currently presented to the user have been filled in with valid info
     * before allowing the user to add another witness.
     */
    const hasEmptyWitnessField = useMemo(() => {
        return !!witnesses.find((witness) => !(witness.name && (isValidEmail(witness.email) || !!witness.phone)));
    }, [witnesses]);

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_WITNESS_INFORMATION / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1>{contentStrings.title}</h1>
            <p>{contentStrings.description}</p>
            <form>
                {witnesses.map((witness, index) => (
                    // no sorting or filtering of witnesses so ok to use index in key
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`witness-${index}`}>
                        <WitnessFormFields
                            witnessInfo={witness}
                            index={index}
                            errors={errors[index]}
                            onNameBlur={() => handleNameInputBlur(index)}
                            onEmailBlur={() => handleEmailPhoneInputBlur(index)}
                            onPhoneBlur={() => handleEmailPhoneInputBlur(index)}
                            onChange={(key, value) => handleChange(index, key, value)}
                        />
                        {index < witnesses.length - 1 ? <div className="witness-info__separator" /> : null}
                    </div>
                ))}
                <Button
                    label={contentStrings.labels.addAnotherWitness}
                    onClick={handleAddWitness}
                    width="full"
                    variant="secondary"
                    className="witness-info__add-witness-button"
                    disabled={hasEmptyWitnessField}
                />
                <Checkbox
                    id="witness-info__permission-checkbox"
                    name="Witness permission checkbox"
                    label={contentStrings.permission}
                    checked={hasPermission}
                    onChange={(e) => setHasPermission(e.target.checked)}
                    className="witness-info__permission-checkbox"
                />
                <Button
                    label={commonStrings.continue}
                    onClick={() => handleContinue()}
                    width="full"
                    disabled={!canProceed}
                />
            </form>
        </Layout>
    );
};

export default WitnessInfo;
