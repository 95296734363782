import { FC } from 'react';
import success from '../../../assets/images/success.svg';
import { WhatsCoveredV1 } from '../../../content/by-product/cover-terms/types';
import coveredTabV1 from '../../../content/ui/components/covered-tab-v1/coveredTabV1';
import withContent from '../../../hoc/with-content/withContent';
import parseString from '../../../utils/parseString';
import stringToReactKey from '../../../utils/stringToReactKey';
import Card from '../../card/Card';
import Disclosure from '../../disclosure/Disclosure';

import './CoveredTabV1.scss';

const contentMap = {
    heading: 'ui.heading',
    costHeading: 'ui.costHeading',
    includesHeading: 'ui.includesHeading',
    more: 'ui.more',
    less: 'ui.less',
    includesPermanentLossOfHeading: 'ui.includesPermanentLossOfHeading',
    additionalNotesHeading: 'ui.additionalNotesHeading',
};

export interface CoveredTabV1Props {
    content: Record<keyof typeof contentMap, string>;
    whatsCovered: WhatsCoveredV1;
}

const CoveredTabV1: FC<CoveredTabV1Props> = ({ content, whatsCovered }) => {
    const renderString = (string: string) => {
        return <p key={stringToReactKey(string)}>{parseString(string)}</p>;
    };

    return (
        <div className="cover-tab-v1">
            {whatsCovered.coverTiers.map((cover) => {
                return (
                    <Card key={stringToReactKey(cover.title)} className="cover-tab-v1__card">
                        <img src={success} alt="success" />
                        <p className="cover-tab-v1__heading">{cover.title}</p>
                        <div className="cover-tab-v1__description">
                            {cover.descriptionLines.map((line) => {
                                return renderString(line);
                            })}
                        </div>
                        <Disclosure
                            className="cover-tab-v1__details"
                            moreLabel={content.more}
                            lessLabel={content.less}
                            summary={
                                <p>
                                    {cover.payoutAmount} <span>Cash</span>
                                </p>
                            }
                        >
                            <div>
                                <p>{cover.conditionsDescription}</p>
                                <ul className="covered-tab__covered-items">
                                    {cover.includedConditions.map(({ description, items }) => (
                                        <div key={stringToReactKey(description)}>
                                            <li>{description}</li>
                                            {items && items.length > 0 && (
                                                <ul>
                                                    {items.map((item) => (
                                                        <li key={stringToReactKey(item)}>{item}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </ul>
                                {cover.disclaimer && <p className="covered_tab__disclaimer">{cover.disclaimer}</p>}
                            </div>
                        </Disclosure>
                    </Card>
                );
            })}
        </div>
    );
};

export default withContent(CoveredTabV1, contentMap, coveredTabV1);
