import { createMachine } from 'xstate';
import { Destination } from '../../../business-logic/models/RoamingDestinations';
import { setAllDestinations, setChosenDestinations } from '../actions/roamingDestinationsMachineActions';
import { RoamingDestinationsMachineContextTypes } from '../context/roamingDestinationsMachineContext';
import { hasChosenMultipleDestinations, hasMultipleRegions } from '../guards/roamingDestinationsMachineGuards';

const RoamingDestinationsMachine = createMachine(
    {
        id: 'RoamingDestinationsMachine',
        initial: 'initialise',
        schema: {
            context: {} as RoamingDestinationsMachineContextTypes,
            events: {} as
                | { type: 'SELECTED_DESTINATIONS'; data: string[] }
                | { type: 'CONTINUE' }
                | { type: 'LOAD_DESTINATIONS'; data: Destination[] },
        },
        tsTypes: {} as import('./roamingDestinationsMachine.typegen').Typegen0,
        context: {
            availableDestinations: [],
            chosenDestinations: [],
        },
        preserveActionOrder: true,
        states: {
            initialise: {
                tags: ['loading'],
                on: {
                    LOAD_DESTINATIONS: {
                        actions: 'setAllDestinations',
                        target: 'idle',
                    },
                },
            },
            idle: {
                on: {
                    SELECTED_DESTINATIONS: {
                        actions: 'setChosenDestinations',
                    },
                    CONTINUE: {
                        target: 'determineHowManyDestinations',
                    },
                },
            },
            determineHowManyDestinations: {
                tags: ['loading'],
                always: [
                    {
                        cond: 'hasChosenMultipleDestinations',
                        target: 'chooseStartingDestination',
                    },
                    {
                        target: 'determineHowManyRegions',
                    },
                ],
            },
            determineHowManyRegions: {
                tags: ['loading'],
                always: [
                    {
                        cond: 'hasMultipleRegions',
                        target: 'chooseStartingRegion',
                    },
                    {
                        target: 'selectRoamingDates',
                    },
                ],
            },
            selectRoamingDates: {
                entry: 'redirectToSelectDates',
                tags: ['loading'],
                type: 'final',
            },
            chooseStartingRegion: {
                entry: 'redirectToChooseStartingRegion',
                tags: ['loading'],
                type: 'final',
            },
            chooseStartingDestination: {
                entry: 'redirectToChooseStartingDestination',
                tags: ['loading'],
                type: 'final',
            },
        },
    },
    {
        actions: {
            setChosenDestinations,
            setAllDestinations,
        },
        guards: {
            hasChosenMultipleDestinations,
            hasMultipleRegions,
        },
    },
);

export default RoamingDestinationsMachine;
