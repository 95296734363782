import { useOktaAuth } from '@okta/okta-react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import Analytics from '../../analytics/Analytics';
import PageEvents from '../../analytics/PageEvents';
import { BaymaxProductProvider } from '../../business-logic/context-provider/BaymaxProductContext';
import { OpenCoversProvider } from '../../business-logic/context-provider/OpenCoversContext';
import { ProductProvider } from '../../business-logic/context-provider/ProductContext';
import { ReferralProvider } from '../../business-logic/context-provider/ReferralContext';
import { RoamingProvider } from '../../business-logic/context-provider/RoamingContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import ToastProvider from '../../components/toast/ToastProvider';
import ZendeskProvider from '../../components/zendesk/Zendesk';
import Routes, { Route } from '../../utils/Routes';

const LAUNCH_DARKLY_CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '';

/**
 * Adds LaunchDarkly provider and Cover Provider
 *
 * @param param0
 * @returns
 */
const Providers: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDetails } = useUser();
    const { authState } = useOktaAuth();
    const location = useLocation();

    useEffect(() => {
        const pageEvent = PageEvents[location.pathname as Route];

        const waitForAuthStateToResolveBeforeTracking =
            location.pathname === Routes.REGISTER ||
            location.pathname === Routes.LOGIN ||
            location.pathname === Routes.LANDING;

        if (pageEvent) {
            // For these pages we want to wait for auth state to be resolved before tracking
            if (waitForAuthStateToResolveBeforeTracking) {
                // If auth state is resolved
                if (authState !== null) {
                    // Only call identify if not authenticated
                    // If authenticated, will auto redirect to dashboard
                    // which will then call authenticated tacking from there
                    // this prevents additional tracking that is caused by redirects
                    if (!authState.isAuthenticated) {
                        Analytics.identify();
                        Analytics.trackScreen(pageEvent);
                    }
                }
            } else {
                Analytics.identify();
                Analytics.trackScreen(pageEvent);
            }
        }
    }, [authState, location]);

    const AllProviders = (props: any) => (
        <ZendeskProvider>
            <ToastProvider>
                <ReferralProvider>
                    <OpenCoversProvider>
                        <RoamingProvider>
                            <BaymaxProductProvider>
                                <ProductProvider {...props} />
                            </BaymaxProductProvider>
                        </RoamingProvider>
                    </OpenCoversProvider>
                </ReferralProvider>
            </ToastProvider>
        </ZendeskProvider>
    );

    const AllProvidersWithFlags = useMemo(
        () =>
            withLDProvider({
                clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
                context: userDetails.id ? { kind: 'user', key: userDetails.id } : undefined,
            })(AllProviders),
        [userDetails.id],
    );

    return <AllProvidersWithFlags>{children}</AllProvidersWithFlags>;
};

export default Providers;
