import React, { useState, useRef, useEffect } from 'react';
import { DayModifiers, Modifier, Modifiers } from 'react-day-picker/types/Modifiers';
import classNames from 'classnames';
import { Button } from '../button/Button';
import { DatePicker } from '../date-picker/DatePicker';
import common from '../../strings/common';
import './DatePickerButton.scss';

export interface DatePickerButtonProps {
    label: string | React.ReactNode;
    title?: string;
    selectedDays?: Modifier | Modifier[];
    disabledDays?: Modifier | Modifier[];
    fromMonth?: Date;
    toMonth?: Date;
    modifiers?: Partial<Modifiers>;
    disabled?: boolean;
    isSelectDaysButtonDisabled?: boolean;
    innerDatePickerError?: string;
    onButtonClick?: () => void;
    onDayClick: (day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement>) => void;
    onSelectClick?: () => void;
    onOutsideDatePickerClick?: () => void;
    className?: string;
}

const DatePickerButton: React.FC<DatePickerButtonProps> = ({
    title = common.date,
    label,
    selectedDays,
    disabledDays,
    fromMonth,
    toMonth,
    modifiers,
    disabled,
    isSelectDaysButtonDisabled,
    innerDatePickerError,
    onButtonClick,
    onDayClick,
    onSelectClick,
    onOutsideDatePickerClick,
    className,
}) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef<any>(null);

    const handleButtonClick = () => {
        if (onButtonClick) onButtonClick();
        setShowDatePicker(true);
    };

    const handleSelectClick = () => {
        if (onSelectClick) onSelectClick();
        setShowDatePicker(false);
    };

    const handleOutsideDatePickerClick = (event: any) => {
        const datePicker = datePickerRef?.current;

        if (datePicker && !datePicker.contains(event.target)) {
            if (onOutsideDatePickerClick) onOutsideDatePickerClick();
            setShowDatePicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideDatePickerClick, true);
        return () => document.removeEventListener('click', handleOutsideDatePickerClick, true);
    }, [datePickerRef]);

    return (
        <div className={classNames('date-picker-button', showDatePicker && 'date-picker-button--show', className)}>
            <label className={classNames('date-picker-button__label', disabled && 'disabled')} htmlFor="date-picker">
                {title}
            </label>
            <Button
                variant="secondary"
                className="date-picker-button__main"
                label={label}
                width="full"
                disabled={disabled}
                onClick={handleButtonClick}
            />
            <DatePicker
                id="date-picker"
                innerRef={datePickerRef}
                className="date-picker-button__picker"
                selectedDays={selectedDays}
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={disabledDays}
                modifiers={modifiers}
                onDayClick={onDayClick}
                onSelectClick={handleSelectClick}
                isSelectDaysButtonDisabled={isSelectDaysButtonDisabled}
                error={innerDatePickerError}
            />
        </div>
    );
};

export default DatePickerButton;
