import UserDetails from '../../../../../../business-logic/models/UserDetails';
import PersonService from '../../../../../../services/person-service/PersonService';
import formatDateToString from '../../../../../../utils/formatDateToString';
import { KidsDetailsMachineContext } from '../context/kidsDetailsMachineContext';

export type KidsDetailsMachineServices = {
    createGuestDependants: {
        data: { dependants: UserDetails[] };
    };
};

export const createGuestDependants = async (ctx: KidsDetailsMachineContext): Promise<{ dependants: UserDetails[] }> => {
    const dependantsProfiles = ctx.kidDetailsFormMachines.map((k) => {
        const { firstName, lastName, dob, gender } = k.getSnapshot()!.context;
        return {
            firstName,
            lastName,
            dateOfBirth: formatDateToString(new Date(`${dob.year}/${dob.month}/${dob.day}`)),
            gender: gender!,
        };
    });

    const response = await PersonService.createGuestDependants({
        accessToken: ctx.accessToken!,
        dependants: dependantsProfiles,
    });

    return response;
};
