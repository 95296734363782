import LocalizedStrings from 'react-localization';

const scheduleCoverContent = new LocalizedStrings({
    en: {
        heading: 'Select your dates',
        paymentOptionLegend: 'Choose a payment option',
        selectCoverDates: 'Select your cover dates',
        benefitsCTA: "Check what we payout for injuries and the injuries we don't cover",
        injuriesCoveredTab: 'Injuries covered',
        injuriesNotCoveredTab: 'Injuries not covered',
        continueCTA: 'Continue to payment',
        yourPaymentOption: 'Your payment option',
        cancelYourPaymentOption:
            'To change your payment option, <strong>cancel any scheduled cover</strong> or wait for any active cover to end.',
    },
});

export default scheduleCoverContent;
