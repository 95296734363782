import LocalizedStrings from 'react-localization';

const manageCoverSingleCoverCardContent = new LocalizedStrings({
    en: {
        ending: 'Ending {endDate}',
        starting: 'Starting {startDate}',
        region: '{region}',
        benefitsCTA: 'View benefits and cover details',
        injuriesCoveredTab: 'Injuries covered',
        injuriesNotCoveredTab: 'Injuries not covered',
    },
});

export default manageCoverSingleCoverCardContent;
