import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import Button from '../../../components/button/Button';
import ExternalLink from '../../../components/external-link/ExternalLink';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import Layout from '../../../components/layout/Layout';
import claimsFlow from '../../../strings/claimsFlow';
import common from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import ExternalLinks from '../../../utils/constants/ExternalLinks';
import stringToReactKey from '../../../utils/stringToReactKey';

import './ClaimsChecklist.scss';

const ClaimsChecklist: FC = () => {
    const { claimsChecklist: contentStrings } = claimsFlow;

    const history = useHistory();
    const [checked, setChecked] = useState<Record<string, boolean>>(
        contentStrings.checklist.reduce((acc, cur) => ({ ...acc, [stringToReactKey(cur.label)]: false }), {}),
    );

    const { claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_CHECKLIST, claimDetails);
    }, []);

    return (
        <Layout
            title={contentStrings.title}
            description={contentStrings.description}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_CHECKLIST / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            <form
                onSubmit={() => {
                    Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_CHECKLIST, claimDetails);
                    history.push(Routes.CLAIM_REQUIREMENTS);
                }}
            >
                {contentStrings.checklist.map((item) => {
                    const id = stringToReactKey(item.label);
                    return (
                        <Checkbox
                            id={id}
                            key={id}
                            name={item.label}
                            label={
                                <div>
                                    {item.label}
                                    {item.subLabel && (
                                        <span className="claims-checklist__checklist-sub-label">{item.subLabel}</span>
                                    )}
                                </div>
                            }
                            checked={!!checked[id]}
                            onChange={(e) => setChecked((state) => ({ ...state, [id]: e.target.checked }))}
                            className="claims-checklist__checklist"
                        />
                    );
                })}
                <div className="claims-checklist__separator" />
                <h3>{contentStrings.considerationTitle}</h3>
                <ul className="claims-checklist__considerations">
                    <li>
                        {claimsFlow.formatString(contentStrings.considerationsWhatsCovered, {
                            pds: (
                                <ExternalLink
                                    label={common.productDisclosureStatementShort}
                                    to={ExternalLinks.productDisclosureStatement}
                                    onClick={Analytics.trackPDSLinkClicked}
                                    className="claims-checklist__link"
                                />
                            ),
                        })}
                    </li>
                    <li>{contentStrings.considerationKid}</li>
                    <li>
                        {claimsFlow.formatString(contentStrings.considerationChatWithUs, {
                            chatWithUs: (
                                <ExternalLink
                                    label={contentStrings.considerationsChatWithUsLabel}
                                    to={`mailto:${common.flipSupportEmail}`}
                                    className="claims-checklist__link"
                                />
                            ),
                        })}
                    </li>
                </ul>
                <Button
                    width="full"
                    label={contentStrings.letsGetStarted}
                    disabled={!Object.values(checked).reduce((acc, cur) => acc && cur, true)}
                    type="submit"
                />
            </form>
        </Layout>
    );
};

export default ClaimsChecklist;
