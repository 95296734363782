import { useMachine } from '@xstate/react';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import Analytics from '../../analytics/Analytics';
import ProductList from '../../analytics/ProductList';
import stripeLogo from '../../assets/images/stripe-logo.svg';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { CartCheckout, CartErrorDisplay } from '../../business-logic/models/Cart';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import CartError from '../../components/cart-error/CartError';
import ExternalLink from '../../components/external-link/ExternalLink';
import { Layout } from '../../components/layout/Layout';
import CreditBanner from '../../components/layout/banners/credit-banner/CreditBanner';
import { LoadingButtonProps } from '../../components/loading-button/LoadingButton';
import { LoadingSpinnerOverlay } from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import PaymentMethods from '../../components/payment-methods/PaymentMethods';
import TextFieldWithButton from '../../components/text-field-with-button/TextFieldWithButton';
import requireFlags from '../../hoc/require-flags/requireFlags';
import useCart from '../../hooks/use-cart/useCart';
import useOnboarding from '../../hooks/useOnboarding';
import commonStrings from '../../strings/common';
import paymentsStrings from '../../strings/payments';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import formatDateToString from '../../utils/formatDateToString';
import checkoutMachine from './checkout-machine/checkoutMachine';
import CheckoutSummary from './checkout-summary/CheckoutSummary';

import './Checkout.scss';

const Checkout: React.FC = () => {
    const history = useHistory();

    const { accessToken, userTimeZone, creditBalance } = useUser();

    const { openCoverSelections } = useOpenCovers();

    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const { closeCart } = useCart();

    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();

    const [state, send] = useMachine(checkoutMachine, {
        context: {
            accessToken,
            coverSelections: openCoverSelections,
            userTimeZone,
            fetchCreditBalance: creditBalance.fetch,
            isOnboardingFlow,
            selectedPaymentMethod: 'card',
        },
        actions: {
            closeCart: () => {
                closeCart.mutate(undefined, {
                    onError: (err) => {
                        send({
                            type: 'CART_CLOSE_ERROR',
                            data: (err as Error).cause as CartErrorDisplay,
                        });
                    },
                    onSuccess: (data) => {
                        send({ type: 'CART_CLOSE', data: data as CartCheckout });
                    },
                });
            },
            goBack: () => {
                history.goBack();
            },
            redirectToSuccessPage: (ctx) => {
                Analytics.trackCheckoutStepViewed(1, 'Purchase now');
                Analytics.trackCheckoutCompleted();

                let nextPath: string = Routes.SCHEDULE_ALWAYS_ON_SUCCESS;

                if (ctx.coverSelections.some((c) => c.selectedCover === Cover.BOOST)) {
                    nextPath = Routes.SCHEDULE_BOOST_SUCCESS;
                }

                history.push({
                    pathname: nextPath,
                    state: {
                        isOnboarding: isOnboardingFlow,
                    },
                });
            },
        },
    });

    const loadingCheckout = useMemo(() => {
        return (
            state.matches('ready.checkout.showCheckoutSuccess') ||
            state.matches('ready.checkout.successRedirect') ||
            state.matches('ready.checkout.initiateCheckout')
        );
    }, [state]);

    const applyDiscountCodeStatus: LoadingButtonProps['status'] = useMemo(() => {
        if (state.matches('ready.discountCode.displayDiscountSuccess')) {
            return 'success';
        }
        if (state.matches('ready.discountCode.applyDiscount')) {
            return 'loading';
        }
        return 'idle';
    }, [state]);

    const cartError = closeCart.isError ? (closeCart.error as Error).cause : null;

    const renderDiscountCodeSection = () => (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                send('APPLY_DISCOUNT_CODE');
            }}
        >
            <div className="checkout__discount-code-wrapper">
                <div className="checkout__discount-code">
                    <TextFieldWithButton
                        textFieldProps={{
                            id: 'discountCode',
                            placeholder: paymentsStrings.discountCode,
                            name: 'discountCode',
                            className: 'checkout__discount-code--input',
                            value: state.context.discountCode,
                            onChange: (e: ChangeEvent<HTMLInputElement>) =>
                                send('ENTER_DISCOUNT_CODE', { data: e.target.value }),
                            isError: state.matches('ready.discountCode.displayDiscountError'),
                            disabled:
                                state.matches('ready.discountCode.applyDiscount') ||
                                state.matches('ready.discountCode.displayDiscountSuccess'),
                        }}
                        buttonProps={{
                            status: applyDiscountCodeStatus,
                            label: commonStrings.add,
                            onClick: (e) => {
                                e.preventDefault();
                                send('APPLY_DISCOUNT_CODE');
                            },
                            disabled:
                                !state.can('APPLY_DISCOUNT_CODE') ||
                                state.matches('ready.discountCode.displayDiscountError'),
                        }}
                    />
                </div>
                {state.matches('ready.discountCode.displayDiscountError') && (
                    <Alert
                        type={AlertTypes.ERROR}
                        message={
                            <span className="checkout__discount-code-error-message">
                                {state.context.discountErrorInfo}
                            </span>
                        }
                        className="checkout__discount-code-error"
                    />
                )}
            </div>
        </form>
    );

    useEffect(() => {
        Analytics.trackCheckoutStarted({
            products: openCoverSelections.map((c) => ({
                ...ProductList[c.selectedCover],
                quantity: 1,
                variant: formatDateToString(c.coverStartDate, DateFormat.ANALYTICS),
            })),
        });
        Analytics.trackCheckoutStepViewed(1, 'Purchase now');
    }, []);

    if (state.matches('redirectToDashboard')) {
        return <Redirect to={Routes.HOME} />;
    }

    return (
        <Layout
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            title={paymentsStrings.title}
            banner={<CreditBanner />}
            showBackButton={!isOnboardingFlow}
            disableBackButton={loadingCheckout}
        >
            {(state.matches('displayErrorPage') || (!!cartError === false && state.matches('displayCartError'))) && (
                <Alert type={AlertTypes.ERROR} size={AlertSizes.LARGE} />
            )}

            {!!cartError && state.matches('displayCartError') && (
                <CartError
                    cartError={cartError as CartErrorDisplay}
                    tryAgainEvent={() => send({ type: 'CART_TRY_AGAIN' })}
                    prevStepEvent={() => send({ type: 'GO_BACK' })}
                />
            )}

            {state.hasTag('initialising') && <LoadingSpinnerOverlay />}

            {state.matches('ready') && (
                <>
                    {renderDiscountCodeSection()}

                    <CheckoutSummary checkoutDetails={state.context.checkoutDetails} covers={state.context.covers} />

                    <PaymentMethods state={state} send={send} />

                    {state.matches('ready.checkout.displayCheckoutError') && (
                        <Alert
                            className="checkout__error"
                            type={AlertTypes.ERROR}
                            // @ts-expect-error XState typegen marks this as never when this is a valid state under 'ready'
                            message={<span className="checkout__error-message">{state.context.checkoutErrorInfo}</span>}
                        />
                    )}

                    <p className="checkout-disclaimer">
                        {paymentsStrings.formatString(paymentsStrings.disclaimer, {
                            stripeTerms: (
                                <ExternalLink to={ExternalLinks.stripeTerms} label={paymentsStrings.stripeTermsLabel} />
                            ),
                        })}
                    </p>
                    <div className="checkout-powered-by">
                        <div className="checkout-powered-by__row">
                            {paymentsStrings.poweredBy} <img src={stripeLogo} alt="Stripe" />
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};

export default requireFlags(Checkout);
