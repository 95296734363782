import React, { useState } from 'react';
import ContentUploader from 'box-ui-elements/es/elements/content-uploader/ContentUploader';
import { IntlProvider } from 'react-intl';
import { Button } from '../button/Button';
import { List } from '../list/List';
import Regex from '../../utils/RegexCollection';
import commonStrings from '../../strings/common';

import './UploadDocumentButton.scss';

export interface UploadDocumentButtonProps {
    label?: string;
    accessToken?: string;
    folderId?: string;
    uploadedFiles?: Array<any>;
    onUploadComplete?: (files: Array<any>) => void;
}

export const UploadDocumentButton: React.FC<UploadDocumentButtonProps> = ({
    label = commonStrings.uploadDocument.buttonLabel,
    accessToken,
    folderId,
    uploadedFiles = [],
    onUploadComplete,
}) => {
    const [isFileUploaderOpen, setIsFileUploaderOpen] = useState(false);
    const [hasUploadedDisallowedFiles, setHasUploadedDisallowedFiles] = useState(false);

    const openFileUploader = () => setIsFileUploaderOpen(true);

    const closeFileUploader = () => setIsFileUploaderOpen(false);

    const onFileUploadComplete = (files: Array<any>) => {
        if (onUploadComplete) onUploadComplete(files);
        closeFileUploader();
    };

    const rejectDisallowedFilesOnRequest = async (config: any) => {
        const { data } = config;
        const fileToBeUploaded = data.file !== undefined ? data.file : data;

        setHasUploadedDisallowedFiles(false);

        // check if file type doesn't match allowed file types
        if (fileToBeUploaded.name !== undefined && fileToBeUploaded.name.match(Regex.allowedFiles) === null) {
            setHasUploadedDisallowedFiles(true);
            setTimeout(() => closeFileUploader(), 400);
            // dont pass any file in request (fails in background)
            return null;
        }
        return config;
    };

    const uploadedFilesByName = uploadedFiles.map((file) => ({ id: file.id, data: file.name }));

    return (
        <div className="upload-document-button">
            {isFileUploaderOpen && (
                <div className="upload-document-button__overlay">
                    <div className="upload-document-button__modal">
                        <IntlProvider locale="en">
                            <ContentUploader
                                token={accessToken}
                                rootFolderId={folderId}
                                onClose={closeFileUploader}
                                onComplete={onFileUploadComplete}
                                requestInterceptor={rejectDisallowedFilesOnRequest}
                                fileLimit={1}
                            />
                        </IntlProvider>
                    </div>
                </div>
            )}
            {uploadedFilesByName.length > 0 && <List items={uploadedFilesByName} />}
            {hasUploadedDisallowedFiles && <p className="add-evidence__error">{commonStrings.uploadDocument.error}</p>}
            <Button
                variant="secondary"
                className="upload-document-button__activator"
                label={label}
                width="full"
                onClick={openFileUploader}
            />
        </div>
    );
};

export default UploadDocumentButton;
