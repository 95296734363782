import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { DayPassPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const alphaProductInfo = {
    id: Cover.DAY_PASS,
    icon: DayPassIcon,
    name: 'Day Pass',
    group: 'Day Pass',
    price: '$6',
    priceTimePeriod: 'day',
    description: 'Flexible cover for the days you want it.',
    primaryFeatures: [
        'Pay by the day',
        'Book ahead for upcoming activities, events, or trips',
        'No ongoing commitment',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Up to <strong>$20k</strong> depending on your injury',
};

const betaProductInfo = {
    id: Cover.DAY_PASS,
    icon: DayPassIcon,
    name: 'Day Pass',
    group: 'Day Pass',
    price: '$6',
    priceTimePeriod: 'day',
    description: 'Flexible cover for the days you want it.',
    primaryFeatures: [
        'Pay by the day',
        'Book ahead for upcoming activities, events, or trips',
        'No ongoing commitment',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Up to <strong>$20k</strong> depending on your injury',
};

const groguProductInfo = {
    id: Cover.DAY_PASS,
    icon: DayPassIcon,
    name: 'Day Pass',
    group: 'Day Pass',
    price: '$6',
    priceTimePeriod: 'day',
    description: 'On demand cover for the days you want it',
    primaryFeatures: ['Pay by the day', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const dayPassProductInfo: Record<DayPassPdsVersion, ProductInfo> = {
    [DayPassPdsVersion.ALPHA_V1]: alphaProductInfo,
    [DayPassPdsVersion.ALPHA_V2]: alphaProductInfo,
    [DayPassPdsVersion.BETA]: betaProductInfo,
    [DayPassPdsVersion.GROGU]: groguProductInfo,
    [DayPassPdsVersion.GROGU_SCHEDULE]: groguProductInfo,
    [DayPassPdsVersion.GROGU_SEPT_UPDATE]: groguProductInfo,
    [DayPassPdsVersion.GROGU_SCHEDULING_LIMIT_UPDATE]: groguProductInfo,
};

export default dayPassProductInfo;
