import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';
import DisclosureWidget from '../../../components/disclosure-widget/DisclosureWidget';
import Layout from '../../../components/layout/Layout';
import ModalWithCTA from '../../../components/modal-with-cta/ModalWithCTA';
import claimsFlow from '../../../strings/claimsFlow';
import common from '../../../strings/common';
import Constants from '../../../utils/Constants';
import {
    MedicalContactConsentAuth1Version,
    MedicalContactConsentAuth2Version,
} from '../../../utils/constants/MedicalContactConsentVersion';
import parseString from '../../../utils/parseString';
import Routes from '../../../utils/Routes';
import stringToReactKey from '../../../utils/stringToReactKey';
import UserAgreement from './user-agreement/UserAgreement';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import Analytics from '../../../analytics/Analytics';

import './MedicalContactInformation.scss';

enum ModalToShow {
    None,
    Auth1,
    Auth2,
}

const MedicalContactInformation: FC = () => {
    const { medicalContactInformation } = claimsFlow;
    const { authority1, authority2, disagreeModal } = medicalContactInformation;
    const { whatIsAuthority1, authority1Declaration } = authority1[MedicalContactConsentAuth1Version.CURRENT];
    const { whatIsAuthority2, authority2Declaration } = authority2[MedicalContactConsentAuth2Version.CURRENT];

    const history = useHistory();
    const { claimDetails, setClaimDetailsByAttr } = useClaim();

    const [auth1Checked, setAuth1Checked] = useState(claimDetails.healthInfoAccessAuthOneConsent);
    const [auth2Checked, setAuth2Checked] = useState(claimDetails.healthInfoAccessAuthTwoConsent);
    const [openAuth2Details, setOpenAuth2Details] = useState(claimDetails.healthInfoAccessAuthOneConsent);
    const [modalToShow, setModalToShow] = useState(ModalToShow.None);

    const whatIsAuth2Ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_MEDICAL_CONTACT_INFORMATION, claimDetails);
    }, []);

    const handleModalNextClick = () => {
        if (modalToShow === ModalToShow.Auth1) {
            setClaimDetailsByAttr('healthInfoAccessAuthOneConsent', false);
        } else {
            setClaimDetailsByAttr('healthInfoAccessAuthTwoConsent', false);
        }

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_MEDICAL_CONTACT_INFORMATION, claimDetails);

        history.push(Routes.EMERGENCY_SERVICES_REQUIRED);
    };

    const handleAuth1AgreeClick = () => {
        setAuth1Checked(true);
        setOpenAuth2Details(true);

        // Scroll to auth 2
        setTimeout(() => {
            if (whatIsAuth2Ref.current) {
                window.scroll({
                    // Minus 40px cause 40px is the padding above the auth 2 section
                    top: window.pageYOffset + whatIsAuth2Ref.current.getBoundingClientRect().top - 40,
                    behavior: 'smooth',
                });
            }
        }, 0);
    };

    const handleDisagreeClick = (modal: ModalToShow) => () => setModalToShow(modal);

    const handleSubmit = () => {
        setClaimDetailsByAttr('healthInfoAccessAuthOneConsent', auth1Checked);
        setClaimDetailsByAttr('healthInfoAccessAuthTwoConsent', auth2Checked);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_MEDICAL_CONTACT_INFORMATION, claimDetails);

        history.push(Routes.EMERGENCY_SERVICES_REQUIRED);
    };

    return (
        <Layout
            title={medicalContactInformation.title}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_MEDICAL_CONTACT_INFORMATION / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            <form onSubmit={handleSubmit}>
                <p className="medical-contact-information__description">
                    {parseString(medicalContactInformation.description)}
                </p>

                {/* What is authority 1 */}
                <Card>
                    <p className="medical-contact-information__what-is-title">{whatIsAuthority1.title}</p>
                    <p>{parseString(whatIsAuthority1.explanation)}</p>
                    <DisclosureWidget summary={whatIsAuthority1.exampleTitle} open>
                        <ul className="medical-contact-information__list">
                            {whatIsAuthority1.examples.map((example) => (
                                <li
                                    className="medical-contact-information__list-item"
                                    key={stringToReactKey(example, 'what-is-auth-1')}
                                >
                                    {example}
                                </li>
                            ))}
                        </ul>
                    </DisclosureWidget>
                </Card>

                {/* Authority 1 */}
                <h2 className="medical-contact-information__authority-title">{authority1Declaration.title}</h2>
                <DisclosureWidget
                    summary={<strong>{authority1Declaration.summary}</strong>}
                    open
                    className="medical-contact-information__authority"
                >
                    <>
                        <p>{parseString(authority1Declaration.description)}</p>
                        <p>{parseString(authority1Declaration.agreementTitle)}</p>
                        <ul className="medical-contact-information__list">
                            {authority1Declaration.agreementItems.map((item) => (
                                <li
                                    className="medical-contact-information__list-item"
                                    key={stringToReactKey(item, 'auth-1-agree')}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <UserAgreement
                            radioGroupName="auth-1-radio-group"
                            hasAgreed={auth1Checked}
                            onAgree={handleAuth1AgreeClick}
                            onDisagree={handleDisagreeClick(ModalToShow.Auth1)}
                        />
                    </>
                </DisclosureWidget>

                {/* What is authority 2 */}
                <Card ref={whatIsAuth2Ref}>
                    <p className="medical-contact-information__what-is-title">{whatIsAuthority2.title}</p>
                    <p>{parseString(whatIsAuthority2.explanation)}</p>
                    <DisclosureWidget summary={whatIsAuthority2.exampleTitle} open={openAuth2Details}>
                        <ul className="medical-contact-information__list">
                            {whatIsAuthority2.examples.map((example) => (
                                <li
                                    className="medical-contact-information__list-item"
                                    key={stringToReactKey(example, 'what-is-auth-2')}
                                >
                                    {example}
                                </li>
                            ))}
                        </ul>
                        <p className="medical-contact-information__example-paragraph">
                            {parseString(whatIsAuthority2.exampleParagraph)}
                        </p>
                    </DisclosureWidget>
                </Card>

                {/* Authority 2 */}
                <h2 className="medical-contact-information__authority-title">{authority2Declaration.title}</h2>
                <DisclosureWidget
                    summary={<strong>{authority2Declaration.summary}</strong>}
                    className="medical-contact-information__authority"
                    open={openAuth2Details}
                >
                    <>
                        <p>{parseString(authority2Declaration.description)}</p>
                        <ul>
                            {authority2Declaration.descriptionItems.map((item) => (
                                <li key={stringToReactKey(item, 'auth-2-desc')}>{item}</li>
                            ))}
                        </ul>
                        <p>{parseString(authority2Declaration.agreementTitle)}</p>
                        <ul className="medical-contact-information__list">
                            {authority2Declaration.agreementItems.map((item) => (
                                <li
                                    className="medical-contact-information__list-item"
                                    key={stringToReactKey(item, 'auth-2-agree')}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <UserAgreement
                            radioGroupName="auth-2-radio-group"
                            hasAgreed={auth2Checked}
                            onAgree={() => setAuth2Checked(true)}
                            onDisagree={handleDisagreeClick(ModalToShow.Auth2)}
                        />
                    </>
                </DisclosureWidget>

                {/* Continue button */}
                <Button disabled={!auth1Checked || !auth2Checked} label={common.continue} width="full" type="submit" />

                {/* Disagree modal */}
                <ModalWithCTA
                    onClose={() => setModalToShow(ModalToShow.None)}
                    isOpen={modalToShow !== ModalToShow.None}
                    title={
                        modalToShow === ModalToShow.Auth1
                            ? disagreeModal.auth1Description
                            : disagreeModal.auth2Description
                    }
                    textContent={disagreeModal.alert}
                    primaryCTA={{ onClick: handleModalNextClick, label: common.next }}
                    secondaryCTA={{
                        onClick: () => setModalToShow(ModalToShow.None),
                        label: common.back,
                    }}
                />
            </form>
        </Layout>
    );
};

export default MedicalContactInformation;
