import React from 'react';
import classNames from 'classnames';
import './TabButton.scss';

export interface TabButtonProps {
    label: string;
    onClick: () => void;
    isActive?: boolean;
}

export const TabButton: React.FC<TabButtonProps> = ({ label, onClick, isActive, ...props }) => {
    return (
        <button type="button" className={classNames('tab-button', isActive && 'active')} onClick={onClick} {...props}>
            {label}
        </button>
    );
};

export default TabButton;
