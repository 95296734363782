import Cover from './Cover';
import CoverTypeId from './CoverTypeId';

export interface ICoverInformation {
    coverCode: string;
    coverType: CoverTypeId.SUBSCRIPTION_V1 | CoverTypeId.SINGLE_V1;
    isMainCover: boolean;
    insuranceProductId: string | undefined;
}

const CoverInformation: Record<Cover, ICoverInformation> = {
    [Cover.DAY_PASS]: {
        coverCode: 'DAY_PASS_DAILY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_DAY_PASS_DAILY,
    },
    [Cover.ALWAYS_ON]: {
        coverCode: 'ALWAYS_ON_WEEKLY',
        coverType: CoverTypeId.SUBSCRIPTION_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_ALWAYS_ON_WEEKLY,
    },
    [Cover.BOOST]: {
        coverCode: 'BOOST_DAILY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: false,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_BOOST_DAILY,
    },
    [Cover.JAPAN_PASS]: {
        coverCode: 'JAPAN_WEEKLY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_JAPAN_PASS_WEEKLY,
    },
    [Cover.FLIP_ACTIVE_DAILY]: {
        coverCode: 'FLIP_ACTIVE_DAILY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_ACTIVE_DAILY,
    },
    [Cover.FLIP_ACTIVE_WEEKLY]: {
        coverCode: 'FLIP_ACTIVE_WEEKLY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_ACTIVE_WEEKLY,
    },
    [Cover.FLIP_ACTIVE_SUB_MONTHLY]: {
        coverCode: 'FLIP_ACTIVE_SUB_MONTHLY',
        coverType: CoverTypeId.SUBSCRIPTION_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_ACTIVE_SUB_MONTHLY,
    },
    [Cover.FLIP_KIDS_DAILY]: {
        coverCode: 'FLIP_KIDS_DAILY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_KIDS_DAILY,
    },
    [Cover.FLIP_KIDS_WEEKLY]: {
        coverCode: 'FLIP_KIDS_WEEKLY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_KIDS_WEEKLY,
    },
    [Cover.FLIP_KIDS_SUB_MONTHLY]: {
        coverCode: 'FLIP_KIDS_SUB_MONTHLY',
        coverType: CoverTypeId.SUBSCRIPTION_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_KIDS_SUB_MONTHLY,
    },
    [Cover.FLIP_ACTIVE_BOOST]: {
        coverCode: 'FLIP_ACTIVE_BOOST',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: false,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_ACTIVE_BOOST,
    },
    [Cover.FLIP_ROAMING_WEEKLY]: {
        coverCode: 'FLIP_ROAMING_WEEKLY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_ROAMING_WEEKLY,
    },
    [Cover.FLIP_ROAMING_KIDS_WEEKLY]: {
        coverCode: 'FLIP_ROAMING_KIDS_WEEKLY',
        coverType: CoverTypeId.SINGLE_V1,
        isMainCover: true,
        insuranceProductId: process.env.REACT_APP_INSURANCE_PRODUCT_ID_FLIP_ROAMING_KIDS_WEEKLY,
    },
};

export default CoverInformation;
