import { assign, spawn } from 'xstate';
import kidDetailsFormMachine from '../../../../../components/kid-details-form/kid-details-form-machine/kidDetailsFormMachine';
import { PersonsInsuredBaymaxMachineContextTypes } from '../context/personsInsuredBaymaxMachineContext';
import { Typegen0 } from '../persons-insured-baymax-machine/personsInsuredBaymaxMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const spawnKidDetailsFormMachine = assign<
    PersonsInsuredBaymaxMachineContextTypes,
    { type: EventsCausingActions['spawnKidDetailsFormMachine'] }
>({
    kidDetailsFormMachineRef: () => spawn(kidDetailsFormMachine, { sync: true }),
});

export const setSelectedPersons = assign<
    PersonsInsuredBaymaxMachineContextTypes,
    { type: EventsCausingActions['setSelectedPersons']; data: { selectedPersons: string[] } }
>({
    selectedPersons: (ctx, event) => event.data.selectedPersons,
    isAccountHolderInsured: (ctx, event) => event.data.selectedPersons.includes('myself'),
});
