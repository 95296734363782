import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse from '../../business-logic/models/CustomerResponse';
import transformCustomerResponse, { CustomerDetails } from './transformCustomerResponse';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const getCustomer = async ({
    accessToken,
    axios,
}: {
    accessToken: string;
} & ServiceArgs): Promise<CustomerDetails> => {
    const { data } = await axios.get<CustomerResponse>(`${baseApiPath}/api/v1/customer`, toHeaders(accessToken));

    return transformCustomerResponse(data);
};

export default asService(getCustomer);
