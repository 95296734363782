import axios from 'axios';
import { CartInstanceResponse } from '../../business-logic/models/Cart';
import CoverSelection from '../../business-logic/models/CoverSelection';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import toHeaders from '../utils/toHeaders';
import getCoversPayload from './utils/getCoversPayload';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const addItemsToGuestCart = async ({
    accessToken,
    newCovers,
    userTimeZone,
}: {
    accessToken: string | undefined;
    newCovers: CoverSelection[];
    userTimeZone: string;
} & ServiceArgs): Promise<CartInstanceResponse | null> => {
    try {
        assertAccessTokenValidity(accessToken);
        const payload = getCoversPayload(newCovers, userTimeZone);
        const { data } = await axios.put<CartInstanceResponse>(
            `${baseApiPath}/api/v1/guest/cart`,
            payload,
            toHeaders(accessToken),
        );
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            return null; // handle error silently
        }
        throw new Error(`Error with calling PUT cart item service: ${error}`);
    }
};

export default asService(addItemsToGuestCart);
