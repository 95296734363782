import React from 'react';
import { Witness } from '../../../business-logic/models/ClaimDetails';
import './WitnessesItem.scss';

export interface WitnessesItemProps {
    witnesses: Array<Witness>;
}

const WitnessesItem: React.FC<WitnessesItemProps> = ({ witnesses }) => {
    return (
        <ul className="witnesses-item">
            {witnesses.length === 0
                ? '-'
                : witnesses.map((witness) => (
                      <li key={witness.email || witness.phone}>
                          <p className="witnesses-item__name">{witness.name}</p>
                          {witness.email && <p>{witness.email}</p>}
                          {witness.phone && <p>{witness.phone}</p>}
                      </li>
                  ))}
        </ul>
    );
};

export default WitnessesItem;
