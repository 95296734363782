import { CartBaymaxMachineContextTypes } from '../context/cartBaymaxCartMachineContext';

export const isPurchaseStatePresent = (ctx: CartBaymaxMachineContextTypes): boolean => {
    return !!ctx.purchaseState?.selectedProductGrouping;
};

export const isKidsDetailsRequired = (ctx: CartBaymaxMachineContextTypes): boolean => {
    // Get cover codes in selected product option
    const selectedProductGroup = ctx.productGroups?.find(
        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const coverCodes = selectedProductGroup?.options.find(
        (option) => option.id === ctx.purchaseState?.selectedProductOption?.id,
    )?.coverCodes;
    // For each cover code in selected product option
    // Check if any of the cover code is a dependant cover
    return (
        coverCodes?.find((coverCode) => {
            // Get product for specified cover code
            const coverCodeProduct = ctx.products.find(
                (product) => product.productSpec.mainCoverType.coverCode === coverCode,
            );
            // Check if requiredInsuredPersonRelationship is 'Dependant'
            return coverCodeProduct?.productSpec.mainCoverType.requiredInsuredPersonRelationship === 'Dependant';
        }) !== undefined
    );
};

export const canAccountHolderBeInsured = (ctx: CartBaymaxMachineContextTypes): boolean => {
    // Get cover codes in selected product option
    const selectedProductGroup = ctx.productGroups?.find(
        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const selectedCoverCodes = selectedProductGroup?.options.find(
        (option) => option.id === ctx.purchaseState?.selectedProductOption?.id,
    )?.coverCodes;
    return (
        selectedCoverCodes?.some(
            (coverCode) =>
                ctx.products
                    .find((x) => x.productSpec.mainCoverType.coverCode === coverCode)
                    ?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() !== 'dependant',
        ) ?? false
    );
};

export const canDependantsBeInsured = (ctx: CartBaymaxMachineContextTypes): boolean => {
    // Get cover codes in selected product option
    const selectedProductGroup = ctx.productGroups?.find(
        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const selectedCoverCodes = selectedProductGroup?.options.find(
        (option) => option.id === ctx.purchaseState?.selectedProductOption?.id,
    )?.coverCodes;

    return (
        selectedCoverCodes?.some(
            (coverCode) =>
                ctx.products
                    .find((x) => x.productSpec.mainCoverType.coverCode === coverCode)
                    ?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() === 'dependant',
        ) ?? false
    );
};

export const isSelectPersonsInsuredRequired = (ctx: CartBaymaxMachineContextTypes): boolean => {
    return ctx.availableInsuredPersonsTypes.length > 1 || ctx.availableInsuredPersonsTypes.includes('dependant');
};

export const isExistingUser = (ctx: CartBaymaxMachineContextTypes): boolean => {
    return ctx.isExistingUser;
};
