import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import sub from 'date-fns/sub';
import { Modifier } from 'react-day-picker';
import { assign } from 'xstate';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import Cover, { MainCover } from '../../../../utils/constants/Cover';
import Limits from '../../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../../utils/getLastDayToSchedule';
import { RoamingDatesMachineContextTypes } from '../context/roamingDatesMachineContext';
import { Typegen0 } from '../roamingDatesMachine.typegen';
import { Policy } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    RoamingDatesMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            policies: Policy[];
            timezone: string;
            productSpecs: Record<MainCover, ProductResponse>;
            startingRegion: string;
            startingDestination: string;
            destinations: string[];
            selectedCover: Cover;
        };
    }
>({
    policies: (ctx, event) => event.data.policies,
    timezone: (ctx, event) => event.data.timezone,
    productSpecs: (ctx, event) => event.data.productSpecs,
    startingRegion: (ctx, event) => event.data.startingRegion,
    startingDestination: (ctx, event) => event.data.startingDestination,
    selectedCover: (ctx, event) => event.data.selectedCover,
    destinations: (ctx, event) => event.data.destinations,
});

export const setDaysToSchedule = assign<
    RoamingDatesMachineContextTypes,
    { type: EventsCausingActions['setDaysToSchedule']; data: Date[] }
>({
    daysToSchedule: (ctx, event) => event.data,
});

export const evaluateDisabledDays = assign<
    RoamingDatesMachineContextTypes,
    { type: EventsCausingActions['evaluateDisabledDays']; data: MainCover } | { type: '' }
>({
    disabledDays: (ctx) => {
        const zonedToday = utcToZonedTime(new Date(), ctx.timezone);
        const lastDayToGetCover = getLastDayToSchedule(
            zonedToday,
            ctx.selectedCover
                ? ctx.productSpecs![ctx.selectedCover as MainCover].productSpec.mainCoverType.schedule
                      .scheduleLimitInHours
                : Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS, // 3 months fallback
        );

        const weekPassToDisabledModifier = (weekPass: Policy) => ({
            // Disable week pass dates as well as the 6 days before it
            from: sub(utcToZonedTime(fromUnixTime(weekPass.mainCover.activeFrom), ctx.timezone), {
                days: 6,
            }),
            to: utcToZonedTime(fromUnixTime(weekPass.mainCover.activeTo), ctx.timezone),
        });

        const currentCovers = ctx.policies.filter(
            (x) =>
                (ctx.coverFor === 'self'
                    ? x.mainCover.insuredPersonId === null
                    : x.mainCover.insuredPersonId === ctx.dependantToCover) && x.mainCover.status !== 'Canceled',
        );

        const dates: Modifier[] = [
            {
                before: zonedToday,
                after: lastDayToGetCover,
            },
            ...(currentCovers.length > 0 && ctx.enforceOverlappingRules
                ? currentCovers.map(weekPassToDisabledModifier)
                : []),
        ];

        return dates;
    },
});
