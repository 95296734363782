import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    MedicalContactConsentAuth1Version,
    MedicalContactConsentAuth2Version,
} from '../../utils/constants/MedicalContactConsentVersion';
import ThirdPartyConsentVersion from '../../utils/constants/ThirdPartyConsentVersion';
import { BoxFiles } from '../models/BoxFiles';
import ClaimDetails from '../models/ClaimDetails';
import ClaimStatusResponse from '../models/ClaimStatusResponse';
import FileUploadTokens, { folderIdTypes } from '../models/FileUploadTokens';

const env = process.env.REACT_APP_NETLIFY_ENV;

// Interfaces
export interface ClaimState {
    fileUploadTokens: FileUploadTokens;
    claimDetails: ClaimDetails;
    existingClaims: Array<ClaimStatusResponse>;
}

interface ActionsState {
    setFileUploadTokens: (accessToken: string, claimId: string, folderIds: folderIdTypes) => void;
    setClaimDetailsByAttr: (attr: keyof ClaimDetails, value?: any) => void;
    setClaimFilesByAtrr: (attr: keyof BoxFiles, files: any) => void;
    setClaimPayeeBankDetails: (accountName: string, accountNumber: string, bsb: string) => void;
    setExistingClaims: (existingClaims: Array<any>) => void;
    clearCurrentClaimDetails: () => void;
}

// Initial claim state
export const initialState: ClaimState = {
    claimDetails: {
        id: '',
        currentClaimStarted: false,
        insuredPersonId: null,
        claimType: null,
        didAccidentHappenInAustralia: false,
        injuries: [],
        accidentDescription: '',
        accidentLocation: {},
        date: '',
        time: '',
        firstTreatmentDate: '',
        wasMajorSurgeryRequired: false,
        wasEmergencyServicesRequired: false,
        witnesses: [],
        hasWitnessesPermission: false,
        medicalReport: [],
        receipts: [],
        proofOfAccident: [],
        proofOfActivity: [],
        hasSigned: false,
        payeeBankDetails: { accountName: '', accountNumber: '', bsb: '' },
        thirdPartyConsent: false,
        thirdPartyConsentVersion: ThirdPartyConsentVersion.CURRENT,
        healthInfoAccessAuthOneConsent: false,
        healthInfoAccessAuthOneConsentVersion: MedicalContactConsentAuth1Version.CURRENT,
        healthInfoAccessAuthTwoConsent: false,
        healthInfoAccessAuthTwoConsentVersion: MedicalContactConsentAuth2Version.CURRENT,
        signatureData: undefined,
    },
    fileUploadTokens: {
        accessToken: '',
        claimId: '',
        folderIds: { activity: '', evidence: '', medicalReport: '', receipts: '' },
    },
    existingClaims: [],
};

export type IClaimContext = ClaimState & ActionsState;

export const ClaimContext = createContext<any>(initialState);

export const useClaim = (): IClaimContext => {
    const context: IClaimContext = useContext(ClaimContext);

    if (typeof context === 'undefined') {
        throw new Error('Claim Context must be used within the ClaimProvider');
    }
    return context;
};

export const ClaimProvider: React.FC = (props) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (env === 'dev' || env === 'test') {
            console.log('Claim State - ', state);
        }
    }, [state]);

    // Set box response
    const setFileUploadTokens = (accessToken: string, claimId: string, folderIds: folderIdTypes) => {
        setState((prevState) => {
            return { ...prevState, fileUploadTokens: { accessToken, claimId, folderIds } };
        });
    };

    // Update claim details values
    const setClaimDetailsByAttr = (attr: keyof ClaimDetails, value?: any) => {
        setState((prevState) => {
            return {
                ...prevState,
                claimDetails: {
                    ...prevState.claimDetails,
                    [attr]: value,
                },
            };
        });
    };

    // Update uploaded files
    const setClaimFilesByAtrr = (attr: keyof BoxFiles, files: Array<any>) => {
        setState((prevState) => {
            const currentFiles = prevState.claimDetails[attr];
            const currentFileNames = new Set(currentFiles.map((file) => file.name));

            return {
                ...prevState,
                claimDetails: {
                    ...prevState.claimDetails,
                    [attr]: [...currentFiles, ...files.filter((file) => !currentFileNames.has(file.name))],
                },
            };
        });
    };

    // Update bank details
    const setClaimPayeeBankDetails = (accountName: string, accountNumber: string, bsb: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                claimDetails: {
                    ...prevState.claimDetails,
                    payeeBankDetails: { accountName, accountNumber, bsb },
                },
            };
        });
    };

    // set existing claims
    const setExistingClaims = (existingClaims: Array<ClaimStatusResponse>) => {
        setState((prevState) => {
            return { ...prevState, existingClaims };
        });
    };

    // clear current claim details
    const clearCurrentClaimDetails = () => {
        setState((prevState) => {
            return { ...prevState, claimDetails: initialState.claimDetails };
        });
    };

    const value: IClaimContext = {
        ...state,
        setFileUploadTokens,
        setClaimDetailsByAttr,
        setClaimFilesByAtrr,
        setExistingClaims,
        setClaimPayeeBankDetails,
        clearCurrentClaimDetails,
    };

    return <ClaimContext.Provider value={value} {...props} />;
};
