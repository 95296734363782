export default {
    CLAIMS_NO_OF_STEPS: 24,
    CLAIMS_STEP_CLAIM_PERSONS_INSURED: 1,
    CLAIMS_STEP_CLAIM_TYPE: 2,
    CLAIMS_STEP_CHECKLIST: 3,
    CLAIMS_STEP_REQUIREMENTS: 4,
    CLAIMS_STEP_AUTOGRAPH: 5,
    CLAIMS_STEP_ACCIDENT_LOCATION: 6,
    CLAIMS_STEP_INJURY: 7,
    CLAIMS_STEP_ACCIDENT_DESCRIPTION: 8,
    CLAIMS_STEP_THIRD_PARTY_CONSENT: 9,
    CLAIMS_STEP_ACCIDENT_DATE_TIME: 10,
    CLAIMS_STEP_FIRST_TREATMENT_DATE: 11,
    CLAIMS_STEP_SURGERY_REQUIRED: 12,
    CLAIMS_STEP_OVERSEAS_RECEIPTS: 13,
    CLAIMS_STEP_MEDICAL_REPORT: 13,
    CLAIMS_STEP_MEDICAL_CONTACT_CONSENT: 14,
    CLAIMS_STEP_MEDICAL_CONTACT_INFORMATION: 15,
    CLAIMS_STEP_EMERGENCY_SERVICES_REQUIRED: 16,
    CLAIMS_STEP_WITNESS_QUESTIONNAIRE: 17,
    CLAIMS_STEP_WITNESS_INFORMATION: 18,
    CLAIMS_STEP_PHOTO_OF_ACCIDENT_QUESTIONNAIRE: 19,
    CLAIMS_STEP_PHOTO_OF_ACCIDENT: 20,
    CLAIMS_STEP_PROOF_OF_ACTIVITY_QUESTIONNAIRE: 21,
    CLAIMS_STEP_PROOF_OF_ACTIVITY: 22,
    CLAIMS_STEP_BANK_DETAILS: 23,
    CLAIMS_STEP_REVIEW_CLAIM: 24,

    // SPARTAN
    SPARTAN_ONBOARDING_FLOW_STEP_DOB: 2,
    SPARTAN_ONBOARDING_FLOW_STEP_GENDER: 3,
    SPARTAN_ONBOARDING_FLOW_STEP_NAME: 4,
    SPARTAN_ONBOARDING_FLOW_STEP_SELECT_PRODUCT: 5,
    SPARTAN_ONBOARDING_FLOW_STEP_JAPAN_PASS: 6,
    SPARTAN_ONBOARDING_FLOW_STEP_PAYMENT_MODEL: 6,
    SPARTAN_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATE: 7,
    SPARTAN_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATUS: 8,

    // WITH NEW REPURCHASE JOURNEY
    FLIP_ACTIVE_ONBOARDING_FLOW_NO_OF_STEPS: 11,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_REGISTRATION: 1,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_DOB: 2,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_GENDER: 3,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_NAME: 4,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATE: 5,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATUS: 6,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_PERSONS_INSURED: 7,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_SELECT_COVER: 8,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_SCHEDULE_COVER: 9,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_CHECKOUT: 10,
    FLIP_ACTIVE_ONBOARDING_FLOW_STEP_YOUR_ACTIVITIES: 11,

    // ALL BELOW TO BE DEPRECATED

    // OLD ONBOARDING FLOW

    ONBOARDING_FLOW_NO_OF_STEPS: 14,
    ONBOARDING_FLOW_STEP_REGISTRATION: 1,
    // Welcome cards not being used right now,
    // but leaving here as not completely deprecated
    // pending result of experiment
    ONBOARDING_FLOW_STEP_WELCOME_CARDS: 2,
    ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATE: 2,
    ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATUS: 3,
    ONBOARDING_FLOW_STEP_DOB: 4,
    ONBOARDING_FLOW_STEP_GENDER: 5,
    ONBOARDING_FLOW_STEP_NAME: 6,

    // NEW PRODUCT PAGES
    ONBOARDING_FLOW_STEP_SELECT_PRODUCT: 7,
    ONBOARDING_FLOW_STEP_JAPAN_PASS: 8,
    ONBOARDING_FLOW_STEP_PAYMENT_MODEL: 8,
    ONBOARDING_FLOW_STEP_REVIEW_BENEFITS: 9,
    ONBOARDING_FLOW_STEP_FEW_MORE_THINGS: 10,

    ONBOARDING_FLOW_STEP_SCHEDULE_DAY_PASS: 11, // only for day pass
    ONBOARDING_FLOW_STEP_SCHEDULE_ALWAYS_ON: 11, // only for always on
    ONBOARDING_FLOW_STEP_SCHEDULE_JAPAN_PASS: 11,
    ONBOARDING_FLOW_STEP_SCHEDULE_WEEK_PASS: 11,
    ONBOARDING_FLOW_STEP_REVIEW_COVER: 12,
    ONBOARDING_FLOW_STEP_CHECKOUT: 13,
    ONBOARDING_FLOW_STEP_YOUR_ACTIVITIES: 14,

    NEW_ONBOARDING_FLOW_NO_OF_STEPS: 14,
    NEW_ONBOARDING_FLOW_STEP_SELECT_PRODUCT: 1,
    NEW_ONBOARDING_FLOW_STEP_JAPAN_PASS: 2,
    NEW_ONBOARDING_FLOW_STEP_PAYMENT_MODEL: 2,
    NEW_ONBOARDING_FLOW_STEP_REVIEW_BENEFITS: 3,
    NEW_ONBOARDING_FLOW_STEP_FEW_MORE_THINGS: 4,
    NEW_ONBOARDING_FLOW_STEP_SCHEDULE_DAY_PASS: 5, // only for day pass
    NEW_ONBOARDING_FLOW_STEP_SCHEDULE_ALWAYS_ON: 5, // only for always on
    NEW_ONBOARDING_FLOW_STEP_SCHEDULE_JAPAN_PASS: 5,
    NEW_ONBOARDING_FLOW_STEP_REGISTRATION: 6,
    NEW_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATE: 7,
    NEW_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATUS: 8,
    NEW_ONBOARDING_FLOW_STEP_DOB: 9,
    NEW_ONBOARDING_FLOW_STEP_GENDER: 10,
    NEW_ONBOARDING_FLOW_STEP_NAME: 11,
};
