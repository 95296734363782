import Routes, { Route } from '../utils/Routes';
import { Category, PageEvent } from './Analytics.d';

const PageEvents: Record<Route, PageEvent | null> = {
    [Routes.HOME]: [Category.DASHBOARD, 'Dashboard'],
    [Routes.LOGIN]: [Category.AUTHENTICATION, 'Login'],
    [Routes.FORGOT_PASSWORD]: [Category.AUTHENTICATION, 'Forgot password'],

    // Onboarding
    [Routes.LANDING]: [Category.ONBOARDING, 'Landing page'],
    [Routes.REGISTER]: [Category.ONBOARDING, 'Create account'],
    [Routes.YOUR_ACTIVITIES]: [Category.ONBOARDING, 'Your activities'],
    [Routes.PREFLIGHT_CHECK]: [Category.ONBOARDING, 'Preflight check'],

    // Quiz
    [Routes.ONBOARDING_QUIZ]: [Category.ONBOARDING, 'Onboarding Quiz Lets Check Basics'],
    [Routes.ONBOARDING_QUIZ_INJURY]: [Category.ONBOARDING, 'Onboarding Quiz Injury'],
    [Routes.ONBOARDING_QUIZ_WORK]: [Category.ONBOARDING, 'Onboarding Quiz Work'],
    [Routes.ONBOARDING_QUIZ_MOTORCYCLING]: [Category.ONBOARDING, 'Onboarding Quiz Motorcycling'],
    [Routes.ONBOARDING_QUIZ_RESULT_SIGNUP]: [Category.ONBOARDING, 'Onboarding Quiz Result Sign Up'],
    [Routes.ONBOARDING_QUIZ_RESULT_EXCLUSIONS]: [Category.ONBOARDING, 'Onboarding Quiz Result Exclusions'],

    // Checkout
    [Routes.RESIDENCY_STATE]: [Category.CHECKOUT, 'State of residence'],
    [Routes.RESIDENCY_STATUS]: [Category.CHECKOUT, 'Residency status'],
    [Routes.DATE_OF_BIRTH]: [Category.CHECKOUT, 'Date of birth'],
    [Routes.GENDER]: [Category.CHECKOUT, 'Gender'],
    [Routes.NAME]: [Category.CHECKOUT, 'Name'],
    [Routes.SCHEDULE_ALWAYS_ON_SUCCESS]: [Category.CHECKOUT, 'Schedule always on success'],
    [Routes.SCHEDULE_BOOST]: [Category.CHECKOUT, 'Schedule boost'],
    [Routes.SCHEDULE_BOOST_SUCCESS]: [Category.CHECKOUT, 'Schedule boost success'],
    [Routes.CHECKOUT]: [Category.CHECKOUT, 'Checkout'],
    [Routes.BEFORE_YOU_GO]: [Category.CHECKOUT, 'Before you go'],
    [Routes.SCHEDULE_COVER]: [Category.CHECKOUT, 'Schedule cover'],
    [Routes.SCHEDULE_COVER_GUEST]: [Category.CHECKOUT, 'Schedule cover for guest'],
    [Routes.SCHEDULE_ACTIVE_COVER_BAYMAX]: [Category.CHECKOUT, 'Schedule active cover'],
    [Routes.SCHEDULE_KIDS_COVER_BAYMAX]: [Category.CHECKOUT, 'Schedule kids cover'],

    // Profile
    [Routes.PROFILE]: [Category.PROFILE, 'Profile'],
    [Routes.PROFILE_ACCOUNT]: [Category.PROFILE, 'Account details'],
    [Routes.PROFILE_PREFERENCES]: [Category.PROFILE, 'Preferences'],
    [Routes.PROFILE_CERTIFICATE_OF_COVER]: [Category.PROFILE, 'Certificate of cover'],
    [Routes.PROFILE_MANAGE_COVER]: [Category.PROFILE, 'Manage Cover'],

    // Claims
    [Routes.CLAIMS]: [Category.CLAIM_PROCESS, 'Claims'],
    [Routes.CLAIM_PERSONS_INSURED]: [Category.CLAIM_PROCESS, 'Claimant'],
    [Routes.CLAIM_CHECKLIST]: [Category.CLAIM_PROCESS, 'Checklist'],
    [Routes.CLAIM_TYPE]: [Category.CLAIM_PROCESS, 'Type'],
    [Routes.CLAIM_REQUIREMENTS]: [Category.CLAIM_PROCESS, 'Requirements'],
    [Routes.CLAIM_AUTOGRAPH]: [Category.CLAIM_PROCESS, 'Autograph'],
    [Routes.CLAIM_ACCIDENT_LOCATION]: [Category.CLAIM_PROCESS, 'Accident location'],
    [Routes.SELECT_INJURY]: [Category.CLAIM_PROCESS, 'Select injury'],
    [Routes.ACCIDENT_DESCRIPTION]: [Category.CLAIM_PROCESS, 'Accident description'],
    [Routes.THIRD_PARTY_CONSENT]: [Category.CLAIM_PROCESS, 'Third party consent'],
    [Routes.ACCIDENT_DATE]: [Category.CLAIM_PROCESS, 'Accident date'],
    [Routes.FIRST_TREATMENT_DATE]: [Category.CLAIM_PROCESS, 'First treatment date'],
    [Routes.SURGERY_REQUIRED]: [Category.CLAIM_PROCESS, 'Surgery required'],
    [Routes.MEDICAL_REPORT]: [Category.CLAIM_PROCESS, 'Medical report'],
    [Routes.MEDICAL_CONTACT_CONSENT]: [Category.CLAIM_PROCESS, 'Health professional contact consent'],
    [Routes.MEDICAL_CONTACT_INFORMATION]: [Category.CLAIM_PROCESS, 'Health professional contact consent authorities'],
    [Routes.EMERGENCY_SERVICES_REQUIRED]: [Category.CLAIM_PROCESS, 'Emergency services required'],
    [Routes.OVERSEAS_RECEIPTS]: [Category.CLAIM_PROCESS, 'Overseas receipts'],
    [Routes.WITNESS_QUESTIONNAIRE]: [Category.CLAIM_PROCESS, 'Any witness'],
    [Routes.WITNESS_INFO]: [Category.CLAIM_PROCESS, 'Witness info'],
    [Routes.PHOTO_OF_ACCIDENT_QUESTIONNAIRE]: [Category.CLAIM_PROCESS, 'Any photo of accident'],
    [Routes.PHOTO_OF_ACCIDENT]: [Category.CLAIM_PROCESS, 'Upload photo of accident'],
    [Routes.PROOF_OF_ACTIVITY_QUESTIONNAIRE]: [Category.CLAIM_PROCESS, 'Any proof of activity'],
    [Routes.PROOF_OF_ACTIVITY]: [Category.CLAIM_PROCESS, 'Upload proof of activity'],
    [Routes.BANK_DETAILS]: [Category.CLAIM_PROCESS, 'Bank details'],
    [Routes.REVIEW_CLAIM]: [Category.CLAIM_PROCESS, 'Review claim'],
    [Routes.CLAIM_SUBMITTED]: [Category.CLAIM_PROCESS, 'Claim submitted'],
    [Routes.CLAIM_DETAILS]: [Category.CLAIM_PROCESS, 'Claim details'],

    // Intermediate states - do not track, mark with null
    [Routes.LOGIN_CALLBACK]: null,
    [Routes.LOADING]: null,
    [Routes.NOT_FOUND]: null,

    [Routes.PERSONS_INSURED]: [Category.CHECKOUT, 'Persons Insured'],
    [Routes.SELECT_COVER]: [Category.CHECKOUT, 'Select Cover'],
    [Routes.PERSONS_INSURED_GUEST]: [Category.CHECKOUT, 'Persons Insured for guest'],
    [Routes.SELECT_COVER_GUEST]: [Category.CHECKOUT, 'Select Cover for guest'],
    [Routes.SELECT_COVER_BAYMAX]: [Category.CHECKOUT, 'Select Cover'],

    // Roaming
    [Routes.ROAMING_SELECTED_GUEST]: [Category.CHECKOUT, 'Roaming'],
    [Routes.ROAMING_DESTINATIONS_GUEST]: [Category.CHECKOUT, 'Select Destinations'],
    [Routes.ROAMING_START_GUEST]: [Category.CHECKOUT, 'Select Starting Destination'],
    [Routes.ROAMING_REGION_GUEST]: [Category.CHECKOUT, 'Select Starting Region'],
    [Routes.ROAMING_DATES_GUEST]: [Category.CHECKOUT, 'Schedule Cover'],

    [Routes.ROAMING_SELECTED]: [Category.CHECKOUT, 'Roaming'],
    [Routes.ROAMING_DESTINATIONS]: [Category.CHECKOUT, 'Select Destinations'],
    [Routes.ROAMING_START]: [Category.CHECKOUT, 'Select Starting Destination'],
    [Routes.ROAMING_REGION]: [Category.CHECKOUT, 'Select Starting Region'],
    [Routes.ROAMING_DATES]: [Category.CHECKOUT, 'Schedule Cover'],
    [Routes.ROAMING_DESTINATIONS_BAYMAX]: [Category.CHECKOUT, 'Select Destinations'],
    [Routes.ROAMING_START_BAYMAX]: [Category.CHECKOUT, 'Select Starting Destination'],
    [Routes.ROAMING_REGION_BAYMAX]: [Category.CHECKOUT, 'Select Starting Region'],
    [Routes.ROAMING_DATES_BAYMAX]: [Category.CHECKOUT, 'Schedule Cover'],

    [Routes.CART]: [Category.CHECKOUT, 'Cart'],
    [Routes.CART_BAYMAX]: [Category.CHECKOUT, 'Cart'],
    [Routes.CART_SUCCESS]: [Category.CHECKOUT, 'Cart Checkout Success'],
    [Routes.CART_BAYMAX_SUCCESS]: [Category.CHECKOUT, 'Cart Checkout Success'],
};

export default PageEvents;
