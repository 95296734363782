import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import chevronUp from '../../assets/images/ic-chevron-up.svg';
import chevronDown from '../../assets/images/ic-chevron-down.svg';
import './Disclosure.scss';

type TProps = {
    summary: ReactNode;
    children: ReactNode;
    className?: string;
    moreLabel?: string;
    lessLabel?: string;
};

const Disclosure: FC<TProps> = ({ summary, children, className, moreLabel, lessLabel }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className={classNames('disclosure', className)}>
            <div className="disclosure__summary">
                <div>{summary}</div>
                <div role="none" className="disclosure__trigger" onClick={() => setExpanded(!expanded)}>
                    <span>{expanded ? lessLabel : moreLabel}</span>
                    <img src={expanded ? chevronUp : chevronDown} alt="" />
                </div>
            </div>
            {expanded && children}
        </div>
    );
};

export default Disclosure;
