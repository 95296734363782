import { FC, ReactNode } from 'react';
import { Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { ProductInfo } from '../../../content/by-product/product-info/types';
import manageCoverSubscriptionCardContent from '../../../content/ui/components/manage-cover-subcription-card/manageCoverSubscriptionCard';
import withContent from '../../../hoc/with-content/withContent';
import common from '../../../strings/common';
import Cover, { MainCover } from '../../../utils/constants/Cover';
import CoverInformation, { ICoverInformation } from '../../../utils/constants/CoverInformation';
import DateFormat from '../../../utils/constants/DateFormat';
import formatEndingTime from '../../../utils/formatEndingTime';
import getProductInfo from '../../../utils/getProductInfo';
import unixNumToString from '../../../utils/unixNumToString';
import ManageCoverItemCard from '../manage-cover-item-card/ManageCoverItemCard';

import './ManageCoverSubscriptionCard.scss';

const contentMap = {
    renews: 'ui.renews',
    ending: 'ui.ending',
    subscriptionStarting: 'ui.subscriptionStarting',
    active: 'ui.active',
    starting: 'ui.starting',
    priceDisclaimer: 'ui.priceDisclaimer',
    benefitsCTA: 'ui.benefitsCTA',
    injuriesCoveredTab: 'ui.injuriesCoveredTab',
    injuriesNotCoveredTab: 'ui.injuriesNotCoveredTab',
};

interface ManageCoverSubscriptionCardProps {
    content: Record<keyof typeof contentMap, string>;
    policies: Policy[];
    timeZone: string;
    onCancel: () => void;
}

const ManageCoverSubscriptionCard: FC<ManageCoverSubscriptionCardProps> = ({
    content,
    policies,
    timeZone,
    onCancel,
}) => {
    const coverCode = Array.from(new Set(policies.map((x) => x.mainCover.coverCode)))[0];
    const pdsVersion = Array.from(new Set(policies.map((x) => x.PDSVersion)))[0];

    const cover = (
        Object.entries(CoverInformation).find(([, v]) => coverCode === v.coverCode) as [string, ICoverInformation]
    )[0] as MainCover;

    const productInfo = getProductInfo(cover, pdsVersion);

    const canCancel = policies.some(
        (c) =>
            c.mainCover.status !== 'Canceled' &&
            c.mainCover.currentCycleStatus !== 'Canceled' &&
            !c.mainCover.paymentFailed,
    );

    const getNumericPrice = (info: ProductInfo) => parseInt(info.price.match(/\$(\d*)/)![1], 10) * 100;

    const lineItems = policies.reduce<{ key: string; description: ReactNode; price: number }[]>((acc, c) => {
        const { mainCover } = c;

        const isSubscriptionEnding =
            c.status === 'Canceled' || c.mainCover.currentCycleStatus === 'Canceled' || c.mainCover.paymentFailed;

        acc.push({
            key: c.insurancePolicyId,
            description: (
                <div>
                    {isSubscriptionEnding ? (
                        <div className="manage-cover-subscription-cover__period">
                            <strong className="manage-cover-single-cover__period__label">{content.ending}</strong>
                            <div className="manage-cover-single-cover__period__time">
                                {formatEndingTime(mainCover.activeTo, timeZone)}
                            </div>
                        </div>
                    ) : (
                        <div className="manage-cover-subscription-cover__period">
                            <strong className="manage-cover-single-cover__period__label">
                                {c.status === 'Scheduled' ? content.subscriptionStarting : content.renews}
                            </strong>
                            <div className="manage-cover-single-cover__period__time">
                                {unixNumToString(
                                    c.status === 'Scheduled' ? mainCover.activeFrom : mainCover.nextCycleAt!,
                                    timeZone,
                                    DateFormat.VERBOSE_WITH_TIMEZONE,
                                )}
                            </div>
                        </div>
                    )}
                    <span className="manage-cover-v2__price-disclaimer">{productInfo.priceTimePeriodDescription}</span>
                    <span className="manage-cover-v2__price-disclaimer">{content.priceDisclaimer}</span>
                    <span className="manage-cover-v2__pds-version">
                        {common.formatString(common.pdsVersion, { pdsVersion: c.PDSVersion })}
                    </span>
                </div>
            ),
            price: getNumericPrice(productInfo),
        });
        if (c.extraCovers.length > 0) {
            const extraCover = (
                Object.entries(CoverInformation).find(([, v]) => c.extraCovers[0].coverCode === v.coverCode) as [
                    string,
                    ICoverInformation,
                ]
            )[0] as Cover;

            const extraCoverProductInfo = getProductInfo(extraCover, pdsVersion);

            const extraCoverDates = c.extraCovers.map((extra, index) => {
                const isActive = extra.status === 'Started';

                return {
                    key: extra.insuranceCoverId,
                    description: (
                        <div className="manage-cover-subscription-cover__period">
                            <strong className="manage-cover-subscription-cover__period__label">{index + 1}: </strong>
                            <div className="manage-cover-single-cover__period__time">
                                {isActive
                                    ? manageCoverSubscriptionCardContent.formatString(content.active, {
                                          startDate: formatEndingTime(extra.activeTo, timeZone),
                                      })
                                    : manageCoverSubscriptionCardContent.formatString(content.starting, {
                                          startDate: formatEndingTime(extra.activeFrom, timeZone),
                                      })}
                            </div>
                            <span className="manage-cover-v2__pds-version">
                                {common.formatString(common.pdsVersion, { pdsVersion })}
                            </span>
                        </div>
                    ),
                    price: getNumericPrice(extraCoverProductInfo),
                };
            });
            acc.push(...extraCoverDates);
        }

        return acc;
    }, []);

    return (
        <ManageCoverItemCard
            coverName={productInfo.name}
            cover={cover}
            pdsVersion={pdsVersion}
            coverItems={[
                {
                    paymentModel: productInfo.paymentModel,
                    lineItems,
                    canCancel,
                },
            ]}
            benefitsContent={{
                benefitsCTA: content.benefitsCTA,
                injuriesCoveredTab: content.injuriesCoveredTab,
                injuriesNotCoveredTab: content.injuriesNotCoveredTab,
            }}
            onCancel={onCancel}
        />
    );
};

export default withContent(ManageCoverSubscriptionCard, contentMap, manageCoverSubscriptionCardContent);
