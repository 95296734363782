import { FC } from 'react';
import { Modifier } from 'react-day-picker';
import { useHistory } from 'react-router-dom';
import Analytics from '../../../analytics/Analytics';
import { CartErrorDisplay } from '../../../business-logic/models/Cart';
import Button from '../../../components/button/Button';
import CartError from '../../../components/cart-error/CartError';
import DatePickerModal from '../../../components/date-picker-modal/DatePickerModal';
import Fieldset from '../../../components/form/fieldset/Fieldset';
import Layout from '../../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import { NewDatePickerModes } from '../../../components/new-date-picker/types';
import PdsTmdDisclaimer from '../../../components/pds-tmd-disclaimer/PdsTmdDisclaimer';
import SlideInCoverTermsCTA from '../../../components/slide-in-cover-terms-cta/SlideInCoverTermsCTA';
import scheduleCoverContent from '../../../content/ui/screens/schedule-cover/scheduleCover';
import withContent from '../../../hoc/with-content/withContent';
import useOnboarding from '../../../hooks/useOnboarding';
import Cover, { MainCover } from '../../../utils/constants/Cover';
import CoverInformation from '../../../utils/constants/CoverInformation';
import PageTitles from '../../../utils/constants/PageTitles';
import getPdsVersion from '../../../utils/getPdsVersion';
import getProductInfo from '../../../utils/getProductInfo';
import parseString from '../../../utils/parseString';
import { Route } from '../../../utils/Routes';
import CoverTile from './components/cover-tile/CoverTile';
import FixedPaymentModelCoverTile from './components/fixed-payment-model-cover-tile/FixedPaymentModelCoverTile';

import './ScheduleCoverBody.scss';

const contentMap = {
    heading: 'ui.heading',
    paymentOptionLegend: 'ui.paymentOptionLegend',
    selectCoverDates: 'ui.selectCoverDates',
    benefitsCTA: 'ui.benefitsCTA',
    injuriesCoveredTab: 'ui.injuriesCoveredTab',
    injuriesNotCoveredTab: 'ui.injuriesNotCoveredTab',
    continueCTA: 'ui.continueCTA',
    yourPaymentOption: 'ui.yourPaymentOption',
    cancelYourPaymentOption: 'ui.cancelYourPaymentOption',
};

interface ScheduleCoverBodyProps {
    content: Record<keyof typeof contentMap, string>;
    selectedCover: MainCover | null;
    coversAvailable: MainCover[];
    fixedPaymentModelMode: boolean;
    datePickerMode: NewDatePickerModes;
    daysToSchedule: Date[];
    disabledDays: Modifier[];
    cartLoading: boolean;
    cartError?: unknown;
    selectCover: (value: Cover) => void;
    handleDateSubmission: (dates: Date[]) => void;
    canSelectDates: boolean;
    canCheckout: boolean;
    proceedToCheckout: () => void;
    goBack: () => void;
    tryAgainCart?: () => void;
    handleCalendarOpen: () => void;
    isOnboardingFlow?: boolean;
}

const ScheduleCoverBody: FC<ScheduleCoverBodyProps> = ({
    content,
    selectedCover,
    coversAvailable,
    fixedPaymentModelMode,
    datePickerMode,
    daysToSchedule,
    disabledDays,
    cartLoading,
    cartError,
    selectCover,
    handleDateSubmission,
    canSelectDates,
    canCheckout,
    proceedToCheckout,
    goBack,
    tryAgainCart,
    handleCalendarOpen,
    isOnboardingFlow,
}: ScheduleCoverBodyProps) => {
    const productInfos = coversAvailable.map((c) => getProductInfo(c));
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const history = useHistory();
    const trackBenefitsDrawer = (coverCode: any) => {
        const pageName = PageTitles[history?.location.pathname as Route];
        Analytics.trackBenefitsDrawerViewed(pageName, coverCode);
    };

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            {!cartError && cartLoading && <LoadingSpinnerOverlay />}

            {fixedPaymentModelMode ? (
                <>
                    <p className="schedule-cover-body__your-payment-option">{content.yourPaymentOption}</p>
                    <div className="schedule-cover-body__fixed-payment-model">
                        <FixedPaymentModelCoverTile
                            key={productInfos[0].id}
                            {...productInfos[0]}
                            title={productInfos[0].coverUnitDuration}
                        />
                    </div>
                    <p className="schedule-cover-body__cancel-your-payment-option">
                        {parseString(content.cancelYourPaymentOption)}
                    </p>
                </>
            ) : (
                <Fieldset
                    className="schedule-cover-body__cover-tiles"
                    legend={
                        <span className="schedule-cover-body__choose-option-legend">{content.paymentOptionLegend}</span>
                    }
                >
                    <div className="schedule-cover-body__cover-options">
                        {productInfos.map((product) => (
                            <CoverTile
                                key={product.id}
                                {...product}
                                isActive={product.id === selectedCover}
                                value={product.id}
                                onChange={(value) => {
                                    selectCover(value);
                                }}
                                title={product.paymentModel}
                            />
                        ))}
                    </div>
                </Fieldset>
            )}
            <p className="schedule-cover-body__select-cover-dates">{content.selectCoverDates}</p>
            <div className="schedule-cover-body__date-picker-modal-wrapper">
                <DatePickerModal
                    value={daysToSchedule}
                    onChange={(dates) => handleDateSubmission(dates)}
                    mode={datePickerMode}
                    disabled={!canSelectDates}
                    disabledDays={disabledDays}
                    onCalendarOpen={handleCalendarOpen}
                />
            </div>

            {selectedCover && (
                <div className="schedule-cover-body__slide-in-benefits-wrapper">
                    <SlideInCoverTermsCTA
                        selectedCover={selectedCover}
                        pdsVersion={getPdsVersion(selectedCover as Cover)}
                        benefitsContent={{
                            benefitsCTA: content.benefitsCTA,
                            injuriesCoveredTab: content.injuriesCoveredTab,
                            injuriesNotCoveredTab: content.injuriesNotCoveredTab,
                        }}
                        trackDrawerOpen={() => {
                            trackBenefitsDrawer(CoverInformation[selectedCover].coverCode);
                        }}
                    />
                </div>
            )}
            {!!cartError && cartLoading && !!tryAgainCart && (
                <CartError
                    cartError={cartError as CartErrorDisplay}
                    tryAgainEvent={tryAgainCart}
                    prevStepEvent={goBack}
                    showAction={false}
                />
            )}
            {!!cartError === false && (
                <Button
                    label={content.continueCTA}
                    width="full"
                    className="schedule-cover-body__continue-cta"
                    disabled={!canCheckout}
                    onClick={proceedToCheckout}
                />
            )}

            <PdsTmdDisclaimer />
        </Layout>
    );
};

export default withContent(ScheduleCoverBody, contentMap, scheduleCoverContent);
