import React from 'react';
import { Redirect } from 'react-router';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import { Policy } from '../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { Layout } from '../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import profileStrings from '../../strings/profileFlow';
import Routes from '../../utils/Routes';
import ManageCoverItemCards from './manage-cover-item-cards/ManageCoverItemCards';

import './ManageCoverV2.scss';

const ManageCoverV2: React.FC = () => {
    const {
        userTimeZone,
        dependants,
        userDetails: { personId },
    } = useUser();
    const { policies, openCoversInitialised } = useOpenCovers();

    if (!openCoversInitialised) {
        return <LoadingSpinnerOverlay />;
    }

    if (policies.length <= 0) {
        return <Redirect to={Routes.PROFILE} />;
    }

    const [userPolicies, dependantsPolicies] = policies.reduce<Array<Array<Policy>>>(
        (acc, p) => {
            if (p.mainCover.insuredPersonId === null || p.mainCover.insuredPersonId === personId) {
                acc[0].push(p);
            } else {
                acc[1].push(p);
            }
            return acc;
        },
        [[], []],
    );

    const dependantPoliciesToRender = dependants
        .sort((a, b) => a.firstName!.localeCompare(b.firstName!))
        .map((d) => ({
            key: d.personId,
            firstName: d.firstName,
            policies: dependantsPolicies.filter((p) => p.mainCover.insuredPersonId! === d.personId),
        }));

    return (
        <Layout showBackButton bgPattern={false} title={profileStrings.manageCoverLabel}>
            {/* User's cover */}
            <div className="manage-cover-v2__manage-cover-item-cards-wrapper">
                <ManageCoverItemCards policies={userPolicies} userTimeZone={userTimeZone} />
            </div>

            {dependantPoliciesToRender.map((d) => (
                <div key={d.key} className="manage-cover-v2__manage-cover-item-cards-wrapper">
                    <ManageCoverItemCards {...d} userTimeZone={userTimeZone} />
                </div>
            ))}
        </Layout>
    );
};

export default ManageCoverV2;
