import Address, { AddressFields, CountryOptionTypes } from '../business-logic/models/Address';

export const addressToString = ({ addressFields, autocompleteResult }: Address): string => {
    if (autocompleteResult) {
        // If name is repeated in formatted address exclude name
        return autocompleteResult.formatted_address?.match(new RegExp(`^${autocompleteResult.name}`))
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              autocompleteResult.formatted_address!
            : `${autocompleteResult.name}, ${autocompleteResult.formatted_address}`;
    }

    if (addressFields) {
        if (
            (addressFields.country as typeof CountryOptionTypes[keyof typeof CountryOptionTypes])?.value === 'au' &&
            addressFields.state !== null
        ) {
            return `${addressFields.address1}, ${addressFields.address2 && `${addressFields.address2}, `}${
                addressFields.suburb
            } ${addressFields.state.label} ${addressFields.postcode}, ${
                (addressFields.country as typeof CountryOptionTypes[keyof typeof CountryOptionTypes]).label
            }`;
        }
        return `${addressFields.address1}, ${addressFields.address2 && `${addressFields.address2}, `}${
            addressFields.city
        } ${addressFields?.stateProvinceRegion} ${addressFields.postcode}, ${
            (addressFields.country as typeof CountryOptionTypes[keyof typeof CountryOptionTypes]).label ||
            addressFields.country
        }`;
    }

    return '';
};

// Converts address strings from BE to address fields objects to be displayed
export const stringToAddressFields = (addressString: string): AddressFields => {
    const matchResults = addressString.match(/(.*), (.*) (NSW|VIC|QLD|WA|NT|TAS|SA|ACT) (\d{4}), (.*)/);

    if (matchResults) {
        const multipleLinesAddress = matchResults[1].match(/(.*), (.*)/);
        const countryOptionType = Object.values(CountryOptionTypes).find((option) => option.label === matchResults[5]);

        if (countryOptionType) {
            if (countryOptionType.value === 'au') {
                if (multipleLinesAddress) {
                    return {
                        address1: multipleLinesAddress[1],
                        address2: multipleLinesAddress[2],
                        suburb: matchResults[2],
                        city: '',
                        state: { value: matchResults[3], label: matchResults[3] },
                        stateProvinceRegion: '',
                        postcode: matchResults[4],
                        country: countryOptionType,
                    };
                }
                return {
                    address1: matchResults[1],
                    address2: '',
                    suburb: matchResults[2],
                    city: '',
                    state: { value: matchResults[3], label: matchResults[3] },
                    stateProvinceRegion: '',
                    postcode: matchResults[4],
                    country: countryOptionType,
                };
            }

            return {
                address1: '',
                address2: '',
                suburb: '',
                city: '',
                state: null,
                stateProvinceRegion: '',
                postcode: '',
                country: countryOptionType,
            };
        }
    }

    return {
        address1: '',
        address2: '',
        suburb: '',
        city: '',
        state: null,
        stateProvinceRegion: '',
        postcode: '',
        country: '',
    };
};
