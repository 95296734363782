import { FC, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import ModalWithCTA from '../../components/modal-with-cta/ModalWithCTA';
import checkAgeEligibilityContent from '../../content/ui/components/check-age-eligibility/checkAgeEligibility';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import toDate from '../../utils/toDate';
import getAge from '../../validation/getAge';
import withContent from '../with-content/withContent';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';

const ACTIVE_MIN_AGE = 18;
const ACTIVE_MAX_AGE = 64;
const KIDS_MIN_AGE = 5;
const KIDS_MAX_AGE = 17;

const contentMap = {
    title: 'ui.title',
    description: 'ui.description',
    cta: 'ui.cta',
};

function checkRoamingAgeEligibility<T extends JSX.IntrinsicAttributes>(WrappedComponent: FC<T>) {
    const WrappedComponentWithAgeCheck = (
        props: T & { content: Record<keyof typeof contentMap, string> },
    ): ReactElement => {
        const { content, ...passThroughProps } = props;

        const { userDetails, dependants } = useUser();
        const [showModal, setShowModal] = useState(false);
        const { coverFor, selectedKidId } = useRoaming();

        const history = useHistory();
        const backToDashboard = () => {
            history.push(Routes.HOME);
        };

        useEffect(() => {
            if (typeof userDetails.dob === 'undefined') {
                history.push(Routes.DATE_OF_BIRTH);
                return;
            }
            if (coverFor === 'self') {
                const { day, month, year } = toDate(userDetails.dob);

                const isMinAge = getAge(day, month, year) >= ACTIVE_MIN_AGE;

                const isMaxAge = getAge(day, month, year) <= ACTIVE_MAX_AGE;
                if (!isMinAge || !isMaxAge) {
                    setShowModal(true);
                }
            } else if (selectedKidId && coverFor === 'dependant') {
                const dependant = dependants.find((d) => d.personId === selectedKidId);

                const { day, month, year } = toDate(dependant!.dob);

                const isMinAge = getAge(day, month, year) >= KIDS_MIN_AGE;

                const isMaxAge = getAge(day, month, year) <= KIDS_MAX_AGE;

                if (!isMinAge || !isMaxAge) {
                    setShowModal(true);
                }
            }
        }, [coverFor, dependants, history, selectedKidId, userDetails.dob]);

        return (
            <>
                <ModalWithCTA
                    isOpen={showModal}
                    textContent={checkAgeEligibilityContent.formatString(content.title, {
                        minAge: (coverFor === 'dependant' ? KIDS_MIN_AGE : ACTIVE_MIN_AGE).toString(),
                        maxAge: (coverFor === 'dependant' ? KIDS_MAX_AGE : ACTIVE_MAX_AGE).toString(),
                    })}
                    hasError
                    errorMessage={
                        <span>
                            {checkAgeEligibilityContent.formatString(content.description, {
                                customerSupport: (
                                    <a href={`mailto:${common.flipSupportEmail}`}>{common.customerSupport}</a>
                                ),
                            })}
                        </span>
                    }
                    primaryCTA={{ label: content.cta, onClick: backToDashboard }}
                />
                <WrappedComponent {...passThroughProps} />
            </>
        );
    };

    return withContent(WrappedComponentWithAgeCheck, contentMap, checkAgeEligibilityContent);
}

export default checkRoamingAgeEligibility;
