import { useOktaAuth } from '@okta/okta-react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import ModalWithCTA from '../../components/modal-with-cta/ModalWithCTA';
import checkAgeEligibilityContent from '../../content/ui/components/check-age-eligibility/checkAgeEligibility';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import toDate from '../../utils/toDate';
import getAge from '../../validation/getAge';
import withContent from '../with-content/withContent';

const ACTIVE_MIN_AGE = 18;
const ACTIVE_MAX_AGE = 64;

const contentMap = {
    title: 'ui.title',
    description: 'ui.description',
    cta: 'ui.cta',
};

function checkAgeEligibilityBaymax<T extends JSX.IntrinsicAttributes>(WrappedComponent: FC<T>) {
    const WrappedComponentWithAgeCheck = (
        props: T & { content: Record<keyof typeof contentMap, string> },
    ): ReactElement => {
        const { authState } = useOktaAuth();

        const { content, ...passThroughProps } = props;

        const { userDetails } = useUser();
        const [showModal, setShowModal] = useState(false);

        const history = useHistory();

        const backToDashboard = () => {
            history.push(Routes.HOME);
        };

        useEffect(() => {
            // If guest, do not check for age eligibility
            if (!authState?.isAuthenticated) {
                return;
            }

            if (typeof userDetails.dob === 'undefined') {
                history.push(Routes.DATE_OF_BIRTH);
                return;
            }

            const { day, month, year } = toDate(userDetails.dob);

            const isMinAge = getAge(day, month, year) >= ACTIVE_MIN_AGE;

            const isMaxAge = getAge(day, month, year) <= ACTIVE_MAX_AGE;

            if (!isMinAge || !isMaxAge) {
                setShowModal(true);
            }
        }, [authState?.isAuthenticated, history, userDetails.dob]);

        return (
            <>
                <ModalWithCTA
                    isOpen={showModal}
                    textContent={checkAgeEligibilityContent.formatString(content.title, {
                        minAge: ACTIVE_MIN_AGE.toString(),
                        maxAge: ACTIVE_MAX_AGE.toString(),
                    })}
                    hasError
                    errorMessage={
                        <span>
                            {checkAgeEligibilityContent.formatString(content.description, {
                                customerSupport: (
                                    <a href={`mailto:${common.flipSupportEmail}`}>{common.customerSupport}</a>
                                ),
                            })}
                        </span>
                    }
                    primaryCTA={{ label: content.cta, onClick: backToDashboard }}
                />
                <WrappedComponent {...passThroughProps} />
            </>
        );
    };

    return withContent(WrappedComponentWithAgeCheck, contentMap, checkAgeEligibilityContent);
}

export default checkAgeEligibilityBaymax;
