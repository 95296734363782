import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse from '../../business-logic/models/CustomerResponse';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const partnerEventSignUp = async ({
    accessToken,
    partnerEventSignUpCode,
    axios,
}: {
    accessToken: string;
    partnerEventSignUpCode: string;
} & ServiceArgs): Promise<void> => {
    await axios.post<CustomerResponse>(
        `${baseApiPath}/api/v1/partner-event-sign-up`,
        { partnerEventSignUpCode },
        toHeaders(accessToken),
    );
};

export default asService(partnerEventSignUp);
