const Pricing = {
    dayPass: '$6',
    dayPassWithDecimal: '$6.00',
    alwaysOn: '$9',
    alwaysOnWithDecimal: '$9.00',
    japanPass: '$25',
    japanPassWithDecimal: '$25.00',
    boost: '$6',
    boostWithDecimal: '$6.00',
    flipActiveDaily: '$7',
    flipActiveDailyPassWithDecimal: '$7.00',
    flipActiveWeekly: '$25',
    flipActiveWeeklyWithDecimal: '$25.00',
    flipActiveSubMonthly: '$40',
    flipActiveSubMonthlyWithDecimal: '$40.00',
    flipActiveBoost: '$7',
    flipActiveBoostWithDecimal: '$7.00',
    payouts: { tier1: '$200', tier2: '$2,000', tier3: '$20,000' },
    boostPayouts: { tier1: '$400', tier2: '$4,000', tier3: '$40,000' },
};

export default Pricing;
