import cancelIcon from '../../../../assets/images/cancel-circle.svg';
import { Cover as OpenCover, Policy } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { ActionItemProps } from '../../../../components/action-list/action-item/ActionItem';
import coverListCardContent from '../../../../content/ui/components/cover-list-card/coverListCard';
import Cover from '../../../../utils/constants/Cover';
import DateFormat from '../../../../utils/constants/DateFormat';
import formatEndingTime from '../../../../utils/formatEndingTime';
import getProductInfo from '../../../../utils/getProductInfo';
import unixNumToString from '../../../../utils/unixNumToString';
import ActiveBadge from '../active-badge/ActiveBadge';
import renderPdsVersion from './renderPdsVersion';

const createBoostCoverListItem = (
    subscription: Policy,
    boostCover: OpenCover,
    timeZone: string,
    onCancelCover: (coverId: string) => void,
): ActionItemProps => {
    const productInfo = getProductInfo(
        Cover[boostCover.coverCode.toUpperCase() as keyof typeof Cover],
        subscription.PDSVersion,
    );

    const content = {
        coverScheduled: coverListCardContent.formatString(coverListCardContent.coverScheduled, {
            startDate: unixNumToString(boostCover.activeFrom, timeZone, DateFormat.DATE),
        }),
        coverActive: coverListCardContent.formatString(coverListCardContent.coverActive, {
            coverName: productInfo.name,
            endDateTime: <strong>{formatEndingTime(boostCover.activeTo, timeZone)}</strong>,
        }),
        cancelCoverAriaLabel: coverListCardContent.formatString(coverListCardContent.cancelCoverAriaLabel, {
            coverName: productInfo.name,
        }),
    };

    return boostCover.status === 'Started'
        ? {
              leftIcon: productInfo.icon,
              rightIcon: <ActiveBadge />,
              header: productInfo.name,
              customDataElement: (
                  <>
                      <p className="cover-list-card__action-item-subtext">{content.coverActive}</p>
                      {renderPdsVersion(subscription.PDSVersion)}
                  </>
              ),
          }
        : {
              leftIcon: productInfo.icon,
              rightIcon: <img src={cancelIcon} alt="" className="cover-list-card__active-badge-with-cancel-icon" />,
              header: productInfo.name,
              customDataElement: (
                  <>
                      <p className="cover-list-card__action-item-subtext">{content.coverScheduled}</p>
                      {renderPdsVersion(subscription.PDSVersion)}
                  </>
              ),
              onClick: () => onCancelCover(boostCover.insuranceCoverId),
              ariaLabel: content.cancelCoverAriaLabel as string,
          };
};

export default createBoostCoverListItem;
