import React from 'react';
import { useHistory } from 'react-router';
import Routes from '../../../utils/Routes';
import ClaimSubmissionSuccessIcon from '../../../assets/lotties/claim-submission-success-icon.json';
import { SuccessPage } from '../../../components/success-page/SuccessPage';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import parseString from '../../../utils/parseString';

const ClaimSubmitted: React.FC = () => {
    const history = useHistory();

    const handleBtnClick = () => {
        history.push(Routes.CLAIMS);
    };

    return (
        <SuccessPage
            header={claimsFlowStrings.claimSubmitted.title}
            description={parseString(claimsFlowStrings.claimSubmitted.description)}
            icon={ClaimSubmissionSuccessIcon}
            buttonLabel={commonStrings.done}
            buttonOnClick={handleBtnClick}
        />
    );
};

export default ClaimSubmitted;
