import { CoverInfo, CoverInfoV1 } from './types';

import motorcycling from '../../../assets/images/not-covered/motorcycling.svg';
import nonSportsWork from '../../../assets/images/not-covered/non-sports-work.svg';
import overuse from '../../../assets/images/not-covered/overuse.svg';
import overuseGrogu from '../../../assets/images/not-covered/overuse-grogu.svg';
import preExisting from '../../../assets/images/not-covered/pre-existing.svg';
import dental from '../../../assets/images/not-covered/dental.svg';
import outsideAus from '../../../assets/images/not-covered/outside-aus.svg';
import travelOverseas from '../../../assets/images/not-covered/travel-overseas.svg';
import medical from '../../../assets/images/not-covered/medical.svg';
import alcoholDrugs from '../../../assets/images/not-covered/alcohol-drugs.svg';
import suicide from '../../../assets/images/not-covered/suicide.svg';
import illegalAct from '../../../assets/images/not-covered/illegal-act.svg';
import illegalActGrogu from '../../../assets/images/not-covered/illegal-act-grogu.svg';
import war from '../../../assets/images/not-covered/war.svg';

import dislocationIcon from '../../../assets/images/injuries/dislocation-with-pattern.svg';
import dentalIcon from '../../../assets/images/injuries/dental-with-pattern.svg';
import paraplegiaIcon from '../../../assets/images/injuries/paraplegia-with-pattern.svg';
import { AlwaysOnPdsVersion } from '../../../utils/constants/PdsVersion';

const preGroguTerms: CoverInfoV1 = {
    coverTermsVersion: '1',
    whatsCovered: {
        description:
            "If you have an accident and suffer a specified injury as a result while your insurance is active, here's what you get.",
        coverTiers: [
            {
                title: 'Minor injuries that don’t need surgery',
                icon: dislocationIcon,
                payoutAmount: '$200',
                boostPayoutAmount: '$400',
                description: 'For things like muscle sprains and minor dental injuries.',
                conditionsDescription: 'Minor injuries include:',
                descriptionLines: [
                    '{strong}Injuries</strong> to muscles, joints & bones that happen suddenly.',
                    '{strong}Wounds requiring stitches.</strong>',
                    '{strong}Minor dental</strong> injuries.',
                ],
                includedConditions: [
                    {
                        description:
                            'Sprains and strains to muscles, ligaments, tendons, or cartilage that are sudden onset',
                    },
                    { description: 'Wounds requiring stitches or ongoing care' },
                    {
                        description:
                            'Minor dental injuries which means concussion of the tooth or fracturing of the tooth enamel or dentin',
                    },
                    {
                        description: 'Broken bones and dislocated joints that don’t require surgery.',
                    },
                ],
                disclaimer:
                    "You'll need to see a health professional to confirm the injury within 14 days of your accident.",
            },
            {
                title: 'Injuries that do need surgery',
                icon: dentalIcon,
                payoutAmount: '$2,000',
                boostPayoutAmount: '$4,000',
                description: 'For things like major surgery or major dental.',
                conditionsDescription: 'Our surgery or major dental cover includes:',
                descriptionLines: [
                    'Like a {strong}torn ACL</strong> that needs reconstruction or a {strong}broken bone</strong> that needs pin or rods.',
                    '{strong}Major dental</strong> from a cracked, knocked out or displaced tooth:',
                ],
                includedConditions: [
                    { description: 'Surgery requiring admission to hospital' },
                    {
                        description:
                            'A displaced, fractured, or knocked out tooth, and as a result, you had major dental treatments like crown and bridge work, implants, dentures, splints, or root canal therapy.',
                    },
                ],
                disclaimer: 'Treatments must occur within 6 months of the accident.',
            },
            {
                title: 'Significant loss or disability',
                icon: paraplegiaIcon,
                payoutAmount: '$20,000',
                boostPayoutAmount: '$40,000',
                description: 'For really serious stuff like permanent disability.',
                conditionsDescription: 'Our significant loss or disability cover is for:',
                descriptionLines: [
                    'Like {strong}traumatic brain</strong> or {strong}spinal injuries.</strong>',
                    'The loss of {strong}limbs, sight or hearing.</strong>',
                ],
                includedConditions: [
                    { description: 'Use of both arms, both legs, or one arm and one leg' },
                    { description: "Hearing in both ears which can't be corrected" },
                    { description: "Sight in both eyes which can't be corrected" },
                ],
                disclaimer: 'For these unfortunate injuries, the loss needs to be total and irreversible.',
            },
        ],
    },
    whatsNotCovered: {
        description: "We won't pay claims that are a result of",
        exclusions: [
            {
                name: 'Motorcycling',
                icon: motorcycling,
            },
            {
                name: 'Work (except sport-related work)',
                icon: nonSportsWork,
                description:
                    "Paid work or employment isn't covered (e.g. food delivery rider), except when the paid work or employment is a sports related activity (e.g. tennis coaching)",
            },
            {
                name: 'Any overuse injury, like shin splints',
                icon: overuse,
                description: 'We only cover injuries that are caused by accidents',
            },
            {
                name: 'Dental injury when eating or drinking',
                icon: dental,
                description:
                    "Dental injury caused by food or drink including any foreign body in it while being consumed isn't covered",
            },
            {
                name: 'Accidents not in Australia',
                icon: outsideAus,
            },
            {
                name: 'Medical stuff',
                icon: medical,
                description:
                    "Any accident that happens directly as a result of, and mainly because of a medical episode, or a medical condition, or a complication relating to a medical procedure isn't covered. For example, you won't be covered where you suffer a specified injury that occurs as a result of fainting, and you also won't be covered where you suffer a specified injury that occurs as a result of a car crash, if the car crash was caused by an epileptic seizure.",
            },
            {
                name: 'Booze and drugs',
                icon: alcoholDrugs,
                description:
                    "Being under the influence of alcohol, or taking illicit drugs, or taking medication in a way not as directed isn't covered. Under the influence of alcohol means being over the prescribed legal limit for driving a motor vehicle according to the relevant state or territory limit.",
            },
            {
                name: 'Intentional self-injury',
                icon: suicide,
            },
            {
                name: 'Breaking the law',
                icon: illegalAct,
                description: "If you're injured while involved in an illegal act, you're not covered ",
            },
            {
                name: 'War or civil commotion',
                icon: war,
                description:
                    "Acts of war, whether declared or not, hostilities, civil commotion, terrorism or insurrection aren't covered",
            },
        ],
        claimLimits: {
            description: 'Also, there are claim limits. In a rolling 12-month rolling period you can only claim:',
            items: ['Two times for the same injury on the same part of your body', 'Four times for Tier 1 injuries'],
        },
    },
};

const groguTerms: CoverInfoV1 = {
    coverTermsVersion: '1',
    whatsCovered: {
        description:
            "If you have an accident and suffer a specified injury as a result while your insurance is active, here's what you get.",
        coverTiers: [
            {
                title: 'Injuries that don’t need surgery',
                icon: dislocationIcon,
                payoutAmount: '$200',
                boostPayoutAmount: '$400',
                description: 'For things like sprains, strains and minor dental injuries.',
                descriptionLines: [
                    '<strong>Injuries</strong> to muscles, joints & bones that happen suddenly.',
                    '<strong>Wounds requiring stitches.</strong>',
                    '<strong>Minor dental</strong> injuries.',
                ],
                conditionsDescription: 'Minor injuries include:',
                includedConditions: [
                    {
                        description:
                            'Sprains and strains to muscles, ligaments, tendons, or cartilage that happen suddenly (not from overuse or wear and tear)',
                    },
                    { description: 'Wounds requiring stitches or ongoing care' },
                    {
                        description: 'Minor dental injuries, which means chipped or knocked (concussed) tooth',
                    },
                    {
                        description: 'Broken bones and dislocated joints that don’t require surgery.',
                    },
                ],
            },
            {
                title: 'Injuries that do need surgery',
                icon: dentalIcon,
                payoutAmount: '$2,000',
                boostPayoutAmount: '$4,000',
                description: 'For things like surgery or major dental.',
                conditionsDescription: 'Our surgery or major dental cover includes:',
                descriptionLines: [
                    'Like a <strong>torn ACL</strong> that needs reconstruction or a <strong>broken bone</strong> that needs pins or rods.',
                    '<strong>Major dental</strong> from a cracked, knocked out or displaced tooth:',
                ],
                includedConditions: [
                    { description: 'Surgery in a hospital for any physical injury' },
                    {
                        description:
                            'The following dental treatments because of a cracked, knocked out or displaced tooth:',
                        items: ['Crown work', 'Bridge work', 'Implants', 'Dentures', 'Splints', 'Root canal'],
                    },
                ],
            },
            {
                title: 'Significant loss or disability',
                icon: paraplegiaIcon,
                payoutAmount: '$20,000',
                boostPayoutAmount: '$40,000',
                description: 'For really serious injuries like significant loss or disability.',
                conditionsDescription: 'Our significant loss or disability cover is for:',
                descriptionLines: [
                    'Like <strong>traumatic brain</strong> or <strong>spinal injuries.</strong>',
                    "The loss of <strong>limbs, sight or hearing that can't be corrected.</strong>",
                ],
                includedConditions: [
                    {
                        description:
                            "Traumatic brain injury that results in the inability to move two or more limbs (arms or legs) that's persistent 1 month after the injury",
                    },
                    {
                        description:
                            "Traumatic spinal cord injury that results in the inability to move two or more limbs (arms or legs) that's persistent 1 month after the injury",
                    },
                    {
                        description: 'An injury that results in the removal of a hand, arm, foot or leg',
                    },
                    {
                        description:
                            "An injury that has caused a reduction in eyesight to below the Australian legal driving limit, that can't be corrected even with glasses",
                    },
                    {
                        description:
                            "An injury that has caused severe hearing loss in both ears that can't be corrected even with hearing aids",
                    },
                ],
            },
        ],
        additionalNotes: [
            'You get one payout per accident (if you get multiple injuries in the one accident, you get the highest payout, once).',
            'Accident prone? No stress. You can keep claiming for separate accidents.',
            "For all Specified Injuries, you'll need to see the relevant Health Professional no later than 14 days after your Accident. If your injury needs any of the surgery or dental treatment listed above, you'll also need to have this done within 6 months after your Accident.",
        ],
    },
    whatsNotCovered: {
        description: "We won't pay claims that are a result of",
        exclusions: [
            {
                name: 'Motorcycling',
                icon: motorcycling,
                description: "If you're riding a motorbike when your injury happens.",
            },
            {
                name: 'Non sports related work',
                icon: nonSportsWork,
                description:
                    "Paid work or employment isn't covered (e.g. food delivery rider), except when the paid work or employment is a sports related activity (e.g. tennis coaching).",
            },
            {
                name: 'Overuse injuries',
                icon: overuseGrogu,
                description:
                    "We only cover acute injuries caused by accidents, i.e. they happen all of a sudden. Any overuse injury, like shin splints, runners knee and tennis elbow aren't covered.",
            },
            {
                name: 'Pre-existing injuries or conditions',
                icon: preExisting,
                description:
                    "If you have an accident that's caused by an existing condition or injury, or if you're claiming for an injury you got before your insurance was turned on.",
            },
            {
                name: 'Dental injury when eating or drinking',
                icon: dental,
                description:
                    'Dental injuries caused by food or drink including any foreign object in it while being consumed.',
            },
            {
                name: 'Travel outside of Aus. or N.Z.',
                icon: travelOverseas,
                description: "If you're not in Australia or New Zealand when your injury happens.",
            },
            {
                name: 'Medical stuff',
                icon: medical,
                description:
                    'Injuries resulting from accidents caused by medical conditions or medical episodes,  eg. you suffer a specified injury that occurs as a result of fainting, or a dislocated shoulder caused by chronic shoulder instability or a joint injury caused by osteoarthritis.',
            },
            {
                name: 'Booze and drugs',
                icon: alcoholDrugs,
                description:
                    'Being under the influence of alcohol, taking illicit drugs, or taking medication in a way not as directed. ',
            },
            {
                name: 'Intentional self-injury',
                icon: suicide,
                description:
                    "If you hurt yourself on purpose, that's not covered. For example, popping your own shoulder out to prove to your mates you can.",
            },
            {
                name: 'Breaking the law',
                icon: illegalActGrogu,
                description:
                    "If you're injured while involved in an illegal act, you're not covered.\nInvolvement in an illegal act e.g. riding an e-scooter in public spaces in NSW, assaulting someone, solo paragliding without the relevant certification or flying within a restricted airspace.",
            },
            {
                name: 'War or civil commotion',
                icon: war,
                description:
                    "If you're hurt during an act of war, whether declared or not, hostilities, civil commotion, terrorism or insurrection.",
            },
        ],
    },
};

const alwaysOnCoverTerms: Record<AlwaysOnPdsVersion, CoverInfo> = {
    [AlwaysOnPdsVersion.ALPHA_V1]: preGroguTerms,
    [AlwaysOnPdsVersion.ALPHA_V2]: preGroguTerms,
    [AlwaysOnPdsVersion.BETA]: preGroguTerms,
    [AlwaysOnPdsVersion.GROGU]: groguTerms,
    [AlwaysOnPdsVersion.GROGU_SCHEDULE]: groguTerms,
    [AlwaysOnPdsVersion.GROGU_SEPT_UPDATE]: groguTerms,
    [AlwaysOnPdsVersion.GROGU_SCHEDULING_LIMIT_UPDATE]: groguTerms,
};

export default alwaysOnCoverTerms;
