import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import queryString from 'query-string';

type TParams = {
    [key: string]: string;
};

type TReturn = {
    navigate: (url: string, params?: TParams) => void;
};

const useCustomHistory = (): TReturn => {
    const history = useHistory();
    const { search } = useLocation();

    const objectToSearch = (obj: TParams) => {
        return Object.keys(obj).length > 0 ? `?${queryString.stringify(obj)}` : '';
    };

    const navigate = (url: string, params?: TParams) => {
        history.push(`${url}${objectToSearch({ ...(queryString.parse(search) as TParams), ...params })}`);
    };

    return {
        navigate,
    };
};

export default useCustomHistory;
