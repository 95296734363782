import LocalizedStrings from 'react-localization';
import Cover from '../../../utils/constants/Cover';
import ByProduct from '../ByProduct';
import alwaysOnProductInfo from './alwaysOnProductInfo';
import dayPassProductInfo from './dayPassProductInfo';
import flipActiveDailyProductInfo from './flipActiveDailyProductInfo';
import flipActiveSubMonthlyProductInfo from './flipActiveSubMonthlyProductInfo';
import flipActiveWeeklyProductInfo from './flipActiveWeeklyProductInfo';
import flipKidsDailyProductInfo from './flipKidsDailyProductInfo';
import flipKidsSubMonthlyProductInfo from './flipKidsSubMonthlyProductInfo';
import flipKidsWeeklyProductInfo from './flipKidsWeeklyProductInfo';
import japanPassProductInfo from './japanPassProductInfo';
import { ProductInfo } from './types';
import flipActiveBoostProductInfo from './flipActiveBoostProductInfo';
import boostProductInfo from './boostProductInfo';
import flipRoamingProductInfo from './flipRoamingProductInfo';
import flipRoamingKidsProductInfo from './flipRoamingKidsProductInfo';

const productInfo = new LocalizedStrings<ByProduct<ProductInfo>>({
    en: {
        [Cover.ALWAYS_ON]: alwaysOnProductInfo,
        [Cover.BOOST]: boostProductInfo,
        [Cover.DAY_PASS]: dayPassProductInfo,
        [Cover.JAPAN_PASS]: japanPassProductInfo,
        [Cover.FLIP_ACTIVE_DAILY]: flipActiveDailyProductInfo,
        [Cover.FLIP_ACTIVE_WEEKLY]: flipActiveWeeklyProductInfo,
        [Cover.FLIP_ACTIVE_SUB_MONTHLY]: flipActiveSubMonthlyProductInfo,
        [Cover.FLIP_KIDS_DAILY]: flipKidsDailyProductInfo,
        [Cover.FLIP_KIDS_WEEKLY]: flipKidsWeeklyProductInfo,
        [Cover.FLIP_KIDS_SUB_MONTHLY]: flipKidsSubMonthlyProductInfo,
        [Cover.FLIP_ACTIVE_BOOST]: flipActiveBoostProductInfo,
        [Cover.FLIP_ROAMING_WEEKLY]: flipRoamingProductInfo,
        [Cover.FLIP_ROAMING_KIDS_WEEKLY]: flipRoamingKidsProductInfo,
    },
});

export default productInfo;
