import LocalizedStrings from 'react-localization';

const purchaseMoreCover = new LocalizedStrings({
    en: {
        header: 'Purchase more cover',
        buyNowCTA: 'Buy now',
        title: 'Check out our cover options',
        activeCTA: 'Flip Active benefits',
        kidsCTA: 'Flip Kids benefits',
        roamingCTA: 'Flip Roaming benefits',
        whatYouGetDisclaimer: '* If your claim is approved.',
        injuriesCoveredTab: 'Injuries covered',
        injuriesNotCoveredTab: 'Injuries not covered',
    },
});

export default purchaseMoreCover;
