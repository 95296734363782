import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';

import paymentStrings from '../../../strings/payments';
import Alert, { AlertSizes, AlertTypes } from '../../alert/Alert';
import paymentElementMachine from './payment-element-machine/paymentElementMachine';
import StripeElementProvider from './stripe-element-provider/StripeElementProvider';
import DefaultCardPaymentElement from './default-card-payment-element/DefaultCardPaymentElement';
import StripePaymentElement from './stripe-payment-element/StripePaymentElement';
import NewCardPaymentButton from './payment-buttons/NewCardPaymentButton';
import common from '../../../strings/common';

import './PaymentMethodPaymentElement.scss';

interface PaymentMethodPaymentElementProps {
    paymentElementMachineRef: ActorRefFrom<typeof paymentElementMachine>;
    amountDue: number;
}

const PaymentMethodPaymentElement: FC<PaymentMethodPaymentElementProps> = (props) => {
    const { paymentElementMachineRef } = props;
    const [state, send] = useActor(paymentElementMachineRef);

    const hasDefaultCard = state.matches('defaultCard');
    const hasSetupCardError =
        state.matches('newCard.displaySetupCardError') ||
        state.matches('defaultCard.collectCardDetails.displaySetupCardError');
    const hasSetupIntentError =
        state.matches('defaultCard.collectCardDetails.displaySetupIntentError') ||
        state.matches('newCard.displaySetupIntentError');

    if (hasSetupIntentError) {
        return (
            <Alert
                type={AlertTypes.ERROR}
                size={AlertSizes.LARGE}
                cta={{ label: common.tryAgain, onClick: () => send('SETUP_INTENT_TRY_AGAIN') }}
            />
        );
    }

    return (
        <form>
            <h2>{paymentStrings.cardDetails}</h2>

            {hasDefaultCard ? (
                <DefaultCardPaymentElement {...props} />
            ) : (
                <StripeElementProvider paymentElementMachineRef={paymentElementMachineRef}>
                    <StripePaymentElement stripePaymentElementMachineRef={state.context.stripePaymentElementMachine} />
                    <NewCardPaymentButton {...props} />
                </StripeElementProvider>
            )}

            {hasSetupCardError && (
                <Alert
                    className="payment-method-payment-element__error"
                    type={AlertTypes.ERROR}
                    message={
                        <span className="payment-method-payment-element__error-message">
                            {state.context.setupCardError}
                        </span>
                    }
                />
            )}
        </form>
    );
};

export default PaymentMethodPaymentElement;
