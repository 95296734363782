import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { Modifier } from 'react-day-picker';
import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import ProductList from '../../../../analytics/ProductList';
import Cover, { MainCover } from '../../../../utils/constants/Cover';
import Limits from '../../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../../utils/getLastDayToSchedule';
import { ScheduleCoverGuestMachineContextTypes } from '../context/scheduleCoverGuestMachineContext';
import { Typegen0 } from '../scheduleCoverGuestMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const initialiseCoversAvailable = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['initialiseCoversAvailable'] }
>({
    coversAvailable: () => {
        const coversAvailable: MainCover[] = [
            Cover.FLIP_ACTIVE_DAILY,
            Cover.FLIP_ACTIVE_WEEKLY,
            Cover.FLIP_ACTIVE_SUB_MONTHLY,
        ];
        return coversAvailable;
    },
});

export const initialiseCoversAvailableForDependants = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['initialiseCoversAvailableForDependants'] }
>({
    coversAvailable: () => {
        const coversAvailable: MainCover[] = [
            Cover.FLIP_KIDS_DAILY,
            Cover.FLIP_KIDS_WEEKLY,
            Cover.FLIP_KIDS_SUB_MONTHLY,
        ];

        return coversAvailable;
    },
});

export const setSelectedCover = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['setSelectedCover']; data: MainCover }
>({
    selectedCover: (ctx, event) => event.data,
});

export const setDatePickerMode = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['setDatePickerMode']; data: MainCover }
>({
    datePickerMode: (ctx, event) => {
        if (event.data === Cover.FLIP_ACTIVE_SUB_MONTHLY || event.data === Cover.FLIP_KIDS_SUB_MONTHLY) {
            return 'single';
        }
        if (event.data === Cover.FLIP_ACTIVE_DAILY || event.data === Cover.FLIP_KIDS_DAILY) {
            return 'multiple';
        }
        if (event.data === Cover.FLIP_ACTIVE_WEEKLY || event.data === Cover.FLIP_KIDS_WEEKLY) {
            return 'week';
        }
        return 'single';
    },
});

export const setDaysToSchedule = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['setDaysToSchedule']; data: Date[] }
>({
    daysToSchedule: (ctx, event) => event.data,
});

export const resetDaysToSchedule = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['resetDaysToSchedule']; data: MainCover }
>({
    daysToSchedule: () => [],
});

export const evaluateDisabledGuestDays = assign<
    ScheduleCoverGuestMachineContextTypes,
    { type: EventsCausingActions['evaluateDisabledGuestDays']; data: MainCover }
>({
    disabledDays: (ctx) => {
        const zonedToday = utcToZonedTime(new Date(), ctx.userTimeZone);
        const lastDayToGetCover = getLastDayToSchedule(zonedToday, Limits.GUEST_SCHEDULE_LIMIT_IN_HOURS);

        const dates: Modifier[] = [
            {
                before: zonedToday,
                after: lastDayToGetCover,
            },
        ];

        return dates;
    },
});

export const trackFlipActiveProductListViewed = (ctx: ScheduleCoverGuestMachineContextTypes): void => {
    Analytics.trackProductListViewed(
        'Flip Active',
        ctx.coversAvailable.map((c) => ProductList[c]),
    );
};

export const trackFlipKidsProductListViewed = (ctx: ScheduleCoverGuestMachineContextTypes): void => {
    Analytics.trackProductListViewed(
        'Flip Kids',
        ctx.coversAvailable.map((c) => ProductList[c]),
    );
};
