import { OptionType } from '../../components/dropdown/Dropdown';

export const CountryOptionTypes = {
    au: { value: 'au', label: 'Australia' },
    nz: { value: 'nz', label: 'New Zealand' },
} as const;

export interface AddressFields {
    address1: string;
    address2: string;
    suburb: string;
    city: string;
    state: OptionType | null;
    stateProvinceRegion: string;
    postcode: string;
    country: string | typeof CountryOptionTypes[keyof typeof CountryOptionTypes];
}

interface Address {
    autocompleteResult?: google.maps.places.PlaceResult;
    addressFields?: AddressFields;
}

export default Address;
