import { assign } from 'xstate';
import Analytics from '../../../../../analytics/Analytics';
import { CartErrorDisplay, CartInstanceResponse } from '../../../../../business-logic/models/Cart';
import { ReviewCoverSelection } from '../../../../../business-logic/models/ReviewCoverSelection';
import { ReviewCoverBaymaxMachineContextTypes } from '../context/reviewCoverBaymaxMachineContext';
import { Typegen0 } from '../review-cover-baymax-machine/reviewCoverBaymaxMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setCoversToDisplay = assign<
    ReviewCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['setCoversToDisplay']; data: CartInstanceResponse }
>({
    cartItemsByCoverCode: (ctx, event) => {
        const cartResponse = event.data;
        const allProducts = ctx.products.map((x) => {
            return {
                coverCode: x.productSpec.mainCoverType.coverCode,
                name: x.productSpec.mainCoverType.coverName,
                price: x.productSpec.mainCoverType.billing.premium,
            };
        });

        const baseObject = allProducts.map((x) => {
            return { [x.coverCode]: [] };
        });

        const parseCoversFromCart =
            cartResponse?.coverItems.reduce<Record<string, ReviewCoverSelection[]>>((acc, cur) => {
                const productConfig = allProducts.find((x) => x.coverCode === cur.coverCode);
                const productOptionConfig = ctx.productGroups.find((y) =>
                    y.options.find((x) => x.coverCodes.includes(cur.coverCode)),
                );

                if (!productConfig || !productOptionConfig) return acc;

                acc[cur.coverCode].push({
                    selectedCover: cur.coverCode,
                    coverStartDate: cur.startTime,
                    personId: cur.insuredPersonId ?? null,
                    timezone: cur.destination?.startingRegion.timeZone ?? null,
                    insurancePolicyId: cur.insurancePolicyId,
                    insuranceProductId: cur.insuranceProductId,
                    unitPrice: productConfig.price,
                    destination: cur.destination,
                });
                return acc;
            }, Object.assign({}, ...baseObject)) ?? [];

        return Object.entries(parseCoversFromCart)
            .filter(([, covers]) => !!covers.length)
            .map(([coverCode, covers]) => {
                const productConfig = allProducts.find((x) => x.coverCode === coverCode);
                const productOptionConfig = ctx.productGroups
                    .find((y) => y.options.find((x) => x.coverCodes.includes(coverCode)))
                    ?.options.find((y) => y.coverCodes.includes(coverCode));
                const subTotalByCoverCode = covers.reduce((acc, cur) => acc + cur.unitPrice!, 0);
                return {
                    selectedCoverCode: coverCode,
                    name: productConfig?.name ?? '',
                    paymentModel: productOptionConfig?.paymentModel ?? '',
                    subTotalByCoverCode,
                    unitPrice: productConfig?.price ?? 0,
                    group: productOptionConfig?.id ?? '',
                    coverItems: covers,
                    priceTimePeriod: productOptionConfig?.priceTimePeriod ?? '',
                    paymentModelDescription: productOptionConfig?.paymentModelDescription ?? '',
                };
            });
    },
});

export const setCartTotal = assign<
    ReviewCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['setCartTotal']; data: CartInstanceResponse }
>({
    cartTotal: (ctx) => ctx.cartItemsByCoverCode.reduce((acc, cur) => acc + cur.subTotalByCoverCode, 0),
});

export const setCartError = assign<
    ReviewCoverBaymaxMachineContextTypes,
    { type: 'CART_CREATE_ERROR'; data: CartErrorDisplay }
>({
    cartError: (ctx, event) => event.data,
});

export const resetCartError = assign<
    ReviewCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['resetCartError'] }
>({
    cartError: () => null,
});

export const trackProductsSelected = (ctx: ReviewCoverBaymaxMachineContextTypes) => {
    ctx.cartItemsByCoverCode.forEach((x) => {
        const accountHolderPurchase =
            ctx.products
                .find((y) => y.productSpec.mainCoverType.coverCode === x.selectedCoverCode)
                ?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() !== 'dependant';

        Analytics.trackProductAdded({
            coverCode: x.selectedCoverCode,
            purchaseFor: accountHolderPurchase ? 'myself' : 'dependant',
            quantity: accountHolderPurchase
                ? 1
                : ctx.cartItemsByCoverCode.filter((a) => a.selectedCoverCode === x.selectedCoverCode).length,
        });
    });
};
