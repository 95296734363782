import React, { useState } from 'react';
import classNames from 'classnames';
import filesToReviewString from '../../../utils/claims/filesToReviewString';
import claimsFlowStrings from '../../../strings/claimsFlow';
import ClaimStatuses from '../../../utils/constants/ClaimStatuses';
import { UploadDocumentButton } from '../../upload-document-button/UploadDocumentButton';
import { ListItem } from '../../list-item/ListItem';
import './ClaimFileUploadItem.scss';

export interface ClaimFileUploadItemProps {
    claimStatus: string;
    accessToken: string;
    folderId: string;
    uploadButtonLabel: string;
    newUploadsSuccessLabel: string;
}

const ClaimFileUploadItem: React.FC<ClaimFileUploadItemProps> = ({
    uploadButtonLabel,
    newUploadsSuccessLabel,
    claimStatus,
    accessToken,
    folderId,
}) => {
    const { claimDetailsList } = claimsFlowStrings;

    const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);

    const dashSymbol = '-';

    const uploadFileButton = (
        <div className="claim-file-upload-item__button">
            {filesToReviewString(uploadedFiles)}
            <UploadDocumentButton
                label={uploadButtonLabel}
                accessToken={accessToken}
                folderId={folderId}
                onUploadComplete={(files: Array<any>) => setUploadedFiles(files)}
            />
        </div>
    );

    // only allow more files to be added if in submitted, review or more info required state
    const isAllowedToUploadMoreFiles = claimStatus === ClaimStatuses.IN_REVIEW;
    // we assume files have been uploaded when not in draft state as it's required to get past draft e.g. submitted
    const hasPreviouslyUploadedFiles = claimStatus !== ClaimStatuses.DRAFT;
    // add uploader as seperate item in upload list when new files have been uploaded
    const hasNewlyUploadedFiles = isAllowedToUploadMoreFiles && uploadedFiles.length > 0;

    return (
        <div
            className={classNames(
                'claim-file-upload-item',
                hasNewlyUploadedFiles && 'claim-file-upload-item--with-new-uploads',
            )}
        >
            <div className={classNames(hasNewlyUploadedFiles && 'claim-file-upload-item__existing-files')}>
                <p className="claim-file-upload-item__header">
                    {claimStatus === ClaimStatuses.DRAFT && dashSymbol}
                    {hasPreviouslyUploadedFiles && claimDetailsList.successfullyUploaded}
                </p>
                {isAllowedToUploadMoreFiles && uploadedFiles.length === 0 && uploadFileButton}
            </div>
            {hasNewlyUploadedFiles && (
                <ListItem id="new-uploads" header={newUploadsSuccessLabel} customDataElement={uploadFileButton} />
            )}
        </div>
    );
};

export default ClaimFileUploadItem;
