import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import classNames from 'classnames';
import Regex from '../../utils/RegexCollection';
import Alert, { AlertTypes } from '../alert/Alert';

import './NumberCounter.scss';

export interface NumberCounterProps {
    id?: string;
    innerRef?: RefObject<HTMLInputElement>;
    name: string;
    disabled?: boolean;
    className?: string;
    value: number;
    onIncrement: () => void;
    onDecrement: () => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    form?: string;
    minValue?: number;
    maxValue?: number;
    minErrorMessage?: string;
    maxErrorMessage?: string;
}

export const NumberCounter: React.FC<NumberCounterProps> = ({
    id,
    innerRef,
    name,
    value = 0,
    disabled,
    onChange,
    onIncrement,
    onDecrement,
    className,
    form,
    minValue = 0,
    maxValue,
    minErrorMessage,
    maxErrorMessage,
}) => {
    const [rangeError, setRangeError] = useState<string>('');
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const allowedText = Regex.numbers;
        if (input === '' || allowedText.test(input)) {
            onChange(e);
        }
    };
    useEffect(() => {
        if (value < minValue) {
            setRangeError(minErrorMessage!);
        } else if (!!maxValue && value > maxValue) {
            setRangeError(maxErrorMessage!);
        } else {
            setRangeError('');
        }
    }, [value, minErrorMessage, maxErrorMessage, maxValue, minValue]);
    return (
        <>
            <div className={classNames('number-counter', !!rangeError && 'number-counter--error', className)}>
                <button
                    type="button"
                    className={classNames(
                        'number-counter__button',
                        'number-counter__decrement',
                        'button--secondary',
                        disabled && 'number-counter__decrement--disabled',
                        !!rangeError && 'number-counter__decrement--error',
                    )}
                    disabled={value <= minValue}
                    onClick={() => {
                        onDecrement();
                    }}
                >
                    -<span className="number-counter__button__label">Decrement number</span>
                </button>
                <input
                    id={id}
                    ref={innerRef}
                    name={name}
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]"
                    value={value}
                    disabled={disabled}
                    onChange={handleOnChange}
                    min={minValue}
                    className={classNames(
                        'number-counter__input',
                        disabled && 'number-counter__input--disabled',
                        !!rangeError && 'number-counter__input--error',
                    )}
                    form={form}
                />
                <button
                    type="button"
                    className={classNames(
                        'number-counter__button',
                        'number-counter__increment',
                        'button--secondary',
                        disabled && 'number-counter__increment--disabled',
                        !!rangeError && 'number-counter__increment--error',
                    )}
                    onClick={() => {
                        onIncrement();
                    }}
                >
                    +<span className="number-counter__button__label">Increment number</span>
                </button>
            </div>
            {!!rangeError && <Alert message={rangeError} type={AlertTypes.ERROR} className="number-counter--error" />}
        </>
    );
};

export default NumberCounter;
