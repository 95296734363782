import { createMachine } from 'xstate';
import ProductGroupsResponse from '../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import { CartBaymaxStepsWithoutKids } from '../constants/CartBaymaxStep';
import {
    markAccountHolderAsInsuredPerson,
    setAccountHolderAsAvailableInsuredType,
    setCartBaymaxStepsWithKids,
    setCartBaymaxStepsWithoutKids,
    setDependantsAsAvailableInsuredType,
    setPaymentStep,
    setPersonsInsured,
    setPersonsInsuredStep,
    setProfileStep,
    setReviewCoverStep,
    showPaymentStep,
    showPersonsInsuredStep,
    showProfileStep,
    showReviewCoverStep,
    trackCheckoutCompleted,
    trackPaymentStepCompleted,
    trackPaymentStepViewed,
    trackSelectedPersonsToInsure,
    trackPersonsInsuredStepCompleted,
    trackPersonsInsuredStepViewed,
    trackProfileStepCompleted,
    trackProfileStepViewed,
    trackReviewCoverStepCompleted,
    trackReviewCoverStepViewed,
    updateMachineContextFromGlobalContext,
} from './actions/cartBaymaxMachineActions';
import { CartBaymaxMachineContextTypes } from './context/cartBaymaxCartMachineContext';
import {
    canAccountHolderBeInsured,
    canDependantsBeInsured,
    isExistingUser,
    isKidsDetailsRequired,
    isPurchaseStatePresent,
    isSelectPersonsInsuredRequired,
} from './guards/cartBaymaxMachineGuards';

const cartBaymaxMachine = createMachine(
    {
        id: 'CartBaymaxMachine',
        initial: 'init',
        schema: {
            context: {} as CartBaymaxMachineContextTypes,
            events: {} as
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          products: ProductResponse[];
                          productGroups: ProductGroupsResponse | null;
                      };
                  }
                | { type: 'TOGGLE_PROFILE' }
                | { type: 'PROFILE_UPDATE_COMPLETE' }
                | { type: 'TOGGLE_PERSONS_INSURED' }
                | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
                | { type: 'TOGGLE_REVIEW_COVER' }
                | { type: 'TOGGLE_PAYMENT' }
                | { type: 'CONTINUE_TO_PAYMENT' }
                | { type: 'PAYMENT_COMPLETE' },
        },
        context: {
            isExistingUser: false,
            purchaseState: null,
            products: [],
            productGroups: null,
            insuredPersons: {
                accountHolder: false,
                dependants: [],
            },
            availableInsuredPersonsTypes: [],
            currentStep: 0,
            showStep: null,
            steps: CartBaymaxStepsWithoutKids,
        },
        preserveActionOrder: true,
        tsTypes: {} as import('./cartBaymaxMachine.typegen').Typegen0,
        states: {
            init: {
                initial: 'waitForContextInitialisation',
                states: {
                    waitForContextInitialisation: {
                        on: {
                            CONTEXT_INITIALISED: {
                                actions: 'updateMachineContextFromGlobalContext',
                                target: 'checkPurchaseState',
                            },
                        },
                    },
                    checkPurchaseState: {
                        always: [
                            {
                                cond: 'isPurchaseStatePresent',
                                target: 'setupAvailableInsuredPersons',
                            },
                            {
                                target: 'redirectToStart',
                            },
                        ],
                    },
                    redirectToStart: {
                        entry: 'redirectToPurchase',
                        type: 'final',
                    },
                    setupAvailableInsuredPersons: {
                        initial: 'verifyIfAccountHolderCanBeInsuredPersons',
                        states: {
                            verifyIfAccountHolderCanBeInsuredPersons: {
                                always: [
                                    {
                                        cond: 'canAccountHolderBeInsured',
                                        actions: ['setAccountHolderAsAvailableInsuredType'],
                                        target: 'verifyIfDependantCanBeInsuredPersons',
                                    },
                                    {
                                        target: 'verifyIfDependantCanBeInsuredPersons',
                                    },
                                ],
                            },
                            verifyIfDependantCanBeInsuredPersons: {
                                always: [
                                    {
                                        cond: 'canDependantsBeInsured',
                                        actions: ['setDependantsAsAvailableInsuredType'],
                                        target: 'finaliseAvailableInsuredPersons',
                                    },
                                    {
                                        target: 'finaliseAvailableInsuredPersons',
                                    },
                                ],
                            },
                            finaliseAvailableInsuredPersons: {
                                initial: 'setSteps',
                                states: {
                                    setSteps: {
                                        always: [
                                            {
                                                cond: 'isSelectPersonsInsuredRequired',
                                                actions: ['setCartBaymaxStepsWithKids'],
                                                target: 'determineStartingStep',
                                            },
                                            {
                                                actions: [
                                                    'setCartBaymaxStepsWithoutKids',
                                                    'markAccountHolderAsInsuredPerson',
                                                ],
                                                target: 'determineStartingStep',
                                            },
                                        ],
                                    },
                                    determineStartingStep: {
                                        always: [
                                            {
                                                cond: 'isExistingUser',
                                                target: 'setExistingUserStartingStep',
                                            },
                                            {
                                                target: '#CartBaymaxMachine.profileStep',
                                            },
                                        ],
                                    },
                                    setExistingUserStartingStep: {
                                        always: [
                                            {
                                                cond: 'isSelectPersonsInsuredRequired',
                                                target: '#CartBaymaxMachine.personsInsuredStep',
                                            },
                                            {
                                                target: '#CartBaymaxMachine.reviewCoverStep',
                                            },
                                        ],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            profileStep: {
                entry: ['setProfileStep', 'showProfileStep', 'trackProfileStepViewed'],
                exit: 'trackProfileStepCompleted',
                on: {
                    PROFILE_UPDATE_COMPLETE: [
                        {
                            // Flip Roaming or Flip Roaming Kids or Flip Kids
                            cond: 'isKidsDetailsRequired',
                            target: 'personsInsuredStep',
                        },
                        {
                            // Flip Active
                            actions: ['markAccountHolderAsInsuredPerson'],
                            target: 'reviewCoverStep',
                        },
                    ],
                },
            },
            personsInsuredStep: {
                entry: ['setPersonsInsuredStep', 'showPersonsInsuredStep', 'trackPersonsInsuredStepViewed'],
                exit: 'trackPersonsInsuredStepCompleted',
                on: {
                    PERSONS_INSURED_COMPLETE: {
                        actions: ['setPersonsInsured'],
                        target: 'reviewCoverStep',
                    },
                    TOGGLE_PROFILE: {
                        target: 'profileStep',
                    },
                },
            },
            reviewCoverStep: {
                entry: [
                    'setReviewCoverStep',
                    'showReviewCoverStep',
                    'trackReviewCoverStepViewed',
                    'trackSelectedPersonsToInsure',
                ],
                exit: 'trackReviewCoverStepCompleted',
                on: {
                    CONTINUE_TO_PAYMENT: {
                        target: 'paymentStep',
                    },
                    TOGGLE_PROFILE: {
                        target: 'profileStep',
                    },
                    TOGGLE_PERSONS_INSURED: {
                        target: 'personsInsuredStep',
                    },
                },
            },
            paymentStep: {
                entry: ['setPaymentStep', 'showPaymentStep', 'trackPaymentStepViewed'],
                on: {
                    PAYMENT_COMPLETE: {
                        actions: ['trackPaymentStepCompleted', 'trackCheckoutCompleted'], // TODO: add 'clearData' here to clear session storage & purchase state
                    },
                    TOGGLE_PROFILE: {
                        target: 'profileStep',
                    },
                    TOGGLE_PERSONS_INSURED: {
                        target: 'personsInsuredStep',
                    },
                    TOGGLE_REVIEW_COVER: {
                        target: 'reviewCoverStep',
                    },
                },
            },
        },
    },
    {
        guards: {
            isExistingUser,
            isSelectPersonsInsuredRequired,
            canAccountHolderBeInsured,
            canDependantsBeInsured,
            isKidsDetailsRequired,
            isPurchaseStatePresent,
        },
        actions: {
            setAccountHolderAsAvailableInsuredType,
            setDependantsAsAvailableInsuredType,
            setCartBaymaxStepsWithKids,
            setCartBaymaxStepsWithoutKids,
            markAccountHolderAsInsuredPerson,
            updateMachineContextFromGlobalContext,
            setProfileStep,
            setPersonsInsuredStep,
            setPaymentStep,
            showProfileStep,
            showPersonsInsuredStep,
            setPersonsInsured,
            showReviewCoverStep,
            showPaymentStep,
            setReviewCoverStep,
            trackProfileStepViewed,
            trackPersonsInsuredStepViewed,
            trackReviewCoverStepViewed,
            trackPaymentStepViewed,
            trackProfileStepCompleted,
            trackSelectedPersonsToInsure,
            trackPersonsInsuredStepCompleted,
            trackReviewCoverStepCompleted,
            trackPaymentStepCompleted,
            trackCheckoutCompleted,
        },
    },
);

export default cartBaymaxMachine;
