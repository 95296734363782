import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import Button from '../../../components/button/Button';
import Layout from '../../../components/layout/Layout';
import ModalWithCTA from '../../../components/modal-with-cta/ModalWithCTA';
import claimsFlow from '../../../strings/claimsFlow';
import common from '../../../strings/common';
import Constants from '../../../utils/Constants';
import parseString from '../../../utils/parseString';
import Routes from '../../../utils/Routes';

import './MedicalContactConsent.scss';

enum ModalToShow {
    None,
    JargonModal,
    NoModal,
}

const MedicalContactConsent: FC = () => {
    const { medicalContactConsent: contentStrings } = claimsFlow;
    const history = useHistory();
    const [modalToShow, setModalToShow] = useState(ModalToShow.None);
    const { claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_MEDICAL_CONTACT_CONSENT, claimDetails);
    }, []);

    const handleNoClick = () => {
        setModalToShow(ModalToShow.NoModal);
    };

    const handleYesClick = () => {
        setModalToShow(ModalToShow.JargonModal);
    };

    const jargonModalProps = useMemo(
        () => ({
            title: contentStrings.jargonModalDescription,
            textContent: contentStrings.jargonModalAlert,
            primaryCTA: {
                onClick: () => {
                    Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_MEDICAL_CONTACT_CONSENT, claimDetails);
                    history.push(Routes.MEDICAL_CONTACT_INFORMATION);
                },
                label: common.ok,
            },
        }),
        [claimDetails, contentStrings.jargonModalAlert, contentStrings.jargonModalDescription, history],
    );

    const noModalProps = useMemo(
        () => ({
            title: contentStrings.noModalDescription,
            textContent: contentStrings.noModalAlert,
            primaryCTA: {
                onClick: () => {
                    Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_MEDICAL_CONTACT_CONSENT, claimDetails);
                    history.push(Routes.EMERGENCY_SERVICES_REQUIRED);
                },
                label: common.next,
            },
            secondaryCTA: {
                onClick: () => setModalToShow(ModalToShow.None),
                label: common.back,
            },
        }),
        [claimDetails, contentStrings.noModalAlert, contentStrings.noModalDescription, history],
    );

    return (
        <Layout
            title={contentStrings.title}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_MEDICAL_CONTACT_CONSENT / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            <p>{parseString(contentStrings.description)}</p>
            <div className="medical-contact-consent__btn-group">
                <Button label={common.no} variant="secondary" onClick={handleNoClick} />
                <Button label={common.yes} onClick={handleYesClick} />
            </div>
            <ModalWithCTA
                onClose={() => setModalToShow(ModalToShow.None)}
                isOpen={modalToShow !== ModalToShow.None}
                {...(modalToShow === ModalToShow.JargonModal ? jargonModalProps : noModalProps)}
            />
        </Layout>
    );
};

export default MedicalContactConsent;
