import React from 'react';
import appStoreButton from '../../assets/images/app-store-button--filled.svg';
import close from '../../assets/images/close.svg';
import info from '../../assets/images/icon-info-blue-40x40.svg';
import playStoreButton from '../../assets/images/play-store-button--filled.svg';
import claimsDeprecateModalContent from '../../content/ui/components/claims-deprecation/claimsDeprecationModal';
import withContent from '../../hoc/with-content/withContent';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import LinkButton from '../link-button/LinkButton';
import Modal from '../modal/Modal';

import './ClaimsDeprecateModal.scss';

interface ClaimsDeprecateModalProps {
    isOpen: boolean;
    content: Record<keyof typeof contentMap, string | string[]>;
    onClose: () => void;
}

const contentMap = {
    header: 'ui.header',
    description: 'ui.description',
    appStoreActionText: 'ui.appStoreActionText',
    appStoreStoreName: 'ui.appStoreStoreName',
    playStoreActionText: 'ui.playStoreActionText',
    playStoreStoreName: 'ui.playStoreStoreName',
    cat: 'ui.cta',
};

const renderDownloadAppButton = (imageSrc: string, accessibleText: string) => {
    return (
        <>
            <img src={imageSrc} alt="" />
            <span className="claims-deprecate-modal__download-button-hidden-text">{accessibleText}</span>
        </>
    );
};

const ClaimsDeprecateModal: React.FC<ClaimsDeprecateModalProps> = ({ isOpen, content, onClose }) => {
    return (
        <Modal
            className="claims-deprecate-modal"
            isOpen={isOpen}
            parentClass="#root"
            onClose={onClose}
            variant="slide-to-center"
        >
            <div className="claims-deprecate-modal-close-button-wrapper">
                <button
                    type="button"
                    className="claims-deprecate-modal-close-button"
                    aria-label="Close"
                    onClick={onClose}
                >
                    <img src={close} alt="Close" />
                </button>
            </div>
            <div className="claims-deprecate-modal__content">
                <img src={info} alt="" />
                <h3 className="claims-deprecate-modal__content__heading">{content.header}</h3>
                <p>{content.description}</p>
                <div className="claims-deprecate-modal__buttons">
                    <LinkButton
                        label={renderDownloadAppButton(
                            appStoreButton,
                            `${content.appStoreActionText} ${content.appStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppAppleAppStore}
                        variant="secondary"
                        target="_blank"
                    />
                    <LinkButton
                        label={renderDownloadAppButton(
                            playStoreButton,
                            `${content.playStoreActionText} ${content.playStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppGooglePlayStore}
                        variant="secondary"
                        target="_blank"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default withContent(ClaimsDeprecateModal, contentMap, claimsDeprecateModalContent);
