import JapanPassIcon from '../../../assets/images/cover/japan-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { JapanPassPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const japanPassV1ProductInfo = {
    id: Cover.JAPAN_PASS,
    icon: JapanPassIcon,
    name: 'Japan Pass',
    group: 'Japan Pass',
    price: '$25',
    priceTimePeriod: 'week',
    priceTimePeriodDescription: 'per week',
    description: "On-demand cover for Japan's snow season",
    primaryFeatures: [
        'Pay by the week',
        'Book ahead for upcoming activities, events, or trips to Japan',
        'Can be used on top of your travel insurance',
        'Get cash for approved claims to spend how you want',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const japanPassProductInfo: Record<JapanPassPdsVersion, ProductInfo> = {
    [JapanPassPdsVersion.V1]: japanPassV1ProductInfo,
    [JapanPassPdsVersion.V1_FIXED]: japanPassV1ProductInfo,
    [JapanPassPdsVersion.V1_SCHEDULING_LIMIT_UPDATE]: japanPassV1ProductInfo,
};

export default japanPassProductInfo;
