import Cover from './constants/Cover';
import {
    FlipActiveSubMonthlyPdsVersion,
    LatestAlwaysOnPdsVersion,
    LatestDayPassPdsVersion,
    LatestFlipActiveDailyPdsVersion,
    LatestFlipActiveSubMonthlyPdsVersion,
    LatestFlipActiveWeeklyPdsVersion,
    LatestFlipKidsDailyPdsVersion,
    LatestFlipKidsSubMonthlyPdsVersion,
    LatestFlipKidsWeeklyPdsVersion,
    LatestFlipRoamingKidsPdsVersion,
    LatestFlipRoamingPdsVersion,
    LatestJapanPassPdsVersion,
    MainCoverPdsVersion,
} from './constants/PdsVersion';

const getPdsVersion = (cover: Cover): MainCoverPdsVersion => {
    if (cover === Cover.DAY_PASS) {
        return LatestDayPassPdsVersion;
    }

    if (cover === Cover.JAPAN_PASS) {
        return LatestJapanPassPdsVersion;
    }

    if (cover === Cover.FLIP_ACTIVE_DAILY) {
        return LatestFlipActiveDailyPdsVersion;
    }

    if (cover === Cover.FLIP_ACTIVE_WEEKLY) {
        return LatestFlipActiveWeeklyPdsVersion;
    }

    if (cover === Cover.FLIP_ACTIVE_SUB_MONTHLY) {
        return LatestFlipActiveSubMonthlyPdsVersion;
    }

    if (cover === Cover.FLIP_KIDS_DAILY) {
        return LatestFlipKidsDailyPdsVersion;
    }

    if (cover === Cover.FLIP_KIDS_WEEKLY) {
        return LatestFlipKidsWeeklyPdsVersion;
    }

    if (cover === Cover.FLIP_KIDS_SUB_MONTHLY) {
        return LatestFlipKidsSubMonthlyPdsVersion;
    }

    if (cover === Cover.BOOST) {
        return LatestAlwaysOnPdsVersion;
    }

    if (cover === Cover.FLIP_ACTIVE_BOOST) {
        return FlipActiveSubMonthlyPdsVersion.V1_KIDS;
    }

    if (cover === Cover.FLIP_ROAMING_WEEKLY) {
        return LatestFlipRoamingPdsVersion;
    }

    if (cover === Cover.FLIP_ROAMING_KIDS_WEEKLY) {
        return LatestFlipRoamingKidsPdsVersion;
    }
    return LatestAlwaysOnPdsVersion;
};

export default getPdsVersion;
