import { useMachine } from '@xstate/react';
import { FC, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useUser } from '../../../../business-logic/context-provider/user-context/UserContext';
import Alert, { AlertSizes, AlertTypes } from '../../../../components/alert/Alert';
import Button from '../../../../components/button/Button';
import DateOfBirthInput from '../../../../components/date-of-birth-input/DateOfBirthInput';
import Dropdown from '../../../../components/dropdown/Dropdown';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import ModalWithCTA from '../../../../components/modal-with-cta/ModalWithCTA';
import Sticky from '../../../../components/sticky/Sticky';
import TextField from '../../../../components/text-field/TextField';
import guestCartContent from '../../../../content/ui/screens/guest-cart/guestCart';
import withContent from '../../../../hoc/with-content/withContent';
import common from '../../../../strings/common';
import onboardingFlow from '../../../../strings/onboardingFlow';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';
import Gender from '../../../../utils/constants/Gender';
import Limits from '../../../../utils/constants/Limits';
import ResidencyStatusTypes from '../../../../utils/constants/ResidencyStatusTypes';
import State from '../../../../utils/constants/State';
import formatDateToString from '../../../../utils/formatDateToString';
import parseString from '../../../../utils/parseString';
import Routes from '../../../../utils/Routes';
import toDate from '../../../../utils/toDate';
import profileBaymaxMachine from './profile-machine/profileBaymaxMachine';

import './ProfileStep.scss';

const contentMap = {
    kidsInfo: 'ui.profileStep.kidsInfo',
    alreadyHaveAccount: 'ui.profileStep.alreadyHaveAccount',
    emailLabel: 'ui.profileStep.email.label',
    firstNameLabel: 'ui.profileStep.firstName.label',
    lastNameLabel: 'ui.profileStep.lastName.label',
    genderLabel: 'ui.profileStep.gender.label',
    stateLabel: 'ui.profileStep.state.label',
    residencyStatusLabel: 'ui.profileStep.residencyStatus.label',
    dropdownPlaceholder: 'ui.profileStep.dropdownPlaceholder',
    existingActiveUserErrorHeading: 'ui.profileStep.existingActiveUserError.heading',
    existingActiveUserErrorDescription: 'ui.profileStep.existingActiveUserError.description',
    existingActiveUserErrorSignInCta: 'ui.profileStep.existingActiveUserError.signInCta',
    existingActiveUserErrorClose: 'ui.profileStep.existingActiveUserError.close',
    agreeTerms: 'ui.profileStep.agreeTerms',
};

interface ProfileBaymaxStepProps {
    content: Record<keyof typeof contentMap, string>;
    onProfileUpdateComplete: () => void;
    isPurchasingKidsCoverOnly: boolean;
    showKidsInfo: boolean;
}

const ProfileBaymaxStep: FC<ProfileBaymaxStepProps> = ({
    content,
    isPurchasingKidsCoverOnly,
    showKidsInfo,
    onProfileUpdateComplete,
}) => {
    const { userDetails, setUserDetailsByAttr, setAccessToken } = useUser();
    const { authState } = useOktaAuth();
    const history = useHistory();

    const [state, send] = useMachine(profileBaymaxMachine, {
        context: {
            accessToken: authState!.accessToken!.accessToken,
            email: userDetails.email || '',
            firstName: userDetails.firstName || '',
            lastName: userDetails.lastName || '',
            dob: { ...toDate(userDetails.dob), error: '' },
            state: userDetails.state || undefined,
            residencyStatus: (userDetails.residencyStatusType && userDetails.residencyStatusType[0]) || undefined,
            gender: (userDetails.gender as Gender) || undefined,
            isPurchasingKidsCoverOnly,
        },
        actions: {
            updateUserContext: (ctx) => {
                setAccessToken(authState!.accessToken!.accessToken!);
                setUserDetailsByAttr('email', ctx.email);
                setUserDetailsByAttr('firstName', ctx.firstName);
                setUserDetailsByAttr('lastName', ctx.lastName);
                setUserDetailsByAttr(
                    'dob',
                    formatDateToString(new Date(`${ctx.dob.year}/${ctx.dob.month}/${ctx.dob.day}`)),
                );
                setUserDetailsByAttr('state', ctx.state as State);
                setUserDetailsByAttr('residencyStatusType', [ctx.residencyStatus!]);
                setUserDetailsByAttr('gender', ctx.gender!);
            },
            notifyProfileUpdateComplete: () => {
                onProfileUpdateComplete();
            },
        },
    });

    const {
        gender: { options: genderOptions },
        residency: { stateOptions, stateNotInAusOption, residencyOptions, stateError, residencyError },
    } = onboardingFlow;

    const {
        email,
        emailError,
        firstName,
        lastName,
        dob,
        state: residencyState,
        residencyStatus,
        gender,
        genericError,
    } = state.context;

    const genderDropdownOptions = Object.values(genderOptions).map((g) => ({ value: g.id, label: g.label }));
    const stateDropdownOptions = [...stateOptions, stateNotInAusOption];
    const residencyStatusDropdownOptions = [
        { value: ResidencyStatusTypes.AUS_CITIZEN, label: residencyOptions.ausCitizen },
        { value: ResidencyStatusTypes.NZ_CITIZEN, label: residencyOptions.nzCitizen },
        { value: ResidencyStatusTypes.AUS_PR, label: residencyOptions.ausPR },
        { value: ResidencyStatusTypes.AUS_OTHER_VISA, label: residencyOptions.otherVisa },
        { value: 'TOURIST', label: residencyOptions.tourist },
    ];

    const allFieldsValid =
        state.matches('ready.email.valid') &&
        state.matches('ready.firstName.valid') &&
        // @ts-expect-error xstate is wrongly interpreting this as never
        state.matches('ready.lastName.valid') &&
        // @ts-expect-error xstate is wrongly interpreting this as never
        state.matches('ready.dob.valid') &&
        // @ts-expect-error xstate is wrongly interpreting this as never
        state.matches('ready.state.valid') &&
        // @ts-expect-error xstate is wrongly interpreting this as never
        state.matches('ready.residencyStatus.valid') &&
        // @ts-expect-error xstate is wrongly interpreting this as never
        state.matches('ready.gender.valid');

    const hasEmailError = state.matches('ready.email.displayEmailError');
    const hasDobError = state.matches('ready.dob.displayDobError');
    const isResidencyStateIneligible = state.matches('ready.state.ineligible');
    const isResidencyStatusIneligible = state.matches('ready.residencyStatus.ineligible');
    const isUpdatingProfile = state.matches('updateProfile');
    const showExistingActiveUserErrorModal = state.matches('showExistingActiveUserErrorModal');

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isResidencyStateIneligible || isResidencyStatusIneligible) {
            window.location.href = ExternalLinks.landingPage;
            return;
        }

        send('UPDATE_PROFILE');
    };

    return (
        <form onSubmit={handleFormSubmit}>
            {showKidsInfo && <Alert type={AlertTypes.INFO} size={AlertSizes.SMALL} message={content.kidsInfo} />}
            <TextField
                id="email"
                name="email"
                className="profile-step-guest__input-row"
                label={content.emailLabel}
                value={email}
                onChange={(e) => send({ type: 'UPDATE_EMAIL', data: e.target.value })}
                onBlur={() => send({ type: 'BLUR_EMAIL' })}
                disabled={!!state.context.email}
                isError={hasEmailError}
                error={emailError}
            />
            <TextField
                id="firstName"
                name="firstName"
                className="profile-step-guest__input-row"
                label={content.firstNameLabel}
                value={firstName}
                minLength={Limits.NAME_MIN_LENGTH}
                maxLength={Limits.NAME_MAX_LENGTH}
                onChange={(e) => send({ type: 'UPDATE_FIRST_NAME', data: e.target.value })}
                disabled={isUpdatingProfile}
            />
            <TextField
                id="lastName"
                name="lastName"
                label={content.lastNameLabel}
                className="profile-step-guest__input-row"
                value={lastName}
                minLength={Limits.NAME_MIN_LENGTH}
                maxLength={Limits.NAME_MAX_LENGTH}
                onChange={(e) => send({ type: 'UPDATE_LAST_NAME', data: e.target.value })}
                disabled={isUpdatingProfile}
            />
            <div className="profile-step-guest__input-row">
                <DateOfBirthInput
                    day={dob.day}
                    month={dob.month}
                    year={dob.year}
                    hasError={hasDobError}
                    onDayChange={(e) => {
                        send({ type: 'UPDATE_DATE', data: e.target.value });
                    }}
                    onMonthChange={(e) => send({ type: 'UPDATE_MONTH', data: e.target.value })}
                    onYearChange={(e) => send({ type: 'UPDATE_YEAR', data: e.target.value })}
                    onBlur={() => send('BLUR_YEAR')}
                    disabled={isUpdatingProfile}
                />
                {hasDobError && <span className="profile-step-guest__dob-error">{dob.error}</span>}
            </div>
            <Dropdown
                options={genderDropdownOptions}
                label={content.genderLabel}
                placeholder={content.dropdownPlaceholder}
                value={genderDropdownOptions.find((g) => g.value === gender) || null}
                onChange={(selectedGender) => send({ type: 'UPDATE_GENDER', data: selectedGender.value })}
                searchable={false}
                className="profile-step-guest__input-row"
                isDisabled={isUpdatingProfile}
            />
            <Dropdown
                options={stateDropdownOptions}
                label={content.stateLabel}
                placeholder={content.dropdownPlaceholder}
                value={stateDropdownOptions.find((s) => s.value === residencyState) || null}
                onChange={(selectedState) => send({ type: 'UPDATE_STATE', data: selectedState.value })}
                searchable={false}
                className="profile-step-guest__input-row"
                isDisabled={isUpdatingProfile}
            />
            <Dropdown
                options={residencyStatusDropdownOptions}
                label={content.residencyStatusLabel}
                placeholder={content.dropdownPlaceholder}
                value={residencyStatusDropdownOptions.find((r) => r.value === residencyStatus) || null}
                onChange={(selectedStatus) => send({ type: 'UPDATE_RESIDENCY_STATUS', data: selectedStatus.value })}
                searchable={false}
                className="profile-step-guest__input-row"
                isDisabled={isUpdatingProfile}
            />
            {isResidencyStateIneligible && (
                <Alert
                    size={AlertSizes.LARGE}
                    message={parseString(stateError)}
                    className="profile-step-guest__alert-error"
                />
            )}
            {isResidencyStatusIneligible && (
                <Alert
                    size={AlertSizes.LARGE}
                    message={parseString(residencyError)}
                    className="profile-step-guest__alert-error"
                />
            )}
            {genericError && <span className="profile-step-guest__dob-error">{genericError}</span>}
            <p className="profile-step-guest__terms">{parseString(content.agreeTerms)}</p>
            <Sticky>
                <div className="profile-step-guest__submit-button">
                    {isResidencyStateIneligible || isResidencyStatusIneligible ? (
                        <Button label={common.exit} type="submit" width="full" />
                    ) : (
                        <LoadingButton
                            status={isUpdatingProfile ? 'loading' : 'idle'}
                            width="full"
                            type="submit"
                            label={common.continue}
                            disabled={!allFieldsValid}
                        />
                    )}
                </div>
            </Sticky>
            <ModalWithCTA
                title={content.existingActiveUserErrorHeading}
                textContent={content.existingActiveUserErrorDescription}
                isOpen={showExistingActiveUserErrorModal}
                primaryCTA={{
                    label: content.existingActiveUserErrorSignInCta,
                    onClick: () => history.push(Routes.LOGIN),
                }}
                secondaryCTA={{
                    label: content.existingActiveUserErrorClose,
                    onClick: () => send('HIDE_EXISTING_ACTIVE_USER_ERROR_MODAL'),
                }}
            />
        </form>
    );
};

export default withContent(ProfileBaymaxStep, contentMap, guestCartContent);
