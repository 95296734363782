import React from 'react';

export interface BulletPointListProps {
    list: string[];
}

const BulletPointList: React.FC<BulletPointListProps> = ({ list }) => {
    return (
        <ul>
            {list.map((value: string) => (
                <li key={value?.slice(0, 20)?.replace(' ', '-')}>{value}</li>
            ))}
        </ul>
    );
};

export default BulletPointList;
