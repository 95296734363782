import { FC } from 'react';
import { MainCover } from '../../utils/constants/Cover';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import getCoverTerms from '../../utils/getCoverTerms';
import Alert from '../alert/Alert';
import NotCoveredTabV1 from './not-covered-tab-v1/NotCoveredTabV1';
import NotCoveredTabV2 from './not-covered-tab-v2/NotCoveredTabV2';

interface WhatsNotCoveredTabProps {
    selectedCover: MainCover;
    pdsVersion: MainCoverPdsVersion;
    showExamples?: boolean;
}

const NotCoveredTab: FC<WhatsNotCoveredTabProps> = ({ selectedCover, pdsVersion, showExamples = true }) => {
    const coverTerms = getCoverTerms(selectedCover, pdsVersion);

    switch (coverTerms.coverTermsVersion) {
        case '2':
            return <NotCoveredTabV2 whatsNotCovered={coverTerms.whatsNotCovered} />;
        case '1':
            return <NotCoveredTabV1 whatsNotCovered={coverTerms.whatsNotCovered} showExamples={showExamples} />;
        default:
            return <Alert />;
    }
};

export default NotCoveredTab;
