import { useMemo } from 'react';
import { useLocation } from 'react-router';
import Constants from '../utils/Constants';
import Routes from '../utils/Routes';

const useOnboarding = (): { onboardingStepNumber: number; onboardingTotalSteps: number } => {
    const { pathname } = useLocation();

    const onboardingStepNumber = useMemo(() => {
        switch (pathname) {
            case Routes.REGISTER:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_REGISTRATION;
            case Routes.DATE_OF_BIRTH:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_DOB;
            case Routes.GENDER:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_GENDER;
            case Routes.NAME:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_NAME;
            case Routes.PERSONS_INSURED:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_PERSONS_INSURED;
            case Routes.SELECT_COVER:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_SELECT_COVER;
            case Routes.SCHEDULE_COVER:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_SCHEDULE_COVER;
            case Routes.RESIDENCY_STATE:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATE;
            case Routes.RESIDENCY_STATUS:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_AUS_RESIDENT_STATUS;
            case Routes.CHECKOUT:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_CHECKOUT;
            case Routes.YOUR_ACTIVITIES:
                return Constants.FLIP_ACTIVE_ONBOARDING_FLOW_STEP_YOUR_ACTIVITIES;
            default:
                return 0;
        }
    }, [pathname]);

    const onboardingTotalSteps = Constants.FLIP_ACTIVE_ONBOARDING_FLOW_NO_OF_STEPS;

    return { onboardingStepNumber, onboardingTotalSteps };
};

export default useOnboarding;
