import { ChangeEvent, ChangeEventHandler, FC, FormEvent, useState } from 'react';
import close from '../../assets/images/close.svg';
import alert from '../../assets/images/icon-warning-red-40x40.svg';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import cancelCoverFeedbackDrawer from '../../content/ui/components/cancel-cover-feedback-drawer/cancelCoverFeedbackDrawer';
import withContent from '../../hoc/with-content/withContent';
import {
    CancelSingleCoverFeedbackOptions,
    CancelSubscriptionCoverFeedbackOptions,
} from '../../utils/constants/CancelCoverFeedbackOptions';
import parseString from '../../utils/parseString';
import Alert from '../alert/Alert';
import Button from '../button/Button';
import Checkbox from '../form/checkbox/Checkbox';
import Fieldset from '../form/fieldset/Fieldset';
import LoadingButton from '../loading-button/LoadingButton';
import Modal from '../modal/Modal';
import TextField from '../text-field/TextField';

import './CancelCoverFeedbackDrawer.scss';

const contentMap = {
    feedbackHeading: 'ui.feedbackHeading',
    feedbackDescription: 'ui.feedbackDescription',
    feedbackSubheading: 'ui.feedbackSubheading',
    feedbackFreeTextLabel: 'ui.feedbackFreeTextLabel',
    feedbackCTA: 'ui.feedbackCTA',
    noFeedbackCTA: 'ui.noFeedbackCTA',
};

interface CancelCoverFeedbackDrawerProps {
    content: Record<keyof typeof contentMap, string>;
    isOpen: boolean;
    onClose: () => void;
    subscriptionsToCancel: string[];
    singleCoversToCancel: string[];
    resetAnyCoversToCancel: () => void;
}

const CancelCoverFeedbackDrawer: FC<CancelCoverFeedbackDrawerProps> = ({
    content,
    isOpen,
    onClose,
    subscriptionsToCancel,
    singleCoversToCancel,
    // coversAvailableForCancellation,
    resetAnyCoversToCancel,
}) => {
    const [cancelReasons, setCancelReasons] = useState<string[]>([]);
    const [freeText, setFreeText] = useState<string>('');
    const [hasError, setHasError] = useState(false);
    const { cancelSingleOpenCover, cancelSubscriptionOpenCover } = useOpenCovers();
    const [isCancelling, setIsCancelling] = useState(false);

    const handleCancel = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsCancelling(true);
        setHasError(false);
        const allReasons = [...cancelReasons, freeText].filter((item) => !!item);

        try {
            if (subscriptionsToCancel.length > 0) {
                await Promise.all(subscriptionsToCancel.map((c) => cancelSubscriptionOpenCover(c, allReasons)));
            }

            if (singleCoversToCancel.length > 0) {
                await Promise.all(singleCoversToCancel.map((c) => cancelSingleOpenCover(c, allReasons)));
            }
            setIsCancelling(false);
            resetAnyCoversToCancel();
            onClose();
        } catch (error) {
            setHasError(true);
        } finally {
            resetAnyCoversToCancel();
            setCancelReasons([]); // reset
            setFreeText('');
            setIsCancelling(false);
        }
    };

    const options = () => {
        const allReasons = [];
        if (subscriptionsToCancel.length > 0) {
            allReasons.push(CancelSubscriptionCoverFeedbackOptions);
        }
        if (singleCoversToCancel.length > 0) {
            allReasons.push(CancelSingleCoverFeedbackOptions);
        }
        // Amalgates all possible reasons for cancellation,
        // remove duplication and sort by display order
        return allReasons
            .flat()
            .filter((v, i, a) => a.findIndex((v2) => v2.key === v.key) === i)
            .sort((a, b) => a.index - b.index);
    };

    const renderCheckbox = (label: string, onChange: ChangeEventHandler<HTMLInputElement>, checked: boolean) => {
        return (
            <div key={label}>
                <Checkbox
                    id={label}
                    name="cancellationReason"
                    label={
                        <div className="cancel-cover-feedback-drawer__checkbox-label">
                            <span>{parseString(label)}</span>
                        </div>
                    }
                    onChange={onChange}
                    checked={checked}
                    disabled={isCancelling}
                />
                {label === 'Other' && checked && (
                    <TextField
                        name={label}
                        label={content.feedbackFreeTextLabel}
                        onChange={(e) => setFreeText(e.target.value)}
                        minLength={1}
                    />
                )}
            </div>
        );
    };

    const renderFeedbackCheckbox = (key: string, label: string) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setCancelReasons((prev) => [...prev, key]);
            } else {
                setCancelReasons((prev) => prev.filter((i) => i !== key));
            }
        };

        const checked = cancelReasons.some((i) => i === key);
        return renderCheckbox(label, onChange, checked);
    };

    const handleClose = () => {
        setCancelReasons([]);
        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} parentClass="#root" onClose={handleClose} variant="slide-in">
                <div className="cancel-cover-feedback-drawer__modal-close-button-wrapper">
                    <button
                        type="button"
                        className="cancel-cover-feedback-drawer__modal-close-button"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <img src={close} alt="Close" />
                    </button>
                </div>
                <form onSubmit={handleCancel}>
                    <img src={alert} alt="" />
                    <h2 className="cancel-cover-feedback-drawer__heading">{content.feedbackHeading}</h2>
                    <p className="cancel-cover-feedback-drawer__description">{content.feedbackDescription}</p>
                    <Fieldset legend={content.feedbackSubheading} className="cancel-cover-feedback-drawer__fieldset">
                        {options().map((option) => renderFeedbackCheckbox(option.key, option.label))}
                    </Fieldset>

                    <LoadingButton
                        status={isCancelling ? 'loading' : 'idle'}
                        label={content.feedbackCTA}
                        width="full"
                        type="submit"
                        disabled={
                            isCancelling || !cancelReasons.length || (cancelReasons.includes('OTHER') && !freeText)
                        }
                        className="cancel-cover-drawer__cta"
                    />
                    <Button
                        variant="link"
                        label={content.noFeedbackCTA}
                        width="full"
                        type="submit"
                        disabled={isCancelling}
                        onClick={() => {
                            setCancelReasons([]);
                        }}
                        className="cancel-cover-drawer__cta--secondary"
                    />
                </form>
                {hasError && <Alert />}
            </Modal>
        </>
    );
};

export default withContent(CancelCoverFeedbackDrawer, contentMap, cancelCoverFeedbackDrawer);
