import { FC } from 'react';
import check from '../../../assets/images/deep-green-check.svg';
import { WhatsCoveredV2 } from '../../../content/by-product/cover-terms/types';
import common from '../../../strings/common';
import { MainCoverPdsVersion } from '../../../utils/constants/PdsVersion';
import parseString from '../../../utils/parseString';
import PdsTmdDisclaimer from '../../pds-tmd-disclaimer/PdsTmdDisclaimer';

import './CoveredTabV2.scss';

interface CoveredTabV2Props {
    whatsCovered: WhatsCoveredV2;
    pdsVersion: MainCoverPdsVersion;
}

const CoveredTabV2: FC<CoveredTabV2Props> = ({ whatsCovered, pdsVersion }) => {
    return (
        <div>
            <h1>{whatsCovered.title}</h1>
            <p>{parseString(whatsCovered.description)}</p>
            <div className="covered-tab-v2__payout-categories">
                {whatsCovered.payoutCategories.map((payoutCategory) => (
                    <div className="covered-tab-v2__payout-category">
                        {payoutCategory.categoryName ? (
                            <p className="covered-tab-v2__payout-category-name">{payoutCategory.categoryName}</p>
                        ) : null}
                        {payoutCategory.payouts.map((payout) => (
                            <div className="covered-tab-v2__payout">
                                <div className="covered-tab-v2__payout-severity-wrapper">
                                    <img src={check} alt="" className="covered-tab-v2__payout-severity-icon" />
                                    <span>{payout.injurySeverity}</span>
                                </div>

                                <span>{payout.payoutAmount}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <p className="covered-tab-v2__pds-version ">{common.formatString(common.pdsVersion, { pdsVersion })}</p>
            <p>{parseString((whatsCovered.subtext || '') as string)}</p>
            <PdsTmdDisclaimer />
        </div>
    );
};

export default CoveredTabV2;
