import React from 'react';
import classNames from 'classnames';
import './QuizRadio.scss';

type TProps = {
    onChange: (value: boolean) => void;
    className?: string;
    value?: boolean;
    showDivider?: boolean;
};

const QuizRadio: React.FC<TProps> = ({ onChange, className, value, showDivider }: TProps) => {
    return (
        <div className={classNames('quiz-radio', className)}>
            <label
                htmlFor="quiz_radio_positive"
                className={classNames({ 'quiz-radio__button': true, 'quiz-radio__button--checked': value === true })}
            >
                <input
                    name="quiz-radio"
                    id="quiz_radio_positive"
                    type="radio"
                    className="quiz-radio__input"
                    defaultChecked={value === true}
                    onClick={() => onChange(true)}
                />
                Yes
            </label>
            {showDivider && <div className="quiz-radio__divider" />}
            <label
                htmlFor="quiz_radio_negative"
                className={classNames({ 'quiz-radio__button': true, 'quiz-radio__button--checked': value === false })}
            >
                <input
                    name="quiz-radio"
                    id="quiz_radio_negative"
                    type="radio"
                    className="quiz-radio__input"
                    defaultChecked={value === false}
                    onClick={() => onChange(false)}
                />
                No
            </label>
        </div>
    );
};

export default QuizRadio;
