import { compareAsc, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';

import { useUser } from '../../../business-logic/context-provider/user-context';
import { CheckoutDetailsResponse, CoverResponse } from '../../../business-logic/models/CheckoutDetails';
import { ListItemProps } from '../../../components/list-item/ListItem';
import List from '../../../components/list/List';
import useLazyDependency from '../../../hooks/lazy-dependency/useLazyDependency';

import createScheduledCoverListItem from './list-item-creators/createScheduledCoverListItem';
import createAmountDueListItem from './list-item-creators/createAmountDueListItem';
import createDiscountCodeListItem from './list-item-creators/createDiscountCodeListItem';
import createAccountCreditListItem from './list-item-creators/createAccountCreditListItem';

import './CheckoutSummary.scss';

interface CheckoutSummaryProps {
    checkoutDetails: CheckoutDetailsResponse;
    covers: CoverResponse[];
}

const CheckoutSummary: FC<CheckoutSummaryProps> = ({ checkoutDetails, covers }) => {
    const { creditBalance: cbLazyDepObject, userTimeZone } = useUser();
    const creditBalance = useLazyDependency(cbLazyDepObject);

    const items: ListItemProps[] = useMemo(() => {
        const list = covers
            .sort((a, b) => compareAsc(parseISO(a.startTime), parseISO(b.startTime)))
            .reduce((acc, item) => {
                const invoiceItem = checkoutDetails.invoice.items.find(
                    (iItem) => iItem.insuranceCoverId === item.insuranceCoverId,
                );

                if (!invoiceItem) {
                    return acc;
                }

                acc.push(
                    createScheduledCoverListItem(
                        item,
                        invoiceItem,
                        item.destination?.startingRegion.timeZone || userTimeZone,
                    ),
                );
                return acc;
            }, [] as ListItemProps[]);

        if (checkoutDetails.coupon) {
            list.push(createDiscountCodeListItem(checkoutDetails));
        }

        if (creditBalance.value && checkoutDetails.invoice.balanceApplied > 0) {
            list.push(createAccountCreditListItem(checkoutDetails, creditBalance.value));
        }

        list.push(createAmountDueListItem(checkoutDetails));

        return list;
    }, [checkoutDetails, covers, creditBalance.value, userTimeZone]);

    return <List items={items} className="checkout-summary" />;
};

export default CheckoutSummary;
