import { createMachine } from 'xstate';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import { MainCover } from '../../../utils/constants/Cover';
import Limits from '../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';
import {
    evaluateDisabledGuestDays,
    initialiseCoversAvailable,
    initialiseCoversAvailableForDependants,
    resetDaysToSchedule,
    setDatePickerMode,
    setDaysToSchedule,
    setSelectedCover,
    trackFlipActiveProductListViewed,
    trackFlipKidsProductListViewed,
} from './actions/scheduleCoverGuestMachineActions';
import { ScheduleCoverGuestMachineContextTypes } from './context/scheduleCoverGuestMachineContext';
import { isSchedulingCoverForDependant } from './guards/scheduleCoverGuestMachineGuards';

const scheduleCoverGuestMachine = createMachine(
    {
        id: 'ScheduleCoverGuestMachine',
        initial: 'initialise',
        context: {
            coversAvailable: [],
            selectedCover: null,
            daysToSchedule: [],
            datePickerMode: 'single',
            // use browser timezone as default
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            // use fallback limits as default
            disabledDays: [
                {
                    before: new Date(),
                    after: getLastDayToSchedule(new Date(), Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS),
                },
            ],
        },
        schema: {
            context: {} as ScheduleCoverGuestMachineContextTypes,
            events: {} as
                | { type: 'SELECT_COVER'; data: MainCover }
                | { type: 'SELECT_DATES'; data: Date[] }
                | { type: 'CHECKOUT' }
                | { type: 'GO_BACK' }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          productSpecs: Record<MainCover, ProductResponse>;
                      };
                  },
        },
        tsTypes: {} as import('./scheduleCoverGuestMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            initialise: {
                initial: 'determineCoverForSelfOrDependant',
                states: {
                    determineCoverForSelfOrDependant: {
                        always: [
                            {
                                cond: 'isSchedulingCoverForDependant',
                                target: 'initialiseForDependant',
                            },
                            {
                                target: 'initialiseForSelf',
                            },
                        ],
                    },
                    initialiseForSelf: {
                        always: {
                            actions: ['initialiseCoversAvailable', 'trackFlipActiveProductListViewed'],
                            target: '#ScheduleCoverGuestMachine.noPaymentModel',
                        },
                    },
                    initialiseForDependant: {
                        always: {
                            actions: ['initialiseCoversAvailableForDependants', 'trackFlipKidsProductListViewed'],
                            target: '#ScheduleCoverGuestMachine.noPaymentModel',
                        },
                    },
                },
            },
            noPaymentModel: {
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledGuestDays',
                                ],
                                target: 'coverSelected',
                            },
                        },
                    },
                    coverSelected: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledGuestDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                        },
                    },
                    coverAndDatesSelected: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledGuestDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                            CHECKOUT: {
                                actions: 'goToCheckout',
                            },
                        },
                    },
                    displayCartError: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledGuestDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                            GO_BACK: {
                                actions: 'goBack',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            evaluateDisabledGuestDays,
            initialiseCoversAvailable,
            initialiseCoversAvailableForDependants,
            setSelectedCover,
            setDatePickerMode,
            resetDaysToSchedule,
            setDaysToSchedule,
            trackFlipActiveProductListViewed,
            trackFlipKidsProductListViewed,
        },
        guards: {
            isSchedulingCoverForDependant,
        },
    },
);
export default scheduleCoverGuestMachine;
