import { FC } from 'react';
import { AddressFields } from '../../../../business-logic/models/Address';
import { OptionType } from '../../../../components/dropdown/Dropdown';
import TextField from '../../../../components/text-field/TextField';
import claimsFlow from '../../../../strings/claimsFlow';

interface InternationalAddressFieldsProps {
    addressFields: AddressFields;
    updateAddressFieldValues: (key: keyof AddressFields, value: string | OptionType | null) => void;
}

const InternationalAddressFields: FC<InternationalAddressFieldsProps> = ({
    addressFields,
    updateAddressFieldValues,
}) => {
    const { claimAccidentLocation: contentStrings } = claimsFlow;

    return (
        <>
            <TextField
                id="country"
                name={contentStrings.labels.country}
                label={contentStrings.labels.country}
                value={addressFields.country as string}
                onChange={(e) => updateAddressFieldValues('country', e.target.value)}
            />
            <TextField
                id="address-line-1"
                name={contentStrings.labels.addressLine1}
                label={contentStrings.labels.addressLine1}
                value={addressFields.address1}
                onChange={(e) => updateAddressFieldValues('address1', e.target.value)}
            />
            <TextField
                id="address-line-2"
                name={contentStrings.labels.addressLine2}
                label={contentStrings.labels.addressLine2}
                value={addressFields.address2}
                onChange={(e) => updateAddressFieldValues('address2', e.target.value)}
            />
            <TextField
                id="city"
                name={contentStrings.labels.city}
                label={contentStrings.labels.city}
                value={addressFields.city}
                onChange={(e) => updateAddressFieldValues('city', e.target.value)}
            />
            <TextField
                id="stateProvinceRegion"
                name={contentStrings.labels.stateProvinceRegion}
                label={contentStrings.labels.stateProvinceRegion}
                value={addressFields.stateProvinceRegion}
                onChange={(e) => updateAddressFieldValues('stateProvinceRegion', e.target.value)}
            />
            <TextField
                id="postcode"
                name={contentStrings.labels.postcode}
                label={contentStrings.labels.postcode}
                value={addressFields.postcode}
                onChange={(e) => updateAddressFieldValues('postcode', e.target.value)}
            />
        </>
    );
};

export default InternationalAddressFields;
