import Cover from '../../../utils/constants/Cover';
import BoostIcon from '../../../assets/images/boost/boost.svg';
import { ProductInfo } from './types';
import { FlipActiveSubMonthlyPdsVersion } from '../../../utils/constants/PdsVersion';

const flipActiveBoostV1: ProductInfo = {
    id: Cover.FLIP_ACTIVE_BOOST,
    icon: BoostIcon,
    name: 'Boost',
    group: 'Flip Active',
    price: '$7',
    priceTimePeriod: 'day',
    description: 'One time payment.',
    primaryFeatures: ['Pay by the day', 'Boost for $7 per day to double your payout for the day'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $40,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Up to <strong>$40k</strong> depending on your injury',
};

const flipActiveBoostProductInfo: Record<
    FlipActiveSubMonthlyPdsVersion.V1 | FlipActiveSubMonthlyPdsVersion.V1_KIDS,
    ProductInfo
> = {
    [FlipActiveSubMonthlyPdsVersion.V1]: flipActiveBoostV1,
    [FlipActiveSubMonthlyPdsVersion.V1_KIDS]: flipActiveBoostV1,
};

export default flipActiveBoostProductInfo;
