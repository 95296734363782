import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import BulletPointList from '../../../components/bullet-point-list/BulletPointList';
import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import parseString from '../../../utils/parseString';

import './SurgeryRequired.scss';

const SurgeryRequired: React.FC = () => {
    const { setClaimDetailsByAttr, claimDetails } = useClaim();

    const { surgeryRequired } = claimsFlowStrings;
    const history = useHistory();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_SURGERY_REQUIRED, claimDetails);
    }, []);

    const handleAnswerClick = (wasMajorSurgeryRequired: boolean) => {
        setClaimDetailsByAttr('wasMajorSurgeryRequired', wasMajorSurgeryRequired);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_SURGERY_REQUIRED, claimDetails);

        if (claimDetails.claimType === 'overseas') {
            history.push(Routes.OVERSEAS_RECEIPTS);
        } else {
            history.push(Routes.MEDICAL_REPORT);
        }
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_SURGERY_REQUIRED / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="surgery-required__header">{surgeryRequired.title}</h1>
            <p>{surgeryRequired.includesTitle}</p>
            <ul className="surgery-required__list">
                <li>{parseString(surgeryRequired.includedItem1)}</li>
                <li>
                    {parseString(surgeryRequired.includedItem2)}
                    <BulletPointList list={surgeryRequired.includedItem2SubItems} />
                </li>
            </ul>
            <div className="surgery-required__btn-group">
                <Button label={commonStrings.no} onClick={() => handleAnswerClick(false)} />
                <Button label={commonStrings.yes} onClick={() => handleAnswerClick(true)} />
            </div>
        </Layout>
    );
};

export default SurgeryRequired;
