import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import ProductService from '../../services/product-service/ProductService';
import withRetriesAsync from '../../services/utils/withRetriesAsync';
import ProductGroupsResponse from '../models/ProductGroupsResponse';
import ProductResponse from '../models/ProductResponse';

// Interfaces
interface BaymaxProductState {
    loading: boolean;
    initialised: boolean;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    error: boolean;
}

const initialState: BaymaxProductState = {
    loading: false,
    initialised: false,
    products: [],
    productGroups: [],
    error: false,
};

export type BaymaxProductDispatchAction =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_INITIALISED'; payload: boolean }
    | { type: 'SET_PRODUCTS'; payload: ProductResponse[] }
    | { type: 'SET_PRODUCT_GROUPS'; payload: ProductGroupsResponse }
    | { type: 'SET_PRODUCTS_ERROR'; payload: boolean };

export type IBaymaxProductContext = {
    initialised: boolean;
    loading: boolean;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    error: boolean;
};

export const BaymaxProductContext = createContext<any>({});

export const useBaymaxProduct = (): IBaymaxProductContext => {
    const context: IBaymaxProductContext = useContext(BaymaxProductContext);
    if (typeof context === 'undefined') {
        throw new Error('Baymax Product Context must be used within the BaymaxProductContext');
    }
    return context;
};

const reducer = (state: BaymaxProductState, action: BaymaxProductDispatchAction): BaymaxProductState => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_INITIALISED':
            return { ...state, initialised: action.payload };
        case 'SET_PRODUCTS':
            return { ...state, products: action.payload };
        case 'SET_PRODUCT_GROUPS':
            return { ...state, productGroups: action.payload };
        case 'SET_PRODUCTS_ERROR':
            return { ...state, error: action.payload };
        default:
            throw new Error();
    }
};

export const BaymaxProductProvider: React.FC = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setBaymaxProductsLoading = (loading: boolean) => {
        dispatch({ type: 'SET_LOADING', payload: loading });
    };
    const setBaymaxProductsInitialised = (initialised: boolean) => {
        dispatch({ type: 'SET_INITIALISED', payload: initialised });
    };
    const setBaymaxProducts = (products: ProductResponse[]) => {
        dispatch({ type: 'SET_PRODUCTS', payload: products });
    };
    const setBaymaxProductGroups = (productGroups: ProductGroupsResponse) => {
        dispatch({ type: 'SET_PRODUCT_GROUPS', payload: productGroups });
    };
    const setBaymaxProductsError = (error: boolean) => {
        dispatch({ type: 'SET_PRODUCTS_ERROR', payload: error });
    };
    // ***************** Fetchers *****************

    const fetchAllProducts = useCallback(async () => {
        const response = await ProductService.getProducts({
            filterTestProducts: true,
        });

        return response;
    }, []);

    const fetchAllProductGroups = useCallback(async () => {
        const response = await ProductService.getProductGroups({});
        return response;
    }, []);

    // ***************** Fetch and set *****************

    const fetchAndSetAllBaymaxProducts = useCallback(async () => {
        try {
            setBaymaxProductsLoading(true);
            const rawProducts = await withRetriesAsync(() => fetchAllProducts());
            const rawProductGroups = await withRetriesAsync(() => fetchAllProductGroups());
            setBaymaxProducts(rawProducts);
            setBaymaxProductGroups(rawProductGroups);
        } catch (e) {
            setBaymaxProductsError(true);
            setBaymaxProductsInitialised(false);
            setBaymaxProductsLoading(false);
            // TODO - handle error
        } finally {
            setBaymaxProductsInitialised(true);
            setBaymaxProductsLoading(false);
            setBaymaxProductsError(false);
        }
    }, [fetchAllProducts, fetchAllProductGroups]);

    // ***************** Initialise *****************

    useEffect(() => {
        fetchAndSetAllBaymaxProducts();
    }, [fetchAndSetAllBaymaxProducts]);

    const value: IBaymaxProductContext = {
        ...state,
    };

    const { children, ...passThroughProps } = props;
    return (
        <BaymaxProductContext.Provider value={value} {...passThroughProps}>
            {state.loading && <LoadingSpinnerOverlay />}
            {state.error && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            {children}
        </BaymaxProductContext.Provider>
    );
};
