import { createMachine } from 'xstate';
import { ProductOption } from '../../../business-logic/models/ProductGroupsResponse';
import Limits from '../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';
import {
    evaluateDisabledDays,
    resetDaysToSchedule,
    setDatePickerMode,
    setDaysToSchedule,
    setSelectedCover,
    updateMachineContextFromGlobalContext,
} from './actions/scheduleCoverBaymaxMachineActions';
import { ScheduleCoverBaymaxMachineContextTypes } from './context/scheduleCoverBaymaxMachineContext';

const scheduleCoverBaymaxMachine = createMachine(
    {
        id: 'ScheduleCoverBaymaxMachine',
        initial: 'waitForContextInitialisation',
        context: {
            coversAvailable: [],
            selectedCover: null,
            selectedProductGrouping: null,
            scheduleLimitInHours: null,
            daysToSchedule: [],
            datePickerMode: 'single',
            // use browser timezone as default
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            // use fallback limits as default
            disabledDays: [
                {
                    before: new Date(),
                    after: getLastDayToSchedule(new Date(), Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS),
                },
            ],
        },
        schema: {
            context: {} as ScheduleCoverBaymaxMachineContextTypes,
            events: {} as
                | { type: 'SELECT_COVER'; data: ProductOption }
                | { type: 'SELECT_DATES'; data: Date[] }
                | { type: 'CHECKOUT' }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          selectedProductGrouping: string;
                          coversAvailable: ProductOption[];
                      };
                  },
        },
        tsTypes: {} as import('./scheduleCoverBaymaxMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            waitForContextInitialisation: {
                on: {
                    CONTEXT_INITIALISED: {
                        actions: 'updateMachineContextFromGlobalContext',
                        target: 'noPaymentModel',
                    },
                },
            },
            noPaymentModel: {
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setScheduleLimitInHours',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                        },
                    },
                    coverSelected: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setScheduleLimitInHours',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                        },
                    },
                    coverAndDatesSelected: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setScheduleLimitInHours',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                            CHECKOUT: {
                                actions: 'goToCheckout',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            evaluateDisabledDays,
            setSelectedCover,
            setDatePickerMode,
            resetDaysToSchedule,
            setDaysToSchedule,
            updateMachineContextFromGlobalContext,
        },
    },
);
export default scheduleCoverBaymaxMachine;
