import React, { FC } from 'react';
import Slider from '../../../slider/Slider';
import ExampleCard from '../example-card/ExampleCard';
import withContent from '../../../../hoc/with-content/withContent';
import whatsCoveredExamples from '../../../../content/ui/components/whats-covered-examples/whatsCoveredExamples';
import stringToReactKey from '../../../../utils/stringToReactKey';

const contentMap = {
    heading: 'ui.heading',
    coveredTitle: 'ui.coveredTitle',
    notCoveredTitle: 'ui.notCoveredTitle',
    examples: 'ui.examples',
};

type Examples = {
    examples: { covered: string; notCovered: string }[];
};

interface WhatsNotCoveredTabProps {
    content: Record<keyof typeof contentMap, string> & Examples;
}

const Examples: FC<WhatsNotCoveredTabProps> = ({ content }) => {
    return (
        <Slider title={content.heading}>
            {content.examples.map((example: { covered: string; notCovered: string }) => {
                return (
                    <ExampleCard
                        key={stringToReactKey(example.covered)}
                        coveredTitle={content.coveredTitle}
                        notCoveredTitle={content.notCoveredTitle}
                        covered={example.covered}
                        notCovered={example.notCovered}
                    />
                );
            })}
        </Slider>
    );
};

export default withContent(Examples, contentMap, whatsCoveredExamples);
