import { FC, useState } from 'react';

import classNames from 'classnames';
import close from '../../assets/images/close.svg';
import check from '../../assets/images/deep-green-check.svg';
import { CoverInfoV3 } from '../../content/by-product/cover-terms/types';
import common from '../../strings/common';
import { MainCover } from '../../utils/constants/Cover';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import getCoverTerms from '../../utils/getCoverTerms';
import getProductInfo from '../../utils/getProductInfo';
import parseString from '../../utils/parseString';
import stringToReactKey from '../../utils/stringToReactKey';
import Alert from '../alert/Alert';
import Button from '../button/Button';
import { ListItemProps } from '../list-item/ListItem';
import List from '../list/List';
import Modal from '../modal/Modal';
import PdsDisclaimer from '../pds-disclaimer/PdsDisclaimer';

import './SlideInCoverTerms.scss';

interface SlideInCoverTermsProps {
    selectedCover: MainCover;
    pdsVersion: MainCoverPdsVersion;
    isOpen: boolean;
    showGroupPrice?: boolean;
    onClose: () => void;
    onOpen: () => void;
}

const SlideInCoverTerms: FC<SlideInCoverTermsProps> = ({
    selectedCover,
    pdsVersion,
    isOpen,
    showGroupPrice = false,
    onOpen,
    onClose,
}) => {
    const [multiContentTarget, setMultiContentTarget] = useState<keyof CoverInfoV3>('mainMenu');
    const coverTerms = getCoverTerms(selectedCover, pdsVersion) as CoverInfoV3;
    const coverName = getProductInfo(selectedCover, pdsVersion).group;

    const closeModal = () => {
        onClose();
        setMultiContentTarget('mainMenu'); // reset
    };

    const switchSubMenu = (id: string) => {
        onClose();
        setTimeout(() => {
            setMultiContentTarget(id as keyof CoverInfoV3);
            onOpen();
        }, 600);
    };

    const renderTerms = () => {
        const selectedContent = Object.entries(coverTerms[multiContentTarget as keyof CoverInfoV3]!);

        return selectedContent.map(([key, value]) => {
            switch (key) {
                case 'title':
                    return <h1 key={value}>{value}</h1>;
                case 'description':
                    return <p key={value}>{parseString(value)}</p>;
                case 'workingSteps':
                    return (
                        <div key={key}>
                            <p className="slide-in-coverterms__howitworks__title">{value?.title}</p>
                            <div className="slide-in-coverterms__howitworks">
                                {value?.steps.map((step: string, stepIndex: number) => (
                                    <div key={step} className="slide-in-coverterms__howitworks__step">
                                        <div className="slide-in-coverterms__howitworks__step__number">
                                            {stepIndex + 1}
                                        </div>
                                        <span className="slide-in-coverterms__howitworks__step__content">
                                            {parseString(step || '')}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                case 'payoutCategories':
                    return (
                        <div key={key} className="slide-in-coverterms__payout-categories">
                            {value.map(
                                (payoutCategory: {
                                    categoryName: string;
                                    payouts: { injurySeverity: string; payoutAmount: string }[];
                                }) => (
                                    <div
                                        className="slide-in-coverterms__payout-category"
                                        key={payoutCategory.categoryName}
                                    >
                                        {payoutCategory.categoryName ? (
                                            <p className="slide-in-coverterms__payout-category-name">
                                                {payoutCategory.categoryName}
                                            </p>
                                        ) : null}
                                        {payoutCategory.payouts.map(
                                            (payout: { injurySeverity: string; payoutAmount: string }) => (
                                                <div
                                                    className="slide-in-coverterms__payout"
                                                    key={payout.injurySeverity}
                                                >
                                                    <div className="slide-in-coverterms__payout-severity-wrapper">
                                                        <img
                                                            src={check}
                                                            alt=""
                                                            className="slide-in-coverterms__payout-severity-icon"
                                                        />
                                                        <span>{payout.injurySeverity}</span>
                                                    </div>
                                                    <span>{payout.payoutAmount}</span>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                ),
                            )}
                        </div>
                    );
                case 'locations':
                    return (
                        <div key={key}>
                            <h2>{value.title}</h2>
                            <div className="slide-in-coverterms__additional-notes">
                                {value.list.map((location: string) => (
                                    <div key={location} className="slide-in-coverterms__additional-note__item">
                                        <img
                                            src={value.icon}
                                            alt=""
                                            className="slide-in-coverterms__additional-note__icon"
                                        />
                                        <span>{parseString(location)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                case 'exclusions': {
                    const exclusionsList: ListItemProps[] = value.map(
                        (exclusion: { name: string; icon: string; description: string }) => ({
                            id: stringToReactKey(exclusion.name),
                            icon: exclusion.icon,
                            data: exclusion.name,
                            dataExpanded: (
                                <span className="slide-in-coverterms__exclusion--data-expanded">
                                    {exclusion.description}
                                </span>
                            ),
                        }),
                    );
                    return <List key={key} items={exclusionsList} className="slide-in-coverterms__exclusions" />;
                }
                case 'triggerDrawer':
                    return (
                        <div key={key}>
                            <Button
                                variant="drawer-link"
                                className="slide-in-cover-terms__trigger-button"
                                label={value.label}
                                onClick={() => switchSubMenu(value.id)}
                                width="full"
                            />
                        </div>
                    );
                case 'fineprint':
                    return (
                        <div key={key}>
                            {value.map((text: string) => (
                                <p className="slide-in-coverterms__fineprint" key={text}>
                                    {parseString(text)}
                                </p>
                            ))}
                        </div>
                    );
                case 'subtext':
                    return (
                        <div key={key}>
                            {value.map((text: string) => (
                                <p className="slide-in-coverterms__subtext" key={text}>
                                    {parseString(text)}
                                </p>
                            ))}
                        </div>
                    );
                case 'additionalNotes':
                    return (
                        <div key={key}>
                            <p className="slide-in-coverterms__additional-notes__title">{value.title}</p>
                            <div className="slide-in-coverterms__additional-notes">
                                {value.notes.map((note: string) => (
                                    <div key={note} className="slide-in-coverterms__additional-note__item">
                                        <img
                                            src={value.icon}
                                            alt=""
                                            className="slide-in-coverterms__additional-note__icon"
                                        />
                                        <span>{parseString(note)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                case 'alert':
                    return (
                        <Alert
                            key={key}
                            className="slide-in-coverterms__alert"
                            message={parseString(value.message)}
                            type={value.type}
                        />
                    );
                default:
                    return '';
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpen} parentClass="#root" onClose={onClose} variant="slide-in" className="no-scroll">
                <div className="slide-in-coverterms__modal-close-button-wrapper">
                    <button
                        type="button"
                        className="slide-in-coverterms__modal-close-button"
                        aria-label="Close"
                        onClick={closeModal}
                    >
                        <img src={close} alt="Close" />
                    </button>
                    {multiContentTarget !== 'mainMenu' && (
                        <Button
                            type="button"
                            variant="link"
                            width="standard"
                            className="slide-in-coverterms__modal__menu-button"
                            label={`${coverName} Menu`}
                            onClick={() => setMultiContentTarget('mainMenu')}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        'slide-in-coverterms',
                        !!multiContentTarget && multiContentTarget !== 'mainMenu' && 'active',
                    )}
                >
                    <div className="slide-in-coverterms__menu">
                        <div className="slide-in-coverterms__main__pricing">
                            <span>
                                {parseString(
                                    coverTerms.mainMenu.groupPrice && showGroupPrice
                                        ? coverTerms.mainMenu.groupPrice
                                        : coverTerms.mainMenu.pricePerTimePeriod,
                                )}
                            </span>
                        </div>
                        <h1>{coverTerms.mainMenu.title}</h1>
                        <p>{parseString(coverTerms.mainMenu.description)}</p>
                        {coverTerms.mainMenu.links.map((link: { target: string; label: string }) => (
                            <div key={link.target}>
                                <Button
                                    key={link.target}
                                    type="button"
                                    variant="drawer-link"
                                    width="full"
                                    label={link.label}
                                    className="slide-in-coverterms__main__button"
                                    onClick={() => setMultiContentTarget(link.target as keyof CoverInfoV3)}
                                />
                                <div className="slide-in-coverterms__main__button__divider" />
                            </div>
                        ))}
                    </div>
                    <div className="slide-in-coverterms__step">
                        {renderTerms()}
                        <PdsDisclaimer className="slide-in-coverterms__pds-disclaimer" />
                        <span className="slide-in-coverterms__pds">
                            {coverName} {common.formatString(common.pdsVersion, { pdsVersion })}
                        </span>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SlideInCoverTerms;
