import onboardingFlow from '../../strings/onboardingFlow';
import climbing from '../../assets/images/activities/climbing.svg';
import cycling from '../../assets/images/activities/cycling.svg';
import gym from '../../assets/images/activities/gym.svg';
import yoga from '../../assets/images/activities/yoga.svg';
import ballSports from '../../assets/images/activities/ballSports.svg';
import snowSports from '../../assets/images/activities/snowSports.svg';
import waterSports from '../../assets/images/activities/waterSports.svg';
import enduranceSports from '../../assets/images/activities/enduranceSports.svg';
import combatSports from '../../assets/images/activities/combatSports.svg';
import motorcycling from '../../assets/images/activities/motorcycling.svg';
import other from '../../assets/images/activities/other.svg';

const { activities: labels } = onboardingFlow.yourActivities;

const Activities = {
    climbing: {
        label: labels.climbing,
        icon: climbing,
    },
    cycling: {
        label: labels.cycling,
        icon: cycling,
    },
    gym: {
        label: labels.gym,
        icon: gym,
    },
    yoga: {
        label: labels.yoga,
        icon: yoga,
    },
    ballSports: {
        label: labels.ballSports,
        icon: ballSports,
    },
    snowSports: {
        label: labels.snowSports,
        icon: snowSports,
    },
    waterSports: {
        label: labels.waterSports,
        icon: waterSports,
    },
    enduranceSports: {
        label: labels.enduranceSports,
        icon: enduranceSports,
    },
    combatSports: {
        label: labels.combatSports,
        icon: combatSports,
    },
    motorcycling: {
        label: labels.motorcycling,
        icon: motorcycling,
    },
    other: {
        label: labels.other,
        icon: other,
    },
};

export type ActivityId = keyof typeof Activities;

export default Activities;
