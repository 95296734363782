import LocalizedStrings from 'react-localization';
import greenTick from '../assets/images/deep-green-check.svg';
import causeInjuryIcon from '../assets/images/claims/requirement-icons/cause-your-injury.svg';
import dateInjuryIcon from '../assets/images/claims/requirement-icons/date-your-injury.svg';
import detailsIcon from '../assets/images/claims/requirement-icons/details-and-date-visit.svg';
import medicalReportIcon from '../assets/images/claims/requirement-icons/medical-report.svg';
import nameIcon from '../assets/images/claims/requirement-icons/name-and-DOB.svg';
import treatmentsIcon from '../assets/images/claims/requirement-icons/treatments-and-dates.svg';
import { CountryOptionTypes } from '../business-logic/models/Address';
import {
    MedicalContactConsentAuth1Version,
    MedicalContactConsentAuth2Version,
} from '../utils/constants/MedicalContactConsentVersion';
import ThirdPartyConsentVersion from '../utils/constants/ThirdPartyConsentVersion';

const claimsFlow = new LocalizedStrings({
    en: {
        claims: {
            title: 'Claims',
            learnMoreAboutCalims: 'Learn more about claims',
            buttonLabel: 'Make a claim',
            existingClaimsTitle: 'Existing claims',
            status: 'Status: ',
            emptyState: {
                title: 'No claims yet',
                mobileDescription: 'You don’t have any claims. Hit the button below to get started.',
                desktopDescription: 'You don’t have any claims. Hit the button above to get started.',
            },
            howToMakeAClaim: {
                title: 'How to make a claim',
                description: 'Watch this video to learn all you need to know to help us pay you out quickly.',
                buttonLabel: 'Watch video',
                steps: [
                    "We'll need you to answer a <strong>few questions</strong> about your accident and the injuries you got.",
                    'You’ll need <strong>medical proof</strong> from your doctor.',
                    'Once we have all this info, you’ll need to <strong>confirm your bank account details</strong> - so, when your claim’s approved, you get your cash ASAP! 💸',
                ],
            },
            disclaimer: 'Please note that if you’re making a claim on behalf of someone else, contact us at {support}',
            noPurchaseDescription: "You can only make a claim if you've had insurance with us before.",
        },
        claimsChecklist: {
            title: "Let's check the basics",
            description: "Before you get started, let's check a few things off.",
            checklist: [
                {
                    label: 'Was your injury because of an accident?',
                    subLabel: 'e.g. not an existing injury, or an injury caused by a condition or wear and tear',
                },
                {
                    label: 'Was your Flip cover active when you had your accident?',
                },
                {
                    label: 'Have you seen a doctor within 14 days of your accident? ',
                },
            ],
            considerationTitle: 'For consideration',
            considerationsWhatsCovered: "To be sure you're claiming for an injury we cover, check out the {pds}",
            considerationKid: "If you're claiming on behalf of a kid, the questions relate to your kid",
            considerationChatWithUs: 'Still not sure {chatWithUs}',
            considerationsWhatsCoveredLabel: "what's covered",
            considerationsChatWithUsLabel: 'chat with us',
            letsGetStarted: "Let's get started",
        },
        allowedFilesLabel: 'Files need to be .PDF, .PNG, or .JPEG',
        claimRequirements: {
            title: "You'll need these to make a claim",
            mandatory: {
                title: 'Mandatory',
                medicalReport: {
                    title: 'Medical proof',
                    reportRequirements: {
                        title: 'Proof from your doctor that shows:',
                        requirements: [
                            'What the injury is',
                            'Cause of injury',
                            'Date of injury',
                            'Your name and DOB',
                            "Your doctor's details",
                        ],
                    },
                    surgeryRequirements: {
                        title: "And if you've had treatment:",
                        requirements: ['What treatment you had', 'Date of treatment'],
                    },
                },
            },
            speedUpYourClaim: {
                title: 'Speed up your claim',
                description: 'We might not be able to complete your claim without these.',
                thirdPartyConsent: {
                    title: 'Third party consent',
                    details: {
                        title: 'What does this mean?',
                        description:
                            "If we need to, we'll be able to contact relevant third parties like the rock climbing centre where your popped your shoulder, or the organisers of the marathon where you tore your hammy.",
                    },
                },
                healthProfessionalConsent: {
                    title: 'Medical consent',
                    details: {
                        title: 'What does this mean?',
                        description:
                            "If we need to, we'd like to be able to contact your doctors directly about your injuries to help us process your claim.",
                    },
                },
            },
            niceToHave: {
                title: 'Nice to have',
                description: 'The more information you provide, the quicker we can process your claim.',
                policeReport: {
                    title: 'Police report',
                    requirements: {
                        title: 'This includes:',
                        requirements: [
                            'Officers in attendance',
                            'Report date ',
                            'Details of the incident',
                            'Pictures taken',
                            'Statements of witnesses',
                            'Any facts needed to be documented in the case',
                        ],
                    },
                },
                witnessDetails: {
                    title: 'Witness details',
                    requirements: {
                        title: 'This includes their:',
                        requirements: ['Full name', 'Contact number', 'or Email address'],
                    },
                },
                accidentPhoto: {
                    title: 'Photo of the Accident',
                    requirements: {
                        title: 'This could be a photo of:',
                        requirements: [
                            'Damaged equipment',
                            'The scene where it happened',
                            'It also helps if the photo has the date and time of the accident',
                        ],
                    },
                },
                activityProof: {
                    title: 'Proof of the activity when you got injured',
                    requirements: {
                        title: 'This could be:',
                        requirements: [
                            'A screenshot of your event registration',
                            'A screenshot of your venue check-in',
                            'A screenshot from a fitness app showing the activity and time',
                        ],
                    },
                },
            },
            fileRequirements: 'You can only upload .PDF, PNG or JPEG files',
            acceptButtonLabel: 'Ok, got it',
        },
        claimAutograph: {
            title: 'Your autograph, please',
            description:
                'To kick things off, we need your signature. By signing below, you agree to provide accurate info about your claim.',
            signHere: 'Sign here',
            disclaimer:
                'Protecting your privacy is of the utmost importance to us. Your information is of the utmost importance to us. For more information, please review our {privacyPolicy}.',
            modalDescription: 'Insurance fraud is a crime.',
            modalAlert:
                'Please ensure the information you enter is true and accurate. We want to pay your claim, not investigate and report insurance fraud.',
            unableToSignLabel: 'Unable to use a mouse/touch screen to sign?',
            goBackToSignLabel: 'Go back to signing my autograph instead',
            textAutographDescription:
                'Type {agreeText} into the input box below to agree to provide accurate info about your claim.',
            textAutographAgreeText: 'I AGREE',
        },
        claimAccidentLocation: {
            title: 'Where did the Accident happen?',
            description: 'It doesn’t have to be a precise address but the more accurate, the better.',
            labels: {
                autocompleteInput: 'Search for your location',
                addLocationManuallyButton: '+ Add location manually',
                addressLine1: 'Address line 1',
                addressLine2: 'Address line 2',
                suburb: 'Suburb',
                city: 'City',
                state: 'State',
                stateProvinceRegion: 'State / Province / Region',
                postcode: 'Postcode',
                country: 'Country',
            },
            countryOptions: Object.values(CountryOptionTypes),
            stateOptions: [
                { value: 'NSW', label: 'NSW' },
                { value: 'VIC', label: 'VIC' },
                { value: 'QLD', label: 'QLD' },
                { value: 'TAS', label: 'TAS' },
                { value: 'SA', label: 'SA' },
                { value: 'NT', label: 'NT' },
                { value: 'ACT', label: 'ACT' },
                { value: 'WA', label: 'WA' },
            ],
            error: 'Sorry. Only Accidents that occur in Australia are claimable.',
        },
        selectInjury: {
            title: 'Select your injury/injuries',
            error: 'Sorry, something went wrong at our end. Please try again later.',
            injuryOptions: {
                brokenBone: 'Broken bone or fracture',
                muscle: 'Muscle, ligament injury',
                tendon: 'Tendon, cartilage injury',
                dislocation: 'Dislocation',
                wound: 'Wound',
                dental: 'Dental injury',
                blindness: 'Sight in both eyes',
                deafness: 'Hearing in both ears',
                paraplegia: 'Loss of use of limbs',
                other: 'Something else',
            },
        },
        accidentDescription: {
            title: 'Describe the Accident',
            usefulThingsToIncludeTitle: 'Here are a few useful things to include:',
            usefulThingsToInclude: [
                'What you were doing when the accident happened',
                'How the accident happened',
                'How you got injured',
            ],
            usefulDetail: 'Be as detailed as possible.',
            example: `<strong>Example</strong>: I was riding my bike and hurt my leg.`,
        },
        thirdPartyConsent: {
            title: 'Help us speed up your claim',
            description: 'We need your consent to contact third parties with information about your accident.',
            declaration: {
                [ThirdPartyConsentVersion.ONE]:
                    'I authorise any third party involved in my accident, such as sporting facilities or event organisers, to access and release, in writing or verbally, any details of my accident to Flip Insurance, or to third parties they engage.',
            },
            date: 'Todays date: {date}',
            authorisation: 'By clicking yes I authorise Flip to contact third parties.',
            modalDescription:
                'Are you sure? We can still process your claim but we’ll most likely need to ask you for this eventually.',
            modalAlert:
                'We might not be able to complete your claim without the information granted by consent to third parties.',
        },
        accidentDate: {
            title: 'When did the Accident happen?',
            date: 'Date',
            time: 'Time',
            amPm: 'AM/PM',
            errorInvalidTime: 'Sorry, that time doesn’t look right',
            errorInvalidAccidentTime: 'Sorry, only past times are claimable',
        },
        firstTreatmentDate: {
            title: 'When did you first see a doctor?',
            description: 'Please select the date that you saw a doctor for your injuries.',
            date: 'Date',
        },
        surgeryRequired: {
            title: 'Did you require major surgery?',
            includesTitle: 'This includes:',
            includedItem1: '<strong>Surgery</strong> in an operating theatre (not solely for diagnostic purposes)',
            includedItem2:
                '<strong>Major dental work</strong> for a displaced, knocked out or fractured tooth, requiring any of the following:',
            includedItem2SubItems: ['Crown and bridgework', 'Implants', 'Dentures', 'Splints', 'Root canal therapy'],
        },
        overseasReceipts: {
            title: 'Overseas Medical Costs',
            instruction: 'If you have receipts for overseas medical care you wish to claim, upload them below.',
            description:
                'You can claim back up to A$20,000 to reimburse you for overseas medical costs related to this accident, you have already paid for.',
            costsLabel: 'See examples of eligible costs',
            conditions: {
                title: 'If your medical costs meet each of these conditions you can make a claim',
                icon: greenTick,
                items: [
                    'Services and treatments were provided by a registered medical provider.',
                    'The medical care was not as a result of illness or riding a motorbike.',
                    'The costs are not related to repatriation or transportation back to Australia.',
                    'Are not related to travel expenses.',
                    'You have not claimed these expenses from another insurance provider.',
                ],
            },
        },
        emergencyServicesRequired: {
            title: 'Were emergency services needed?',
            exampleTitle: 'For example:',
            examples: ['Police', 'Ambulance', 'Fire', 'Emergency medical services'],
        },
        witnessQuestionnaire: {
            title: 'Were there any witnesses?',
            description:
                "Speed up your claim by adding witness details. It could be a friend or a random who was there at the scene of the accident.\n\nWe'll only contact them if we need more information, promise we won't spam them!",
        },
        witnessInfo: {
            title: 'Add witness information',
            description: 'We’ll need their name and an email address or phone number.',
            labels: {
                name: 'Witness’ name *',
                email: 'Witness’ Email',
                phone: 'Witness’ Phone number',
                addAnotherWitness: '+ Add another witness',
            },
            permission:
                'The witness has given me permission to provide their details, and for Flip to contact them if necessary.',
            errors: {
                eitherPhoneOrEmail: "You'll need to add either an email or a phone number",
                invalidEmail: 'Sorry, that email doesn’t look right',
            },
        },
        photoOfAccidentQuestionnaire: {
            title: 'Got a photo of the Accident?',
            description:
                "This step's optional, but if you have one, a photo of the Accident helps. Might be a photo of your broken bike or your injury. Bonus points if there's a timestamp!",
        },
        proofOfActivityQuestionnaire: {
            title: 'Do you have proof of the activity?',
            description:
                "This one's also optional. We're looking for anything that shows what you were doing and when. This could include an email confirmation or a receipt.",
        },
        medicalReport: {
            title: 'Upload all medical evidence',
            description: 'Upload all medical documents relating to your accident.',
            itemsDescription: 'The documents must show:',
            itemsToInclude: [
                {
                    icon: medicalReportIcon,
                    title: 'Your diagnosis',
                },
                {
                    icon: causeInjuryIcon,
                    title: 'The cause of your injury',
                },
                {
                    icon: dateInjuryIcon,
                    title: 'The date of your accident',
                },
                {
                    icon: treatmentsIcon,
                    title: 'Your treatments and dates',
                },
                {
                    icon: nameIcon,
                    title: 'Your name and DOB',
                },
                {
                    icon: detailsIcon,
                    title: 'Doctor’s details and date of visit',
                },
            ],
            itemsToIncludeIfHadSurgery: ['Type of surgery performed', 'Date of surgery'],
        },
        medicalContactConsent: {
            title: 'Can we contact your health professionals?',
            description:
                '<strong>We need your consent to contact your doctors directly about your injuries.</strong>\n\nLess hassle for you and faster claims... winning!\n\nIs that ok?',
            jargonModalDescription: 'Legal and medical jargon alert 🤓',
            jargonModalAlert:
                "We try hard to avoid using legalese, but we're not allowed to change the wording for this part of our process.\n\nSo, strap yourself in, there's a bit of reading, but it's super important and it'll make your life easier in the long run.",
            noModalDescription:
                'Are you sure? We can still process your claim but we’ll most likely need to ask you for this eventually.',
            noModalAlert:
                'We might not be able to complete your claim without the information granted by consent to doctors. ',
        },
        medicalContactInformation: {
            title: 'Consent to access your health information',
            description:
                '<strong>Releasing information about your health</strong>\n\nYour health information includes details about all your interactions with health providers, and may include details such as your symptoms, treatment, consultations, personal medical history and lifestyle. Health providers cannot release this information about you without your consent. \n\nWe, Flip Insurance, collect and use your health information to assess and manage your claim, or to confirm the information you gave us when you made a claim. This is why we need your consent. \n\nEach time you make a claim, we will ask you for a fresh consent. We’ll respect your privacy by only asking for the information we reasonably need, and we will tell you each time we use your consent. \n\nThere are two Authorities. Please read each explanation and authority carefully, before signing.',
            authority1: {
                [MedicalContactConsentAuth1Version.ONE]: {
                    whatIsAuthority1: {
                        title: 'What is Authority 1?',
                        explanation:
                            'Through Authority 1, with the exception of a copy of the consultation notes held by your General Practitioner/Practice, <strong>you are consenting to</strong> any health provider releasing any health information about you in the form we ask for.',
                        exampleTitle: 'This may involve, for example:',
                        examples: [
                            'preparing a general report and/or a report about a specific condition;',
                            'accessing and releasing your records in SafeScript;',
                            'releasing your hospital patient notes;',
                            'releasing the results of any investigations they have done; and/or',
                            'releasing correspondence with other health providers.',
                        ],
                    },
                    authority1Declaration: {
                        title: 'Authority 1',
                        summary:
                            'Release any of my health information except the consultation notes held by my General Practitioner/Practice',
                        description:
                            'With the exception of consultation notes held by any General Practitioner/Practice I have attended, <strong>I authorise</strong> any health provider, practitioner, practice, psychologist, dentist, allied health services provider or any hospital to access and release, in writing or verbally, any details of my health information to Flip Insurance, or to third parties they engage. ',
                        agreementTitle: '<strong>I agree</strong> to all the following: ',
                        agreementItems: [
                            'My health information can be released in the form HCF Life asks for, such as a general report, a report about a specific condition, my records in SafeScript, any hospital notes, or correspondence between health providers. ',
                            'HCF Life can collect, use, store and disclose my personal information (including sensitive information) in accordance with privacy laws and Australian Privacy Principles. ',
                            'This Authority is valid only while HCF Life is assessing my claim or application for cover, or is verifying disclosures I made in connection with the cover. ',
                            'A copy or transcript of this Authority will be valid and effective, and this Authority should be accepted as valid and effective where I have signed electronically or consented verbally.',
                        ],
                    },
                },
            },
            authority2: {
                [MedicalContactConsentAuth2Version.ONE]: {
                    whatIsAuthority2: {
                        title: 'What is Authority 2?',
                        explanation:
                            'Through Authority 2, <strong>you are consenting to</strong> any General Practitioner/Practice you have attended releasing a copy of your full record, including consultation notes, but only if we’ve asked them to provide a general report and/or a report about a specific condition under Authority 1. ',
                        exampleTitle: 'This may involve for example:',
                        examples: [
                            'they’ll be unable to, or didn’t, provide the report within 4 weeks; or ',
                            'the report provided is incomplete, or contains inconsistencies or inaccuracies',
                        ],
                        exampleParagraph:
                            'Your General Practitioner maintains consultation notes to support quality care, your wellbeing and to meet legal and professional requirements. \n\nGeneral Practitioners/Practices should only release a copy of your full record, including consultation notes, for life insurance purposes in the rare circumstances set out above. \n\nIf you choose to withhold your consent to this authority, <strong>we may not be able to process your claim.</strong>',
                    },
                    authority2Declaration: {
                        title: 'Authority 2',
                        summary:
                            'Release a copy of the full record, including consultation notes, held by my General Practitioner/Practice in specified circumstances',
                        description:
                            '<strong>I authorise</strong> any General Practitioner/Practice I have attended to release a copy of my full record, including consultation notes, to Flip Insurance, or to third parties they engage, only if HCF Life has asked them for a report on my health and either: ',
                        descriptionItems: [
                            'the General Practitioner/Practice will be unable to, or didn’t, provide the report within four weeks; or ',
                            'the report is incomplete, or contains inconsistencies or inaccuracies. ',
                        ],
                        agreementTitle: '<strong>I agree</strong> to all the following: ',
                        agreementItems: [
                            'HCF Life can collect, use, store and disclose my personal information (including sensitive information) in accordance with privacy laws and Australian Privacy Principles. ',
                            'This Authority is valid only while HCF Life is assessing my claim or application for cover, or is verifying disclosures I made in connection with the cover. ',
                            'A copy or transcript of this Authority will be valid and effective, and this Authority should be accepted as valid and effective where I have signed electronically or consented verbally. ',
                        ],
                    },
                },
            },
            disagreeModal: {
                auth1Description:
                    'Are you sure? We can start processing your claim but we’ll most likely need to ask you for this eventually.',
                auth2Description:
                    "Are you sure? \n\nYou need to agree to both consents for Flip to access your health info. If you don't, we can start processing your claim but we’ll most likely need to ask you for this eventually.",
                alert: 'We might not be able to complete your claim without the information granted by consenting to access your health information. ',
            },
            todaysDate: "Today's date: {date}",
            name: 'Name: {name}',
            clickAgreeStatement: 'By clicking agree I authorise Flip to contact my doctors.',
        },
        photoOfAccident: {
            title: 'Upload the photo of your Accident',
            subtitle: 'Here are a few examples:',
            itemsToInclude: [
                'Your surfboard in two pieces after your run-in with the reef',
                'A photo of that life saver giving you mouth to mouth ',
                'Your mangled leg (we’re not scared of gore)',
            ],
        },
        proofOfActivity: {
            title: 'Upload proof of the activity',
            subtitle: 'Here are a few examples:',
            itemsToInclude: [
                'A screenshot of an email confirmation',
                'A photo of your ski pass',
                'A photo that you took during your activity',
                'A screenshot of your activity app (Strava, Google Maps)',
            ],
        },
        bankDetails: {
            titleBankDetailsNotSet: 'Nearly done. We just need your bank details',
            titleBankDetailsAlreadySet: 'Please confirm your bank details',
            errorInvalidFormat: 'Sorry, that format doesn’t look right',
            errorInvalidNumber: 'Sorry, that number doesn’t look right',
        },
        reviewClaim: {
            title: 'Review your claim',
            errorInfoMissing: 'Sorry, looks like some information is missing. Please try re-entering them',
            buttonLabel: 'Submit claim',
        },
        claimDetails: {
            successfullyUploaded: 'Successfully uploaded',
            addMoreMedicalReports: '+ Add more medical report(s)',
            addMorePhotosOfAccident: '+ Add more photos of accident',
            addMoreProofOfActivity: '+ Add more proof of activity',
            cards: {
                draft: {
                    description: 'Here’s a draft of your claim. Jump back in at any time.',
                    buttonLabel: 'Resume claim',
                },
                moreInfoRequired: {
                    description: 'Your claim requires more information to be processed.',
                },
            },
            failedToGetClaim: {
                title: 'Oops, we can’t find that claim',
                subtitle: 'Please go back to Claims and try again.',
            },
        },
        claimSubmitted: {
            title: 'All done!',
            description: `We&apos;ll get right on to checking out your claim. If all looks good, it should be approved within the next few days! 🎉\n\nCheck your email for your claim summary, and we’ll be in touch ASAP with an update on your claim.\n\nRest up and look after yourself – Team Flip.`,
        },
        claimDetailsList: {
            overview: 'Overview',
            overViewListHeaders: {
                status: 'Status',
                claimNumber: 'Claim number',
            },
            whatHappened: 'What happened',
            whatHappenedListHeaders: {
                who: 'Who',
                where: 'Where',
                injuryType: 'Injury type',
                accident: 'Accident',
                when: 'when',
                treatmentDate: 'Treatment date',
                majorSurgery: 'Major surgery',
                emergencyServices: 'Emergency services',
                thirdPartyConsent: 'Third party consent',
                medicalConsent: 'Medical consent',
            },
            theEvidence: 'The evidence',
            theEvidenceListHeaders: {
                medicalReport: 'Medical report',
                newMedicalReportUploaded: 'New medical report(s) uploaded',
                receipts: 'Overseas Medical Costs',
                newReceiptsUploaded: 'New overseas medical receipt(s) uploaded',
                photoOfAccident: 'Photo of accident',
                newPhotoOfAccidentUploaded: 'New photo(s) of accident uploaded',
                proofOfActivity: 'Proof of activity',
                newProofOfActivityUploaded: 'New proof of activity uploaded',
                witnessDetails: 'Witness details',
            },
            bankDetails: 'Bank details',
            successfullyUploaded: 'Successfully uploaded',
            addMoreMedicalReports: '+ Add more Medical report(s)',
            addMoreOverseasReceipts: '+ Add more Overseas medical receipt(s)',
            addMorePhotosOfAccident: '+ Add more photos of accident',
            addMoreProofOfActivity: '+ Add more proof of activity',
        },
        speedUpYourClaim: 'Speed up your claim',
        claimExamples: {
            medicalReport: {
                example1: {
                    title: 'Medical report',
                    description: 'Awesome! This one shows a description and date of the injury. 🙌',
                },
                example2: {
                    title: 'Doctor’s certificate',
                    description: 'Sweet! This one shows the date of the accident and the injury. 👍',
                },
            },
            photoOfAccident: {
                example1: {
                    title: 'Time of the accident',
                    description: 'Perfect! This screenshot shows the time of the accident. ⏰',
                },
                example2: {
                    title: 'Photo of broken gear',
                    description: 'This example clearly shows the damage, and we’re sorry about the gear. 😢',
                },
                example3: {
                    title: 'Photo of the injury',
                    description: 'Ouch! But it’s clear to see the injury from this photo. 🤕',
                },
            },
            proofOfActivity: {
                example1: {
                    title: 'Screenshot of the receipt',
                    description: 'Great! This one shows you were climbing at the date and time of your Accident ⏰',
                },
                example2: {
                    title: 'Screenshot of fitness app',
                    description: 'Perfect! This Strava screenshot shows you were cycling🏅',
                },
                example3: {
                    title: 'Photo with the time',
                    description: 'This screenshot’s good because it shows the activity, time and location.',
                },
            },
        },
        claimUploadExampleModal: {
            seeFewExamples: 'See a few examples',
            flipRatingLabel: 'Flip rating',
        },
    },
});

export default claimsFlow;
