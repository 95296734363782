import { ScheduleCoverGuestMachineContextTypes } from '../context/scheduleCoverGuestMachineContext';

export const isPersonsInsuredInfoValid = (ctx: ScheduleCoverGuestMachineContextTypes): boolean => {
    if (ctx.coverFor === 'self') {
        return true;
    }

    if (ctx.coverFor === 'dependant' && ctx.dependantToCover && ctx.dependantToCover.length > 0) {
        return true;
    }

    return false;
};

export const isSchedulingCoverForDependant = (ctx: ScheduleCoverGuestMachineContextTypes): boolean => {
    return ctx.coverFor === 'dependant';
};
