import MedicalReportExample1 from '../../assets/images/claims/example-images/medical-report/example-1.png';
import MedicalReportExample2 from '../../assets/images/claims/example-images/medical-report/example-2.png';

import PhotoOfAccidentExample1 from '../../assets/images/claims/example-images/accident/example-1.png';
import PhotoOfAccidentExample2 from '../../assets/images/claims/example-images/accident/example-2.png';
import PhotoOfAccidentExample3 from '../../assets/images/claims/example-images/accident/example-3.png';

import ProofOfActivityExample1 from '../../assets/images/claims/example-images/activity/example-1.png';
import ProofOfActivityExample2 from '../../assets/images/claims/example-images/activity/example-2.png';
import ProofOfActivityExample3 from '../../assets/images/claims/example-images/activity/example-3.png';

import claimsFlowStrings from '../../strings/claimsFlow';

const {
    claimExamples: { medicalReport, photoOfAccident, proofOfActivity },
} = claimsFlowStrings;

const ClaimExamples = {
    medicalReport: [
        {
            id: 'medical-report-example-1',
            title: medicalReport.example1.title,
            description: medicalReport.example1.description,
            image: MedicalReportExample1,
            rating: 10,
        },
        {
            id: 'medical-report-example-2',
            title: medicalReport.example2.title,
            description: medicalReport.example2.description,
            image: MedicalReportExample2,
            rating: 10,
        },
    ],
    photoOfAccident: [
        {
            id: 'photo-of-accident-example-1',
            title: photoOfAccident.example1.title,
            description: photoOfAccident.example1.description,
            image: PhotoOfAccidentExample1,
            rating: 10,
        },
        {
            id: 'photo-of-accident-example-2',
            title: photoOfAccident.example2.title,
            description: photoOfAccident.example2.description,
            image: PhotoOfAccidentExample2,
            rating: 8,
        },
        {
            id: 'photo-of-accident-example-3',
            title: photoOfAccident.example3.title,
            description: photoOfAccident.example3.description,
            image: PhotoOfAccidentExample3,
            rating: 8,
        },
    ],
    proofOfActivity: [
        {
            id: 'proof-of-activity-example-1',
            title: proofOfActivity.example1.title,
            description: proofOfActivity.example1.description,
            image: ProofOfActivityExample1,
            rating: 10,
        },
        {
            id: 'proof-of-activity-example-2',
            title: proofOfActivity.example2.title,
            description: proofOfActivity.example2.description,
            image: ProofOfActivityExample2,
            rating: 10,
        },
        {
            id: 'proof-of-activity-example-3',
            title: proofOfActivity.example3.title,
            description: proofOfActivity.example3.description,
            image: ProofOfActivityExample3,
            rating: 10,
        },
    ],
};

export default ClaimExamples;
