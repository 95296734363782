import Analytics from '../../../../analytics/Analytics';
import MultiProductEncodedCart from '../../../../business-logic/models/MultiProductEncodedCart';
import ReferralService from '../../../../services/referral-service/ReferralService';
import StorageKeys from '../../../../utils/constants/StorageKeys';
import { GuestCartMachineContextTypes } from '../context/guestCartMachineContext';

export type GuestCartMachineServicesTypes = {
    parseMultiProductCartString: {
        data: MultiProductEncodedCart | null;
    };
    validateReferralCode: {
        data: boolean | null;
    };
};

export const parseMultiProductCartString = async ({
    encodedCartString,
}: GuestCartMachineContextTypes): Promise<MultiProductEncodedCart | null> => {
    if (encodedCartString) {
        return JSON.parse(decodeURIComponent(atob(encodedCartString)));
    }
    throw new Error('missing multi product encoded cart string');
};

export const validateReferralCode = async ({ referralCode }: GuestCartMachineContextTypes): Promise<boolean | null> => {
    if (referralCode && typeof referralCode === 'string') {
        try {
            const response = await ReferralService.validateReferralCode({ referralCode });

            // Set an entry in session storage to indicate that this is a referral flow
            // Used in okta sign in pre-redirect callback as well as in the new welcome screens
            if (response) {
                Analytics.trackOnboardingStartedWithReferral(referralCode);
                sessionStorage.setItem(StorageKeys.VALIDATED_REFERRAL, 'true');
                sessionStorage.setItem(StorageKeys.VALIDATED_REFERRAL_CODE, referralCode);
            } else {
                Analytics.trackOnboardingStartedInvalidReferral(referralCode);
                sessionStorage.removeItem(StorageKeys.VALIDATED_REFERRAL);
                sessionStorage.removeItem(StorageKeys.VALIDATED_REFERRAL_CODE);
            }

            return response;
        } catch (e) {
            sessionStorage.removeItem(StorageKeys.VALIDATED_REFERRAL);
            sessionStorage.removeItem(StorageKeys.VALIDATED_REFERRAL_CODE);

            return false;
        }
    }

    sessionStorage.removeItem(StorageKeys.VALIDATED_REFERRAL);
    sessionStorage.removeItem(StorageKeys.VALIDATED_REFERRAL_CODE);

    return null;
};
