import LocalizedStrings from 'react-localization';
import Cover from '../../../utils/constants/Cover';
import ByProduct from '../ByProduct';
import alwaysOnCoverTerms from './alwaysOnCoverTerms';
import dayPassCoverTerms from './dayPassCoverTerms';
import flipActiveDailyCoverTerms from './flipActiveDailyCoverTerms';
import flipActiveSubMonthlyCoverTerms from './flipActiveSubMonthlyCoverTerms';
import flipActiveWeeklyCoverTerms from './flipActiveWeeklyCoverTerms';
import flipKidsDailyCoverTerms from './flipKidsDailyCoverTerms';
import flipKidsSubMonthlyCoverTerms from './flipKidsSubMonthlyCoverTerms';
import flipKidsWeeklyCoverTerms from './flipKidsWeeklyCoverTerms';
import flipRoamingCoverTerms from './flipRoamingCoverTerms';
import japanPassCoverTerms from './japanPassCoverTerms';
import { CoverInfo } from './types';

const coverTerms = new LocalizedStrings<ByProduct<CoverInfo>>({
    en: {
        [Cover.ALWAYS_ON]: alwaysOnCoverTerms,
        [Cover.BOOST]: alwaysOnCoverTerms,
        [Cover.DAY_PASS]: dayPassCoverTerms,
        [Cover.JAPAN_PASS]: japanPassCoverTerms,
        [Cover.FLIP_ACTIVE_DAILY]: flipActiveDailyCoverTerms,
        [Cover.FLIP_ACTIVE_WEEKLY]: flipActiveWeeklyCoverTerms,
        [Cover.FLIP_ACTIVE_SUB_MONTHLY]: flipActiveSubMonthlyCoverTerms,
        [Cover.FLIP_ACTIVE_BOOST]: flipActiveSubMonthlyCoverTerms,
        [Cover.FLIP_KIDS_DAILY]: flipKidsDailyCoverTerms,
        [Cover.FLIP_KIDS_WEEKLY]: flipKidsWeeklyCoverTerms,
        [Cover.FLIP_KIDS_SUB_MONTHLY]: flipKidsSubMonthlyCoverTerms,
        [Cover.FLIP_ROAMING_WEEKLY]: flipRoamingCoverTerms,
        [Cover.FLIP_ROAMING_KIDS_WEEKLY]: flipRoamingCoverTerms,
    },
});

export default coverTerms;
