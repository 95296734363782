import { FC, FormEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import Button from '../../../components/button/Button';
import Fieldset from '../../../components/form/fieldset/Fieldset';
import RadioButton from '../../../components/form/radio-button/RadioButton';
import Layout from '../../../components/layout/Layout';
import claimTypeContent from '../../../content/ui/screens/claim-type/claimType';
import withContent from '../../../hoc/with-content/withContent';
import common from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';

import './ClaimType.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
    localRadioButtonLabel: 'ui.localRadioButtonLabel',
    overseasRadioButtonLabel: 'ui.overseasRadioButtonLabel',
};

const AUS_NEWZEALAND_CLAIM = 'local';
const OVERSEAS_CLAIM = 'overseas';

interface ClaimTypeProps {
    content: Record<keyof typeof contentMap, string>;
}

const ClaimType: FC<ClaimTypeProps> = ({ content }) => {
    const { claimDetails, setClaimDetailsByAttr } = useClaim();
    const { push } = useHistory();

    const [selectedClaimType, setSelectedClaimType] = useState<string | null>(claimDetails.claimType);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setClaimDetailsByAttr('claimType', selectedClaimType);
        push(Routes.CLAIM_CHECKLIST);
    };

    return (
        <Layout
            title={content.heading}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_CLAIM_TYPE / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            <p>{content.description}</p>
            <form onSubmit={handleSubmit}>
                <Fieldset legend={content.heading} visuallyHideLegend className="claim-type__fieldset">
                    <RadioButton
                        id={content.localRadioButtonLabel}
                        className="claim-type__radio-button"
                        name="claim-type"
                        label={content.localRadioButtonLabel}
                        onChange={() => setSelectedClaimType(AUS_NEWZEALAND_CLAIM)}
                        checked={selectedClaimType === AUS_NEWZEALAND_CLAIM}
                    />
                    <RadioButton
                        id={content.overseasRadioButtonLabel}
                        className="claim-type__radio-button"
                        name="claim-type"
                        label={content.overseasRadioButtonLabel}
                        onChange={() => setSelectedClaimType(OVERSEAS_CLAIM)}
                        checked={selectedClaimType === OVERSEAS_CLAIM}
                    />
                </Fieldset>
                <Button width="full" label={common.continue} type="submit" disabled={!!selectedClaimType === false} />
            </form>
        </Layout>
    );
};

export default withContent(ClaimType, contentMap, claimTypeContent);
