import LocalizedStrings from 'react-localization';

const notCoveredTabV1 = new LocalizedStrings({
    en: {
        promise: "That's it, we swear.",
        example: 'For example',
    },
});

export default notCoveredTabV1;
