import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import Routes from '../../../utils/Routes';
import { SuccessPage } from '../../../components/success-page/SuccessPage';
import boost from '../../../strings/boostFlow';
import commonStrings from '../../../strings/common';
import parseString from '../../../utils/parseString';
import { useOpenCovers } from '../../../business-logic/context-provider/OpenCoversContext';

const ScheduleBoostSuccess: React.FC = () => {
    const history = useHistory();
    const { clearOpenCoversSelections } = useOpenCovers();

    useEffect(() => {
        clearOpenCoversSelections();
    }, []);

    const handleBtnClick = () => {
        history.push(Routes.HOME);
    };

    return (
        <SuccessPage
            header={boost.scheduleBoostSuccess.title}
            description={parseString(boost.scheduleBoostSuccess.description)}
            buttonLabel={commonStrings.continue}
            buttonOnClick={handleBtnClick}
        />
    );
};

export default ScheduleBoostSuccess;
