import { ChangeEvent, ReactElement } from 'react';
import { Witness } from '../../../../business-logic/models/ClaimDetails';
import TextField from '../../../../components/text-field/TextField';
import claimsFlowStrings from '../../../../strings/claimsFlow';
import InputType from '../../../../utils/constants/InputType';

export interface WitnessFormFieldError {
    email: string;
    phone: string;
}

export interface WitnessFormFieldsProps {
    witnessInfo: Witness;
    index: number;
    errors: WitnessFormFieldError;
    onChange: (key: string, value: string) => void;
    onNameBlur: () => void;
    onEmailBlur: () => void;
    onPhoneBlur: () => void;
}

const WitnessFormFields = ({
    witnessInfo,
    index,
    errors,
    onChange,
    onNameBlur,
    onEmailBlur,
    onPhoneBlur,
}: WitnessFormFieldsProps): ReactElement => {
    const { witnessInfo: contentStrings } = claimsFlowStrings;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.name, e.target.value);
    };

    return (
        <>
            <TextField
                id={`witness-${index}-name`}
                className="witness-info__input"
                name="name"
                label={contentStrings.labels.name}
                value={witnessInfo.name}
                onBlur={onNameBlur}
                onChange={handleChange}
                maxLength={60}
            />
            <TextField
                id={`witness-${index}-email`}
                className="witness-info__input"
                name="email"
                type={InputType.EMAIL}
                label={contentStrings.labels.email}
                value={witnessInfo.email}
                onChange={handleChange}
                onBlur={onEmailBlur}
                isError={!!errors.email}
                error={errors.email}
                maxLength={80}
            />
            <TextField
                id={`witness-${index}-phone`}
                className="witness-info__input"
                name="phone"
                type={InputType.NUMBER}
                label={contentStrings.labels.phone}
                value={witnessInfo.phone}
                onChange={handleChange}
                onBlur={onPhoneBlur}
                isError={!!errors.phone}
                error={errors.phone}
                inputMode="numeric"
                maxLength={14}
            />
        </>
    );
};

export default WitnessFormFields;
