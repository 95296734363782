import { datadogRum } from '@datadog/browser-rum';
import CreateDependantProfile from '../../business-logic/models/CreateDependantProfile';
import DependantsResponse from '../../business-logic/models/DependantsResponse';
import UserDetails from '../../business-logic/models/UserDetails';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios409Error from '../utils/isAxios409Error';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';
import transformDependantsResponse from './transformDependantsResponse';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const createDependants = async ({
    accessToken,
    dependants,
    axios,
}: {
    accessToken: string;
    dependants: CreateDependantProfile[];
} & ServiceArgs): Promise<{ dependants: UserDetails[] }> => {
    const { data } = await withRetriesAsync(
        () =>
            axios.post<DependantsResponse>(
                `${baseApiPath}/api/v1/person/relation/dependant`,
                { createDependant: dependants },
                toHeaders(accessToken),
            ),
        undefined,
        (error) => {
            // If 409, log error and retry fetch
            if (isAxios409Error(error)) {
                datadogRum.addError(error, {
                    serviceName: 'createDependants',
                    description: 'conflict in dependants creation call',
                });
                return true;
            }
            return false;
        },
    );

    return transformDependantsResponse(data);
};

export default asService(createDependants);
