import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { FlipRoamingKidsPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const flipRoamingKidsProductV1: ProductInfo = {
    id: Cover.FLIP_ROAMING_KIDS_WEEKLY,
    icon: DayPassIcon,
    name: 'Flip Roaming',
    group: 'Flip Roaming',
    pluralisedName: 'Flip Roaming',
    price: '$80',
    priceTimePeriod: 'week',
    description: 'One time payment',
    primaryFeatures: ['Pay by the week', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Up to A$70,000 in cash payouts.'],
    paymentModel: 'By the week',
    coverUnitDuration: 'per week',
    paymentModelDescription: 'One time payment',
    shortDescription: 'Add as many weeks as you want',
    benefitDescription: 'Claim as many times up to <strong>A$70k</strong>',
};

const flipRoamingKidsProductInfo: Record<FlipRoamingKidsPdsVersion, ProductInfo> = {
    [FlipRoamingKidsPdsVersion.V1]: flipRoamingKidsProductV1,
};

export default flipRoamingKidsProductInfo;
