import { useMutation } from '@tanstack/react-query';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import {
    CartCheckout,
    CartError,
    CartErrorDisplay,
    CartInstanceResponse,
    CartRequestItem,
    CartResponseItem,
} from '../../business-logic/models/Cart';
import CoverSelection from '../../business-logic/models/CoverSelection';
import CartService from '../../services/cart-service/CartService';
import { cartErrorCodesToActions } from './constants/CartErrorMessages';
import withCartErrorHandling from './utils/withCartErrorHandling';

const useCart = () => {
    const { accessToken, userTimeZone } = useUser();

    /* Guest flow */
    const postGuestCart = (
        selectedCovers: CoverSelection[],
    ): Promise<CartInstanceResponse | CartErrorDisplay | CartError | null> => {
        return withCartErrorHandling(CartService.createGuestCart({ accessToken, selectedCovers, userTimeZone }));
    };

    const postBaymaxGuestCart = (
        selectedCovers: CartRequestItem[],
    ): Promise<CartInstanceResponse | CartErrorDisplay | CartError | null> => {
        return withCartErrorHandling(CartService.createBaymaxGuestCart({ accessToken, cartItems: selectedCovers }));
    };

    const addGuestCovers = async (newCovers: CoverSelection[]) => {
        try {
            return await CartService.addItemsToGuestCart({ accessToken, newCovers, userTimeZone });
        } catch (error) {
            throw new Error('Guest Cart: Error with adding covers to cart: ' + error);
        }
    };

    // TODO: Assumes that a UI event is attached to each item iterated from the cart object's coverItems array
    const deleteGuestCovers = async (coversToDelete: CartResponseItem[]) => {
        try {
            return await CartService.deleteItemsFromGuestCart({ accessToken, coversToDelete });
        } catch (error) {
            throw new Error('Guest Cart: Error with deleting covers from cart: ' + error);
        }
    };

    const closingGuestCart = async (): Promise<CartCheckout | CartError | null> => {
        const response = await withCartErrorHandling(CartService.closeGuestCart({ accessToken }));
        return {
            checkoutDetails: {
                checkoutDetails: {
                    ...(response as CartInstanceResponse),
                },
                covers: (response as CartInstanceResponse).coverItems,
            },
            paymentMethods: [],
        };
    };

    /* Authenticated flow */
    const postCart = (
        selectedCovers: CoverSelection[],
    ): Promise<CartInstanceResponse | CartError | CartErrorDisplay | null> => {
        return withCartErrorHandling(CartService.createCart({ accessToken, selectedCovers, userTimeZone }));
    };

    const postBaymaxCart = (
        selectedCovers: CartRequestItem[],
    ): Promise<CartInstanceResponse | CartErrorDisplay | CartError | null> => {
        return withCartErrorHandling(CartService.createBaymaxCart({ accessToken, cartItems: selectedCovers }));
    };

    const addCovers = async (newCovers: CoverSelection[]) => {
        try {
            return await CartService.addItemsToCart({ accessToken, newCovers, userTimeZone });
        } catch (error) {
            throw new Error('Authenticated Cart: Error with adding covers to cart: ' + error);
        }
    };

    // TODO: Assumes that a UI event is attached to each item iterated from the cart object's coverItems array
    const deleteCovers = async (coversToDelete: CartResponseItem[]) => {
        try {
            return await CartService.deleteItemsFromCart({ accessToken, coversToDelete });
        } catch (error) {
            throw new Error('Authenticated Cart: Error with deleting covers from cart: ' + error);
        }
    };

    const closingCart = async (): Promise<CartCheckout | CartError | null> => {
        const response = await withCartErrorHandling(CartService.closeCart({ accessToken }));
        return {
            checkoutDetails: {
                checkoutDetails: {
                    ...(response as CartInstanceResponse),
                },
                covers: (response as CartInstanceResponse).coverItems,
            },
            paymentMethods: [],
        };
    };

    /* Guest flow */
    const createGuestCart = useMutation(postGuestCart, { retry: 3 });
    const createBaymaxGuestCart = useMutation(postBaymaxGuestCart, { retry: 3 });
    const createBaymaxCart = useMutation(postBaymaxCart, {
        retry: (failureCount, error) => {
            if (error && !!(error as Error).cause) {
                const cartError = cartErrorCodesToActions.find(
                    (item: CartErrorDisplay) =>
                        item.errorCode === ((error as Error).cause as CartErrorDisplay).errorCode,
                );
                if (cartError) return false;
            }
            return failureCount < 3;
        },
    });
    const addItemsToGuestCart = useMutation(addGuestCovers);
    const deleteItemsFromGuestCart = useMutation(deleteGuestCovers);
    const closeGuestCart = useMutation(closingGuestCart, { retry: 3 });

    // Temporarily disengaged until needed
    // const getGuestCart = useQuery({
    //     queryKey: ['guestCart'],
    //     queryFn: () => CartService.getGuestCart({ accessToken }),
    //     enabled: !!accessToken && newCartApi,
    // });

    /* Authenticated flow */
    const createCart = useMutation(postCart, { retry: 3 });
    const addItemsToCart = useMutation(addCovers);
    const deleteItemsFromCart = useMutation(deleteCovers);
    const closeCart = useMutation(closingCart, { retry: 3 });

    // Temporarily disengaged until needed
    // const getCart = useQuery({
    //     queryKey: ['cart'],
    //     queryFn: () => CartService.getCart({ accessToken }),
    //     enabled: !!accessToken && newCartApi && authState?.isAuthenticated,
    // });

    return {
        createGuestCart,
        createBaymaxGuestCart,
        createBaymaxCart,
        // getGuestCart,
        addItemsToGuestCart,
        deleteItemsFromGuestCart,
        closeGuestCart,
        createCart,
        // getCart,
        addItemsToCart,
        deleteItemsFromCart,
        closeCart,
    };
};

export default useCart;
