import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import airplane from '../../assets/images/airplane-blue.svg';
import { useUser } from '../../business-logic/context-provider/user-context';
import { ReviewCoverSelection } from '../../business-logic/models/ReviewCoverSelection';
import coverSummaryCardContent from '../../content/ui/components/cover-summary-card/coverSummaryCard';
import withContent from '../../hoc/with-content/withContent';
import Cover from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import formatPrice from '../../utils/formatPrice';
import getTimezone from '../../utils/getTimezone';
import isRoamingCover from '../../utils/isRoamingCover';
import { Card } from '../card/Card';

import './CoverSummaryCard.scss';

const contentMap = {
    unitPrice: 'ui.unitPrice',
    singleLineItemLabel: 'ui.singleLineItemLabel',
    singleLineItemDate: 'ui.singleLineItemDate',
    subscriptionLineItemDescription: 'ui.subscriptionLineItemDescription',
    subtotal: 'ui.subtotal',
};

interface CoverSummaryCardBaymaxProps {
    subtotal: number;
    paymentModel: string;
    coverName: string;
    coverSelections: ReviewCoverSelection[];
    unitPrice: number;
    priceTimePeriod: string | null;
    paymentModelDescription: string;
    content: Record<keyof typeof contentMap, string>;
    className?: string;
}

const CoverSummaryCardBaymax: FC<CoverSummaryCardBaymaxProps> = ({
    coverSelections,
    paymentModel,
    subtotal,
    coverName,
    paymentModelDescription,
    unitPrice,
    priceTimePeriod,
    content,
    className,
}) => {
    const {
        dependants,
        userDetails: { personId: userPersonId, firstName, lastName },
        userTimeZone,
    } = useUser();

    const selfCoverHolderName = `${firstName} ${lastName}`;

    const renderCoverDate = (day: string, index: number, timezone: string | null) => (
        <div className="cover-summary-card__week" key={day}>
            <div className="cover-summary-card__week-label">
                <strong className="cover-summary-card__week-label__count">
                    {coverSummaryCardContent.formatString(
                        priceTimePeriod ? content.singleLineItemLabel : content.subscriptionLineItemDescription,
                        {
                            priceTimePeriod: capitalize(priceTimePeriod || ''),
                            index: index + 1,
                        },
                    )}
                </strong>
                <div className="cover-summary-card__week-time">
                    {coverSummaryCardContent.formatString(content.singleLineItemDate, {
                        date: `${formatDateToString(parseISO(day), DateFormat.VERBOSE)}  (${getTimezone(
                            timezone || userTimeZone,
                            format(parseISO(day), DateFormat.DEFAULT),
                        )})`,
                    })}
                </div>
            </div>

            <div className="cover-summary-card__week-price">{formatPrice(unitPrice)}</div>
        </div>
    );

    const renderDestination = (selection: ReviewCoverSelection[]): JSX.Element | string => {
        if (!selection.length) return '';
        const startingDestination = Array.from(
            new Set(
                selection.map((item) => {
                    return {
                        coverStartDate: item.coverStartDate,
                        selectedCover: item.selectedCover,
                        region: item.destination?.startingRegion.region,
                        destinations: item.destination?.destinations,
                        timezone: item.timezone,
                    };
                }),
            ),
        )[0];
        if (isEmpty(startingDestination)) return '';
        return (
            <div className="cover-summary-card__destination">
                <img src={airplane} alt="" className="cover-summary-card__destination__icon" />
                <>{startingDestination.destinations!.sort((a: string, b: string) => a.localeCompare(b)).join(', ')}</>
            </div>
        );
    };

    const renderCoverDates = () => {
        const selfCoverSelections = coverSelections.filter((c) => c.personId === null || c.personId === userPersonId);
        const groupedDependantCoverSelections = coverSelections
            .filter((c) => c.personId !== null && c.personId !== userPersonId)
            .reduce<Record<string, ReviewCoverSelection[]>>((acc, cur) => {
                if (acc[cur.personId!]) {
                    acc[cur.personId!].push(cur);
                } else {
                    acc[cur.personId!] = [cur];
                }

                return acc;
            }, {});

        return (
            <>
                {selfCoverSelections.length > 0 && (
                    <div className="cover-summary-card__cover-dates">
                        <p className="cover-summary-card__name">{selfCoverHolderName}</p>
                        {selfCoverSelections.map((c, index) => renderCoverDate(c.coverStartDate, index, c.timezone))}
                        {selfCoverSelections.every((s) =>
                            isRoamingCover(Cover[s.selectedCover as keyof typeof Cover]),
                        ) && renderDestination(selfCoverSelections)}
                    </div>
                )}
                {Object.entries(groupedDependantCoverSelections).map(([personId, selections]) => {
                    const dependant = dependants.find((d) => d.personId === personId);

                    return (
                        <div className="cover-summary-card__cover-dates" key={personId}>
                            <p className="cover-summary-card__name">
                                {dependant?.firstName} {dependant?.lastName}
                            </p>
                            {selections.map((s, i) => renderCoverDate(s.coverStartDate, i, s.timezone))}
                            {selections.every((s) => isRoamingCover(Cover[s.selectedCover as keyof typeof Cover])) &&
                                renderDestination(selections)}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <Card className={classNames('cover-summary-card', className)}>
            <div className="cover-summary-card__header">
                <div className="cover-summary-card__title">{coverName}</div>
            </div>
            <div className="cover-summary-card__details">
                <div className="cover-summary-card__label">{paymentModel}</div>
                {renderCoverDates()}
            </div>
            <div className="cover-summary-card__subtotal-row">
                <span className="cover-summary-card__payment-model-description">{paymentModelDescription}</span>
                <span className="cover-summary-card__total-price">
                    {content.subtotal}
                    <span>{formatPrice(subtotal)}</span>
                </span>
            </div>
        </Card>
    );
};

export default withContent(CoverSummaryCardBaymax, contentMap, coverSummaryCardContent);
