import { useOktaAuth } from '@okta/okta-react';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import PdsTmdDisclaimer from '../../components/pds-tmd-disclaimer/PdsTmdDisclaimer';
import SlideInCoverTerms from '../../components/slide-in-cover-terms/SlideInCoverTerms';
import roamingSelectedContent from '../../content/ui/screens/roaming-selected/roamingSelected';
import withContent from '../../hoc/with-content/withContent';
import useOnboarding from '../../hooks/useOnboarding';
import Routes from '../../utils/Routes';
import Cover, { MainCover } from '../../utils/constants/Cover';
import getPdsVersion from '../../utils/getPdsVersion';
import getProductInfo from '../../utils/getProductInfo';
import parseString from '../../utils/parseString';
import RoamingPriceCoverTile from '../roaming-dates/components/roaming-price-cover-tile/RoamingPriceCoverTile';

import './RoamingSelected.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
    infolink: 'ui.infolink',
    roamingCta: 'ui.roamingcta',
};

interface RoamingSelectedProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingSelected: FC<RoamingSelectedProps> = ({ content }) => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const history = useHistory();
    const [isRoamingBenefitsOpen, setIsRoamingBenefitsOpen] = useState<boolean>(false);
    const { selectedCover } = useRoaming();
    const productInfo = getProductInfo(selectedCover as Cover, getPdsVersion(selectedCover as Cover));
    const { authState } = useOktaAuth();

    useEffect(() => {
        const currentPath = location.pathname;
        if (authState?.isAuthenticated && currentPath === Routes.ROAMING_SELECTED_GUEST) {
            history.push(Routes.HOME);
        }
    }, [location, authState, history]);

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            <div>
                <p className="roaming-selected__description">{parseString(content.description)}</p>
                <SlideInCoverTerms
                    selectedCover={selectedCover as MainCover}
                    pdsVersion={getPdsVersion(selectedCover as Cover)}
                    isOpen={isRoamingBenefitsOpen}
                    onOpen={() => setIsRoamingBenefitsOpen(true)}
                    onClose={() => setIsRoamingBenefitsOpen(false)}
                />
                <div className="roaming-selected__price">
                    <RoamingPriceCoverTile price={productInfo.price} title={productInfo.coverUnitDuration} />
                </div>
                <Button
                    width="full"
                    variant="drawer-link"
                    label={content.infolink}
                    className="roaming-selected__info-button"
                    onClick={() => setIsRoamingBenefitsOpen(true)}
                />
                <Button
                    width="full"
                    variant="primary"
                    label={content.roamingCta}
                    className="roaming-selected__cta"
                    onClick={() =>
                        history.push({
                            pathname: authState?.isAuthenticated
                                ? Routes.ROAMING_DESTINATIONS
                                : Routes.ROAMING_DESTINATIONS_GUEST,
                        })
                    }
                />
                <PdsTmdDisclaimer className="roaming-selected__disclaimer" />
            </div>
        </Layout>
    );
};

export default withContent(RoamingSelected, contentMap, roamingSelectedContent);
