import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { Modifier } from 'react-day-picker';
import { assign } from 'xstate';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import Cover from '../../../../utils/constants/Cover';
import Limits from '../../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../../utils/getLastDayToSchedule';
import { ScheduleCoverBaymaxMachineContextTypes } from '../context/scheduleCoverBaymaxMachineContext';
import { Typegen0 } from '../scheduleCoverBaymaxMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    ScheduleCoverBaymaxMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            selectedProductGrouping: string;
            coversAvailable: ProductOption[];
        };
    }
>({
    selectedProductGrouping: (ctx, event) => event.data.selectedProductGrouping,
    coversAvailable: (ctx, event) => event.data.coversAvailable,
});

export const setSelectedCover = assign<
    ScheduleCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['setSelectedCover']; data: ProductOption }
>({
    selectedCover: (ctx, event) => event.data,
});

export const setDatePickerMode = assign<
    ScheduleCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['setDatePickerMode']; data: ProductOption }
>({
    datePickerMode: (ctx, event) => {
        const coverChosen = Cover[event.data.representedByCoverCode as keyof typeof Cover];
        if (coverChosen === Cover.FLIP_ACTIVE_SUB_MONTHLY || coverChosen === Cover.FLIP_KIDS_SUB_MONTHLY) {
            return 'single';
        }
        if (coverChosen === Cover.FLIP_ACTIVE_DAILY || coverChosen === Cover.FLIP_KIDS_DAILY) {
            return 'multiple';
        }
        if (coverChosen === Cover.FLIP_ACTIVE_WEEKLY || coverChosen === Cover.FLIP_KIDS_WEEKLY) {
            return 'week';
        }
        return 'single';
    },
});

export const setDaysToSchedule = assign<
    ScheduleCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['setDaysToSchedule']; data: Date[] }
>({
    daysToSchedule: (ctx, event) => event.data,
});

export const resetDaysToSchedule = assign<
    ScheduleCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['resetDaysToSchedule']; data: ProductOption }
>({
    daysToSchedule: () => [],
});

export const evaluateDisabledDays = assign<
    ScheduleCoverBaymaxMachineContextTypes,
    { type: EventsCausingActions['evaluateDisabledDays']; data: ProductOption }
>({
    disabledDays: (ctx) => {
        const zonedToday = utcToZonedTime(new Date(), ctx.userTimeZone);
        const lastDayToGetCover = getLastDayToSchedule(
            zonedToday,
            ctx.scheduleLimitInHours ?? Limits.GUEST_SCHEDULE_LIMIT_IN_HOURS,
        );

        const dates: Modifier[] = [
            {
                before: zonedToday,
                after: lastDayToGetCover,
            },
        ];

        return dates;
    },
});
