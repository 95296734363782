import { FC } from 'react';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import RadioButton from '../../../../components/form/radio-button/RadioButton';
import claimsFlow from '../../../../strings/claimsFlow';
import common from '../../../../strings/common';
import formatDateToString from '../../../../utils/formatDateToString';

interface UserAgreementProps {
    radioGroupName: string;
    hasAgreed: boolean;
    onAgree: () => void;
    onDisagree: () => void;
}

const UserAgreement: FC<UserAgreementProps> = ({ radioGroupName, hasAgreed, onAgree, onDisagree }) => {
    const { medicalContactInformation } = claimsFlow;
    const { userDetails } = useUser();

    return (
        <>
            <p>
                {claimsFlow.formatString(medicalContactInformation.todaysDate, {
                    date: <strong>{formatDateToString(new Date())}</strong>,
                })}
            </p>
            <p>
                {claimsFlow.formatString(medicalContactInformation.name, {
                    name: (
                        <strong>
                            {userDetails.firstName} {userDetails.lastName}
                        </strong>
                    ),
                })}
            </p>
            <p>
                <strong>{medicalContactInformation.clickAgreeStatement}</strong>
            </p>
            <div className="medical-contact-information__btn-group">
                <RadioButton
                    id={`${radioGroupName}-disagree`}
                    label={common.disagree}
                    name={radioGroupName}
                    onChange={onDisagree}
                    checked={false}
                    className="medical-contact-information__radio-button"
                />
                <RadioButton
                    name={radioGroupName}
                    label={common.agree}
                    id={`${radioGroupName}-agree`}
                    checked={hasAgreed}
                    onChange={onAgree}
                    className="medical-contact-information__radio-button"
                />
            </div>
        </>
    );
};

export default UserAgreement;
