import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Analytics from '../../analytics/Analytics';
import { CONTEXT_TYPE } from '../../analytics/AnalyticsConstants';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Button } from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import ImageButtonInput, { ImageButtonInputProps } from '../../components/form/image-button-input/ImageButtonInput';
import { Layout } from '../../components/layout/Layout';
import PartnerEventBanner from '../../components/layout/banners/partner-event-banner/PartnerEventBanner';
import useOnboarding from '../../hooks/useOnboarding';
import CustomerService from '../../services/customer-service/CustomerService';
import commonStrings from '../../strings/common';
import onBoardingStrings from '../../strings/onboardingFlow';
import Routes from '../../utils/Routes';

import './Gender.scss';

// Clone of alpha screen, with difference in progress bar and back button
const Gender: React.FC = () => {
    const { userDetails, setUserDetailsByAttr, accessToken } = useUser();
    const { partnerEvents } = useOpenCovers();
    const history = useHistory();

    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();

    const { gender: contentStrings } = onBoardingStrings;
    const {
        gender: { options },
    } = onBoardingStrings;

    const [selected, setSelected] = useState<string | undefined>(userDetails.gender);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setError] = useState(false);

    // only call when we have userDetails loaded
    useEffect(() => {
        if (selected === undefined && userDetails.gender !== undefined) {
            setSelected(userDetails.gender);
        }
    }, [userDetails]);

    useEffect(() => {
        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepViewed(
                onboardingStepNumber,
                undefined,
                undefined,
                undefined,
                partnerEvents.length > 0
                    ? { type: CONTEXT_TYPE.PARTNER_EVENT, partnerEventId: partnerEvents[0].partnerEvent.partnerEventId }
                    : undefined,
            );
        }
    }, [isOnboardingFlow, onboardingStepNumber, partnerEvents]);

    const handleContinueClick = async () => {
        setUserDetailsByAttr('gender', selected);
        Analytics.identify({ gender: selected });

        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepCompleted(onboardingStepNumber);
        }

        setIsLoading(true);

        try {
            await CustomerService.updateCustomerProfile({
                accessToken: accessToken!,
                profile: { gender: selected },
            });
            history.push({
                pathname: Routes.NAME,
                state: { isOnboarding: isOnboardingFlow },
            });
        } catch (error) {
            console.log(error);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClick = (id: string) => {
        setSelected(id);
    };

    const genderOptions: ImageButtonInputProps[] = useMemo(
        () =>
            Object.values(options).map((option) => ({
                ...option,
                name: 'gender',
                type: 'radio',
                variant: 'horizontal',
                checked: option.id === selected,
                onChange: () => handleClick(option.id),
            })),
        [options, selected],
    );

    return (
        <Layout
            title={contentStrings.title}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
            showLoading={isLoading}
            banner={<PartnerEventBanner />}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <Fieldset legend={contentStrings.title} visuallyHideLegend>
                    {genderOptions.map((option) => (
                        <ImageButtonInput {...option} name="gender" className="gender__option" />
                    ))}
                </Fieldset>
                <Button
                    label={commonStrings.continue}
                    disabled={selected == null}
                    width="full"
                    className="gender__btn-continue"
                    onClick={handleContinueClick}
                    type="submit"
                />
                {hasError && <p className="error">{commonStrings.errorSomethingWentWrong}</p>}
            </form>
        </Layout>
    );
};

export default Gender;
