import classNames from 'classnames';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import compareAsc from 'date-fns/compareAsc';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import fromUnixTime from 'date-fns/fromUnixTime';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import sub from 'date-fns/sub';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Analytics from '../../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../../analytics/AnalyticsConstants';
import ProductList from '../../../analytics/ProductList';
import { useOpenCovers } from '../../../business-logic/context-provider/OpenCoversContext';
import { useProduct } from '../../../business-logic/context-provider/ProductContext';
import { useUser } from '../../../business-logic/context-provider/user-context';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import { Button } from '../../../components/button/Button';
import DatePickerButton from '../../../components/date-picker-button/DatePickerButton';
import { Layout } from '../../../components/layout/Layout';
import { List } from '../../../components/list/List';
import ModalWithCTA from '../../../components/modal-with-cta/ModalWithCTA';
import boost from '../../../strings/boostFlow';
import common from '../../../strings/common';
import Routes from '../../../utils/Routes';
import Cover, { MainCover } from '../../../utils/constants/Cover';
import CoverInformation from '../../../utils/constants/CoverInformation';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import DateFormat from '../../../utils/constants/DateFormat';
import Limits from '../../../utils/constants/Limits';
import { FlipActiveSubMonthlyPdsVersion } from '../../../utils/constants/PdsVersion';
import Pricing from '../../../utils/constants/Pricing';
import formatCoverDayTitle from '../../../utils/formatCoverDayTitle';
import formatDateToString from '../../../utils/formatDateToString';
import getBoostActiveDates from '../../../utils/getBoostActiveDates';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';

import './ScheduleBoost.scss';

const MAX_BOOSTS_ALLOWED_PER_SESSION = 10;

const ScheduleBoost: React.FC = () => {
    const {
        userTimeZone,
        userDetails: { personId },
    } = useUser();
    const { policies, setOpenCoversSelections } = useOpenCovers();
    const { products } = useProduct();

    // Due to product history, the business rule is this always evaluates to only one subscription per account holder
    // Only current subscription-v1 covers with existing boost can purchase more boost
    const subscriptionWithExistingBoost = policies.filter(
        (x) =>
            !x.mainCover.paymentFailed &&
            x.mainCover.currentCycleStatus !== 'Canceled' &&
            x.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1 &&
            x.PDSVersion < FlipActiveSubMonthlyPdsVersion.FLIP_2_0,
    )[0];

    const mainCover = Object.entries(CoverInformation).find(
        ([, info]) => info.coverCode === subscriptionWithExistingBoost.mainCover?.coverCode,
    )?.[0] as MainCover;
    const productSpec = products[mainCover].find(
        (p) => `${p.pdsVersionMajor}.${p.pdsVersionMinor}` === subscriptionWithExistingBoost?.PDSVersion,
    ) as ProductResponse;
    const {
        productSpec: { extraCoverTypes },
    } = productSpec;
    const hasExtraCoversAvailable = Object.values(extraCoverTypes).length > 0;
    const scheduleLimitInHours = hasExtraCoversAvailable
        ? Object.values(extraCoverTypes)[0].schedule.scheduleLimitInHours
        : Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS;

    const history = useHistory();

    const { scheduleBoost, unableToScheduleBoostModal, noExtraCoverAvailable } = boost;
    const [showBoostDays, setShowBoostDays] = useState(false);
    const [isUnableToScheduleBoost, setIsUnableToScheduleBoost] = useState(false);
    const [selectedBoostDays, setSelectedBoostDays] = useState<any[]>([]);
    const [tempSelectedBoostDays, setTempSelectedBoostDays] = useState<any[]>([]);
    const [datePickerError, setDatePickerError] = useState('');
    const hasBoostDays = selectedBoostDays.length > 0;

    const noDaysSelected = tempSelectedBoostDays.length === 0 && selectedBoostDays.length === 0;

    // determine cover rules for view
    const isFlipActiveBoost =
        subscriptionWithExistingBoost?.mainCover.coverCode ===
        CoverInformation[Cover.FLIP_ACTIVE_SUB_MONTHLY].coverCode;

    const boosts = subscriptionWithExistingBoost.extraCovers.map((x) => {
        return {
            id: x.insuranceCoverId,
            activeFrom: x.activeFrom,
            activeTo: x.activeTo,
            status: x.status,
            coverCode: x.coverCode,
        };
    });
    const purchasedBoostDates = getBoostActiveDates(boosts, userTimeZone);

    // date picker constraints
    const zonedToday = utcToZonedTime(new Date(), userTimeZone);
    let lastDayToBoost = getLastDayToSchedule(zonedToday, scheduleLimitInHours);

    if (subscriptionWithExistingBoost?.mainCover.activeTo) {
        const sunsetDate = fromUnixTime(subscriptionWithExistingBoost?.mainCover.activeTo);
        if (isBefore(sunsetDate, lastDayToBoost)) {
            // We can only schedule boost 1 day before it ends, so minus 1 day
            lastDayToBoost = sub(sunsetDate, { days: 1 });
        }
    }

    const onSelectDateClick = () => {
        setTempSelectedBoostDays([...selectedBoostDays]);
    };

    const handleConfirmClick = async () => {
        const days: string[] = [];
        selectedBoostDays.forEach((day: Date) => {
            const formattedDateString = formatDateToString(day);
            days.push(formattedDateString);

            Analytics.trackProductAdded({
                ...ProductList.boost,
                quantity: 1,
                variant: formatDateToString(day, DateFormat.ANALYTICS),
                scheduledFor: isSameDay(zonedToday, day) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                startingInDays: differenceInCalendarDays(day, zonedToday),
            });

            return days;
        });

        setOpenCoversSelections(
            days.map((d) => ({
                selectedCover: isFlipActiveBoost ? Cover.FLIP_ACTIVE_BOOST : Cover.BOOST,
                coverStartDate: d,
                personId,
                timezone: null,
                insurancePolicyId: subscriptionWithExistingBoost?.insurancePolicyId,
            })),
        );

        history.push({
            pathname: Routes.CHECKOUT,
        });
    };

    const resetTempSelectedBoostDays = () => setTempSelectedBoostDays([]);

    const handleSelectClick = () => {
        const selectedDaysSorted = tempSelectedBoostDays.sort((a, b) => compareAsc(a, b));

        setSelectedBoostDays(selectedDaysSorted);
        setShowBoostDays(true);
        resetTempSelectedBoostDays();
    };

    const onOutsideDatePickerClick = () => {
        resetTempSelectedBoostDays();
        setShowBoostDays(true);
        setDatePickerError('');
    };

    const handleDayClick = (day: Date, { selected, disabled, selectedDisabled }: any) => {
        // don't allow disabled or purchased boost dates to be selected
        if (disabled || selectedDisabled) return;

        setDatePickerError('');

        const selectedDays = [...tempSelectedBoostDays];

        if (showBoostDays) setShowBoostDays(false);

        /// date was already selected, so deselect it
        if (selected) {
            const selectedIndex = selectedDays.findIndex((selectedDay) => isSameDay(selectedDay, day));
            selectedDays.splice(selectedIndex, 1);
            setTempSelectedBoostDays(selectedDays);
            return;
        }

        /// new date selected, check that it's valid before setting
        // don't allow a user to select more than 10 days per session or more
        if (selectedDays.length >= MAX_BOOSTS_ALLOWED_PER_SESSION) {
            setDatePickerError(scheduleBoost.maxBoostsAtATimeReachedError);
            return;
        }

        selectedDays.push(day);
        setTempSelectedBoostDays(selectedDays);
    };

    const buildBoostDaysList = selectedBoostDays.map((day: Date) => {
        const dayFormatted = formatCoverDayTitle(day, userTimeZone);
        const dateFormatted = formatDateToString(day);

        return {
            id: dateFormatted,
            header: dayFormatted,
            customDataElement: (
                <>
                    <p className="schedule-boost__item-data">{dateFormatted}</p>
                    <p className="schedule-boost__item-data">
                        {isFlipActiveBoost ? Pricing.flipActiveBoostWithDecimal : Pricing.boostWithDecimal}
                    </p>
                </>
            ),
        };
    });

    useEffect(() => {
        if (subscriptionWithExistingBoost?.mainCover.status === 'Scheduled') {
            setIsUnableToScheduleBoost(true);
        }
    }, []);

    const canBoost = hasExtraCoversAvailable && !isUnableToScheduleBoost;

    return (
        <Layout title={canBoost ? scheduleBoost.title : ''} showBackButton={canBoost}>
            {canBoost && (
                <>
                    <DatePickerButton
                        label={scheduleBoost.addDays}
                        onButtonClick={onSelectDateClick}
                        selectedDays={showBoostDays ? selectedBoostDays : tempSelectedBoostDays}
                        fromMonth={zonedToday}
                        toMonth={lastDayToBoost}
                        disabledDays={[
                            {
                                before: zonedToday,
                                after: lastDayToBoost,
                            },
                        ]}
                        modifiers={{
                            selectedDisabled: purchasedBoostDates,
                        }}
                        onDayClick={handleDayClick}
                        onSelectClick={handleSelectClick}
                        isSelectDaysButtonDisabled={noDaysSelected}
                        innerDatePickerError={datePickerError}
                        onOutsideDatePickerClick={onOutsideDatePickerClick}
                    />

                    {hasBoostDays && (
                        <>
                            <List items={buildBoostDaysList} className="schedule-boost__list" />
                            <p className="schedule-boost__disclaimer">{scheduleBoost.disclaimer}</p>
                        </>
                    )}
                    <Button
                        disabled={!hasBoostDays}
                        className={classNames('schedule-boost__btn')}
                        label={common.confirm}
                        onClick={handleConfirmClick}
                        width="full"
                    />
                </>
            )}
            <ModalWithCTA
                title={unableToScheduleBoostModal.title}
                textContent={unableToScheduleBoostModal.description}
                isOpen={isUnableToScheduleBoost}
                primaryCTA={{ label: common.ok, onClick: () => history.push(Routes.HOME) }}
                onClose={() => history.push(Routes.HOME)}
            />
            <ModalWithCTA
                title={noExtraCoverAvailable.title}
                textContent={noExtraCoverAvailable.description}
                isOpen={!hasExtraCoversAvailable}
                primaryCTA={{ label: common.ok, onClick: () => history.push(Routes.HOME) }}
                onClose={() => history.push(Routes.HOME)}
            />
        </Layout>
    );
};

export default ScheduleBoost;
