import LocalizedStrings from 'react-localization';

const manageCoverSubscriptionCardContent = new LocalizedStrings({
    en: {
        renews: 'Next billing date',
        ending: 'Ending: ',
        subscriptionStarting: 'Starting',
        active: 'Active {startDate}',
        starting: 'Starting {startDate}',
        priceDisclaimer: 'Discounts or credits not included',
        benefitsCTA: 'View benefits and cover details',
        injuriesCoveredTab: 'Injuries covered',
        injuriesNotCoveredTab: 'Injuries not covered',
    },
});

export default manageCoverSubscriptionCardContent;
