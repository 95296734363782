import { FC } from 'react';
import { Redirect, useLocation } from 'react-router';

import Layout from '../../components/layout/Layout';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import Routes from '../../utils/Routes';

const Registration: FC = () => {
    const location = useLocation<LocationState>();

    return (
        <Layout>
            <Redirect to={{ pathname: Routes.LOGIN, search: location.search }} />
            {/* 
            This page only redirects to /signin now, there is no more registration form
            */}
            <LoadingSpinner />
        </Layout>
    );
};

export default Registration;
