import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout/Layout';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import ClaimStatuses from '../../../utils/constants/ClaimStatuses';
import ClaimsService from '../../../services/ClaimsService';
import { Card, CardTypes } from '../../../components/card/Card';
import { Button } from '../../../components/button/Button';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Routes from '../../../utils/Routes';
import claimsFlowStrings from '../../../strings/claimsFlow';
import { BackButton } from '../../../components/back-button/BackButton';
import ClaimDetailsList, { ClaimDetailsListTypes } from '../../../components/claim-details-list/ClaimDetailsList';
import getClaimType from '../../../utils/claims/getClaimType';
import unixNumToString from '../../../utils/unixNumToString';
import DateFormat from '../../../utils/constants/DateFormat';

import './ClaimDetails.scss';

const ClaimDetails: FC = (): ReactElement => {
    const { existingClaims, setClaimDetailsByAttr, setFileUploadTokens, fileUploadTokens, clearCurrentClaimDetails } =
        useClaim();
    const {
        accessToken,
        userDetails: { bankDetails },
        userTimeZone,
        dependants,
    } = useUser();

    const history = useHistory();
    const { claimId } = useParams<{ claimId: string }>();
    const [selectedClaim, setSelectedClaim] = useState<ClaimDetailsListTypes>();
    const [isAustraliaNewZealandClaim, setIsAustraliaNewZealandClaim] = useState<boolean>(false);

    const {
        claimDetails,
        claimDetails: { cards },
    } = claimsFlowStrings;

    const getClaimBoxUploadToken = async () => {
        try {
            await ClaimsService.getClaimBoxUploadToken(claimId, accessToken).then((response: any) => {
                setFileUploadTokens(response.accessToken, response.claimId, response.folderIds);
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!claimId) return;

        const claim = existingClaims.find((existingClaim) => existingClaim.id === claimId);

        if (!claim) return;

        // TODO: Revise this implementation upon Claims 2.0
        const isAustraliaNewZealand =
            (claim.claimType !== undefined && claim.claimType === 'local') ||
            (typeof claim.injuryLocation === 'string' &&
                (claim.injuryLocation.includes('Australia') || claim.injuryLocation.includes('New Zealand')));
        setIsAustraliaNewZealandClaim(isAustraliaNewZealand);

        const formattedClaim: ClaimDetailsListTypes = {
            currentClaimStarted: claim.currentClaimStarted,
            id: claim.id,
            insuredPersonId: claim.insuredPersonId,
            claimType: claim.claimType,
            didAccidentHappenInAustralia: true,
            date: claim.injuryDate ? unixNumToString(claim.injuryDate, userTimeZone, DateFormat.DEFAULT) : '',
            time: claim.injuryDate ? unixNumToString(claim.injuryDate, userTimeZone, DateFormat.TIME) : '',
            accidentDescription: claim.description,
            accidentLocation: claim.injuryLocation,
            firstTreatmentDate: claim.treatedAt
                ? unixNumToString(claim.injuryDate, userTimeZone, DateFormat.DEFAULT)
                : '',
            wasMajorSurgeryRequired: claim.surgery,
            wasEmergencyServicesRequired: claim.emergency,
            injuries: [],
            injuriesById: claim.injuryTypes,
            witnesses: claim.witnesses,
            // we assume permission was ticked since witnesses were submitted
            hasWitnessesPermission: claim.witnesses.length > 0,
            payeeBankDetails: {
                accountName: bankDetails?.accountName || '',
                accountNumber: bankDetails?.accountNumber || '',
                bsb: bankDetails?.bsb || '',
            },
            status: claim.status,
            number: claim.number,
            hasSigned: true,
            medicalReport: [],
            receipts: [],
            proofOfAccident: [],
            proofOfActivity: [],
            thirdPartyConsent: claim.thirdPartyConsent,
            thirdPartyConsentVersion: claim.thirdPartyConsentVersion,
            healthInfoAccessAuthOneConsent: claim.healthInfoAccessAuthOneConsent,
            healthInfoAccessAuthOneConsentVersion: claim.healthInfoAccessAuthOneConsentVersion,
            healthInfoAccessAuthTwoConsent: claim.healthInfoAccessAuthTwoConsent,
            healthInfoAccessAuthTwoConsentVersion: claim.healthInfoAccessAuthTwoConsentVersion,
            signatureData: undefined,
        };
        setSelectedClaim(formattedClaim);
        getClaimBoxUploadToken();
    }, [claimId, existingClaims]);

    const resumeClaim = () => {
        if (selectedClaim === undefined) return;
        // Clear any previous claim states that may be hanging around
        clearCurrentClaimDetails();
        setClaimDetailsByAttr('currentClaimStarted', true);
        // currently, when a claim is in `draft` that means there's NO claim info in BE except FOR claimId
        // so no need to set existing claim info in state, just push user to input all claim info
        setClaimDetailsByAttr('id', selectedClaim.id);
        history.push(dependants.length > 0 ? Routes.CLAIM_PERSONS_INSURED : Routes.CLAIM_TYPE);
    };

    const buildClaimDetails = () => {
        if (selectedClaim === undefined) return null;

        const injuryType = getClaimType(selectedClaim.injuriesById || []);

        return (
            <>
                <h1 className="h1--on-page-with-back-button">{injuryType}</h1>
                {selectedClaim.status === ClaimStatuses.DRAFT && (
                    <Card type={CardTypes.INFO} className="claim-details__card">
                        <p>{cards.draft.description}</p>
                        <Button
                            variant="secondary"
                            label={cards.draft.buttonLabel}
                            width="full"
                            onClick={resumeClaim}
                        />
                    </Card>
                )}
                {selectedClaim.status === ClaimStatuses.MORE_INFORMATION_REQUIRED && (
                    <Card type={CardTypes.ATTENTION} className="claim-details__card">
                        <p>{cards.moreInfoRequired.description}</p>
                    </Card>
                )}
                <ClaimDetailsList
                    claimDetails={selectedClaim}
                    fileUploadTokens={fileUploadTokens}
                    isAustraliaNewZealandClaim={isAustraliaNewZealandClaim}
                    isClaimDetailsScreen
                />
            </>
        );
    };

    const buildClaimDetailsErrorState = () => (
        <>
            <h1 className="h1--on-page-with-back-button">{claimDetails.failedToGetClaim.title}</h1>
            <p>{claimDetails.failedToGetClaim.subtitle}</p>
        </>
    );

    return (
        <Layout className="claim-details">
            <BackButton />
            {selectedClaim === undefined ? buildClaimDetailsErrorState() : buildClaimDetails()}
        </Layout>
    );
};

export default ClaimDetails;
