import classNames from 'classnames';
import React, { useState } from 'react';
import { TabButton } from '../tab-button/TabButton';
import './Tabs.scss';

interface Tab {
    id: string;
    label: string;
    content: React.ReactNode;
}

export interface TabsProps {
    tabs: Tab[];
    className?: string;
}

export const Tabs: React.FC<TabsProps> = ({ tabs, className }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <div className={classNames('tabs', className)}>
                {tabs.map((tab, index) => (
                    <TabButton
                        key={tab.id}
                        label={tab.label}
                        isActive={activeTab === index}
                        onClick={() => {
                            setActiveTab(index);
                        }}
                    />
                ))}
                <div className="tabs__divider" />
            </div>
            {tabs[activeTab].content}
        </>
    );
};
