import React, { FC } from 'react';
import Card from '../../../../../components/card/Card';
import './Items.scss';

type TProps = {
    text: string;
    icon: string;
};
const Item: FC<TProps> = ({ text, icon }) => {
    return (
        <Card className="item">
            <img src={icon} alt={text} />
            <p className="item__content">{text}</p>
        </Card>
    );
};

export default Item;
