import { Destination, RoamingDestinationsResponse } from '../../business-logic/models/RoamingDestinations';
import Cover from '../../utils/constants/Cover';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = process.env.REACT_APP_BASE_CDN_PATH;

type transformDestinationsProps = {
    data: RoamingDestinationsResponse;
    coverCode: Cover;
    PDSVersion: MainCoverPdsVersion;
};

const getCoverDestinations = ({ data, coverCode, PDSVersion }: transformDestinationsProps) => {
    const selectedCover = Object.keys(Cover)[Object.values(Cover).indexOf(coverCode)];
    return data[selectedCover as keyof RoamingDestinationsResponse][PDSVersion].destinations;
};

const getCoverConfig = async ({
    axios,
    coverCode,
    PDSVersion,
}: {
    coverCode: Cover;
    PDSVersion: MainCoverPdsVersion;
} & ServiceArgs): Promise<Destination[]> => {
    try {
        const { data } = await axios.get(`${baseApiPath}/json/coverConfig.json`);
        return getCoverDestinations({ data, coverCode, PDSVersion });
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve data from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving destination resource from Flip content delivery network`);
    }
};

export default asService(getCoverConfig, false);
