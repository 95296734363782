import { FC, useState } from 'react';
import { Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import CancelCoverDrawer from '../../../components/cancel-cover-drawer/CancelCoverDrawer';
import manageCoverItemCardsContent from '../../../content/ui/components/manage-cover-item-cards/manageCoverItemCards';
import withContent from '../../../hoc/with-content/withContent';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import ManageCoverSingleCoverCard from '../manage-cover-single-cover-card/ManageCoverSingleCoverCard';
import ManageCoverSubscriptionCard from '../manage-cover-subscription-card/ManageCoverSubscriptionCard';
import groupByCoverCodeAndPds from './utils/groupByPds';

const contentMap = {
    yourCover: 'ui.yourCover',
    firstNameCover: 'ui.firstNameCover',
};

interface ManageCoverItemCardsProps {
    content: Record<keyof typeof contentMap, string>;
    firstName?: string;
    policies: Policy[];
    userTimeZone: string;
}

const ManageCoverItemCards: FC<ManageCoverItemCardsProps> = ({ content, firstName, policies, userTimeZone }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [coverIdToBeCancelled, setCoverIdToBeCancelled] = useState<string | null>(null);

    const hasCover = policies.length > 0;

    const groupedSubscriptionCovers = groupByCoverCodeAndPds(
        policies.filter((p) => p.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1),
    );
    const groupedSingleCovers = groupByCoverCodeAndPds(
        policies.filter((p) => p.mainCover.coverTypeId === CoverTypeId.SINGLE_V1),
    );

    const numberOfCovers = policies.length > 1 ? 's' : '';

    return hasCover ? (
        <>
            <h2>
                {firstName
                    ? manageCoverItemCardsContent.formatString(content.firstNameCover, { firstName, numberOfCovers })
                    : manageCoverItemCardsContent.formatString(content.yourCover, { numberOfCovers })}
            </h2>

            {groupedSubscriptionCovers.map((coverCodeGroup) =>
                coverCodeGroup.map((pdsVersionGroup, index) => (
                    <ManageCoverSubscriptionCard
                        key={pdsVersionGroup.join('')}
                        policies={pdsVersionGroup.sort((a, b) => a.mainCover.activeFrom - b.mainCover.activeFrom)}
                        timeZone={userTimeZone}
                        onCancel={() => setCoverIdToBeCancelled(pdsVersionGroup[index].mainCover.insuranceCoverId)}
                    />
                )),
            )}

            {groupedSingleCovers.map((coverCodeGroup) =>
                coverCodeGroup.map((pdsVersionGroup, index) => (
                    <ManageCoverSingleCoverCard
                        key={pdsVersionGroup.join('')}
                        policies={pdsVersionGroup}
                        timeZone={userTimeZone}
                        onCancel={() => setCoverIdToBeCancelled(pdsVersionGroup[index].mainCover.insuranceCoverId)}
                    />
                )),
            )}

            <CancelCoverDrawer
                firstName={firstName}
                policies={policies}
                isOpen={!!coverIdToBeCancelled}
                preselectedCover={coverIdToBeCancelled}
                onClose={() => setCoverIdToBeCancelled(null)}
            />
        </>
    ) : null;
};

export default withContent(ManageCoverItemCards, contentMap, manageCoverItemCardsContent);
