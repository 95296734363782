import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import requireFlags from '../../hoc/require-flags/requireFlags';
import redirectToOnboarding from '../../hoc/with-redirect-to-onboarding/withRedirectToOnboarding';
import Routes from '../../utils/Routes';
import BeforeYouGo from '../before-you-go/BeforeYouGo';
import ScheduleBoost from '../boost/schedule-boost/ScheduleBoost';
import CertificateOfCover from '../certificate-of-cover/CertificateOfCover';
import Checkout from '../checkout/Checkout';
import SelectCover from '../select-cover/SelectCover';

import RoamingDestinations from '../roaming-destinations/RoamingDestinations';
import RoamingSelected from '../roaming-selected/RoamingSelected';
import RoamingStartingDestination from '../roaming-start-destination/RoamingStartDestination';
import RoamingStartingRegion from '../roaming-starting-region/RoamingStartingRegion';

import ScheduleBoostSuccess from '../boost/schedule-boost-success/ScheduleBoostSuccess';
import CartBaymaxSuccess from '../cart-baymax-success/CartBaymaxSuccess';
import CartBaymax from '../cart-baymax/CartBaymax';
import DateOfBirth from '../date-of-birth/DateOfBirth';
import Gender from '../gender/Gender';
import GuestCartSuccess from '../guest-cart-success/GuestCartSuccess';
import GuestCart from '../guest-cart/GuestCart';
import HomeV2 from '../home-v2/HomeV2';
import Loading from '../loading/Loading';
import Login from '../login/Login';
import ManageCoverV2 from '../manage-cover-v2/ManageCoverV2';
import Name from '../name/Name';
import NotFound from '../not-found/NotFound';
import OnboardingStart from '../onboarding/onboarding-start/OnboardingStart';
import PersonsInsured from '../persons-insured/PersonsInsured';
import PreflightCheck from '../preflight-check/PreflightCheck';
import ProfileAccount from '../profile-account/ProfileAccount';
import ProfilePreferences from '../profile-preferences/ProfilePreferences';
import Profile from '../profile/Profile';
import ExistingInjury from '../quiz/existing-injury/ExistingInjury';
import Motorcycling from '../quiz/motorcycling/Motorcycling';
import OnboardingQuiz from '../quiz/onboarding-quiz/OnboardingQuiz';
import Exclusions from '../quiz/result/exclusions/Exclusions';
import QuizSignup from '../quiz/result/quiz-signup/QuizSignup';
import WorkAccidents from '../quiz/work-accidents/WorkAccidents';
import Registration from '../registration/Registration';
import ResidencyState from '../residency-state/ResidencyState';
import ResidencyStatus from '../residency-status/ResidencyStatus';
import RoamingDatesAuthenticated from '../roaming-dates/RoamingDatesAuthenticated';
import RoamingDatesGuest from '../roaming-dates/RoamingDatesGuest';
import ScheduleAlwaysOnSuccess from '../schedule-always-on-success/ScheduleAlwaysOnSuccess';
import ScheduleCoverAuthenticated from '../schedule-cover/ScheduleCoverAuthenticated';
import ScheduleCoverBaymax from '../schedule-cover/ScheduleCoverBaymax';
import ScheduleCoverGuest from '../schedule-cover/ScheduleCoverGuest';
import SelectCoverBaymax from '../select-cover/SelectCoverBaymax';
import YourActivities from '../your-activities/YourActivities';
import ClaimsRouter from './ClaimsRouter';
import RoamingBaymaxRouter from './RoamingBaymaxRouter';

const Router: FC = () => (
    <Switch>
        <Route exact path={Routes.LANDING} component={OnboardingStart} />
        <Route exact path={Routes.ONBOARDING_QUIZ} component={OnboardingQuiz} />
        <Route exact path={Routes.ONBOARDING_QUIZ_INJURY} component={ExistingInjury} />
        <Route exact path={Routes.ONBOARDING_QUIZ_WORK} component={WorkAccidents} />
        <Route exact path={Routes.ONBOARDING_QUIZ_MOTORCYCLING} component={Motorcycling} />
        <Route exact path={Routes.ONBOARDING_QUIZ_RESULT_EXCLUSIONS} component={Exclusions} />
        <Route exact path={Routes.ONBOARDING_QUIZ_RESULT_SIGNUP} component={QuizSignup} />
        <Route path={Routes.BEFORE_YOU_GO} component={BeforeYouGo} />
        <Route exact path={Routes.CART} component={requireFlags(GuestCart)} />
        <Route exact path={Routes.CART_SUCCESS} component={GuestCartSuccess} />
        <Route path={Routes.REGISTER} exact component={Registration} />
        {/* Guest purchase flow */}
        <Route exact path={Routes.PREFLIGHT_CHECK} component={PreflightCheck} />
        <Route exact path={Routes.PERSONS_INSURED_GUEST} component={PersonsInsured} />
        <Route exact path={Routes.SELECT_COVER_GUEST} component={SelectCover} />
        <Route exact path={Routes.SELECT_COVER_BAYMAX} component={SelectCoverBaymax} />
        <Route exact path={Routes.SCHEDULE_COVER_GUEST} component={ScheduleCoverGuest} />
        <Route exact path={Routes.SCHEDULE_ACTIVE_COVER_BAYMAX} component={ScheduleCoverBaymax} />
        <Route exact path={Routes.SCHEDULE_KIDS_COVER_BAYMAX} component={ScheduleCoverBaymax} />
        <Route path={Routes.ROAMING_SELECTED_GUEST} component={RoamingSelected} />
        <Route path={Routes.ROAMING_DESTINATIONS_GUEST} component={RoamingDestinations} />
        <Route path={Routes.ROAMING_START_GUEST} component={RoamingStartingDestination} />
        <Route path={Routes.ROAMING_REGION_GUEST} component={RoamingStartingRegion} />
        <Route path={Routes.ROAMING_DATES_GUEST} component={RoamingDatesGuest} />
        <Route path={Routes.ROAMING_DESTINATIONS_BAYMAX} component={RoamingBaymaxRouter} />
        <SecureRoute exact path={Routes.CART_BAYMAX} component={CartBaymax} />
        <SecureRoute exact path={Routes.CART_BAYMAX_SUCCESS} component={CartBaymaxSuccess} />

        <Route path={Routes.LOGIN_CALLBACK} component={LoginCallback} />
        {/* Catch all for Okta widget scenarios below - must go after Register and callback */}
        <Route path={Routes.LOGIN} component={Login} />
        <Route path={Routes.LOADING} component={Loading} />

        <SecureRoute path={Routes.HOME} component={requireFlags(redirectToOnboarding(HomeV2))} />
        <SecureRoute path={Routes.RESIDENCY_STATE} component={ResidencyState} />
        <SecureRoute path={Routes.RESIDENCY_STATUS} component={ResidencyStatus} />
        <SecureRoute path={Routes.DATE_OF_BIRTH} component={DateOfBirth} />
        <SecureRoute path={Routes.PERSONS_INSURED} component={PersonsInsured} />
        <SecureRoute path={Routes.SELECT_COVER} component={SelectCover} />

        <SecureRoute path={Routes.ROAMING_SELECTED} component={RoamingSelected} />
        <SecureRoute path={Routes.ROAMING_DESTINATIONS} component={RoamingDestinations} />
        <SecureRoute path={Routes.ROAMING_START} component={RoamingStartingDestination} />
        <SecureRoute path={Routes.ROAMING_REGION} component={RoamingStartingRegion} />
        <SecureRoute path={Routes.ROAMING_DATES} component={RoamingDatesAuthenticated} />

        <SecureRoute exact path={Routes.SCHEDULE_COVER} component={ScheduleCoverAuthenticated} />
        <SecureRoute path={Routes.GENDER} component={Gender} />
        <SecureRoute path={Routes.NAME} component={Name} />
        <SecureRoute path={Routes.YOUR_ACTIVITIES} component={YourActivities} />
        <SecureRoute exact path={Routes.SCHEDULE_BOOST} component={ScheduleBoost} />
        <SecureRoute path={Routes.SCHEDULE_BOOST_SUCCESS} component={ScheduleBoostSuccess} />
        <SecureRoute path={Routes.SCHEDULE_ALWAYS_ON_SUCCESS} component={ScheduleAlwaysOnSuccess} />
        <SecureRoute exact path={Routes.PROFILE} component={Profile} />
        <SecureRoute path={Routes.PROFILE_ACCOUNT} component={ProfileAccount} />
        <SecureRoute path={Routes.PROFILE_MANAGE_COVER} component={ManageCoverV2} />
        <SecureRoute path={Routes.PROFILE_PREFERENCES} component={ProfilePreferences} />
        <SecureRoute path={Routes.PROFILE_CERTIFICATE_OF_COVER} component={CertificateOfCover} />
        <SecureRoute exact path={Routes.CHECKOUT} component={Checkout} />
        <SecureRoute path={Routes.CLAIMS} component={ClaimsRouter} />
        <Route path={Routes.NOT_FOUND} component={NotFound} />
        <Redirect to={Routes.NOT_FOUND} />
    </Switch>
);

export default Router;
