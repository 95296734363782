import React, { useEffect } from 'react';
import { Layout } from '../../../../components/layout/Layout';
import quizFlow from '../../../../strings/quizFlow';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import Button from '../../../../components/button/Button';
import Alert, { AlertTypes } from '../../../../components/alert/Alert';
import Routes from '../../../../utils/Routes';
import StorageKeys from '../../../../utils/constants/StorageKeys';
import Analytics from '../../../../analytics/Analytics';
import useCustomHistory from '../../hooks/useCustomHistory';
import './QuizSignup.scss';

const QuizSignup: React.FC = () => {
    const { title, description, alert, continueBtnText } = quizFlow.signup;
    const { navigate } = useCustomHistory();

    const {
        onboardingQuizAnswers: { workAccidents, existingInjury, motorcycling },
    } = useUser();

    useEffect(() => {
        if (
            typeof workAccidents === 'undefined' ||
            typeof existingInjury === 'undefined' ||
            typeof motorcycling === 'undefined'
        ) {
            navigate(Routes.ONBOARDING_QUIZ);
        } else {
            sessionStorage.setItem(StorageKeys.IS_QUIZ_COMPLETED, 'true');
        }
    }, [workAccidents, navigate, existingInjury, motorcycling]);

    useEffect(() => {
        Analytics.trackOnboardingQuizCompleted();
    }, []);

    const onContinueClickHandler = () => {
        navigate('/signin/register', {
            completedQuiz: 'true',
        });
    };

    return (
        <Layout isContentCentered bgPattern={false} showPdsVersion={false} title={title}>
            <Alert className="quiz-signup__alert" type={AlertTypes.INFO} message={alert} />
            <p className="quiz-signup__description">{description}</p>
            <Button variant="primary" label={continueBtnText} width="full" onClick={onContinueClickHandler} />
        </Layout>
    );
};

export default QuizSignup;
