// matching box claim status template that BE follows
const ClaimStatuses = {
    DRAFT: 'draft',
    SUBMITTED: 'submitted',
    IN_REVIEW: 'review',
    MORE_INFORMATION_REQUIRED: 'more info required',
    APPROVED: 'accepted',
    DECLINED: 'declined',
    PAID: 'paid',
};

export default ClaimStatuses;
