import toHeaders from '../utils/toHeaders';
import asService, { ServiceArgs } from '../utils/asService';
import SetupIntent from '../../business-logic/models/SetupIntent';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const setupCard = async ({
    accessToken,
    axios,
}: {
    accessToken: string;
} & ServiceArgs): Promise<SetupIntent> => {
    const { data } = await axios.post<SetupIntent>(
        `${baseApiPath}/api/v1/billing/stripe/setup-intent`,
        {},
        toHeaders(accessToken),
    );

    return data;
};

export default asService(setupCard);
