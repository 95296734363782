import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import parseString from '../../../utils/parseString';
import ExternalLink from '../../../components/external-link/ExternalLink';
import ExternalLinks from '../../../utils/constants/ExternalLinks';
import cancelCircle from '../../../assets/images/cancel-circle.svg';
import ModalWithCTA from '../../../components/modal-with-cta/ModalWithCTA';
import TextField from '../../../components/text-field/TextField';

import './ClaimAutograph.scss';

const ClaimAutograph: React.FC = () => {
    const history = useHistory();
    const { claimDetails, setClaimDetailsByAttr } = useClaim();

    const { hasSigned: hasAlreadySigned, signatureData: savedSignatureData } = claimDetails;
    const [hasSigned, setHasSigned] = useState(hasAlreadySigned);
    const [signatureData, setSignatureData] = useState(savedSignatureData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [signatureRef, setSignatureRef] = useState<SignatureCanvas | null>(null);
    const [useTextAutograph, setUseTextAutograph] = useState(false);
    const [textAutographValue, setTextAutographValue] = useState('');
    const [isTextAutographValid, setIsTextAutographValid] = useState(false);

    const { claimAutograph } = claimsFlowStrings;

    useEffect(() => {
        if (signatureRef && signatureData) {
            // Prefill signature data if available
            signatureRef?.fromData(signatureData);
        }
    }, [signatureData, signatureRef]);

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_AUTOGRAPH, claimDetails);
    }, []);

    const handleTextAutographChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTextAutographValue(e.target.value.toUpperCase());

        if (e.target.value.toUpperCase() === claimAutograph.textAutographAgreeText) {
            setIsTextAutographValid(true);
        } else {
            setIsTextAutographValid(false);
        }
    };

    const handleNextClick = () => {
        setClaimDetailsByAttr('hasSigned', hasSigned || isTextAutographValid);
        setClaimDetailsByAttr('signatureData', signatureData);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_AUTOGRAPH, claimDetails);

        history.push(Routes.CLAIM_ACCIDENT_LOCATION);
    };

    const validateSignature = () => {
        const signature = signatureRef?.toData();

        // signature box empty or has no data
        if (signatureRef?.isEmpty() || signature === undefined) {
            setHasSigned(false);
            return;
        }
        // check if signature path(s) long enough
        const isSignatureValid = signature.some((data) => data.length > 4);
        setHasSigned(isSignatureValid);
        setSignatureData(signature);
    };

    const clearSignature = () => {
        signatureRef?.clear();
        setHasSigned(false);
    };

    return (
        <Layout
            className="claim-autograph"
            title={claimAutograph.title}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_AUTOGRAPH / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            {!useTextAutograph && (
                <>
                    <p>{parseString(claimAutograph.description)}</p>
                    <p className="claim-autograph__label">{claimAutograph.signHere}</p>
                    <div className="claim-autograph__sign-box-wrapper">
                        <SignatureCanvas
                            ref={(ref) => setSignatureRef(ref)}
                            onEnd={validateSignature}
                            penColor="black"
                            canvasProps={{ className: 'claim-autograph__sign-box' }}
                        />
                        {hasSigned && (
                            <button type="button" className="claim-autograph__sign-clear" onClick={clearSignature}>
                                <img src={cancelCircle} alt="clear signature" />
                            </button>
                        )}
                    </div>
                    <Button
                        variant="link"
                        label={claimAutograph.unableToSignLabel}
                        onClick={() => setUseTextAutograph(true)}
                        className="claim-autograph__text-autograph-button"
                    />
                </>
            )}
            {useTextAutograph && (
                <>
                    <TextField
                        name="Text Autograph"
                        label={
                            <p className="claim-autograph__text-autograph-label">
                                {claimsFlowStrings.formatString(claimAutograph.textAutographDescription, {
                                    agreeText: <strong>{claimAutograph.textAutographAgreeText}</strong>,
                                })}
                            </p>
                        }
                        value={textAutographValue}
                        onChange={handleTextAutographChange}
                        className="claim-autograph__text-autograph"
                    />
                    <Button
                        variant="link"
                        label={claimAutograph.goBackToSignLabel}
                        onClick={() => setUseTextAutograph(false)}
                        className="claim-autograph__text-autograph-button"
                    />
                </>
            )}
            <Button
                label={commonStrings.continue}
                width="full"
                className="claim-autograph__btn"
                disabled={useTextAutograph ? !isTextAutographValid : !hasSigned}
                onClick={() => setIsModalOpen(true)}
            />
            <p className="claim-autograph__info">
                {claimsFlowStrings.formatString(claimAutograph.disclaimer, {
                    privacyPolicy: (
                        <ExternalLink to={ExternalLinks.privacyPolicy} label={commonStrings.privacyPolicy} />
                    ),
                })}
            </p>
            <ModalWithCTA
                onClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
                title={claimAutograph.modalDescription}
                textContent={claimAutograph.modalAlert}
                primaryCTA={{ onClick: handleNextClick, label: commonStrings.next }}
                secondaryCTA={{ onClick: () => setIsModalOpen(false), label: commonStrings.back }}
            />
        </Layout>
    );
};

export default ClaimAutograph;
