const CLAIMS_BASE = '/claims';
const PROFILE_BASE = '/profile';
const SCHEDULE_BOOST_BASE = '/schedule-boost';
const CHECKOUT_BASE = '/checkout';
const LOGIN_BASE = '/signin';
const ROAMING_BASE = '/roaming';
const GUEST = '/guest';
const BAYMAX_PURCHASE = '/purchase';
const BAYMAX_ROAMING_BASE = '/flipRoaming';

export type Route = typeof Routes[keyof typeof Routes];

const Routes = {
    LANDING: '/',
    LOGIN_CALLBACK: '/login/callback',
    HOME: '/dashboard',
    RESIDENCY_STATE: '/residency-state',
    RESIDENCY_STATUS: '/residency-status',
    DATE_OF_BIRTH: '/date-of-birth',
    GENDER: '/gender',
    NAME: '/name',
    LOADING: '/loading',

    ONBOARDING_QUIZ: '/onboarding-quiz',
    ONBOARDING_QUIZ_INJURY: '/onboarding-quiz/existing-injury',
    ONBOARDING_QUIZ_WORK: '/onboarding-quiz/work-accidents',
    ONBOARDING_QUIZ_MOTORCYCLING: '/onboarding-quiz/motorcycling',
    ONBOARDING_QUIZ_RESULT_EXCLUSIONS: '/onboarding-quiz/result/exclusions',
    ONBOARDING_QUIZ_RESULT_SIGNUP: '/onboarding-quiz/result/signup',

    CLAIMS: CLAIMS_BASE,
    CLAIM_PERSONS_INSURED: `${CLAIMS_BASE}/persons-insured`,
    CLAIM_TYPE: `${CLAIMS_BASE}/type`,
    CLAIM_CHECKLIST: `${CLAIMS_BASE}/checklist`,
    CLAIM_REQUIREMENTS: `${CLAIMS_BASE}/requirements`,
    CLAIM_AUTOGRAPH: `${CLAIMS_BASE}/autograph`,
    CLAIM_ACCIDENT_LOCATION: `${CLAIMS_BASE}/accident-location`,
    SELECT_INJURY: `${CLAIMS_BASE}/select-injury`,
    ACCIDENT_DESCRIPTION: `${CLAIMS_BASE}/accident-description`,
    THIRD_PARTY_CONSENT: `${CLAIMS_BASE}/third-party-consent`,
    ACCIDENT_DATE: `${CLAIMS_BASE}/accident-date`,
    FIRST_TREATMENT_DATE: `${CLAIMS_BASE}/first-treatment-date`,
    SURGERY_REQUIRED: `${CLAIMS_BASE}/surgery-required`,
    OVERSEAS_RECEIPTS: `${CLAIMS_BASE}/overseas-costs`,
    MEDICAL_REPORT: `${CLAIMS_BASE}/medical-report`,
    MEDICAL_CONTACT_CONSENT: `${CLAIMS_BASE}/medical-contact-consent`,
    MEDICAL_CONTACT_INFORMATION: `${CLAIMS_BASE}/medical-contact-information`,
    EMERGENCY_SERVICES_REQUIRED: `${CLAIMS_BASE}/emergency-services-required`,
    WITNESS_QUESTIONNAIRE: `${CLAIMS_BASE}/witness-questionnaire`,
    WITNESS_INFO: `${CLAIMS_BASE}/witness-info`,
    PHOTO_OF_ACCIDENT_QUESTIONNAIRE: `${CLAIMS_BASE}/photo-of-accident-questionnaire`,
    PHOTO_OF_ACCIDENT: `${CLAIMS_BASE}/photo-of-accident`,
    PROOF_OF_ACTIVITY_QUESTIONNAIRE: `${CLAIMS_BASE}/proof-of-activity-questionnaire`,
    PROOF_OF_ACTIVITY: `${CLAIMS_BASE}/proof-of-activity`,
    BANK_DETAILS: `${CLAIMS_BASE}/bank-details`,
    REVIEW_CLAIM: `${CLAIMS_BASE}/review-claim`,
    CLAIM_SUBMITTED: `${CLAIMS_BASE}/done`,
    CLAIM_DETAILS: `${CLAIMS_BASE}/details/:claimId`,

    PROFILE: PROFILE_BASE,
    PROFILE_ACCOUNT: `${PROFILE_BASE}/account`,
    PROFILE_MANAGE_COVER: `${PROFILE_BASE}/manage-cover`,
    PROFILE_PREFERENCES: `${PROFILE_BASE}/preferences`,
    PROFILE_CERTIFICATE_OF_COVER: `${PROFILE_BASE}/certificate-of-cover`,

    SCHEDULE_BOOST: SCHEDULE_BOOST_BASE,
    SCHEDULE_BOOST_SUCCESS: `${SCHEDULE_BOOST_BASE}/success`,

    ROAMING_SELECTED: `${ROAMING_BASE}/selected`,
    ROAMING_DESTINATIONS: `${ROAMING_BASE}/destinations`,
    ROAMING_START: `${ROAMING_BASE}/starting-destination`,
    ROAMING_REGION: `${ROAMING_BASE}/starting-region`,
    ROAMING_DATES: `${ROAMING_BASE}/dates`,
    ROAMING_SELECTED_GUEST: `${GUEST}${ROAMING_BASE}/selected`,
    ROAMING_DESTINATIONS_GUEST: `${GUEST}${ROAMING_BASE}/destinations`,
    ROAMING_START_GUEST: `${GUEST}${ROAMING_BASE}/starting-destination`,
    ROAMING_REGION_GUEST: `${GUEST}${ROAMING_BASE}/starting-region`,
    ROAMING_DATES_GUEST: `${GUEST}${ROAMING_BASE}/dates`,
    ROAMING_DESTINATIONS_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}`,
    ROAMING_START_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}/starting-destination`,
    ROAMING_REGION_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}/starting-region`,
    ROAMING_DATES_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}/dates`,

    CHECKOUT: CHECKOUT_BASE,
    CART: '/cart',
    CART_BAYMAX: `${BAYMAX_PURCHASE}/cart`,
    CART_SUCCESS: '/cart/success',
    CART_BAYMAX_SUCCESS: `${BAYMAX_PURCHASE}/success`,

    // Beta
    YOUR_ACTIVITIES: '/your-activities',
    SCHEDULE_ALWAYS_ON_SUCCESS: `/schedule-always-on-success`,
    SCHEDULE_COVER_GUEST: `${GUEST}/schedule-cover`,
    SCHEDULE_COVER: `/schedule-cover`,
    SCHEDULE_ACTIVE_COVER_BAYMAX: `${BAYMAX_PURCHASE}/flipactive`,
    SCHEDULE_KIDS_COVER_BAYMAX: `${BAYMAX_PURCHASE}/flipkids`,
    BEFORE_YOU_GO: '/before-you-go',

    PREFLIGHT_CHECK: `${GUEST}/preflight`,
    PERSONS_INSURED: '/persons-insured',
    PERSONS_INSURED_GUEST: `${GUEST}/persons-insured`,
    SELECT_COVER: '/select-cover',
    SELECT_COVER_GUEST: `${GUEST}/select-cover`,
    SELECT_COVER_BAYMAX: `${BAYMAX_PURCHASE}`,

    // external
    LOGIN: `${LOGIN_BASE}`,
    REGISTER: `${LOGIN_BASE}/register`,
    FORGOT_PASSWORD: `${LOGIN_BASE}/forgot-password`,

    NOT_FOUND: '/404',
} as const;

export default Routes;
