import React, { ReactNode, useEffect } from 'react';
import { range } from 'lodash';
import Glide from '@glidejs/glide';
import './Slider.scss';
import classNames from 'classnames';

type TProps = {
    children: ReactNode[];
    title?: string;
    className?: string;
};

const Slider: React.FC<TProps> = ({ children, title, className }: TProps) => {
    useEffect(() => {
        const glide = new Glide('.glide', {
            type: 'slider',
            perView: 2,
            gap: '16',
            breakpoints: {
                768: {
                    perView: 1,
                    peek: 40,
                },
                1024: {
                    perView: 2,
                    peek: 0,
                },
            },
        });
        glide.mount();
    }, []);

    return (
        <div className={classNames('slider', className)}>
            {title && <p className={classNames(`slider__title`)}>{title}</p>}
            <div className="glide" id="intro">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {React.Children.map(children, (child) => (
                            <li className="glide__slide">{child}</li>
                        ))}
                    </ul>
                </div>
                <div className="glide__bullets" data-glide-el="controls[nav]">
                    {range(children.length).map((i) => {
                        return <div key={i} className="glide__bullet" data-glide-dir={`=${i}`} />;
                    })}
                </div>
                <div data-glide-el="controls" className="glide__controls">
                    <div data-glide-dir="<" className="glide__control glide__control--left" />
                    <div data-glide-dir=">" className="glide__control glide__control--right" />
                </div>
            </div>
        </div>
    );
};

export default Slider;
