import { FC } from 'react';
import classNames from 'classnames';
import Cover from '../../../../../utils/constants/Cover';

import './CoverTile.scss';

type TProps = {
    value: Cover;
    onChange: (value: Cover) => void;
    isActive: boolean;
    title: string;
    name: string;
    priceTimePeriod: string;
    price: string;
};

const CoverTile: FC<TProps> = ({ onChange, isActive, price, name, value, priceTimePeriod, title }: TProps) => {
    return (
        <div className={classNames('cover-tile')}>
            <label
                htmlFor={name}
                className={classNames('cover-tile__content', {
                    'cover-tile__content--active': isActive,
                })}
            >
                <p className="cover-tile__title">{title}</p>
                <div className="cover-tile__price-section">
                    <p
                        className={classNames('cover-tile__price', {
                            'cover-tile__price--active': isActive,
                        })}
                    >
                        {price}
                    </p>

                    <p className="cover-tile__description">{priceTimePeriod}</p>

                    <input
                        checked={isActive}
                        name="product_model"
                        type="radio"
                        className="cover-tile__input"
                        id={name}
                        onChange={() => onChange(value)}
                    />
                </div>
            </label>
        </div>
    );
};

export default CoverTile;
