import LocalizedStrings from 'react-localization';

const quizFlow = new LocalizedStrings({
    en: {
        onboarding: {
            getStarted: 'Get Started',
            title: 'Let’s check some basics',
            description:
                "We'll ask you 3 quick questions. Your answers won't affect how much your insurance will cost.",
        },
        existingInjury: {
            title: 'Do you have an existing injury, that you want to claim for?',
        },
        workAccidents: {
            title: 'Are you looking for insurance that covers accidents at work?',
        },
        motorcycling: {
            title: 'Are you looking for insurance that covers you for motorcycling accidents?',
        },
        exclusions: {
            title: "We get asked about these a lot, so we're telling you upfront that we don't pay claims for:",
            description: "We'll show you a detailed list of what is and isn't covered in the next few steps",
            backBtnText: 'Back to homepage',
            understandBtnText: 'I understand',
            list: {
                injuries: 'Pre-existing injuries or conditions',
                accidents: 'Work accidents (unless your work is sports related, e.g. sports coach or ski instructor)',
                motorcycling: 'Motorcycling accidents',
            },
        },
        signup: {
            title: "Let's get you signed up",
            alert: "By continuing, you understand that Flip doesn't pay claims for pre-existing injuries, work accidents (that aren't sports related) and motorcycling accidents",
            description: "We'll show you a detailed list of what is and isn't covered in the next few steps",
            continueBtnText: 'Continue',
        },
    },
});

export default quizFlow;
