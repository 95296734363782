import { createMachine } from 'xstate';
import { CartErrorDisplay } from '../../../business-logic/models/Cart';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import { Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { MainCover } from '../../../utils/constants/Cover';
import Limits from '../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';
import {
    evaluateDisabledDays,
    initialiseCoversAvailable,
    initialiseCoversAvailableForDependants,
    initialiseFixedPaymentModelDatePickerMode,
    initialiseFixedPaymentModelSelectedCover,
    resetDaysToSchedule,
    setDatePickerMode,
    setDaysToSchedule,
    setSelectedCover,
    trackFlipActiveProductListViewed,
    trackFlipKidsProductListViewed,
    updateMachineContextFromGlobalContext,
} from './actions/scheduleCoverAuthenticatedMachineActions';
import { ScheduleCoverAuthenticatedMachineContextTypes } from './context/scheduleCoverAuthenticatedMachineContext';
import {
    disableOverlappingCovers,
    isPersonsInsuredInfoValid,
    isSchedulingCoverForDependant,
} from './guards/scheduleCoverAuthenticatedMachineGuards';

const scheduleCoverAuthenticatedMachine = createMachine(
    {
        id: 'ScheduleCoverAuthenticatedMachine',
        initial: 'waitForContextInitialisation',
        context: {
            policies: [],
            coversAvailable: [],
            selectedCover: null,
            daysToSchedule: [],
            datePickerMode: 'single',
            accessToken: '',
            // Defaults to allowing all cover purchases at any time
            enforceOverlappingRules: false,
            // use browser timezone as default
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            // use fallback limits as default
            disabledDays: [
                {
                    before: new Date(),
                    after: getLastDayToSchedule(new Date(), Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS),
                },
            ],
            productSpecs: null,
        },
        schema: {
            context: {} as ScheduleCoverAuthenticatedMachineContextTypes,
            events: {} as
                | { type: 'SELECT_COVER'; data: MainCover }
                | { type: 'SELECT_DATES'; data: Date[] }
                | { type: 'CART_CREATE_SUCCESS' }
                | {
                      type: 'CART_CREATE_ERROR';
                      data: CartErrorDisplay;
                  }
                | { type: 'CHECKOUT' }
                | { type: 'GO_BACK' }
                | { type: 'CART_TRY_AGAIN'; data: Date[] }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          policies: Policy[];
                          userTimeZone: string;
                          productSpecs: Record<MainCover, ProductResponse>;
                      };
                  },
        },
        tsTypes: {} as import('./scheduleCoverAuthenticatedMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            waitForContextInitialisation: {
                on: {
                    CONTEXT_INITIALISED: {
                        actions: 'updateMachineContextFromGlobalContext',
                        target: 'verifyValidPersonsInsuredInfoProvided',
                    },
                },
            },
            verifyValidPersonsInsuredInfoProvided: {
                always: [
                    {
                        cond: 'isPersonsInsuredInfoValid',
                        target: 'initialise',
                    },
                    {
                        target: 'redirectToPersonsInsuredScreen',
                    },
                ],
            },
            redirectToPersonsInsuredScreen: {
                type: 'final',
                entry: 'redirectToPersonsInsuredScreen',
            },
            initialise: {
                initial: 'determineCoverForSelfOrDependant',
                states: {
                    determineCoverForSelfOrDependant: {
                        always: [
                            {
                                cond: 'isSchedulingCoverForDependant',
                                target: 'initialiseForDependant',
                            },
                            {
                                target: 'initialiseForSelf',
                            },
                        ],
                    },
                    initialiseForSelf: {
                        always: [
                            {
                                cond: 'disableOverlappingCovers',
                                actions: ['initialiseCoversAvailable', 'trackFlipActiveProductListViewed'],
                                target: '#ScheduleCoverAuthenticatedMachine.fixedPaymentModel',
                            },
                            {
                                actions: ['initialiseCoversAvailable', 'trackFlipActiveProductListViewed'],
                                target: '#ScheduleCoverAuthenticatedMachine.noPaymentModel',
                            },
                        ],
                    },
                    initialiseForDependant: {
                        always: [
                            {
                                cond: 'disableOverlappingCovers',
                                actions: ['initialiseCoversAvailableForDependants', 'trackFlipKidsProductListViewed'],
                                target: '#ScheduleCoverAuthenticatedMachine.fixedPaymentModel',
                            },
                            {
                                actions: ['initialiseCoversAvailableForDependants', 'trackFlipKidsProductListViewed'],
                                target: '#ScheduleCoverAuthenticatedMachine.noPaymentModel',
                            },
                        ],
                    },
                },
            },
            fixedPaymentModel: {
                initial: 'init',
                states: {
                    init: {
                        always: {
                            actions: [
                                'initialiseFixedPaymentModelSelectedCover',
                                'initialiseFixedPaymentModelDatePickerMode',
                                'evaluateDisabledDays',
                            ],
                            target: 'idle',
                        },
                    },
                    idle: {
                        on: {
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'datesSelected',
                            },
                            CART_CREATE_SUCCESS: {
                                target: 'datesSelected',
                            },
                            CART_CREATE_ERROR: {
                                target: 'displayCartError',
                            },
                        },
                    },
                    datesSelected: {
                        on: {
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'datesSelected',
                            },
                            CHECKOUT: {
                                actions: 'goToCheckout',
                            },
                            CART_CREATE_SUCCESS: {
                                target: 'datesSelected',
                            },
                            CART_CREATE_ERROR: {
                                target: 'displayCartError',
                            },
                        },
                    },
                    displayCartError: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'datesSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'datesSelected',
                            },
                            GO_BACK: {
                                actions: 'goBack',
                            },
                        },
                    },
                },
            },
            noPaymentModel: {
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                        },
                    },
                    coverSelected: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                            CART_CREATE_SUCCESS: {
                                target: 'coverAndDatesSelected',
                            },
                            CART_CREATE_ERROR: {
                                target: 'displayCartError',
                            },
                        },
                    },
                    coverAndDatesSelected: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                            CART_CREATE_SUCCESS: {
                                target: 'coverAndDatesSelected',
                            },
                            CART_CREATE_ERROR: {
                                target: 'displayCartError',
                            },
                            CHECKOUT: {
                                actions: 'goToCheckout',
                            },
                        },
                    },
                    displayCartError: {
                        on: {
                            SELECT_COVER: {
                                actions: [
                                    'setSelectedCover',
                                    'setDatePickerMode',
                                    'resetDaysToSchedule',
                                    'evaluateDisabledDays',
                                ],
                                target: 'coverSelected',
                            },
                            SELECT_DATES: {
                                actions: 'setDaysToSchedule',
                                target: 'coverAndDatesSelected',
                            },
                            GO_BACK: {
                                actions: 'goBack',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            initialiseCoversAvailable,
            initialiseCoversAvailableForDependants,
            setSelectedCover,
            setDatePickerMode,
            resetDaysToSchedule,
            setDaysToSchedule,
            initialiseFixedPaymentModelDatePickerMode,
            initialiseFixedPaymentModelSelectedCover,
            updateMachineContextFromGlobalContext,
            evaluateDisabledDays,
            trackFlipActiveProductListViewed,
            trackFlipKidsProductListViewed,
        },
        guards: {
            isPersonsInsuredInfoValid,
            isSchedulingCoverForDependant,
            disableOverlappingCovers,
        },
    },
);
export default scheduleCoverAuthenticatedMachine;
