import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosPromise } from 'axios';
import toHeaders from './utils/toHeaders';
import PoliciesResponse from '../business-logic/models/PoliciesResponse';

// TODO: Transition to async/await pattern

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const cancelSubscription = (
    accessToken: string | undefined,
    subscriptionNumber: number,
    reasonsForCancellation?: string[],
): AxiosPromise => {
    return axios
        .delete(`${baseApiPath}/api/subscription/${subscriptionNumber}`, {
            data: { insights: reasonsForCancellation || [] },
            headers: toHeaders(accessToken).headers,
        })
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'cancelSubscription',
                description: 'Failed to cancel subscription',
                data: { insights: reasonsForCancellation || [] },
            });
            throw new Error(error);
        });
};

/** New calls as part of Payments V2 below */

const getUserPolicies = (accessToken: string | undefined): Promise<PoliciesResponse> => {
    return axios
        .get(`${baseApiPath}/api/v1/bff-policies`, toHeaders(accessToken))
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'getUserPolicies',
                description: 'Failed to fetch user policies from bff layer',
            });
            throw new Error(error);
        });
};

const getUserAndPartnerEventPolicies = (accessToken: string | undefined): Promise<PoliciesResponse> => {
    return axios
        .get(`${baseApiPath}/api/v1/bff-policies/policies`, toHeaders(accessToken))
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'getUserAndPartnerEventPolicies',
                description: 'Failed to fetch user and partner event policies from bff layer',
            });
            throw new Error(error);
        });
};

const getTotalPurchases = (accessToken: string | undefined): Promise<number> => {
    return axios
        .get(`${baseApiPath}/api/v1/bff-policies/policies/total`, toHeaders(accessToken))
        .then((response) => response.data.totalPolicies)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'getTotalPurchases',
                description: 'Failed to get total purchases',
            });
            throw new Error(error);
        });
};

export default {
    cancelSubscription,
    getUserPolicies,
    getUserAndPartnerEventPolicies,
    getTotalPurchases,
};
