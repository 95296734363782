import { createMachine } from 'xstate';
import { CartErrorDisplay } from '../../../business-logic/models/Cart';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import Cover, { MainCover } from '../../../utils/constants/Cover';
import Limits from '../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';
import {
    evaluateDisabledDays,
    setDaysToSchedule,
    updateMachineContextFromGlobalContext,
} from './actions/roamingDatesMachineActions';
import { RoamingDatesMachineContextTypes } from './context/roamingDatesMachineContext';
import { isPersonsInsuredInfoValid } from './guards/roamingDatesMachineGuards';
import { Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';

const roamingDatesMachine = createMachine(
    {
        id: 'RoamingDatesMachine',
        initial: 'waitForContextInitialisation',
        context: {
            policies: [],
            coversAvailable: [],
            selectedCover: undefined,
            daysToSchedule: [],
            datePickerMode: 'week',
            accessToken: '',
            // use location specific timezone
            timezone: '',
            destinations: [],
            startingRegion: '',
            startingDestination: '',
            // Defaults to allowing all cover purchases at any time
            enforceOverlappingRules: false,
            // use fallback limits as default
            disabledDays: [
                {
                    before: new Date(),
                    after: getLastDayToSchedule(new Date(), Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS),
                },
            ],
            productSpecs: null,
        },
        schema: {
            context: {} as RoamingDatesMachineContextTypes,
            events: {} as
                | { type: 'SELECT_DATES'; data: Date[] }
                | { type: 'CART_CREATE_SUCCESS' }
                | {
                      type: 'CART_CREATE_ERROR';
                      data: CartErrorDisplay;
                  }
                | { type: 'AGREE_CONDITIONS' }
                | { type: 'CHECKOUT' }
                | { type: 'GO_BACK' }
                | { type: 'CART_TRY_AGAIN'; data: Date[] }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          timezone: string;
                          destinations: string[];
                          selectedCover: Cover;
                          startingDestination: string;
                          startingRegion: string;
                          policies: Policy[];
                          productSpecs: Record<MainCover, ProductResponse>;
                      };
                  },
        },
        tsTypes: {} as import('./roamingDatesMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            waitForContextInitialisation: {
                on: {
                    CONTEXT_INITIALISED: {
                        actions: 'updateMachineContextFromGlobalContext',
                        target: 'verifyValidPersonsInsuredInfoProvided',
                    },
                },
            },
            verifyValidPersonsInsuredInfoProvided: {
                always: [
                    {
                        cond: 'isPersonsInsuredInfoValid',
                        target: 'prepareCalendarPicker',
                    },
                    {
                        target: 'redirectToPersonsInsuredScreen',
                    },
                ],
            },
            redirectToPersonsInsuredScreen: {
                type: 'final',
                entry: 'redirectToPersonsInsuredScreen',
            },
            prepareCalendarPicker: {
                always: [
                    {
                        actions: 'evaluateDisabledDays',
                        target: 'selectingDates',
                    },
                ],
            },
            selectingDates: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'datesSelected',
                    },
                    CART_CREATE_SUCCESS: {
                        target: 'datesSelected',
                    },
                    CART_CREATE_ERROR: {
                        target: 'displayCartError',
                    },
                },
            },
            datesSelected: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'datesSelected',
                    },
                    AGREE_CONDITIONS: {
                        target: 'showRoamingModal',
                    },
                    CART_CREATE_SUCCESS: {
                        target: 'datesSelected',
                    },
                    CART_CREATE_ERROR: {
                        target: 'displayCartError',
                    },
                },
            },
            showRoamingModal: {
                on: {
                    CHECKOUT: {
                        actions: 'goToCheckout',
                    },
                },
            },
            displayCartError: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'datesSelected',
                    },
                    GO_BACK: {
                        actions: 'goBack',
                    },
                },
            },
        },
    },
    {
        actions: {
            setDaysToSchedule,
            updateMachineContextFromGlobalContext,
            evaluateDisabledDays,
        },
        guards: {
            isPersonsInsuredInfoValid,
        },
    },
);
export default roamingDatesMachine;
