import classNames from 'classnames';
import React, { useState } from 'react';
import commonStrings from '../../strings/common';
import Checkbox from '../form/checkbox/Checkbox';
import { RadioCheckboxProps } from '../form/RadioCheckboxProps';
import { TextField } from '../text-field/TextField';
import './CheckboxList.scss';

export interface CheckboxListProps {
    list: RadioCheckboxProps[];
    onOtherOptionChange?: (value: string) => void;
    shouldPersistOtherField?: boolean;
    className?: string;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
    list,
    onOtherOptionChange,
    shouldPersistOtherField = false,
    className,
}) => {
    const [otherOption, setOtherOption] = useState('');

    const onCheckboxChange = (id: string, checked = false) => {
        if (!shouldPersistOtherField) return;
        // remove other textfield value if unchecked
        if (id === 'other' && !checked && !!otherOption.length) {
            setOtherOption('');
            if (onOtherOptionChange) onOtherOptionChange('');
        }
    };

    return (
        <ul className={classNames('checkbox-list', className)}>
            {list.map((item) => (
                <li key={item.id} className="checkbox-list__item">
                    <Checkbox
                        id={item.id}
                        name={item.name}
                        label={item.label}
                        checked={item.checked}
                        onChange={(e) => {
                            onCheckboxChange(item.id, item.checked);
                            item.onChange(e);
                        }}
                        onFocus={item.onFocus}
                        onBlur={item.onBlur}
                        className={item.className}
                        disabled={item.disabled}
                    />
                    {item.id === 'other' && item.checked && (
                        <TextField
                            name={commonStrings.other}
                            value={otherOption}
                            className="checkbox-list__other-textfield"
                            onChange={(e) => {
                                setOtherOption(e.target.value);
                                if (onOtherOptionChange) onOtherOptionChange(e.target.value);
                            }}
                        />
                    )}
                </li>
            ))}
        </ul>
    );
};

export default CheckboxList;
