import { FC } from 'react';
import { MainCover } from '../../utils/constants/Cover';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import getCoverTerms from '../../utils/getCoverTerms';
import Alert from '../alert/Alert';
import CoveredTabV1 from './covered-tab-v1/CoveredTabV1';
import CoveredTabV2 from './covered-tab-v2/CoveredTabV2';

export interface WhatsCoveredTabProps {
    selectedCover: MainCover;
    pdsVersion: MainCoverPdsVersion;
}

const CoveredTab: FC<WhatsCoveredTabProps> = ({ selectedCover, pdsVersion }) => {
    const coverTerms = getCoverTerms(selectedCover, pdsVersion);

    switch (coverTerms.coverTermsVersion) {
        case '2':
            return <CoveredTabV2 whatsCovered={coverTerms.whatsCovered} pdsVersion={pdsVersion} />;
        case '1':
            return <CoveredTabV1 whatsCovered={coverTerms.whatsCovered} />;
        default:
            return <Alert />;
    }
};

export default CoveredTab;
