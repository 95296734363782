import React, { useState, useEffect, ChangeEvent } from 'react';
import parse from 'date-fns/parse';
import sub from 'date-fns/sub';
import isAfter from 'date-fns/isAfter';
import { useHistory } from 'react-router-dom';

import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { DatePickerInput } from '../../../components/date-picker-input/DatePickerInput';
import { TextField } from '../../../components/text-field/TextField';
import { Dropdown, OptionType } from '../../../components/dropdown/Dropdown';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Routes from '../../../utils/Routes';
import Regex from '../../../utils/RegexCollection';
import Constants from '../../../utils/Constants';
import toTime from '../../../utils/claims/toTime';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import toOption from '../../../utils/toOption';
import Analytics from '../../../analytics/Analytics';
import isValidTime from '../../../validation/isValidTime';
import DateFormat from '../../../utils/constants/DateFormat';

import './AccidentDate.scss';

const AccidentDate: React.FC = () => {
    const { claimDetails, setClaimDetailsByAttr } = useClaim();

    const { accidentDate } = claimsFlowStrings;

    const timePeriodOptions: OptionType[] = [
        { value: 'AM', label: commonStrings.am },
        { value: 'PM', label: commonStrings.pm },
    ];

    // auto formats time to 12 hours and gives it back as obj
    const fullTime = toTime(claimDetails.time);

    const [timePeriod, setTimePeriod] = useState(toOption(timePeriodOptions, fullTime.timePeriod));
    const [time, setTime] = useState(fullTime.time);
    const [date, setDate] = useState(claimDetails.date);
    const [isValidAccidentDate, setIsValidAccidentDate] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    const formIsNotEmpty = date.length > 0 && time.length > 0 && timePeriod !== null;
    const isTimeValid = isValidTime(time, timePeriod?.value);
    const isValidDateAndTime = formIsNotEmpty && isTimeValid && isValidAccidentDate;

    // parse claim date string if exists or leave it as undefined
    const selectedDate = claimDetails.date ? parse(claimDetails.date, DateFormat.DEFAULT, new Date()) : undefined;

    // date picker constraints (claim can be from up to 6 months from current date)
    const todayDate = new Date();
    const lastDayToSubmitClaim = sub(todayDate, { months: 6 });

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_ACCIDENT_DATE_TIME, claimDetails);
    }, []);

    const handleValidation = () => {
        setError('');

        // validate full time when time and period when both are filled
        if (time.length === 0 || timePeriod === null) return;
        if (!isTimeValid) setError(accidentDate.errorInvalidTime);

        // validate date and time when all fields are filled
        if (date.length === 0) return;

        const selectedFullDate = parse(`${date} ${time}${timePeriod?.value}`, DateFormat.DATE_AND_TIME, new Date());

        if (isAfter(selectedFullDate, new Date())) {
            setIsValidAccidentDate(false);
            setError(accidentDate.errorInvalidAccidentTime);
            return;
        }

        setIsValidAccidentDate(true);
    };

    const hasError = error !== '';

    const handleTimeChange = (e: ChangeEvent) => {
        const { value } = e.target as HTMLInputElement;
        const allowedText = Regex.time;
        setError('');

        if (value === '' || allowedText.test(value)) {
            if (value.length === 1 && parseInt(value[0], 10) > 1) {
                setTime(`0${value}:`);
            } else if (value.length === 2) {
                if (value.length < time.length) {
                    // delete event
                    setTime(value[0]);
                } else if (value[1] === ':') {
                    setTime(`0${value}`);
                } else if (parseInt(value[1], 10) > 2 && parseInt(value[0], 10) > 0) {
                    setTime(`0${value[0]}:${value[1]}`);
                } else {
                    setTime(`${value}:`);
                }
            } else if (value.length === 4 && value[3] === ':') {
                setTime(time);
            } else {
                setTime(value);
            }
        }
        handleValidation();
    };

    const handleTimerPeriodChange = (selectedOption: OptionType) => {
        setTimePeriod(selectedOption);
        handleValidation();
    };

    useEffect(() => {
        handleValidation();
    });

    const handleContinueClick = () => {
        setClaimDetailsByAttr('date', date);
        setClaimDetailsByAttr('time', `${time}${timePeriod?.value}`);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_ACCIDENT_DATE_TIME, claimDetails);

        history.push(Routes.FIRST_TREATMENT_DATE);
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_ACCIDENT_DATE_TIME / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="accident-date__header">{accidentDate.title}</h1>
            <form>
                <DatePickerInput
                    selectedDate={selectedDate}
                    monthToShow={todayDate}
                    fromMonth={lastDayToSubmitClaim}
                    toMonth={todayDate}
                    disabledDays={{ before: lastDayToSubmitClaim, after: todayDate }}
                    onChange={(value) => setDate(value)}
                />
                <div className="accident-date__btn-group">
                    <TextField
                        name="time"
                        label={accidentDate.time}
                        maxLength={5}
                        value={time}
                        placeholder="00:00"
                        onChange={handleTimeChange}
                        onBlur={handleValidation}
                        isError={hasError}
                    />
                    {/* TODO: Look at dropdown default border color, looks like its darker than input fields */}
                    <Dropdown
                        options={timePeriodOptions}
                        label={accidentDate.amPm}
                        value={timePeriod}
                        onChange={handleTimerPeriodChange}
                    />
                </div>
                {hasError && <p className="accident-date__error">{error}</p>}
                <Button
                    className="accident-date__btn-continue"
                    label={commonStrings.continue}
                    width="full"
                    disabled={!isValidDateAndTime}
                    onClick={handleContinueClick}
                />
            </form>
        </Layout>
    );
};

export default AccidentDate;
