import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import sub from 'date-fns/sub';
import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { DatePickerInput } from '../../../components/date-picker-input/DatePickerInput';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Routes from '../../../utils/Routes';
import Constants from '../../../utils/Constants';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import DateFormat from '../../../utils/constants/DateFormat';

import './FirstTreatmentDate.scss';

const FirstTreatmentDate: React.FC = () => {
    const { claimDetails, setClaimDetailsByAttr } = useClaim();

    const { firstTreatmentDate: contentStrings } = claimsFlowStrings;

    const [date, setDate] = useState(claimDetails.firstTreatmentDate);
    const history = useHistory();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_FIRST_TREATMENT_DATE, claimDetails);
    }, []);

    // first treatment date to prefill date picker if this has been populated
    const selectedDate = useMemo(
        () =>
            claimDetails.firstTreatmentDate
                ? parse(claimDetails.firstTreatmentDate, DateFormat.DEFAULT, new Date())
                : undefined,
        [claimDetails.firstTreatmentDate],
    );

    // validator which runs when date is changed
    const isValidDate = useMemo(() => {
        if (date.length === 0 || date === null) {
            return false;
        }

        const newDate = parse(date, DateFormat.DEFAULT, new Date());

        if (!isValid(newDate)) {
            return false;
        }
        if (isAfter(newDate, new Date())) {
            return false;
        }
        return true;
    }, [date]);

    const handleDateChange = (newDate: string) => {
        setDate(newDate);
    };

    const handleContinueClick = () => {
        setClaimDetailsByAttr('firstTreatmentDate', date);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_FIRST_TREATMENT_DATE, claimDetails);

        history.push(Routes.SURGERY_REQUIRED);
    };

    const toDate = useMemo(() => new Date(), []);
    const sixMonthsEarlier = useMemo(() => sub(new Date(), { months: 6 }), []);

    return (
        <Layout>
            <ProgressBar
                completed={(Constants.CLAIMS_STEP_FIRST_TREATMENT_DATE / Constants.CLAIMS_NO_OF_STEPS) * 100}
            />
            <h1 className="first-treatment-date__header">{contentStrings.title}</h1>
            <p>{contentStrings.description}</p>
            <form>
                <DatePickerInput
                    selectedDate={selectedDate}
                    monthToShow={toDate}
                    fromMonth={sixMonthsEarlier}
                    toMonth={toDate}
                    disabledDays={{ before: sixMonthsEarlier, after: toDate }}
                    onChange={handleDateChange}
                />
                <Button
                    className="first-treatment-date__btn-continue"
                    label={commonStrings.continue}
                    width="full"
                    disabled={!isValidDate}
                    onClick={handleContinueClick}
                />
            </form>
        </Layout>
    );
};

export default FirstTreatmentDate;
