import classNames from 'classnames';
import { format } from 'date-fns';
import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import { DayModifiers } from 'react-day-picker/types/Modifiers';

import common from '../../../../strings/common';
import Alert, { AlertTypes } from '../../../alert/Alert';
import { Button } from '../../../button/Button';
import defaultClassNames from '../../constants';
import { NewDatePickerProps } from '../../types';
import CalendarLabel from '../calendar-label/CalendarLabel';

import './DatePicker.scss';

const DatePicker: React.FC<NewDatePickerProps> = ({
    id,
    innerRef,
    onFocus,
    onBlur,
    onChange,
    value,
    monthToShow,
    fromMonth,
    toMonth,
    disabledDays,
    modifiers,
    showYearInCaption,
    className,
    error,
    label,
}) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(value[0] || null);
    const [hoveredDay, setHoveredDay] = useState<Date | null>(null);

    const handleDayMouseEnter = (day: Date, m: DayModifiers) => {
        if (m.disabled) return;
        setHoveredDay(day);
    };

    const handleDayMouseLeave = () => {
        setHoveredDay(null);
    };

    const handleTouchMouseLeave = () => {
        // On touch screens, the events that get fired when a date
        // is selected are in the following order
        // touch enter, touch leave, mouse enter
        // the mouse is captured and not released on touch
        // causing a hover state to remain after touch leave
        // we add a delay here to set hovered to null on touch leave
        // to override mouse enter firing after touch leave
        // and release the hovered state
        setTimeout(() => {
            setHoveredDay(null);
        }, 50);
    };

    const onDayClickHandler = (day: Date, m: DayModifiers) => {
        if (day && !m.disabled) {
            setSelectedDate(day);
        }
    };

    const mods = {
        ...modifiers,
        hovered: hoveredDay || undefined,
        selected: selectedDate || undefined,
    };

    const renderDay = (day: Date) => {
        const date = day.getDate();
        return <div className="day-value">{date}</div>;
    };

    const onDoneClick = () => {
        if (onChange && selectedDate) {
            onChange([selectedDate]);
        }
    };

    const renderCaptionElement = ({ date }: { date: Date }) => (
        <div className="DayPicker-Caption">
            <p>{`${format(date, 'MMMM').toUpperCase()} ${showYearInCaption ? format(date, 'Y') : ''}`}</p>
            {label && <CalendarLabel text={label} />}
        </div>
    );

    return (
        <div id={id} ref={innerRef} className="date-picker-day">
            <DayPicker
                renderDay={renderDay}
                className={classNames('day-picker', className)}
                classNames={{
                    ...defaultClassNames,
                    day: 'DayPicker-Day-custom',
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onDayClick={onDayClickHandler}
                month={monthToShow}
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={disabledDays}
                modifiers={mods}
                fixedWeeks
                onDayMouseEnter={handleDayMouseEnter}
                onDayMouseLeave={handleDayMouseLeave}
                onDayTouchStart={handleDayMouseEnter}
                onDayTouchEnd={handleTouchMouseLeave}
                captionElement={renderCaptionElement}
            />
            {error && <Alert className="date-picker__alert" message={error} type={AlertTypes.INFO} />}

            {selectedDate && <Button label={common.done} className="date-picker-day__btn" onClick={onDoneClick} />}
        </div>
    );
};

export default DatePicker;
