import { useOktaAuth } from '@okta/okta-react';
import classNames from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import Routes from '../../utils/Routes';
import Alert from '../alert/Alert';
import LoadingSpinnerOverlay from '../loading-spinner-overlay/LoadingSpinnerOverlay';
import setupOktaWidget from './setup/setupOktaWidget';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import '../text-field/TextField.scss';
import './OktaSigninWidget.scss';

interface OktaSigninWidgetProps {
    className?: string;
}

const OktaSigninWidget: FC<OktaSigninWidgetProps> = ({ className }) => {
    const { authState } = useOktaAuth();
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(location.pathname === Routes.REGISTER);
    const [error, setError] = useState<ReactNode | null>(null);

    useEffect(() => {
        if (authState && !authState.isAuthenticated) {
            const widget = setupOktaWidget({
                setLoading,
                setError,
            });

            return () => widget.remove();
        }
        return () => {};
    }, [authState]);

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            history.push(Routes.HOME);
        }
    }, [authState, history]);

    return (
        <>
            {loading && <LoadingSpinnerOverlay />}
            <div
                id="okta-sign-in-widget-wrapper"
                className={classNames(className, {
                    'okta-sign-in-widget__registration-loading': loading,
                })}
            />
            {error === null ? null : <Alert message={error} className="okta-sign-in-widget__error" />}
        </>
    );
};

export default OktaSigninWidget;
