import { useMachine } from '@xstate/react';
import { differenceInCalendarDays, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { assign } from 'xstate';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import ProductList from '../../analytics/ProductList';
import { useBaymaxProduct } from '../../business-logic/context-provider/BaymaxProductContext';
import { ProductOption } from '../../business-logic/models/ProductGroupsResponse';
import checkAgeEligibilityBaymax from '../../hoc/check-age-eligibility/checkAgeEligibilityBaymax';
import requireFlags from '../../hoc/require-flags/requireFlags';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import scheduleCoverBaymaxMachine from './schedule-cover-baymax-machine/scheduleCoverBaymaxMachine';
import ScheduleCoverBodyBaymax from './schedule-cover-body-baymax/ScheduleCoverBodyBaymax';

const ScheduleCoverBaymax = () => {
    const location = useLocation<PurchaseState>();
    const history = useHistory();
    const {
        products,
        productGroups,
        initialised: baymaxProductInitialised,
        loading: baymaxProductsLoading,
    } = useBaymaxProduct();
    const chosenProductGroup = location.pathname.split('/').pop();
    const [state, send] = useMachine(scheduleCoverBaymaxMachine, {
        actions: {
            setScheduleLimitInHours: assign({
                scheduleLimitInHours: (ctx) =>
                    products.find(
                        (product) =>
                            product.productSpec.mainCoverType.coverCode === ctx.selectedCover?.representedByCoverCode,
                    )?.productSpec.mainCoverType.schedule.scheduleLimitInHours ?? null,
            }),
            goToCheckout: (ctx) => {
                const zonedToday = utcToZonedTime(new Date(), ctx.userTimeZone);
                ctx.daysToSchedule.forEach((date) => {
                    Analytics.trackProductAdded({
                        productGroup: ctx.selectedProductGrouping,
                        variant: formatDateToString(date, DateFormat.ANALYTICS),
                        scheduledFor: isSameDay(zonedToday, date) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                        startingInDays: differenceInCalendarDays(date, zonedToday),
                    });
                });
                if (chosenProductGroup) {
                    history.push({
                        pathname: Routes.CART_BAYMAX,
                        state: {
                            products,
                            selectedProductGrouping: chosenProductGroup,
                            selectedProductOption: productGroups
                                .find((x) => {
                                    return x.id.toLowerCase() === chosenProductGroup?.toLowerCase();
                                })
                                ?.options.find((y) =>
                                    y.coverCodes.some((z) => z === ctx.selectedCover!.representedByCoverCode),
                                ),
                            coverStartDates: [
                                ...ctx.daysToSchedule.map((date) => formatDateToString(date, DateFormat.DEFAULT)),
                            ],
                        },
                    });
                } else {
                    history.push({
                        pathname: Routes.SELECT_COVER_BAYMAX,
                        // RESET
                        state: {
                            selectedProductGrouping: null,
                            selectedProductOption: null,
                            coverStartDates: [],
                            destinations: null,
                        },
                    });
                }
            },
        },
    });
    useEffect(() => {
        if (!baymaxProductsLoading && baymaxProductInitialised && !!chosenProductGroup && productGroups.length > 0) {
            const coversAvailable =
                productGroups.find((x) => x.id.toLowerCase() === chosenProductGroup.toLowerCase())?.options || [];
            const contextState = {
                selectedProductGrouping: chosenProductGroup,
                coversAvailable,
            };
            send({
                type: 'CONTEXT_INITIALISED',
                data: contextState,
            });
        }
    }, [baymaxProductInitialised, chosenProductGroup, productGroups, send, baymaxProductsLoading, products]);

    const { coversAvailable, selectedCover, datePickerMode, daysToSchedule, disabledDays } = state.context;

    const handleCalendarOpen = () => {
        Analytics.trackCalendarOpened(ProductList[selectedCover!.representedByCoverCode as Cover]);
    };

    const handleDateSubmission = (dates: Date[]) => {
        send({ type: 'SELECT_DATES', data: [...dates] });
    };

    useEffect(() => {
        if (!chosenProductGroup) return;
        const label = productGroups.find((x) => x.id.toLowerCase() === chosenProductGroup?.toLowerCase())?.name ?? '';
        Analytics.trackProductListViewed(label, coversAvailable.map((x) => x.coverCodes).flat());
    }, [chosenProductGroup, coversAvailable, productGroups]);

    return (
        <>
            <ScheduleCoverBodyBaymax
                products={products}
                loading={!baymaxProductInitialised}
                selectedCover={selectedCover}
                datePickerMode={datePickerMode}
                coversAvailable={coversAvailable}
                daysToSchedule={daysToSchedule}
                disabledDays={disabledDays}
                selectCover={(value) => send({ type: 'SELECT_COVER', data: value as ProductOption })}
                handleDateSubmission={handleDateSubmission}
                canSelectDates={state.can({ type: 'SELECT_DATES', data: [] })}
                canCheckout={state.can({ type: 'CHECKOUT' })}
                proceedToCheckout={() => send('CHECKOUT')}
                handleCalendarOpen={handleCalendarOpen}
            />
        </>
    );
};

export default requireFlags(checkAgeEligibilityBaymax(ScheduleCoverBaymax));
