import React, { FC } from 'react';
import Card from '../../../../../components/card/Card';
import DisclosureWidget from '../../../../../components/disclosure-widget/DisclosureWidget';
import infoIcon from '../../../../../assets/images/alert-info-medium.svg';
import withContent from '../../../../../hoc/with-content/withContent';
import medicalReportExamples from '../../../../../content/ui/components/medical-report-examples/medicalReportExamples';
import './Examples.scss';

const contentMap = {
    heading: 'ui.heading',
    items: 'ui.items',
};

interface ExamplesProps {
    content: Record<keyof typeof contentMap, string> & {
        items: string[];
    };
}

const Examples: FC<ExamplesProps> = ({ content }) => {
    return (
        <Card className="example">
            <DisclosureWidget
                summary={
                    <p className="example__summary">
                        <img src={infoIcon} alt="info icon" /> {content.heading}
                    </p>
                }
            >
                <ul>
                    {content.items.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </DisclosureWidget>
        </Card>
    );
};

export default withContent(Examples, contentMap, medicalReportExamples);
