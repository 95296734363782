import { assign } from 'xstate';
import { Destination, Region } from '../../../../business-logic/models/RoamingDestinations';
import { RoamingStartingRegionBaymaxMachineContextTypes } from '../context/roamingStartingRegionBaymaxMachineContext';
import { Typegen0 } from '../roamingStartingRegionBaymaxMachine.typegen';
import Analytics from '../../../../analytics/Analytics';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setChosenRegion = assign<
    RoamingStartingRegionBaymaxMachineContextTypes,
    { type: EventsCausingActions['setChosenRegion']; data: Region | null }
>({
    chosenRegion: (_, event) => event.data,
});

export const setAllRegions = assign<
    RoamingStartingRegionBaymaxMachineContextTypes,
    {
        type: EventsCausingActions['setAllRegions'];
        data: {
            fetchedDestinations: Destination[];
        };
    }
>({
    availableRegions: (ctx, event) =>
        event.data.fetchedDestinations.find((x: Destination) => x.destination === ctx.selectedDestination)?.regions ??
        [],
});

export const trackStartRegionSelected = (ctx: RoamingStartingRegionBaymaxMachineContextTypes) => {
    if (!ctx.chosenRegion) return;
    Analytics.trackStartRegionSelected(ctx.chosenRegion.region!);
};
