import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { Modifier } from 'react-day-picker';
import { assign } from 'xstate';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import Limits from '../../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../../utils/getLastDayToSchedule';
import { RoamingDatesBaymaxMachineContextTypes } from '../context/roamingDatesBaymaxMachineContext';
import { Typegen0 } from '../roamingDatesBaymaxMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    RoamingDatesBaymaxMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            coversAvailable: ProductOption[];
            selectedProductGrouping: string;
        };
    }
>({
    coversAvailable: (ctx, event) => {
        return event.data.coversAvailable;
    },
    selectedProductGrouping: (ctx, event) => event.data.selectedProductGrouping,
});

export const setSelectedCover = assign<
    RoamingDatesBaymaxMachineContextTypes,
    { type: EventsCausingActions['setSelectedCover'] }
>({
    selectedCover: (ctx) => {
        return ctx.coversAvailable[0];
    },
});

export const setDaysToSchedule = assign<
    RoamingDatesBaymaxMachineContextTypes,
    { type: EventsCausingActions['setDaysToSchedule']; data: Date[] }
>({
    daysToSchedule: (ctx, event) => event.data,
});

export const evaluateDisabledDays = assign<
    RoamingDatesBaymaxMachineContextTypes,
    { type: EventsCausingActions['evaluateDisabledDays'] }
>({
    disabledDays: (ctx) => {
        const zonedToday = utcToZonedTime(new Date(), ctx.timezone);
        const lastDayToGetCover = getLastDayToSchedule(
            zonedToday,
            ctx.scheduleLimitInHours ?? Limits.GUEST_SCHEDULE_LIMIT_IN_HOURS,
        );

        const dates: Modifier[] = [
            {
                before: zonedToday,
                after: lastDayToGetCover,
            },
        ];

        return dates;
    },
});
