import Slider from 'react-slick';
import claimsFlowStrings from '../../../../strings/claimsFlow';
import { DashboardCard } from '../../../../components/dashboard/Dashboard';
import { Card } from '../../../../components/card/Card';
import Step1Icon from '../../../../assets/images/numbers/one.svg';
import Step2Icon from '../../../../assets/images/numbers/two.svg';
import Step3Icon from '../../../../assets/images/numbers/three.svg';
import parseString from '../../../../utils/parseString';
import common from '../../../../strings/common';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HowToMakeAClaim.scss';

const HowToMakeAClaim: React.FC = () => {
    const {
        claims: { howToMakeAClaim },
    } = claimsFlowStrings;

    const howToMakeAClaimItems = [
        { id: 'step-1', icon: Step1Icon, description: howToMakeAClaim.steps[0] },
        { id: 'step-2', icon: Step2Icon, description: howToMakeAClaim.steps[1] },
        { id: 'step-3', icon: Step3Icon, description: howToMakeAClaim.steps[2] },
    ];

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 300,
        unslick: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    unslick: false,
                },
            },
        ],
    };

    return (
        <DashboardCard className="how-to-make-a-claim">
            <div className="how-to-make-a-claim__title-container">
                <div>
                    <h2 className="how-to-make-a-claim__title">{howToMakeAClaim.title}</h2>
                </div>
            </div>
            <div className="how-to-make-a-claim__steps">
                <Slider {...settings}>
                    {howToMakeAClaimItems.map((item) => (
                        <Card key={item.id} icon={item.icon} className="how-to-make-a-claim__step-card">
                            <div className="how-to-make-a-claim__step-description">{parseString(item.description)}</div>
                        </Card>
                    ))}
                </Slider>
            </div>
            <p className="how-to-make-a-claim__disclaimer">
                {claimsFlowStrings.formatString(claimsFlowStrings.claims.disclaimer, {
                    support: (
                        <a href={`mailto:${common.flipSupportEmail}?subject=Making a claim on behalf of someone else`}>
                            {common.flipSupportEmail}
                        </a>
                    ),
                })}
            </p>
        </DashboardCard>
    );
};

export default HowToMakeAClaim;
