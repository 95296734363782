import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import { CustomerResponseProfile } from '../../business-logic/models/CustomerResponse';
import withRetriesAsync from '../utils/withRetriesAsync';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const updateGuestCustomerProfile = async ({
    accessToken,
    axios,
    profile,
}: {
    accessToken: string;
    profile: Partial<CustomerResponseProfile>;
} & ServiceArgs): Promise<void> => {
    await withRetriesAsync(() =>
        axios.put(`${baseApiPath}/api/v1/guest/customer/profile`, profile, toHeaders(accessToken)),
    );
};

export default asService(updateGuestCustomerProfile);
