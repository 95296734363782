import capitalize from 'lodash/capitalize';
import { FC } from 'react';
import airplane from '../../../assets/images/airplane-blue.svg';
import { Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import manageCoverSingleCoverCardContent from '../../../content/ui/components/manage-cover-single-cover-card/manageCoverSingleCoverCard';
import withContent from '../../../hoc/with-content/withContent';
import common from '../../../strings/common';
import Cover, { MainCover } from '../../../utils/constants/Cover';
import CoverInformation, { ICoverInformation } from '../../../utils/constants/CoverInformation';
import DateFormat from '../../../utils/constants/DateFormat';
import getProductInfo from '../../../utils/getProductInfo';
import unixNumToString from '../../../utils/unixNumToString';
import ManageCoverItemCard from '../manage-cover-item-card/ManageCoverItemCard';

import './ManageCoverSingleCoverCard.scss';

const contentMap = {
    ending: 'ui.ending',
    starting: 'ui.starting',
    benefitsCTA: 'ui.benefitsCTA',
    injuriesCoveredTab: 'ui.injuriesCoveredTab',
    injuriesNotCoveredTab: 'ui.injuriesNotCoveredTab',
};

interface ManageCoverSingleCoverCardProps {
    content: Record<keyof typeof contentMap, string>;
    policies: Policy[];
    timeZone: string;
    onCancel: () => void;
}

const ManageCoverSingleCoverCard: FC<ManageCoverSingleCoverCardProps> = ({ content, policies, timeZone, onCancel }) => {
    const cover = (
        Object.entries(CoverInformation).find(([, v]) => policies[0].mainCover.coverCode === v.coverCode) as [
            string,
            ICoverInformation,
        ]
    )[0] as MainCover;

    const productInfo = getProductInfo(cover, policies[0].PDSVersion);

    const canCancel = policies.some((c) => c.mainCover.status === 'Scheduled');

    const lineItems = policies.map((c, index) => {
        const { mainCover } = c;
        const isActive = mainCover.status === 'Started';
        const selectedCoverInformation = getProductInfo(Cover[mainCover.coverCode as keyof typeof Cover]);
        return {
            key: c.insurancePolicyId,
            description: (
                <div className="manage-cover-single-cover__period">
                    <strong className="manage-cover-single-cover__period__label">
                        {capitalize(selectedCoverInformation.priceTimePeriod)} {index + 1}:{' '}
                    </strong>
                    <div className="manage-cover-single-cover__period__time">
                        {isActive
                            ? manageCoverSingleCoverCardContent.formatString(content.ending, {
                                  endDate: unixNumToString(
                                      mainCover.activeTo,
                                      mainCover.coverTimezone || timeZone,
                                      DateFormat.VERBOSE_WITH_TIMEZONE,
                                  ),
                              })
                            : manageCoverSingleCoverCardContent.formatString(content.starting, {
                                  startDate: unixNumToString(
                                      mainCover.activeFrom,
                                      mainCover.coverTimezone || timeZone,
                                      DateFormat.VERBOSE_WITH_TIMEZONE,
                                  ),
                              })}
                    </div>
                </div>
            ),
            ...(mainCover.destination !== undefined &&
                !!mainCover.destination.startingRegion.region && {
                    region: (
                        <span className="manage-cover-single-cover__region">
                            <img src={airplane} alt="" className="manage-cover-single-cover__region__icon" />
                            <>{mainCover.destination.destinations.sort((a, b) => a.localeCompare(b)).join(', ')}</>{' '}
                        </span>
                    ),
                }),
        };
    });

    lineItems.push({
        key: `${policies[0].insurancePolicyId}-pdsVersion`,
        description: (
            <span className="manage-cover-v2__pds-version">
                {common.formatString(common.pdsVersion, { pdsVersion: policies[0].PDSVersion })}
            </span>
        ),
    });

    return (
        <ManageCoverItemCard
            coverName={productInfo.name}
            cover={cover}
            pdsVersion={policies[0].PDSVersion}
            coverItems={[
                {
                    paymentModel: productInfo.paymentModel,
                    lineItems,
                    canCancel,
                },
            ]}
            benefitsContent={{
                benefitsCTA: content.benefitsCTA,
                injuriesCoveredTab: content.injuriesCoveredTab,
                injuriesNotCoveredTab: content.injuriesNotCoveredTab,
            }}
            onCancel={onCancel}
        />
    );
};

export default withContent(ManageCoverSingleCoverCard, contentMap, manageCoverSingleCoverCardContent);
