import payCheckout from './payCheckout';
import getCheckoutDetails from './getCheckoutDetails';
import applyPromoCode from './applyPromoCode';
import setupCard from './setupCard';
import getPaymentMethods from './getPaymentMethods';
import getCreditBalance from './getCreditBalance';
import getSetupIntent from './getSetupIntent';
import stripeConfirmSetup from './stripeConfirmSetup';
import getGuestCheckoutDetails from './getGuestCheckoutDetails';
import applyGuestPromoCode from './applyGuestPromoCode';
import payGuestCheckout from './payGuestCheckout';
import getGuestSetupIntent from './getGuestSetupIntent';

export default {
    setupCard,
    payCheckout,
    getCheckoutDetails,
    getGuestCheckoutDetails,
    applyPromoCode,
    getPaymentMethods,
    getCreditBalance,
    getSetupIntent,
    applyGuestPromoCode,
    stripeConfirmSetup,
    payGuestCheckout,
    getGuestSetupIntent,
};
