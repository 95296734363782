import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse from '../../business-logic/models/CustomerResponse';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const updateCustomerVariables = async ({
    accessToken,
    axios,
    variables,
}: {
    accessToken: string;
    variables: Record<string, unknown>;
} & ServiceArgs): Promise<void> => {
    await axios.put<CustomerResponse>(`${baseApiPath}/api/v1/customer/variables`, variables, toHeaders(accessToken));
};

export default asService(updateCustomerVariables);
