import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import classNames from 'classnames';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Layout } from '../../components/layout/Layout';
import { LoadingSpinnerOverlay } from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import profileStrings from '../../strings/profileFlow';
import commonStrings from '../../strings/common';
import PolicyService from '../../services/PolicyService';
import { BackButton } from '../../components/back-button/BackButton';
import './CertificateOfCover.scss';

// need to set service worker here for it to work in production
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const {
    certificateOfCover: { failedToGetCertificateOfCover, failedToLoadCertificateOfCover },
} = profileStrings;

enum PdfErrorType {
    LOADING_ERROR,
    SOURCE_ERROR,
}

export const CertificateOfCover: React.FC = () => {
    const { accessToken } = useUser();
    const [pdfFile, setPdfFile] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(0);
    const [pdfErrorType, setPdfErrorType] = useState<null | PdfErrorType>(null);
    const [pdfNumOfPages, setPdfNumOfPages] = useState(1);

    const layoutElement = document.querySelector<HTMLElement>('.certificate-of-cover');
    const setCoverWidth = () => setScreenWidth((layoutElement?.offsetWidth || 360) - 34);
    window.addEventListener('resize', () => setCoverWidth());

    const generatePolicy = async () => {
        setIsLoading(true);
        setPdfErrorType(null);

        try {
            const policyDocument = await PolicyService.generatePolicyDocument(accessToken);
            const policyDocumentBase64 = Buffer.from(policyDocument, 'binary').toString('base64');
            setPdfFile(`data:application/pdf;base64,${policyDocumentBase64}`);
        } catch (error) {
            console.error(error);
            setPdfErrorType(PdfErrorType.SOURCE_ERROR);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    const onPdfLoadSuccess = ({ numPages }: any) => setPdfNumOfPages(numPages);

    const onPdfLoadError = (error: any) => {
        console.log(error);

        if (error.name === 'IdnvalidPDFException') {
            setPdfErrorType(PdfErrorType.SOURCE_ERROR);
            return;
        }
        setPdfErrorType(PdfErrorType.LOADING_ERROR);
    };

    const onPdfSourceError = (error: any) => {
        console.log(error);
        setPdfErrorType(PdfErrorType.SOURCE_ERROR);
    };

    useEffect(() => {
        if (accessToken !== undefined && pdfFile === null) generatePolicy();
        setCoverWidth();
    }, [accessToken, pdfFile]);

    const failedToGetPdfErrorState = (
        <>
            <h1 className="h1--on-page-with-back-button">{failedToGetCertificateOfCover.title}</h1>
            <p>{failedToGetCertificateOfCover.subtitle}</p>
        </>
    );

    const failedToLoadPdfErrorState = (
        <>
            <h1 className="h1--on-page-with-back-button">{failedToLoadCertificateOfCover.title}</h1>
            <p>{failedToLoadCertificateOfCover.subtitle}</p>
            {pdfFile && (
                <a href={pdfFile} download="Flip Certificate of Cover">
                    {commonStrings.download}
                </a>
            )}
        </>
    );

    const buildPdfPages = () => {
        const pages = [];
        for (let pageNum = 1; pageNum <= pdfNumOfPages; pageNum += 1) {
            pages.push(<Page className="certificate-of-cover__page" width={screenWidth} pageNumber={pageNum} />);
        }
        return pages;
    };

    return (
        <Layout
            isContentCentered={false}
            className={classNames('certificate-of-cover', pdfErrorType !== null && 'certificate-of-cover--error')}
            bgPattern={false}
        >
            {isLoading && (
                <>
                    <LoadingSpinnerOverlay />
                    <h1 className="certificate-of-cover__header">{profileStrings.yourCertificateOfCover}</h1>
                </>
            )}
            {!isLoading && (
                <>
                    <div className="certificate-of-cover__nav">
                        <BackButton />
                        {pdfFile !== null && (
                            <a href={pdfFile} download="Flip Certificate of Cover">
                                {commonStrings.download}
                            </a>
                        )}
                    </div>
                    <Document
                        renderMode="svg"
                        loading={<LoadingSpinnerOverlay />}
                        file={pdfFile}
                        onLoadError={onPdfLoadError}
                        onSourceError={onPdfSourceError}
                        onLoadSuccess={onPdfLoadSuccess}
                        noData=""
                        error=""
                    >
                        {buildPdfPages()}
                    </Document>
                    {pdfErrorType !== null &&
                        (pdfErrorType === PdfErrorType.LOADING_ERROR && pdfFile !== null
                            ? failedToLoadPdfErrorState
                            : failedToGetPdfErrorState)}
                </>
            )}
        </Layout>
    );
};

export default CertificateOfCover;
