import { createMachine } from 'xstate';
import { setSelectedPersons, spawnKidDetailsFormMachine } from '../actions/personsInsuredBaymaxMachineActions';
import { PersonsInsuredBaymaxMachineContextTypes } from '../context/personsInsuredBaymaxMachineContext';
import { hasSelectedKid } from '../guards/personsInsuredBaymaxMachineGuards';
import { PersonsInsuredBaymaxMachineServices, createDependant } from '../services/personsInsuredBaymaxMachineServices';

const PersonsInsuredBaymaxMachine = createMachine(
    {
        id: 'PersonsInsuredBaymaxMachine',
        initial: 'determineIfSelectionIsValid',
        schema: {
            context: {} as PersonsInsuredBaymaxMachineContextTypes,
            events: {} as
                | { type: 'SELECT_MYSELF' }
                | { type: 'SELECT_KIDS' }
                | { type: 'CONTINUE' }
                | { type: 'ADD_A_KID' }
                | { type: 'SET_MYSELF_INSURED'; data: { selectedPersons: string[] } }
                | { type: 'SET_SELECTED_PERSONS'; data: { selectedPersons: string[] } }
                | { type: 'SAVE_KID' }
                | { type: 'CANCEL_ADD_A_KID' },
            services: {} as PersonsInsuredBaymaxMachineServices,
        },
        tsTypes: {} as import('./personsInsuredBaymaxMachine.typegen').Typegen0,
        context: {
            isAccountHolderInsured: false,
            selectedPersons: [],
            dependants: [],
            kidDetailsFormMachineRef: null,
            accessToken: '',
            isAuthenticated: false,
        },
        preserveActionOrder: true,
        states: {
            determineIfSelectionIsValid: {
                always: [{ cond: 'hasSelectedKid', target: 'readyToContinue' }, { target: 'idle' }],
            },
            idle: {
                on: {
                    ADD_A_KID: {
                        target: 'addAKid',
                    },
                    SET_SELECTED_PERSONS: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                    SET_MYSELF_INSURED: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                },
            },
            readyToContinue: {
                on: {
                    ADD_A_KID: {
                        target: 'addAKid',
                    },
                    SET_SELECTED_PERSONS: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                    SET_MYSELF_INSURED: {
                        actions: 'setSelectedPersons',
                        target: 'determineIfSelectionIsValid',
                    },
                    CONTINUE: {
                        actions: 'notifyDependantsCreationComplete',
                    },
                },
            },
            addAKid: {
                initial: 'spawnKidDetailsFormMachine',
                states: {
                    spawnKidDetailsFormMachine: {
                        always: {
                            actions: 'spawnKidDetailsFormMachine',
                            target: 'ready',
                        },
                    },
                    ready: {
                        on: {
                            SAVE_KID: {
                                target: 'createDependant',
                            },
                            CANCEL_ADD_A_KID: {
                                target: '#PersonsInsuredBaymaxMachine.readyToContinue',
                            },
                        },
                    },
                    createDependant: {
                        invoke: {
                            src: 'createDependant',
                            onDone: {
                                actions: ['updateUserContext'],
                                target: '#PersonsInsuredBaymaxMachine.determineIfSelectionIsValid',
                            },
                            onError: {
                                target: 'displayCreateDependantError',
                            },
                        },
                    },
                    displayCreateDependantError: {
                        on: {
                            SAVE_KID: {
                                target: 'createDependant',
                            },
                            CANCEL_ADD_A_KID: {
                                target: '#PersonsInsuredBaymaxMachine.readyToContinue',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        guards: {
            hasSelectedKid,
        },
        actions: {
            setSelectedPersons,
            spawnKidDetailsFormMachine,
        },
        services: {
            createDependant,
        },
    },
);

export default PersonsInsuredBaymaxMachine;
