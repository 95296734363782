import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/button/Button';
import { Layout } from '../../components/layout/Layout';
import preflightCheck from '../../content/ui/screens/preflight-check/preflightCheck';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import commonStrings from '../../strings/common';
import Routes from '../../utils/Routes';
import QuizRadio from '../quiz/components/quiz-radio/QuizRadio';

import './PreflightCheck.scss';

const contentMap = {
    heading: 'ui.heading',
    yesChoice: 'ui.yesChoice',
    noChoice: 'ui.noChoice',
};

interface PreflightCheckProps {
    content: Record<keyof typeof contentMap, string>;
}

const PreflightCheck: React.FC<PreflightCheckProps> = ({ content }) => {
    const [preflightChoice, setPreflightChoice] = useState<boolean | undefined>(undefined);
    const history = useHistory();
    const { authState } = useOktaAuth();

    const handleContinueClick = () => {
        if (preflightChoice === undefined) {
            return;
        }
        history.push(preflightChoice ? Routes.LOGIN : Routes.PERSONS_INSURED_GUEST);
    };

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            history.replace(Routes.PERSONS_INSURED);
        }
    }, [authState, history]);

    return (
        <Layout title={content.heading} showBackButton>
            <div className="preflight-toggles-wrapper">
                <QuizRadio
                    className="preflight-toggles"
                    showDivider
                    value={preflightChoice}
                    onChange={(choice) => {
                        setPreflightChoice(choice);
                    }}
                />
            </div>
            <Button
                label={commonStrings.next}
                disabled={preflightChoice === undefined}
                width="full"
                className="preflight__btn-continue"
                onClick={handleContinueClick}
            />
        </Layout>
    );
};

export default requireFlags(withContent(PreflightCheck, contentMap, preflightCheck));
