import React from 'react';
import appStoreButton from '../../../assets/images/app-store-button--filled.svg';
import playStoreButton from '../../../assets/images/play-store-button--filled.svg';
import Layout from '../../../components/layout/Layout';
import LinkButton from '../../../components/link-button/LinkButton';
import claimsDeprecationContent from '../../../content/ui/components/claims-deprecation/claimsDeprecationModal';
import withContent from '../../../hoc/with-content/withContent';
import ExternalLinks from '../../../utils/constants/ExternalLinks';

import './ClaimsDeprecation.scss';

interface ClaimsDeprecationProps {
    content: Record<keyof typeof contentMap, string>;
}

const contentMap = {
    header: 'ui.header',
    description: 'ui.description',
    appStoreActionText: 'ui.appStoreActionText',
    appStoreStoreName: 'ui.appStoreStoreName',
    playStoreActionText: 'ui.playStoreActionText',
    playStoreStoreName: 'ui.playStoreStoreName',
    cta: 'ui.cta',
};
const renderDownloadAppButton = (imageSrc: string, accessibleText: string) => {
    return (
        <>
            <img src={imageSrc} alt="" />
            <span className="claims-deprecate__download-button-hidden-text">{accessibleText}</span>
        </>
    );
};
const ClaimsDeprecation: React.FC<ClaimsDeprecationProps> = ({ content }) => {
    return (
        <Layout nav title={content.header}>
            <p>{content.description}</p>
            <div className="claims-deprecate__buttons">
                <LinkButton
                    label={renderDownloadAppButton(
                        appStoreButton,
                        `${content.appStoreActionText} ${content.appStoreStoreName}`,
                    )}
                    href={ExternalLinks.mobileAppAppleAppStore}
                    variant="secondary"
                    target="_blank"
                />
                <LinkButton
                    label={renderDownloadAppButton(
                        playStoreButton,
                        `${content.playStoreActionText} ${content.playStoreStoreName}`,
                    )}
                    href={ExternalLinks.mobileAppGooglePlayStore}
                    variant="secondary"
                    target="_blank"
                />
            </div>
        </Layout>
    );
};

export default withContent(ClaimsDeprecation, contentMap, claimsDeprecationContent);
