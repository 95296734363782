import { useMachine } from '@xstate/react';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRoamingBaymax } from '../../business-logic/context-provider/RoamingBaymaxContext';
import { Destination } from '../../business-logic/models/RoamingDestinations';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import roamingStartDestinationContent from '../../content/ui/screens/roaming-start-destination/roamingStartDestination';
import withContent from '../../hoc/with-content/withContent';
import common from '../../strings/common';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import RoamingStartDestinationBaymaxMachine from './roaming-start-destination-baymax-machine/roamingStartDestinationBaymaxMachine';

import './RoamingStartDestination.scss';

const contentMap = {
    heading: 'ui.heading',
    cta: 'ui.cta',
};

interface RoamingStartBaymaxProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingStartDestinationBaymax: FC<RoamingStartBaymaxProps> = ({ content }) => {
    const history = useHistory();
    const location = useLocation<PurchaseState>();
    const {
        loading: roamingLoading,
        initialised: roamingInitialised,
        chosenProductGroup,
        selectedProductOption,
        destinations,
    } = useRoamingBaymax();

    const [state, send] = useMachine(RoamingStartDestinationBaymaxMachine, {
        context: {
            selectedProductGrouping: chosenProductGroup,
            selectedProductOption,
            chosenDestinations: location?.state?.destination?.destinations ?? [],
        },
        actions: {
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER_BAYMAX,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        coverStartDates: [],
                        destination: null,
                    },
                });
            },
            redirectToSelectDates: (ctx) => {
                // one region for chosen destination
                const chosenDestination = destinations.find((x) => x.destination === ctx.startDestination) ?? null;
                if (!chosenDestination) return; // TODO
                const { regions } = chosenDestination;
                history.push({
                    pathname: Routes.ROAMING_DATES_BAYMAX,
                    state: {
                        selectedProductGrouping: chosenProductGroup,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: ctx.chosenDestinations,
                            startingDestination: ctx.startDestination,
                            startingRegion: regions[0].region,
                            timezone: regions[0].timezone,
                        },
                    },
                });
            },
            redirectToChooseStartingRegion: (ctx) => {
                // multiple regions
                const chosenDestination = destinations.find((x) => x.destination === ctx.startDestination) ?? null;
                if (!chosenDestination) return; // TODO

                history.push({
                    pathname: Routes.ROAMING_REGION_BAYMAX,
                    state: {
                        selectedProductGrouping: chosenProductGroup,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: ctx.chosenDestinations,
                            startingDestination: ctx.startDestination,
                            startingRegion: '',
                            timezone: '',
                        },
                    },
                });
            },
        },
    });

    const confirmStartingDestination = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send({ type: 'CONTINUE' });
    };

    useEffect(() => {
        if (!roamingLoading && roamingInitialised && destinations.length) {
            send({
                type: 'LOAD_DESTINATIONS',
                data: {
                    availableDestinations: destinations as Destination[],
                },
            });
        }
    }, [send, roamingLoading, roamingInitialised, destinations]);

    return (
        <Layout title={content.heading} showBackButton showLoading={roamingLoading || state.hasTag('loading')}>
            {(!state.hasTag('loading') || !roamingLoading) && (!roamingInitialised || destinations.length === 0) && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            <form onSubmit={confirmStartingDestination}>
                <Fieldset legend={content.heading} visuallyHideLegend>
                    {roamingInitialised &&
                        destinations.length > 0 &&
                        !state.hasTag('loading') &&
                        destinations
                            .filter((item) => state.context.chosenDestinations.includes(item.destination))
                            .map((item: Destination) => (
                                <div key={item.destination}>
                                    <RadioButton
                                        id={item.destination}
                                        name="destination__option"
                                        className="roaming__start-destination"
                                        label={item.destination}
                                        onChange={(e) => {
                                            send({
                                                type: 'SELECT_START_DESTINATION',
                                                data: e.target.checked ? item.destination : null,
                                            });
                                        }}
                                        checked={state.context.startDestination === item.destination}
                                    />
                                </div>
                            ))}
                    <Sticky>
                        <Button
                            width="full"
                            type="submit"
                            variant="primary"
                            label={common.continue}
                            disabled={!!state.context.startDestination === false}
                            className="roaming-destinations__cta"
                        />
                    </Sticky>
                </Fieldset>
            </form>
        </Layout>
    );
};

export default withContent(RoamingStartDestinationBaymax, contentMap, roamingStartDestinationContent);
