import { useMachine } from '@xstate/react';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import ProductList from '../../analytics/ProductList';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { CartErrorDisplay } from '../../business-logic/models/Cart';
import ModalWithCTA from '../../components/modal-with-cta/ModalWithCTA';
import checkAgeEligibility from '../../hoc/check-age-eligibility/checkAgeEligibility';
import requireFlags from '../../hoc/require-flags/requireFlags';
import useCart from '../../hooks/use-cart/useCart';
import boostContent from '../../strings/boostFlow';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import { MainCover } from '../../utils/constants/Cover';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import { FlipActiveSubMonthlyPdsVersion } from '../../utils/constants/PdsVersion';
import formatDateToString from '../../utils/formatDateToString';
import scheduleCoverAuthenticatedMachine from './schedule-cover-authenticated-machine/scheduleCoverAuthenticatedMachine';
import ScheduleCoverBody from './schedule-cover-body/ScheduleCoverBody';

const ScheduleCoverAuthenticated = () => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const history = useHistory();
    const { enforceOverlappingRules } = useFlags();
    const [isSubscriptionUnableToBuy, setIsSubscriptionUnableToBuy] = useState(false);
    const { unableToScheduleBoostModal } = boostContent;

    const { userTimeZone, initialised: userContextInitialised, userDetails, accessToken } = useUser();

    const { policies, openCoversInitialised, setOpenCoversSelections } = useOpenCovers();

    const { initialised: productContextInitialised, latestProductsByPdsVersion } = useProduct();
    const { createCart } = useCart();

    // Due to product history, the business rule is that a subscription with boost always evaluates to only
    // one subscription per accountholder.
    // Only current subscription-v1 covers with existing boost can purchase more boost
    const canSubscriptionHolderBuyBoost = policies.filter(
        (x) =>
            !x.mainCover.paymentFailed &&
            x.mainCover.currentCycleStatus !== 'Canceled' &&
            x.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1 &&
            x.PDSVersion < FlipActiveSubMonthlyPdsVersion.FLIP_2_0 &&
            (location.state?.coverFor === 'self'
                ? x.mainCover.insuredPersonId === null
                : x.mainCover.insuredPersonId === location.state?.dependantToCover),
    );

    // This page does not support boost right now, redirect if on subscription to boost page
    // As boost is to be deprecated soon there will be no further improvements to allow this to happen on this page
    if (canSubscriptionHolderBuyBoost.length > 0 && location.state.coverFor === 'self') {
        history.replace(Routes.SCHEDULE_BOOST);
    }

    const [state, send] = useMachine(scheduleCoverAuthenticatedMachine, {
        context: {
            userTimeZone,
            coverFor: location.state?.coverFor,
            dependantToCover: location.state?.dependantToCover,
            accessToken,
            enforceOverlappingRules,
        },
        actions: {
            goBack: () => {
                history.goBack();
            },
            goToCheckout: (ctx) => {
                const zonedToday = utcToZonedTime(new Date(), ctx.userTimeZone);
                ctx.daysToSchedule.forEach((date) => {
                    Analytics.trackProductAdded({
                        ...ProductList[ctx.selectedCover!],
                        quantity: 1,
                        variant: formatDateToString(date, DateFormat.ANALYTICS),
                        scheduledFor: isSameDay(zonedToday, date) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                        startingInDays: differenceInCalendarDays(date, zonedToday),
                    });
                });
                history.push({
                    pathname: Routes.CHECKOUT,
                    state: { isOnboarding: isOnboardingFlow },
                });
            },
            redirectToPersonsInsuredScreen: () => history.push(Routes.PERSONS_INSURED),
        },
    });
    useEffect(() => {
        // If selected person has current subscriptions that is active in current cycle
        // & covers cannot overlap, user cannot repurchase more covers;
        const canSubscriptionHolderBuyAnyCover =
            policies.filter(
                (x) =>
                    x.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1 &&
                    x.mainCover.status !== 'Canceled' &&
                    (location.state?.coverFor === 'self'
                        ? x.mainCover.insuredPersonId === null
                        : x.mainCover.insuredPersonId === location.state?.dependantToCover),
            ).length > 0 && !!enforceOverlappingRules;

        setIsSubscriptionUnableToBuy(canSubscriptionHolderBuyAnyCover);

        if (userContextInitialised && openCoversInitialised && productContextInitialised) {
            const contextState = {
                policies,
                userTimeZone,
                productSpecs: latestProductsByPdsVersion,
            };
            send({
                type: 'CONTEXT_INITIALISED',
                data: contextState,
            });
        }
    }, [
        send,
        userContextInitialised,
        userTimeZone,
        productContextInitialised,
        location.state?.coverFor,
        location.state?.dependantToCover,
        latestProductsByPdsVersion,
        openCoversInitialised,
        policies,
        location.state,
        enforceOverlappingRules,
    ]);

    const { coversAvailable, selectedCover, datePickerMode, daysToSchedule, disabledDays } = state.context;
    const fixedPaymentModelMode = state.matches('fixedPaymentModel');

    const handleCalendarOpen = () => {
        Analytics.trackCalendarOpened(ProductList[selectedCover!]);
    };

    const handleDateSubmission = (dates: Date[]) => {
        const coversToAdd = dates.map((d) => ({
            selectedCover: selectedCover!,
            coverStartDate: formatDateToString(d),
            timezone: null,
            personId: state.context.coverFor === 'dependant' ? state.context.dependantToCover! : userDetails.personId!,
        }));

        send({ type: 'SELECT_DATES', data: [...dates] });

        createCart.mutate([...coversToAdd], {
            onError: (err) => {
                send({
                    type: 'CART_CREATE_ERROR',
                    data: (err as Error).cause as CartErrorDisplay,
                });
            },
            onSuccess: () => {
                setOpenCoversSelections([...coversToAdd]);
                send({ type: 'CART_CREATE_SUCCESS' });
            },
        });
    };

    const cartError = createCart.isError ? (createCart.error as Error).cause : null;

    return (
        <>
            <ModalWithCTA
                title={unableToScheduleBoostModal.title}
                textContent={unableToScheduleBoostModal.description}
                isOpen={isSubscriptionUnableToBuy}
                primaryCTA={{ label: common.ok, onClick: () => history.push(Routes.HOME) }}
                onClose={() => history.push(Routes.HOME)}
            />

            {!isSubscriptionUnableToBuy && (
                <ScheduleCoverBody
                    selectedCover={selectedCover}
                    datePickerMode={datePickerMode}
                    coversAvailable={coversAvailable}
                    daysToSchedule={daysToSchedule}
                    disabledDays={disabledDays}
                    goBack={() => send({ type: 'GO_BACK' })}
                    fixedPaymentModelMode={fixedPaymentModelMode}
                    cartLoading={
                        state.matches('noPaymentModel.displayCartError') ||
                        state.matches('fixedPaymentModel.displayCartError')
                    }
                    cartError={cartError}
                    selectCover={(value) => send({ type: 'SELECT_COVER', data: value as MainCover })}
                    handleDateSubmission={handleDateSubmission}
                    canSelectDates={state.can({ type: 'SELECT_DATES', data: [] })}
                    canCheckout={state.can({ type: 'CHECKOUT' })}
                    proceedToCheckout={() => send('CHECKOUT')}
                    tryAgainCart={() => send({ type: 'CART_TRY_AGAIN', data: [] })}
                    handleCalendarOpen={handleCalendarOpen}
                    isOnboardingFlow={location.state ? location.state.isOnboarding : false}
                />
            )}
        </>
    );
};

export default requireFlags(checkAgeEligibility(ScheduleCoverAuthenticated));
