import classNames from 'classnames';
import format from 'date-fns/format';
import { FC, useState } from 'react';
import { Modifier } from 'react-day-picker';
import { useHistory } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import { ProductOption } from '../../business-logic/models/ProductGroupsResponse';
import Button from '../../components/button/Button';
import DatePickerModal from '../../components/date-picker-modal/DatePickerModal';
import Layout from '../../components/layout/Layout';
import { NewDatePickerModes } from '../../components/new-date-picker/types';
import PdsTmdDisclaimer from '../../components/pds-tmd-disclaimer/PdsTmdDisclaimer';
import RoamingProductModal from '../../components/roaming-product-modal/RoamingProductModal';
import SlideInCoverTerms from '../../components/slide-in-cover-terms/SlideInCoverTerms';
import Sticky from '../../components/sticky/Sticky';
import roamingDatesContent from '../../content/ui/screens/roaming-dates/roamingDates';
import withContent from '../../hoc/with-content/withContent';
import Routes, { Route } from '../../utils/Routes';
import Cover, { MainCover } from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import PageTitles from '../../utils/constants/PageTitles';
import getPdsVersion from '../../utils/getPdsVersion';
import getTimezone from '../../utils/getTimezone';
import RoamingPriceCoverTileBaymax from './components/roaming-price-cover-tile/RoamingPriceCoverTileBaymax';

interface RoamingDatesBaymaxBodyProps {
    content: Record<keyof typeof contentMap, string>;
    selectedRoamingCover?: ProductOption | null;
    startingRegion: string;
    timezone: string;
    destinations: string[];
    datePickerMode: NewDatePickerModes;
    daysToSchedule: Date[];
    disabledDays: Modifier[];
    loading: boolean;
    openRoamingProductModal: boolean;
    canSelectDates: boolean;
    canAgreeToConditions?: boolean;
    handleDateSubmission: (dates: Date[]) => void;
    proceedToCheckout: () => void;
    handleCalendarOpen: () => void;
    agreeToConditions: () => void;
}

const contentMap = {
    heading: 'ui.heading',
    paymentOptionLegend: 'ui.paymentOptionLegend',
    continueCTA: 'ui.continueCTA',
    selectCoverDates: 'ui.selectCoverDates',
    benefitsCTA: 'ui.benefitsCTA',
};

const RoamingDatesBaymaxBody: FC<RoamingDatesBaymaxBodyProps> = ({
    content,
    selectedRoamingCover,
    daysToSchedule,
    destinations,
    startingRegion,
    timezone,
    loading = false,
    disabledDays,
    datePickerMode,
    openRoamingProductModal,
    canSelectDates,
    proceedToCheckout,
    handleCalendarOpen,
    handleDateSubmission,
    agreeToConditions,
    canAgreeToConditions,
}) => {
    const history = useHistory();
    const [isRoamingBenefitsOpen, setIsRoamingBenefitsOpen] = useState<boolean>(false);
    const trackRoamingCancellation = (nextSlide: boolean) => {
        Analytics.trackRoamingCancellation(!nextSlide ? 'First' : 'Last');
    };
    return (
        <Layout title={content.heading} showLoading={loading} showBackButton>
            {!!selectedRoamingCover && (
                <>
                    <h2 className="roaming-dates__subheading">{selectedRoamingCover?.name}</h2>
                    <div className="roaming-price__cover-tile__wrapper">
                        <RoamingPriceCoverTileBaymax
                            key={selectedRoamingCover?.id}
                            title={`${selectedRoamingCover?.productLabel}`}
                            price={selectedRoamingCover?.price}
                        />
                    </div>
                </>
            )}
            <ul className={classNames('roaming__dates__details')}>
                <li>
                    <b>Dest: </b>
                    {destinations.length && destinations.join(', ')}
                </li>
                <li>
                    <b>Start: </b>
                    <>{startingRegion}</> {`(${getTimezone(timezone, format(new Date(), DateFormat.DEFAULT))})`}
                </li>
            </ul>
            <p
                className={classNames('roaming-price__select-cover-dates', {
                    'roaming-price__select-cover-dates--dates-loaded': !!daysToSchedule.length === false,
                })}
            >
                {content.selectCoverDates}
            </p>
            <div className="roaming-price__date-picker-modal-wrapper">
                <DatePickerModal
                    value={daysToSchedule}
                    onChange={(dates) => handleDateSubmission(dates)}
                    mode={datePickerMode}
                    disabled={!canSelectDates}
                    disabledDays={disabledDays}
                    onCalendarOpen={handleCalendarOpen}
                    changeButtonOnDateSelected
                />
            </div>
            <RoamingProductModal
                isOpen={openRoamingProductModal}
                onCancelYesClick={proceedToCheckout}
                onExitClick={() => {
                    history.push(Routes.HOME);
                }}
                trackCancelClick={trackRoamingCancellation}
            />
            {selectedRoamingCover && (
                <>
                    <SlideInCoverTerms
                        selectedCover={
                            Cover[selectedRoamingCover.representedByCoverCode as keyof typeof Cover] as MainCover
                        }
                        pdsVersion={getPdsVersion(
                            Cover[selectedRoamingCover.representedByCoverCode as keyof typeof Cover],
                        )}
                        isOpen={isRoamingBenefitsOpen}
                        onOpen={() => {
                            setIsRoamingBenefitsOpen(true);
                        }}
                        onClose={() => setIsRoamingBenefitsOpen(false)}
                    />
                    <Button
                        width="full"
                        variant="drawer-link"
                        label={content.benefitsCTA}
                        className="roaming-selected__info-button"
                        onClick={() => {
                            setIsRoamingBenefitsOpen(true);
                            const pageName = PageTitles[history?.location.pathname as Route];
                            Analytics.trackBenefitsDrawerViewed(pageName, selectedRoamingCover.representedByCoverCode);
                        }}
                    />
                </>
            )}
            {canAgreeToConditions && (
                <Sticky>
                    <Button
                        label={content.continueCTA}
                        width="full"
                        className="roaming-price__continue-cta"
                        onClick={agreeToConditions}
                    />
                    <PdsTmdDisclaimer />
                </Sticky>
            )}
        </Layout>
    );
};

export default withContent(RoamingDatesBaymaxBody, contentMap, roamingDatesContent);
