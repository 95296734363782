import React, { createContext, useContext, useEffect, useState } from 'react';
import Cover from '../../utils/constants/Cover';

const env = process.env.REACT_APP_NETLIFY_ENV;

export interface RoamingState {
    coverFor: 'self' | 'dependant' | undefined;
    selectedCover: Cover | undefined;
    destinations: string[];
    startingDestination: string;
    startingRegion: string;
    timezone: string;
    loading: boolean;
    selectedKidId: string;
    numberOfGuestKids: number;
}

interface RoamingActionsState {
    setRoamingData: (attr: keyof RoamingState, value?: any) => void;
    clearRoamingState: () => void;
    resetRoamingSelection: () => void;
}

// Initial claim state
const initialState: RoamingState = {
    coverFor: undefined,
    selectedCover: undefined,
    destinations: [],
    startingDestination: '',
    startingRegion: '',
    timezone: '',
    loading: false,
    selectedKidId: '',
    numberOfGuestKids: 0,
};

type Context = RoamingState & RoamingActionsState;

const RoamingContext = createContext<any>(initialState);

// A context hook to capture user data through the authentiated flow for a roaming product
export const useRoaming = (): Context => {
    const context: Context = useContext(RoamingContext);

    if (typeof context === 'undefined') {
        throw new Error('Roaming Context must be used within the RoamingProvider');
    }
    return context;
};

export const RoamingProvider: React.FC = (props) => {
    const [state, setState] = useState(initialState);
    useEffect(() => {
        if (env === 'dev' || env === 'test') {
            console.log('Roaming State - ', state);
        }
    }, [state]);

    const setRoamingData = (attr: keyof RoamingState, value?: any) => {
        setState((prevState) => {
            return {
                ...prevState,
                [attr]: value,
            };
        });
    };

    const clearRoamingState = () => {
        setState({ ...initialState });
    };

    const resetRoamingSelection = () => {
        setState((prevState) => {
            return {
                ...prevState,
                destinations: [],
                startingDestination: '',
                startingRegion: '',
                timezone: '',
            };
        });
    };

    const value: Context = {
        ...state,
        setRoamingData,
        clearRoamingState,
        resetRoamingSelection,
    };

    return <RoamingContext.Provider value={value} {...props} />;
};
