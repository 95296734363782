import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Button from '../../../components/button/Button';
import Fieldset from '../../../components/form/fieldset/Fieldset';
import RadioButton from '../../../components/form/radio-button/RadioButton';
import Layout from '../../../components/layout/Layout';
import claimPersonsInsured from '../../../content/ui/screens/claim-persons-insured/claimPersonsInsured';
import withContent from '../../../hoc/with-content/withContent';
import common from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';

import './ClaimPersonsInsured.scss';

const contentMap = {
    heading: 'ui.heading',
    radioButtonMyselfLabel: 'ui.radioButtonMyselfLabel',
    radioButtonDependantLabel: 'ui.radioButtonDependantLabel',
};

interface ClaimPersonsInsuredProps {
    content: Record<keyof typeof contentMap, string>;
}

const ClaimPersonsInsured: FC<ClaimPersonsInsuredProps> = ({ content }) => {
    const { userDetails, dependants } = useUser();
    const {
        claimDetails,
        setClaimDetailsByAttr,
        claimDetails: { currentClaimStarted },
    } = useClaim();
    const { push } = useHistory();

    const [selectedPersonsInsured, setSelectedPersonsInsured] = useState<string | null>(claimDetails.insuredPersonId);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedPersonsInsured(e.target.id);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setClaimDetailsByAttr(
            'insuredPersonId',
            // BE requires this to be null if is claiming for self
            selectedPersonsInsured === userDetails.personId ? null : selectedPersonsInsured,
        );
        push(Routes.CLAIM_TYPE);
    };

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_CLAIM_PERSONS_INSURED, claimDetails);
    }, [currentClaimStarted, setClaimDetailsByAttr, claimDetails]);

    return (
        <Layout
            title={content.heading}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_CLAIM_PERSONS_INSURED / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            <form onSubmit={handleSubmit}>
                <Fieldset legend={content.heading} visuallyHideLegend className="claim-persons-insured__fieldset">
                    <RadioButton
                        id={userDetails.personId!}
                        className="claim-persons-insured__radio-button"
                        name="persons-insured"
                        key={userDetails.personId}
                        label={content.radioButtonMyselfLabel}
                        onChange={handleChange}
                        checked={selectedPersonsInsured === userDetails.personId}
                    />
                    {dependants.map((p) => (
                        <RadioButton
                            id={p.personId!}
                            className="claim-persons-insured__radio-button"
                            name="persons-insured"
                            key={p.personId}
                            label={claimPersonsInsured.formatString(content.radioButtonDependantLabel, {
                                firstName: p.firstName!,
                            })}
                            onChange={handleChange}
                            checked={selectedPersonsInsured === p.personId}
                        />
                    ))}
                </Fieldset>
                <Button width="full" label={common.continue} type="submit" disabled={selectedPersonsInsured === null} />
            </form>
        </Layout>
    );
};

export default withContent(ClaimPersonsInsured, contentMap, claimPersonsInsured);
