import classNames from 'classnames';
import React from 'react';
import './EmptyState.scss';

export interface EmptyStateProps {
    title: string;
    description?: string;
    icon?: string;
    className?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ title, description, icon, className }) => {
    return (
        <div className={classNames('empty-state', className)}>
            {icon && <img src={icon} className="empty-state__icon" alt={`${title} illustration`} />}
            <p className="empty-state__title">{title}</p>
            {description && <p className="empty-state__description">{description}</p>}
        </div>
    );
};

export default EmptyState;
