import {
    BulkCoversResponse,
    BulkPoliciesResponse,
    CheckoutDetailsResponse,
    CoverResponse,
} from '../../business-logic/models/CheckoutDetails';
import CoverSelection from '../../business-logic/models/CoverSelection';
import CoverInformation from '../../utils/constants/CoverInformation';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import getScheduledStartTime from '../../utils/getScheduledStartTime';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;
const BULK_ENDPOINT_LIMIT = 15;

interface GetCheckoutResponse {
    checkoutDetails: CheckoutDetailsResponse;
    covers: CoverResponse[];
}

// ****************************************
// NETWORK LEVEL CALLS
// ****************************************

const createPolicies = async ({
    accessToken,
    coverSelections,
    axios,
}: {
    accessToken: string | undefined;
    coverSelections: CoverSelection[];
} & ServiceArgs) => {
    // Function that makes the bulk service call and then maps response
    // back to the request items
    const bulkServiceCall = async (selections: CoverSelection[]) => {
        // Create object to send to service call
        const createPolicyBody = selections.map((c) => {
            const { insuranceProductId } = CoverInformation[c.selectedCover];

            if (!insuranceProductId) {
                throw new Error('Insurance product id missing');
            }

            return {
                insuranceProductId,
                timeZone: c.timezone,
                personId: c.personId,
            };
        });

        // Make service call
        const { data: policiesResponse } = await axios.post<BulkPoliciesResponse>(
            `${baseApiPath}/api/v1/guest/insurance-policy/bulk`,
            { createPolicy: createPolicyBody },
            toHeaders(accessToken),
        );

        // Verify responses match and map responses to request items
        return selections.map((s) => {
            const responseIndex = policiesResponse.policies.findIndex(
                (r) =>
                    r.pds.insuranceProductId === CoverInformation[s.selectedCover].insuranceProductId &&
                    (r.insuredPerson ? r.insuredPerson.personId === s.personId : true) &&
                    r.policyTimeZone === s.timezone,
            );

            if (responseIndex <= -1) {
                throw new Error('Mismatch in policy response');
            }

            const returnObject = {
                ...s,
                insurancePolicyId: policiesResponse.policies[responseIndex].insurancePolicyId,
            };

            policiesResponse.policies.splice(responseIndex, 1);

            return returnObject;
        });
    };

    const serviceCalls = [];

    // Split request items based on the limit of bulk endpoint
    for (let i = 0; i < coverSelections.length; i += BULK_ENDPOINT_LIMIT) {
        const splitCoverSelections = coverSelections.slice(i, i + BULK_ENDPOINT_LIMIT);
        serviceCalls.push(bulkServiceCall(splitCoverSelections));
    }

    // Make all the calls
    const policies = await Promise.all(serviceCalls);

    // Flatten into a single array
    return policies.flat();
};

const createCoversByCoverType = async ({
    accessToken,
    coverSelectionsWithPolicyId,
    coverType,
    userTimeZone,
    axios,
}: {
    accessToken: string | undefined;
    coverSelectionsWithPolicyId: Required<CoverSelection>[];
    coverType: CoverTypeId.SINGLE_V1 | CoverTypeId.SUBSCRIPTION_V1;
    userTimeZone: string;
} & ServiceArgs) => {
    // Function that makes the bulk service call and then maps response
    // back to the request items
    const bulkServiceCall = async (selections: Required<CoverSelection>[]) => {
        // Create object to send to service call
        const createCoverBody = selections.map((c) => {
            const { coverCode } = CoverInformation[c.selectedCover];

            if (!coverCode) {
                throw new Error('Cover code missing');
            }

            return {
                insurancePolicyId: c.insurancePolicyId,
                coverCode,
                scheduledStartTime: getScheduledStartTime(c.coverStartDate, c.timezone || userTimeZone),
            };
        });

        // Make service call
        // retry if response is 404 (this can happen if FE sends requests faster than BE events can keep up)
        const { data: coversResponse } = await withRetriesAsync(
            () =>
                axios.post<BulkCoversResponse>(
                    `${baseApiPath}/api/v1/guest/insurance-cover/type/${coverType}/bulk`,
                    {
                        createCover: createCoverBody,
                    },
                    toHeaders(accessToken),
                ),
            undefined,
            isAxios404Error,
        );

        // Verify responses match and map responses to request items
        return selections.map((s) => {
            const responseIndex = coversResponse.insuranceCovers.findIndex(
                (r) => r.insurancePolicyId === s.insurancePolicyId,
            );

            if (responseIndex <= -1) {
                throw new Error('Mismatch in policy response');
            }

            const returnObject = {
                ...s,
                coverResponse: coversResponse.insuranceCovers[responseIndex],
            };

            coversResponse.insuranceCovers.splice(responseIndex, 1);

            return returnObject;
        });
    };

    const serviceCalls = [];

    // Split request items based on the limit of bulk endpoint
    for (let i = 0; i < coverSelectionsWithPolicyId.length; i += BULK_ENDPOINT_LIMIT) {
        const splitCoverSelections = coverSelectionsWithPolicyId.slice(i, i + BULK_ENDPOINT_LIMIT);
        serviceCalls.push(bulkServiceCall(splitCoverSelections));
    }

    // Make all the calls
    const covers = await Promise.all(serviceCalls);

    // Flatten into a single array
    return covers.flat();
};

const createCheckoutSession = async ({
    accessToken,
    insuranceCoverIDs,
    axios,
}: {
    accessToken: string | undefined;
    insuranceCoverIDs: string[];
} & ServiceArgs) => {
    const { data: checkoutDetails } = await axios.post<CheckoutDetailsResponse>(
        `${baseApiPath}/api/v1/guest/billing/stripe/checkout`,
        { insuranceCoverIDs },
        toHeaders(accessToken),
    );

    return checkoutDetails;
};

// ****************************************
// CREATE POLICY AND COVER ORCHESTRATION
// ****************************************

const getGuestCheckoutDetails = async ({
    accessToken,
    coverSelections,
    userTimeZone,
    axios,
}: {
    accessToken: string | undefined;
    coverSelections: CoverSelection[];
    userTimeZone: string;
} & ServiceArgs): Promise<GetCheckoutResponse> => {
    // Split by cover types, with each cover type split by main or extra cover
    const coversByCoverType = coverSelections.reduce<
        Record<CoverTypeId.SUBSCRIPTION_V1 | CoverTypeId.SINGLE_V1, { main: CoverSelection[]; extra: CoverSelection[] }>
    >(
        (acc, cur) => {
            const { coverType, isMainCover } = CoverInformation[cur.selectedCover];

            if (isMainCover) {
                acc[coverType].main.push(cur);
            } else {
                acc[coverType].extra.push(cur);
            }

            return acc;
        },
        {
            [CoverTypeId.SINGLE_V1]: {
                main: [],
                extra: [],
            },
            [CoverTypeId.SUBSCRIPTION_V1]: {
                main: [],
                extra: [],
            },
        },
    );

    // Create policies and covers
    const coversToCheckoutPromises = await Promise.all(
        // For each cover type
        Object.entries(coversByCoverType).map(async ([coverType, { main, extra }]) => {
            if (main.length) {
                // For the main covers, create policy then create cover
                const coverSelectionsWithPolicyId = await createPolicies({
                    accessToken,
                    coverSelections: main,
                    axios,
                });

                const covers = await createCoversByCoverType({
                    accessToken,
                    coverSelectionsWithPolicyId,
                    coverType: coverType as CoverTypeId.SUBSCRIPTION_V1 | CoverTypeId.SINGLE_V1,
                    userTimeZone,
                    axios,
                });

                return covers;
            }

            if (extra.length) {
                // For the extra covers, create cover only using existing policy id
                if (coverSelections.some((c) => c.insurancePolicyId === undefined)) {
                    throw new Error('Current policy id is missing');
                }

                const covers = await createCoversByCoverType({
                    accessToken,
                    coverSelectionsWithPolicyId: coverSelections as Required<CoverSelection>[],
                    coverType: coverType as CoverTypeId.SUBSCRIPTION_V1 | CoverTypeId.SINGLE_V1,
                    userTimeZone,
                    axios,
                });

                return covers;
            }

            // For no covers of this cover type, skip
            return [];
        }),
    );

    const coversToCheckout = coversToCheckoutPromises.flat();

    const checkoutDetails = await withRetriesAsync(
        () =>
            createCheckoutSession({
                axios,
                accessToken,
                insuranceCoverIDs: coversToCheckout.map((cover) => cover.coverResponse.insuranceCoverId),
            }),
        undefined,
        isAxios404Error,
    );

    return {
        checkoutDetails,
        covers: coversToCheckout.map((c) => c.coverResponse),
    };
};

export default asService(getGuestCheckoutDetails);
