import { CartRequestItem } from '../../../business-logic/models/Cart';
import CoverSelection from '../../../business-logic/models/CoverSelection';
import CoverInformation from '../../../utils/constants/CoverInformation';
import getScheduledStartTime from '../../../utils/getScheduledStartTime';

const getCoversPayload = (covers: CoverSelection[], userTimeZone: string): CartRequestItem[] => {
    return [
        ...covers.map((c) => {
            return {
                coverCode: CoverInformation[c.selectedCover].coverCode,
                scheduledStartTime: getScheduledStartTime(c.coverStartDate, c.timezone || userTimeZone.toString()),
                personId: c.personId,
                insurancePolicyId: null, // TODO: check for extras cover to return policyID, otherwise return null
                insuranceProductId: CoverInformation[c.selectedCover].insuranceProductId || '',
                coverTypeId: CoverInformation[c.selectedCover].coverType,
                destination: c.destination || undefined,
                timeZone: c.timezone || undefined,
            };
        }),
    ];
};

export default getCoversPayload;
