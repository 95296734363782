import { createMachine } from 'xstate';
import Cover from '../../../utils/constants/Cover';
import Limits from '../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';
import { evaluateDisabledDays, setDaysToSchedule } from './actions/roamingDatesGuestMachineActions';
import { RoamingDatesGuestMachineContextTypes } from './context/roamingDatesGuestMachineContext';

const roamingDatesGuestMachine = createMachine(
    {
        id: 'RoamingDatesGuestMachine',
        initial: 'prepareCalendarPicker',
        context: {
            selectedCover: undefined,
            daysToSchedule: [],
            datePickerMode: 'week',
            timezone: '',
            destinations: [],
            startingRegion: '',
            startingDestination: '',
            // use fallback limits as default
            disabledDays: [
                {
                    before: new Date(),
                    after: getLastDayToSchedule(new Date(), Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS),
                },
            ],
        },
        schema: {
            context: {} as RoamingDatesGuestMachineContextTypes,
            events: {} as
                | { type: 'SELECT_DATES'; data: Date[] }
                | { type: 'AGREE_CONDITIONS' }
                | { type: 'CHECKOUT' }
                | { type: 'GO_BACK' }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          timezone: string;
                          destinations: string[];
                          selectedCover: Cover;
                          startingDestination: string;
                          startingRegion: string;
                      };
                  },
        },
        tsTypes: {} as import('./roamingDatesGuestMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            prepareCalendarPicker: {
                always: [
                    {
                        actions: ['evaluateDisabledDays'],
                        target: 'selectingDates',
                    },
                ],
            },
            selectingDates: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'datesSelected',
                    },
                },
            },
            datesSelected: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'datesSelected',
                    },
                    AGREE_CONDITIONS: {
                        target: 'showRoamingModal',
                    },
                },
            },
            showRoamingModal: {
                on: {
                    CHECKOUT: {
                        actions: 'goToCheckout',
                    },
                },
            },
            displayCartError: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'datesSelected',
                    },
                    GO_BACK: {
                        actions: 'goBack',
                    },
                },
            },
        },
    },
    {
        actions: {
            setDaysToSchedule,
            evaluateDisabledDays,
        },
    },
);
export default roamingDatesGuestMachine;
