import { OktaAuthOptions } from '@okta/okta-auth-js';
import { WidgetOptions } from '@okta/okta-signin-widget';
import common from '../strings/common';
import oktaFlow from '../strings/oktaFlow';

const googleIdpId = process.env.REACT_APP_GOOGLE_IDP_ID;
const appleIdpId = process.env.REACT_APP_APPLE_IDP_ID;

const oktaAuthConfig: OktaAuthOptions = {
    clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
    issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    devMode: process.env.NODE_ENV === 'development',
};

const oktaSignInConfig: WidgetOptions = {
    clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
    baseUrl: `https://${process.env.REACT_APP_OKTA_DOMAIN}`,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    i18n: {
        // See packages/@okta/i18n/src/properties/login.properties at
        // https://github.com/okta/okta-signin-widget for more properties
        en: {
            'primaryauth.title': common.login,
            'primaryauth.submit': common.login,
            'primaryauth.username.placeholder': common.email,
            'primaryauth.username.tooltip': common.email,
            'registration.form.title': oktaFlow.registration.formTitle,
            'registration.form.submit': oktaFlow.registration.formSubmit,
            'registration.passwordComplexity.minLength': oktaFlow.registration.passwordMinLength,
            'registration.error.userName.invalidEmail': oktaFlow.registration.invalidEmail,
            'registration.passwordComplexity.excludeUsername': oktaFlow.registration.passwordExcludeUsername,
            'error.username.required': oktaFlow.error.usernameRequired,
            'password.complexity.no_username': oktaFlow.error.passwordNoUsername,
            'password.complexity.no_username.description': oktaFlow.error.passwordNoUsernameDescription,
            'email.button.send': oktaFlow.login.sendEmailCode,
            'email.code.label': oktaFlow.login.enterCode,
            'mfa.challenge.verify': common.confirm,
        },
    },
    features: {
        emailRecovery: true,
        restrictRedirectToForeground: true,
        deviceFingerprinting: true,
        consent: true,
        useDeviceFingerprintForSecurityImage: false,
        customExpiredPassword: true,
        router: true,
        showPasswordToggleOnSignInPage: false,
        securityImage: false,
        autoPush: true,
        smsRecovery: false,
        idpDiscovery: false,
        selfServiceUnlock: false,
        webauthn: true,
        passwordlessAuth: true,
        showPasswordRequirementsAsHtmlList: true,
        registration: true,
        rememberMe: false,
        callRecovery: false,
        multiOptionalFactorEnroll: true,
    },
    idps: [
        { type: 'apple', id: appleIdpId || '' },
        { type: 'google', id: googleIdpId || '' },
    ],
    idpDisplay: 'SECONDARY',
};

export default { oktaAuthConfig, oktaSignInConfig };
