import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import { ReactComponent as EmergencyServicesImage } from '../../../assets/images/claims/emergency-services.svg';
import { ReactComponent as EmergencyServicesGround } from '../../../assets/images/claims/emergency-services-ground.svg';
import BulletPointList from '../../../components/bullet-point-list/BulletPointList';

import './EmergencyServicesRequired.scss';

const EmergencyServicesRequired: React.FC = () => {
    const { setClaimDetailsByAttr, claimDetails } = useClaim();

    const { emergencyServicesRequired: contentStrings } = claimsFlowStrings;

    const history = useHistory();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_EMERGENCY_SERVICES_REQUIRED, claimDetails);
    }, []);

    const handleAnswerClick = (wasEmergencyServicesRequired: boolean) => {
        setClaimDetailsByAttr('wasEmergencyServicesRequired', wasEmergencyServicesRequired);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_EMERGENCY_SERVICES_REQUIRED, claimDetails);

        history.push(Routes.WITNESS_QUESTIONNAIRE);
    };

    return (
        <Layout>
            <ProgressBar
                completed={(Constants.CLAIMS_STEP_EMERGENCY_SERVICES_REQUIRED / Constants.CLAIMS_NO_OF_STEPS) * 100}
            />
            <h1 className="emergency-services-required__header">{contentStrings.title}</h1>
            <div className="emergency-services-required__image-wrapper">
                <EmergencyServicesImage />
                <EmergencyServicesGround className="emergency-services-required__image-ground" />
            </div>
            <p className="emergency-services-required__example-title">{contentStrings.exampleTitle}</p>
            <BulletPointList list={contentStrings.examples} />
            <div className="emergency-services-required__btn-group">
                <Button label={commonStrings.no} onClick={() => handleAnswerClick(false)} />
                <Button label={commonStrings.yes} onClick={() => handleAnswerClick(true)} />
            </div>
        </Layout>
    );
};

export default EmergencyServicesRequired;
