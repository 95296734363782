import { CoverInfo, CoverInfoV3 } from './types';

import alert from '../../../assets/images/alert-red.svg';
import greenCheck from '../../../assets/images/deep-green-check.svg';
import alcoholDrugs from '../../../assets/images/not-covered/alcohol-drugs.svg';
import dental from '../../../assets/images/not-covered/dental.svg';
import illegalActGrogu from '../../../assets/images/not-covered/illegal-act-grogu.svg';
import injuryChild from '../../../assets/images/not-covered/injury-child.svg';
import medical from '../../../assets/images/not-covered/medical.svg';
import motorcycling from '../../../assets/images/not-covered/motorcycling.svg';
import nonSportsWork from '../../../assets/images/not-covered/non-sports-work.svg';
import overuseGrogu from '../../../assets/images/not-covered/overuse-grogu.svg';
import preExisting from '../../../assets/images/not-covered/pre-existing.svg';
import suicide from '../../../assets/images/not-covered/suicide.svg';
import travelOverseas from '../../../assets/images/not-covered/travel-overseas.svg';
import war from '../../../assets/images/not-covered/war.svg';
import { AlertTypes } from '../../../components/alert/Alert';
import { FlipRoamingPdsVersion } from '../../../utils/constants/PdsVersion';

const flipRoamingFlip2Terms: CoverInfoV3 = {
    coverTermsVersion: '3',
    mainMenu: {
        id: 'mainMenu',
        title: 'Flip Roaming',
        description:
            'Accidental injury insurance while at a covered destination outside Aus/NZ. Up to A$70,000 in cash payouts.',
        pricePerTimePeriod: 'A$80 a week',
        groupPrice: 'A$80 a week',
        links: [
            {
                target: 'howItWorks',
                label: 'Get cash back, with our Overseas Medical Cash. Learn more.',
            },
            {
                target: 'whatsCovered',
                label: 'Plus, get Extra Recovery Cash, to help you recover. Learn more.',
            },
            {
                target: 'whatsNotCovered',
                label: 'Cover while doing almost any activity. Check the exclusions.',
            },
            {
                target: 'destinations',
                label: 'Check the destinations we cover.',
            },
        ],
    },
    whatsCovered: {
        id: 'whatsCovered',
        title: 'Cash to help you recover.',
        description:
            'With Flip Roaming&apos;s <b>Extra Recovery Cash benefit</b>, we pay you an extra cash amount directly to your bank account on approved claims, to spend however you want.\n\nThis is <strong>paid in addition to</strong> the Overseas Medical Cash benefit.<h2>Here is what you get ...</h2>If a Registered Doctor confirms that your accidental injury is of a severity that requires any of the following, even after returning to Australia:',
        payoutCategories: [
            {
                payouts: [
                    {
                        injurySeverity: 'Diagnosis by an X-ray or ultrasound',
                        payoutAmount: 'A$100',
                    },
                    {
                        injurySeverity: 'Diagnosis by an MRI or CT scan',
                        payoutAmount: 'A$100',
                    },
                    {
                        injurySeverity: 'At least 2 physio* sessions',
                        payoutAmount: 'A$150',
                    },
                    {
                        injurySeverity: 'At least 4 physio* sessions (Paid in addition to the A$150 benefit above)',
                        payoutAmount: 'A$150',
                    },
                    {
                        injurySeverity: 'Specialist care',
                        payoutAmount: 'A$100',
                    },
                    {
                        injurySeverity: 'A cast, brace or splint',
                        payoutAmount: 'A$100',
                    },
                    {
                        injurySeverity: 'Urgent treatment in a hospital emergency department',
                        payoutAmount: 'A$200',
                    },
                    {
                        injurySeverity: 'Inpatient hospital care for 2 or more nights',
                        payoutAmount: 'A$1,000',
                    },
                    {
                        injurySeverity: 'Surgery in hospital',
                        payoutAmount: 'A$2,000',
                    },
                    {
                        injurySeverity: 'Major dental (root canal, crown or bridge work, implants, dentures or splint)',
                        payoutAmount: 'A$2,000',
                    },
                    {
                        injurySeverity: 'Care in an intensive care unit (ICU)',
                        payoutAmount: 'A$5,000',
                    },
                    {
                        injurySeverity: 'Continuous ventilatory support in a hospital for 3 or more nights',
                        payoutAmount: 'A$15,000',
                    },
                ],
            },
            {
                categoryName: 'Additional specific injury payouts',
                payouts: [
                    {
                        injurySeverity:
                            'A traumatic brain or spinal cord injury requiring care in an intensive care unit (ICU) for 5 or more nights',
                        payoutAmount: 'A$25,000',
                    },
                    {
                        injurySeverity:
                            'A spinal cord injury that results in an inability to move two or more limbs that is persistent 1 month after the injury',
                        payoutAmount: 'A$50,000',
                    },
                    {
                        injurySeverity: 'The removal of a hand, arm, foot or leg',
                        payoutAmount: 'A$50,000',
                    },
                ],
            },
        ],
        subtext: [
            'For one Accident, you can qualify for multiple benefit types.\n\nYou can claim each benefit type once per Accident and you can receive up to A$50,000 in payouts.',
        ],
        fineprint: [
            '<b>*Reminder</b>: A Registered Doctor must confirm that your injury is of a severity that requires a certain amount of physiotherapy.',
        ],
        alert: {
            type: AlertTypes.INFO,
            message:
                '<span>Any reference to a <b>Registered Doctor</b> means a medical practitioner or dentist relevant to the Accidental Injury you have suffered, that is registered or licensed to practise medicine or dentistry by the relevant licensing body in the country where they are practising.</span>',
        },
    },
    whatsNotCovered: {
        id: 'whatsNotCovered',
        title: 'We cover you while doing most activities.',
        description:
            '<h2>There are some things we don&apos;t cover, check the list below.</h2>' +
            'If your activity doesn&apos;t match anything on this list, that means it&apos;s covered.',
        exclusions: [
            {
                name: 'Motorcycling',
                icon: motorcycling,
                description: "If you're injured as a result of riding a motorbike.",
            },
            {
                name: 'Injuries outside of Covered Destinations',
                icon: travelOverseas,
                description: "If you're not in a Covered Destination when your injury happens.",
            },
            {
                name: 'Non sports related work',
                icon: nonSportsWork,
                description:
                    "Paid work or employment isn't covered (e.g. food delivery rider), except when the paid work or employment is a sports related activity (e.g. tennis coaching).",
            },
            {
                name: 'Overuse injuries',
                icon: overuseGrogu,
                description:
                    "We only cover acute injuries caused by accidents, i.e. they happen all of a sudden and are unexpected. Any overuse injury, like shin splints, runners knee and tennis elbow aren't covered.",
            },
            {
                name: "Accidents that happen while your insurance isn't active",
                icon: preExisting,
                description:
                    "If you have an accident before your insurance starts or after it ends, that's not covered.",
            },
            {
                name: 'Dental injury when eating or drinking',
                icon: dental,
                description:
                    'Dental injuries caused by food or drink including any foreign object in it while being consumed.',
            },
            {
                name: 'Medical stuff',
                icon: medical,
                description:
                    'Accidents or injuries caused by medical conditions or episodes e.g. an injury caused by osteoarthritis, a seizure or a heart attack, or by medical procedures or related complications.',
            },
            {
                name: 'Booze and drugs',
                icon: alcoholDrugs,
                description:
                    'Being under the influence of alcohol, taking illicit drugs, or taking medication in a way not as directed. ',
            },
            {
                name: 'Intentional self-injury',
                icon: suicide,
                description:
                    "If you hurt yourself on purpose, that's not covered. For example, popping your own shoulder out to prove to your mates you can.",
            },
            {
                name: 'Injury to a child caused by a parent',
                icon: injuryChild,
                description: 'Injury to a child caused by a parent, guardian or someone living with the child.',
            },
            {
                name: 'Breaking the law',
                icon: illegalActGrogu,
                description:
                    "If you're injured while involved in an illegal act e.g. assaulting someone or solo paragliding without the relevant certification.",
            },
            {
                name: 'War or civil commotion',
                icon: war,
                description:
                    "If you're hurt during an act of war, whether declared or not, hostilities, civil commotion, terrorism or insurrection.",
            },
        ],
        subtext: [`That's it, we swear.`],
    },
    destinations: {
        id: 'destinations',
        title: `Cover for when you're overseas.`,
        description:
            '<strong>Flip Roaming</strong> is accidental injury insurance to cover you while you&apos;re adventuring outside of Australia or NZ in a covered destination.',
        locations: {
            title: 'Covered destinations',
            icon: greenCheck,
            list: [
                'Canada',
                'European Union Countries',
                'Indonesia',
                'Fiji',
                'Japan',
                'Norway',
                'Switzerland',
                'United Kingdom',
            ],
        },
        subtext: [
            '<strong>What about New Zealand?</strong><br/>Flip Roaming doesn&apos;t provide cover for accidental injuries in New Zealand. But don&apos;t worry, we cover accidental injuries in New Zealand with our <a href="https://www.getflip.com.au/products/flip-active" target="_blank">Flip Active</a> and <a href="https://www.getflip.com.au/products/flip-kids" target="_blank">Flip Kids</a> cover.',
            '<b>Is your destination missing?</b><br/>Let us know what other countries you&apos;d like to see us cover by email us at <a href="mailto:help@getflip.com.au">help@getflip.com.au</a>.',
        ],
    },
    howItWorks: {
        title: 'Overseas Medical Cash.',
        id: 'howItWorks',
        description:
            '<h2>Claim up to a total of A$20,000 in reimbursements for eligible medical care while abroad.</h2>' +
            'If you get injured and need medical assistance in one of our covered destinations, our Overseas Medical Cash benefit means you can claim back up to A$20,000 in cash.',
        workingSteps: {
            title: 'How it works',
            steps: [
                '<h6>Buy Flip Roaming</h6>',
                '<h6>Get injured overseas</h6><p>Have an Injury that&apos;s caused by an Accident, while your insurance is active and visit a Registered Doctor within 14 days.</p>',
                '<h6>Pay for your overseas medical care</h6><p>You&apos;ll need to pay for your overseas medical services directly. But keep the receipts and medical reports to claim back the costs from Flip.</p>',
                '<h6>Claim your overseas medical care</h6><p>Login to your Flip account on the Flip app.</p><p>Follow the prompts and upload your evidence.</p><p>We approve most claims within 1 business day and will pay you back in cash, straight to your bank account.</p>',
            ],
        },
        additionalNotes: {
            title: 'Some important things to note',
            icon: alert,
            notes: [
                'We don&apos;t cover illness, other travel related costs such as cancellation fees or lost luggage, or the costs of transport back to Australia.',
                'Check the <b>FAQ</b>s or <b>PDS</b> for what counts as eligible Overseas Medical Care.',
                'You need to pay for the medical care first, and claim a reimbursement.',
            ],
        },
        alert: {
            type: AlertTypes.INFO,
            message:
                '<span>Any reference to a <b>Registered Doctor</b> means a medical practitioner or dentist relevant to the Accidental Injury you have suffered, that is registered or licensed to practise medicine or dentistry by the relevant licensing body in the country where they are practising.</span>',
        },
    },
};

const flipRoamingTerms: Record<FlipRoamingPdsVersion, CoverInfo> = {
    [FlipRoamingPdsVersion.V1]: flipRoamingFlip2Terms,
};

export default flipRoamingTerms;
