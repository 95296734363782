import isFuture from 'date-fns/isFuture';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Analytics from '../../analytics/Analytics';
import { CONTEXT_TYPE } from '../../analytics/AnalyticsConstants';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Button } from '../../components/button/Button';
import DateOfBirthInput from '../../components/date-of-birth-input/DateOfBirthInput';
import { Layout } from '../../components/layout/Layout';
import PartnerEventBanner from '../../components/layout/banners/partner-event-banner/PartnerEventBanner';
import useOnboarding from '../../hooks/useOnboarding';
import CustomerService from '../../services/customer-service/CustomerService';
import commonStrings from '../../strings/common';
import onBoardingStrings from '../../strings/onboardingFlow';
import Routes from '../../utils/Routes';
import formatDateToString from '../../utils/formatDateToString';
import isNan from '../../utils/isNan';
import toDate from '../../utils/toDate';
import getDate from '../../validation/getDate';
import isValidDate from '../../validation/isValidDate';

import './DateOfBirth.scss';

// Clone of date of birth page, without min and max age validation
const DateOfBirth: React.FC = () => {
    const { userDetails, setUserDetailsByAttr, accessToken } = useUser();
    const { partnerEvents } = useOpenCovers();
    const history = useHistory();

    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();

    const [isLoading, setIsLoading] = useState(false);

    const { dateOfBirth } = onBoardingStrings;

    const date = toDate(userDetails.dob);

    const [day, setDay] = useState(!isNan(date.day) ? date.day : '');
    const [month, setMonth] = useState(!isNan(date.month) ? date.month : '');
    const [year, setYear] = useState(!isNan(date.year) ? date.year : '');
    const [error, setError] = useState('');

    const isFormatValid = isValidDate(day, month, year);
    const isEmpty = day.length === 0 && month.length === 0 && year.length === 0;
    const isNotEmpty = day.length > 0 && month.length > 0 && year.length === 4;
    const isNotInTheFuture = !isFuture(getDate(day, month, year));
    const isValidDateOfBirth = isNotEmpty && isFormatValid && isNotInTheFuture;

    const handleContinueClick = async () => {
        const dob = new Date(`${year}/${month}/${day}`);
        const formattedDob = formatDateToString(dob);

        // Update global state
        setUserDetailsByAttr('dob', formattedDob);
        Analytics.identify({ birth_date: formattedDob });

        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepCompleted(onboardingStepNumber);
        }

        setIsLoading(true);

        try {
            await CustomerService.updateCustomerProfile({
                accessToken: accessToken!,
                profile: { dateOfBirth: formattedDob },
            });

            // Navigate to next state
            history.push({
                pathname: Routes.GENDER,
                state: { isOnboarding: isOnboardingFlow },
            });
        } catch (err) {
            console.log(err);
            setError(commonStrings.errorSomethingWentWrong);
        } finally {
            setIsLoading(false);
        }
    };

    const handleValidation = () => {
        setError('');
        if (!isNotEmpty) return;

        if (!isFormatValid) {
            setError(dateOfBirth.errorInvalidDate);
        }

        if (!isNotInTheFuture) {
            setError(dateOfBirth.errorFutureDate);
        }
    };

    const hasError = error !== '';

    // only call when we have userDetails loaded
    useEffect(() => {
        // set values if exists userDetails
        if (isEmpty && userDetails.dob !== undefined) {
            setDay(!isNan(date.day) ? date.day : '');
            setMonth(!isNan(date.month) ? date.month : '');
            setYear(!isNan(date.year) ? date.year : '');
        }
    }, [userDetails]);

    useEffect(() => {
        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepViewed(
                onboardingStepNumber,
                undefined,
                undefined,
                undefined,
                partnerEvents.length > 0
                    ? { type: CONTEXT_TYPE.PARTNER_EVENT, partnerEventId: partnerEvents[0].partnerEvent.partnerEventId }
                    : undefined,
            );
        }
    }, [isOnboardingFlow, onboardingStepNumber, partnerEvents]);

    const handleOnBlur = () => {
        handleValidation();
    };

    const handleDayChange = (e: any) => {
        setError('');
        setDay(e.target.value);
        handleValidation();
    };

    const handleMonthChange = (e: any) => {
        setError('');
        setMonth(e.target.value);
        handleValidation();
    };

    const handleYearChange = (e: any) => {
        setError('');
        setYear(e.target.value);
        handleValidation();
    };

    return (
        <Layout
            title={dateOfBirth.title}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
            showLoading={isLoading}
            banner={<PartnerEventBanner />}
        >
            <form>
                <DateOfBirthInput
                    day={day}
                    month={month}
                    year={year}
                    hasError={hasError}
                    onDayChange={handleDayChange}
                    onMonthChange={handleMonthChange}
                    onYearChange={handleYearChange}
                    onBlur={handleOnBlur}
                />
                {hasError && <p className="date-of-birth__error ">{error}</p>}
                <Button
                    label={commonStrings.continue}
                    disabled={!isValidDateOfBirth}
                    width="full"
                    className="date-of-birth__btn-continue"
                    onClick={handleContinueClick}
                />
            </form>
        </Layout>
    );
};

export default DateOfBirth;
