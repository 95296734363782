import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { AddressFields, CountryOptionTypes } from '../../../business-logic/models/Address';
import { Button } from '../../../components/button/Button';
import Dropdown, { OptionType } from '../../../components/dropdown/Dropdown';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import TextField from '../../../components/text-field/TextField';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import { addressToString, stringToAddressFields } from '../../../utils/addressUtils';
import loadGooglePlacesScript from '../../../utils/third-party-dependencies/loadGooglePlacesScript';
import AustralianAddressFields from './form-fields/AustralianAddressFields';
import InternationalAddressFields from './form-fields/InternationalAddressFields';
import JapanNZAddressFields from './form-fields/JapanNZAddressFields';

import './ClaimAccidentLocation.scss';

const ClaimAccidentLocation: React.FC = () => {
    const { setClaimDetailsByAttr, claimDetails } = useClaim();
    const { claimAccidentLocation: contentStrings } = claimsFlowStrings;
    const history = useHistory();

    /**
     * Autocomplete input ref
     */
    const autocompleteInputRef = useRef<HTMLInputElement>(null);

    /**
     * The google autocomplete widget class instance
     * https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete
     */
    const autocompleteInstanceRef = useRef<google.maps.places.Autocomplete>();

    const [useManualLocation, setUseManualLocation] = useState(!!claimDetails.accidentLocation.addressFields);

    /**
     * The google autocomplete result, prefill from claim details if available
     */
    const [autocompleteResult, setAutocompleteResult] = useState<google.maps.places.PlaceResult | undefined>(
        claimDetails.accidentLocation.autocompleteResult || undefined,
    );

    /**
     * Flag to determine if the value in the autocomplete input box is currently valid.
     * This is set to false whenever the user does a change to the input value and on mount
     * if there is no saved autocomplete result in claim details. This is only
     * set to true when the user selects a location using the autocomplete API.
     */
    const [isAutocompleteInputValid, setIsAutocompleteInputValid] = useState(
        !!claimDetails.accidentLocation.autocompleteResult,
    );

    /**
     * The manual address input field values
     */
    const [addressFields, setAddressFields] = useState<AddressFields>(
        claimDetails.accidentLocation.addressFields || {
            address1: '',
            address2: '',
            suburb: '',
            city: '',
            state: null,
            stateProvinceRegion: '',
            postcode: '',
            country: claimDetails.claimType === 'local' ? contentStrings.countryOptions[0] : '',
        },
    );

    /**
     * The prefill value for the autocomplete input box, this is set to the saved autocomplete
     * result from claim details if available, otherwise is set to undefined. This will be set
     * to undefined once the user does any changes to the prefilled value in the autocomplete
     * input box.
     *
     * Undefined is important here as it sets the input box back to an uncontrolled input
     * for the autocomplete widget to do it's job.
     */
    const [autocompletePrefillValue, setAutocompletePrefillValue] = useState(
        claimDetails.accidentLocation.autocompleteResult ? addressToString(claimDetails.accidentLocation) : undefined,
    );

    /**
     * Initialise the autocomplete widget on mount
     */
    const initAutocomplete = () => {
        const autoCompleteRestrictedCountries = claimDetails.claimType === 'local' ? ['au', 'nz'] : []; // overseas claims to autocomplete to all global addresse
        try {
            const autocomplete = new google.maps.places.Autocomplete(autocompleteInputRef.current as HTMLInputElement, {
                componentRestrictions: { country: autoCompleteRestrictedCountries },
                fields: ['formatted_address', 'name'],
            });

            // Handler for the user selection of a place
            autocomplete.addListener('place_changed', () => {
                setAutocompleteResult(autocomplete.getPlace());
                setIsAutocompleteInputValid(true);
            });

            autocompleteInstanceRef.current = autocomplete;
        } catch {
            // If error loading autocomplete library fallback to manual input
            setUseManualLocation(true);
        }
    };

    useEffect(() => {
        // Load Google Places API Client Library
        if (!window.google) {
            loadGooglePlacesScript(initAutocomplete, () => setUseManualLocation(true));
        } else {
            initAutocomplete();
        }

        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_ACCIDENT_LOCATION, claimDetails);
    }, []);

    /**
     * Handle continue click
     */
    const handleContinueClick = () => {
        // Only set either the address field or autocomplete result as we will use whichever is set to determine
        // if we show the manual or autocomplete input fields if the user returns to this page with prefilled values
        setClaimDetailsByAttr('accidentLocation', useManualLocation ? { addressFields } : { autocompleteResult });
        // TODO: Remove this when BE is updated to no longer have this field
        setClaimDetailsByAttr('didAccidentHappenInAustralia', true);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_ACCIDENT_LOCATION, claimDetails);

        history.push(Routes.SELECT_INJURY);
    };

    const handleUseManualLocation = () => {
        if (window.google && autocompleteInstanceRef.current) {
            // unbind listener events
            autocompleteInstanceRef.current?.unbindAll();
            // Can't quite find out the right type to use here (google library things)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            google.maps.event.clearInstanceListeners(autocompleteInputRef.current!);
        }
        setUseManualLocation(true);

        // If autocomplete result is present attempt to prefill manual fields
        if (autocompleteResult) {
            setAddressFields(stringToAddressFields(addressToString({ autocompleteResult })));
        }
    };

    const updateAddressFieldValues = (key: keyof AddressFields, value: string | OptionType | null) => {
        setAddressFields((prevState) => ({ ...prevState, [key]: value }));
    };

    const handleAutocompleteChange = () => {
        if (isAutocompleteInputValid) {
            setIsAutocompleteInputValid(false);
        }
        // We want to reset the init value to undefined
        if (autocompletePrefillValue) {
            setAutocompletePrefillValue(undefined);
        }
    };

    const canProceed = useMemo(() => {
        if (claimDetails.claimType === 'local') {
            return (
                (autocompleteResult && isAutocompleteInputValid) ||
                (addressFields.address1 &&
                    addressFields.postcode &&
                    addressFields.postcode.length === 4 &&
                    ((addressFields.state && addressFields.suburb) || addressFields.stateProvinceRegion))
            );
        }
        return (
            (autocompleteResult && isAutocompleteInputValid) ||
            (addressFields.address1 &&
                addressFields.country &&
                (addressFields.city || addressFields.postcode || addressFields.stateProvinceRegion))
        );
    }, [
        addressFields.address1,
        addressFields.city,
        addressFields.country,
        addressFields.postcode,
        addressFields.state,
        addressFields.stateProvinceRegion,
        addressFields.suburb,
        autocompleteResult,
        claimDetails.claimType,
        isAutocompleteInputValid,
    ]);

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_ACCIDENT_LOCATION / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="claim-accident-location__header">{contentStrings.title}</h1>
            <p className="claim-accident-location__description">{contentStrings.description}</p>
            <form>
                <div className="claim-accident-location__manual-section">
                    {!useManualLocation ? (
                        <>
                            <TextField
                                id="autocomplete-input"
                                name={contentStrings.labels.autocompleteInput}
                                label={contentStrings.labels.autocompleteInput}
                                onChange={handleAutocompleteChange}
                                innerRef={autocompleteInputRef}
                                value={autocompletePrefillValue}
                                onKeyDown={(e) => {
                                    // prevent form submit if user press enter to select address
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder=""
                            />
                            <div className="claim-accident-location__separator" />

                            <Button
                                label={contentStrings.labels.addLocationManuallyButton}
                                onClick={handleUseManualLocation}
                                width="full"
                                variant="secondary"
                            />
                        </>
                    ) : (
                        <>
                            {claimDetails.claimType === 'local' ? (
                                <>
                                    <Dropdown
                                        options={contentStrings.countryOptions}
                                        label={contentStrings.labels.country}
                                        value={
                                            addressFields.country as typeof CountryOptionTypes[keyof typeof CountryOptionTypes]
                                        }
                                        className="claim-accident-location__country"
                                        onChange={(value) => {
                                            setAddressFields({
                                                address1: '',
                                                address2: '',
                                                suburb: '',
                                                city: '',
                                                state: null,
                                                stateProvinceRegion: '',
                                                postcode: '',
                                                country: value,
                                            });
                                        }}
                                    />
                                    {(
                                        addressFields.country as typeof CountryOptionTypes[keyof typeof CountryOptionTypes]
                                    )?.value === 'au' ? (
                                        <AustralianAddressFields
                                            addressFields={addressFields}
                                            updateAddressFieldValues={updateAddressFieldValues}
                                        />
                                    ) : (
                                        <JapanNZAddressFields
                                            addressFields={addressFields}
                                            updateAddressFieldValues={updateAddressFieldValues}
                                        />
                                    )}
                                </>
                            ) : (
                                <InternationalAddressFields
                                    updateAddressFieldValues={updateAddressFieldValues}
                                    addressFields={addressFields}
                                />
                            )}
                        </>
                    )}
                </div>
                <Button
                    label={commonStrings.continue}
                    onClick={handleContinueClick}
                    width="full"
                    disabled={!canProceed}
                />
            </form>
        </Layout>
    );
};

export default ClaimAccidentLocation;
