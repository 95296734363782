import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import { UploadDocumentButton } from '../../../components/upload-document-button/UploadDocumentButton';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import Analytics from '../../../analytics/Analytics';
import { ClaimUploadExampleModal } from '../../../components/claim-upload-example-modal/ClaimUploadExampleModal';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import ClaimExamples from '../../../utils/constants/ClaimExamples';
import BulletPointList from '../../../components/bullet-point-list/BulletPointList';

import './ProofOfActivity.scss';

const MedicalReport: React.FC = () => {
    const history = useHistory();
    const { fileUploadTokens, claimDetails, setClaimFilesByAtrr } = useClaim();
    const { proofOfActivity: contentString, allowedFilesLabel } = claimsFlowStrings;

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_PROOF_OF_ACTIVITY, claimDetails);
    }, []);

    const handleClickContinue = () => {
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_PROOF_OF_ACTIVITY, claimDetails);

        history.push(Routes.BANK_DETAILS);
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_PROOF_OF_ACTIVITY / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="proof-of-activity__header">{contentString.title}</h1>
            <p>{contentString.subtitle}</p>
            <BulletPointList list={contentString.itemsToInclude} />
            <ClaimUploadExampleModal claimUploadExamples={ClaimExamples.photoOfAccident} />
            <p className="proof-of-activity__files-allowed">{allowedFilesLabel}</p>
            <UploadDocumentButton
                accessToken={fileUploadTokens.accessToken}
                folderId={fileUploadTokens.folderIds.activity}
                onUploadComplete={(files: Array<any>) => setClaimFilesByAtrr('proofOfActivity', files)}
                uploadedFiles={claimDetails.proofOfActivity}
            />
            <Button
                className="proof-of-activity__btn-continue"
                label={commonStrings.continue}
                width="full"
                disabled={claimDetails.proofOfActivity.length === 0}
                onClick={handleClickContinue}
            />
        </Layout>
    );
};

export default MedicalReport;
