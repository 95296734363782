import { assign } from 'xstate';
import errors from '../../../../../../content/common/errors';
import guestCartContent from '../../../../../../content/ui/screens/guest-cart/guestCart';
import Gender from '../../../../../../utils/constants/Gender';
import ResidencyStatusTypes from '../../../../../../utils/constants/ResidencyStatusTypes';
import State from '../../../../../../utils/constants/State';
import { ProfileBaymaxMachineContext } from '../context/profileBaymaxMachineContext';
import { Typegen0 } from '../profileBaymaxMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setEmail = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setEmail']; data: string }>({
    email: (ctx, event) => event.data,
});

export const setEmailError = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setEmailError'] }>({
    emailError: () => guestCartContent.profileStep.emailError,
});

export const clearEmailError = assign<
    ProfileBaymaxMachineContext,
    | {
          type: Extract<EventsCausingActions['clearEmailError'], 'UPDATE_EMAIL'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearEmailError'], 'UPDATE_EMAIL'>;
      }
>({
    emailError: () => '',
});

export const setFirstName = assign<
    ProfileBaymaxMachineContext,
    { type: EventsCausingActions['setFirstName']; data: string }
>({
    firstName: (ctx, event) => event.data,
});

export const setLastName = assign<
    ProfileBaymaxMachineContext,
    { type: EventsCausingActions['setLastName']; data: string }
>({
    lastName: (ctx, event) => event.data,
});

export const setDate = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setDate']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, day: event.data }),
});

export const setMonth = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setMonth']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, month: event.data }),
});

export const setYear = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setYear']; data: string }>({
    dob: (ctx, event) => ({ ...ctx.dob, year: event.data }),
});

export const setInvalidDateFormatError = assign<
    ProfileBaymaxMachineContext,
    { type: EventsCausingActions['setInvalidDateFormatError'] }
>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: guestCartContent.profileStep.dobError.errorInvalidDate,
    }),
});

export const setMinAgeError = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setMinAgeError'] }>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: guestCartContent.profileStep.dobError.errorMustBeOver18,
    }),
});

export const setMaxAgeError = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setMaxAgeError'] }>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: guestCartContent.profileStep.dobError.errorMustBeUnder65,
    }),
});

export const clearDobError = assign<
    ProfileBaymaxMachineContext,
    | {
          type: Extract<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
      }
>({
    dob: (ctx) => ({ ...ctx.dob, error: '' }),
});

export const setState = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setState']; data: State }>({
    state: (ctx, event) => event.data,
});

export const setResidencyStatus = assign<
    ProfileBaymaxMachineContext,
    { type: EventsCausingActions['setResidencyStatus']; data: ResidencyStatusTypes }
>({
    residencyStatus: (ctx, event) => event.data,
});

export const setGender = assign<ProfileBaymaxMachineContext, { type: EventsCausingActions['setGender']; data: Gender }>(
    {
        gender: (ctx, event) => event.data,
    },
);

export const setGenericError = assign<
    ProfileBaymaxMachineContext,
    { type: EventsCausingActions['setGenericError']; data: unknown }
>({
    genericError: () => errors.errorSomethingWentWrongTryAgain,
});

export const clearGenericError = assign<
    ProfileBaymaxMachineContext,
    { type: EventsCausingActions['clearGenericError'] }
>({
    genericError: () => '',
});
