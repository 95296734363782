import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import ModalWithCTA from '../../../components/modal-with-cta/ModalWithCTA';
import { ModalAlert } from '../../../components/modal-with-cta/utils/ModalWithCTAAlertTypes';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import { UploadDocumentButton } from '../../../components/upload-document-button/UploadDocumentButton';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import parseString from '../../../utils/parseString';

import './OverseasReceipts.scss';

const OverseasReceipts: React.FC = () => {
    const history = useHistory();
    const { fileUploadTokens, claimDetails, setClaimFilesByAtrr } = useClaim();
    const [overseasReceiptsExamplesModal, setOverseasReceiptsExamplesModal] = useState<boolean>(false);
    const { overseasReceipts: contentString, allowedFilesLabel } = claimsFlowStrings;

    const handleClickContinue = () => {
        history.push(Routes.MEDICAL_REPORT);
    };

    const renderOverseasReceiptsExamples = () => {
        return (
            <div className="overseas-receipts__example-modal">
                <h3>{contentString.conditions.title}</h3>
                <div className="overseas-receipts__example-modal__list">
                    {contentString.conditions.items.map((condition: string) => (
                        <div key={condition} className="overseas-receipts__example-modal__list__item">
                            <img
                                src={contentString.conditions.icon}
                                alt=""
                                className="overseas-receipts__example-modal__list__item__icon"
                            />
                            <span>{parseString(condition)}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_OVERSEAS_RECEIPTS / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="overseas-receipts__header">{contentString.title}</h1>
            <strong>{contentString.instruction}</strong>
            <p>{contentString.description}</p>
            <Button
                variant="link"
                className="overseas-receipts__modal-btn"
                label={contentString.costsLabel}
                onClick={() => setOverseasReceiptsExamplesModal(true)}
            />
            <p className="overseas-receipts__files-allowed">{allowedFilesLabel}</p>
            <UploadDocumentButton
                accessToken={fileUploadTokens.accessToken}
                folderId={fileUploadTokens.folderIds.receipts}
                onUploadComplete={(files: Array<any>) => setClaimFilesByAtrr('receipts', files)}
                uploadedFiles={claimDetails.receipts}
            />
            <Button
                className="overseas-receipts__btn-continue"
                label={commonStrings.continue}
                width="full"
                disabled={claimDetails.receipts.length === 0}
                onClick={handleClickContinue}
            />
            <ModalWithCTA
                icon={ModalAlert.INFO}
                isOpen={overseasReceiptsExamplesModal}
                textContent={renderOverseasReceiptsExamples()}
                primaryCTA={{
                    label: commonStrings.ok,
                    onClick: () => setOverseasReceiptsExamplesModal(false),
                }}
            />
        </Layout>
    );
};

export default OverseasReceipts;
