import React, { RefObject } from 'react';
import classNames from 'classnames';
import commonStrings from '../../strings/common';
import './TextArea.scss';

export interface TextAreaProps {
    id?: string;
    innerRef?: RefObject<HTMLTextAreaElement>;
    name: string;
    label?: string;
    isError?: boolean;
    error?: string;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    maxLength?: number;
    showCharsRemaining?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({
    id,
    innerRef,
    name,
    label,
    placeholder,
    value,
    isError,
    error,
    disabled,
    onChange,
    onFocus,
    onBlur,
    className,
    maxLength = 600,
    showCharsRemaining,
}) => {
    const charCount = value?.length || 0;

    return (
        <div className={classNames('text-area', className)}>
            {label && (
                <label className={classNames('text-area__label', disabled && 'disabled')} htmlFor={id}>
                    {label}
                </label>
            )}
            <textarea
                id={id}
                ref={innerRef}
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                maxLength={maxLength}
                className={classNames('text-area__input', disabled && 'disabled', isError && 'error')}
            />
            {showCharsRemaining && (
                <p className="text-area__chars-remaining">
                    {maxLength - charCount} {commonStrings.charsRemaining}
                </p>
            )}
            {isError && error && <span className="text-area__error">{error}</span>}
        </div>
    );
};

export default TextArea;
