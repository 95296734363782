import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import parseISO from 'date-fns/parseISO';

import { CoverResponse, InvoiceItem } from '../../../../business-logic/models/CheckoutDetails';
import { ListItemProps } from '../../../../components/list-item/ListItem';
import formatDateToString from '../../../../utils/formatDateToString';
import formatPrice from '../../../../utils/formatPrice';
import PriceLineItem from '../price-line-item/PriceLineItem';

export default (item: CoverResponse, invoiceItem: InvoiceItem, userTimeZone: string): ListItemProps => {
    return {
        id: item.insuranceCoverId,
        header: invoiceItem.description,
        customDataElement: (
            <PriceLineItem
                description={formatDateToString(utcToZonedTime(parseISO(item.startTime), userTimeZone))}
                amount={formatPrice(invoiceItem.amount)}
                withTopSpacing
            />
        ),
    };
};
