import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import { ClaimUploadExampleItem, ClaimUploadExampleItemProps } from './ClaimUploadExampleItem/ClaimUploadExampleItem';
import { Button } from '../button/Button';
import { ReactComponent as CloseIcon } from '../../assets/images/close-white.svg';
import claimsFlowStrings from '../../strings/claimsFlow';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClaimUploadExampleModal.scss';

export interface ClaimUploadExampleModalProps {
    claimUploadExamples: ClaimUploadExampleItemProps[];
}

export const ClaimUploadExampleModal: React.FC<ClaimUploadExampleModalProps> = ({ claimUploadExamples }) => {
    // binding modal to appElement (https://reactcommunity.org/react-modal/accessibility/)
    useEffect(() => Modal.setAppElement('#root'));

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);

    const closeModal = () => setIsOpen(false);

    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        autoplaySpeed: 5000,
    };

    const { claimUploadExampleModal } = claimsFlowStrings;

    return (
        <>
            <Button variant="link" label={claimUploadExampleModal.seeFewExamples} onClick={openModal} />
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="claim-upload-example-modal"
                overlayClassName="claim-upload-example-modal__overlay"
            >
                <button type="button" onClick={closeModal} className="claim-upload-example-modal__btn-close">
                    <CloseIcon />
                </button>
                <Slider {...sliderSettings}>
                    {claimUploadExamples.map((example) => (
                        <ClaimUploadExampleItem
                            key={example.id}
                            id={example.id}
                            title={example.title}
                            description={example.description}
                            image={example.image}
                            rating={example.rating}
                        />
                    ))}
                </Slider>
            </Modal>
        </>
    );
};

export default ClaimUploadExampleModal;
