import { datadogRum } from '@datadog/browser-rum';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Alert from '../../../components/alert/Alert';
import ErrorMessages from '../../../components/alert/error-messages/ErrorMessages';
import { Button } from '../../../components/button/Button';
import ClaimDetailsList from '../../../components/claim-details-list/ClaimDetailsList';
import { Layout } from '../../../components/layout/Layout';
import { LoadingSpinnerOverlay } from '../../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import ClaimsService from '../../../services/ClaimsService';
import CustomerService from '../../../services/customer-service/CustomerService';
import claimsFlowStrings from '../../../strings/claimsFlow';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';

import './ReviewClaim.scss';

const ReviewClaim: React.FC = () => {
    const { reviewClaim } = claimsFlowStrings;

    const {
        claimDetails,
        claimDetails: {
            claimType,
            date,
            time,
            accidentLocation,
            injuries,
            accidentDescription,
            medicalReport,
            receipts,
            witnesses,
            hasWitnessesPermission,
            hasSigned,
            didAccidentHappenInAustralia,
            payeeBankDetails: { accountName, accountNumber, bsb },
        },
        fileUploadTokens,
        clearCurrentClaimDetails,
    } = useClaim();

    // TODO: Revise this implementation upon Claims 2.0
    const isAustraliaNewZealandClaim = claimType !== undefined && claimType === 'local';

    const { accessToken, setUserBankDetails } = useUser();
    const history = useHistory();

    const [submitClaimError, setSubmitClaimError] = useState<string | ReactNode>('');
    const [isLoading, setIsLoading] = useState(false);

    // only look at mandatory fields
    const dateError = date.length === 0;
    const timeError = time.length === 0;
    const accidentDescriptionError = accidentDescription.length === 0;
    const accidentLocationError = !accidentLocation.addressFields && !accidentLocation.autocompleteResult;
    const injuriesError = injuries.length === 0;
    const medicalError = medicalReport.length === 0;
    const receiptsError = claimType === 'overseas' && receipts.length === 0;
    const accountError = accountName.length === 0;
    const accountNumberError = accountNumber.length === 0;
    const bsbError = bsb.length === 0;
    const didAccidentHappenInAustraliaError = didAccidentHappenInAustralia === false;
    // if there's a witness we must have permission
    const witnessError = witnesses.length !== 0 && !hasWitnessesPermission;
    const hasSignedError = hasSigned === false;

    const { current: areAnyClaimDetailsMissing } = useRef(
        dateError ||
            timeError ||
            accidentDescriptionError ||
            accidentLocationError ||
            injuriesError ||
            receiptsError ||
            medicalError ||
            accountError ||
            accountNumberError ||
            bsbError ||
            didAccidentHappenInAustraliaError ||
            witnessError ||
            hasSignedError,
    );

    useEffect(() => {
        if (areAnyClaimDetailsMissing) {
            datadogRum.addError(new Error('Missing claims info'), {
                data: {
                    errors: {
                        dateError,
                        timeError,
                        accidentDescriptionError,
                        accidentLocationError,
                        injuriesError,
                        medicalError,
                        accountError,
                        accountNumberError,
                        bsbError,
                        didAccidentHappenInAustraliaError,
                        witnessError,
                        hasSignedError,
                    },
                },
            });
            setSubmitClaimError(reviewClaim.errorInfoMissing);
        }
    }, [
        accidentDescriptionError,
        accidentLocationError,
        accountError,
        accountNumberError,
        areAnyClaimDetailsMissing,
        bsbError,
        claimDetails,
        dateError,
        didAccidentHappenInAustraliaError,
        hasSignedError,
        injuriesError,
        medicalError,
        reviewClaim.errorInfoMissing,
        timeError,
        witnessError,
    ]);

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_REVIEW_CLAIM, claimDetails);
    }, []);

    const handleSubmitClaim = async () => {
        setIsLoading(true);

        try {
            await Promise.all([
                ClaimsService.submitClaim(accessToken, claimDetails),
                CustomerService.updateCustomerBankAccount({
                    accessToken: accessToken!,
                    bankAccount: claimDetails.payeeBankDetails,
                }),
            ]);
            // store bank details in state, it will also be stored in the BE
            setUserBankDetails(accountName, accountNumber, bsb);

            clearCurrentClaimDetails();

            // potentially rerendering here just before the analytics
            Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_REVIEW_CLAIM, claimDetails);
            Analytics.trackClaimSubmitted(claimDetails);

            setIsLoading(false);
            if (!areAnyClaimDetailsMissing) {
                history.push(Routes.CLAIM_SUBMITTED);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setSubmitClaimError(ErrorMessages.refreshOrComeback);
        }
    };

    return (
        <Layout>
            {isLoading && <LoadingSpinnerOverlay />}
            <ProgressBar completed={(Constants.CLAIMS_STEP_REVIEW_CLAIM / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            <h1 className="review-claim__header">{reviewClaim.title}</h1>
            <ClaimDetailsList
                claimDetails={claimDetails}
                fileUploadTokens={fileUploadTokens}
                isAustraliaNewZealandClaim={isAustraliaNewZealandClaim}
            />
            {!!submitClaimError && <Alert message={submitClaimError} className="review-claim__alert" />}
            <Button
                className={classNames('review-claim__btn-continue', {
                    'review-claim__btn-continue--error': !!submitClaimError,
                })}
                label={reviewClaim.buttonLabel}
                width="full"
                onClick={handleSubmitClaim}
                disabled={areAnyClaimDetailsMissing}
            />
        </Layout>
    );
};

export default ReviewClaim;
