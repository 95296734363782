import React from 'react';
import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import quizFlow from '../../../strings/quizFlow';
import Routes from '../../../utils/Routes';
import useCustomHistory from '../hooks/useCustomHistory';
import Analytics from '../../../analytics/Analytics';
import './OnboardingQuiz.scss';

const OnboardingQuiz: React.FC = () => {
    const { navigate } = useCustomHistory();
    const handleGetStartedClick = () => {
        Analytics.trackOnboardingQuizStarted();
        navigate(Routes.ONBOARDING_QUIZ_INJURY);
    };

    return (
        <Layout isContentCentered bgPattern={false} showPdsVersion={false} title={quizFlow.onboarding.title}>
            <p className="onboarding-quiz__description">{quizFlow.onboarding.description}</p>
            <div>
                <Button
                    variant="primary"
                    label={quizFlow.onboarding.getStarted}
                    width="full"
                    onClick={handleGetStartedClick}
                />
            </div>
        </Layout>
    );
};

export default OnboardingQuiz;
