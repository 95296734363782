import { Policy } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { MainCoverPdsVersion } from '../../../../utils/constants/PdsVersion';

const groupByMainCoverCoverCode = (policies: Policy[]): Policy[][] => {
    const policiesReduced = policies.reduce((acc, cur) => {
        if (acc[cur.mainCover.coverCode]) {
            acc[cur.mainCover.coverCode].push(cur);
        } else {
            acc[cur.mainCover.coverCode] = [cur];
        }

        return acc;
    }, {} as Record<string, Policy[]>);

    return Object.entries(policiesReduced)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([, coversOfSameCoverCode]) => coversOfSameCoverCode);
};

const groupByPds = (policies: Policy[]): Policy[][] => {
    const singleCoversReduced = policies.reduce((acc, cur) => {
        if (acc[cur.PDSVersion]) {
            acc[cur.PDSVersion].push(cur);
        } else {
            acc[cur.PDSVersion] = [cur];
        }

        return acc;
    }, {} as Record<MainCoverPdsVersion, Policy[]>);

    return Object.entries(singleCoversReduced)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([, coversOfSamePds]) => coversOfSamePds);
};

const groupByCoverCodeAndPds = (policies: Policy[]): Policy[][][] => {
    // Group by cover code
    const policiesGroupedByCoverCode = groupByMainCoverCoverCode(policies);

    // Then within each group, group by PDS version
    const policiesGroupedByCoverCodeAndPds = policiesGroupedByCoverCode.map(groupByPds);

    return policiesGroupedByCoverCodeAndPds;
};

export default groupByCoverCodeAndPds;
