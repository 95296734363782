import React, { useEffect } from 'react';
import { Layout } from '../../../components/layout/Layout';
import quizFlow from '../../../strings/quizFlow';
import QuizRadio from '../components/quiz-radio/QuizRadio';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Routes from '../../../utils/Routes';
import Analytics from '../../../analytics/Analytics';
import useCustomHistory from '../hooks/useCustomHistory';

const Motorcycling: React.FC = () => {
    const { trackOnboardingQuizAnswer } = Analytics;
    const { navigate } = useCustomHistory();

    const {
        setQuizAnswer,
        onboardingQuizAnswers: { workAccidents, existingInjury, motorcycling },
    } = useUser();

    useEffect(() => {
        if (typeof workAccidents === 'undefined') {
            navigate(Routes.ONBOARDING_QUIZ);
        }
    }, [workAccidents, navigate]);

    const onChangeHandler = (value: boolean) => {
        setQuizAnswer({ motorcycling: value });
        trackOnboardingQuizAnswer('motorcycling', value ? 'yes' : 'no');

        setTimeout(() => {
            if (value || workAccidents || existingInjury) {
                navigate(Routes.ONBOARDING_QUIZ_RESULT_EXCLUSIONS);
            } else {
                navigate(Routes.ONBOARDING_QUIZ_RESULT_SIGNUP);
            }
        }, 300);
    };

    return (
        <Layout
            isContentCentered
            bgPattern={false}
            showPdsVersion={false}
            currentStepCount={3}
            totalStepCount={3}
            title={quizFlow.motorcycling.title}
        >
            <QuizRadio onChange={onChangeHandler} value={motorcycling} />
        </Layout>
    );
};

export default Motorcycling;
