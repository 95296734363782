import Cover from '../../../utils/constants/Cover';
import AlwaysOnIcon from '../../../assets/images/cover/always-on.svg';
import { ProductInfo } from './types';
import { FlipActiveSubMonthlyPdsVersion } from '../../../utils/constants/PdsVersion';

const flipActiveSubMonthlyV1: ProductInfo = {
    id: Cover.FLIP_ACTIVE_SUB_MONTHLY,
    icon: AlwaysOnIcon,
    name: 'Flip Active Subscription',
    group: 'Flip Active',
    price: '$40',
    priceTimePeriod: 'month',
    priceTimePeriodDescription: 'Recurring monthly',
    description: 'Ongoing cover for peace of mind',
    primaryFeatures: ['Monthly subscription, cancel anytime', 'Boost for $7 per day to double your payout for the day'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'Subscription',
    coverUnitDuration: 'Subscription',
    paymentModelDescription: 'Monthly subscription, cancel anytime',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipActiveSubMonthlyProductInfo: Record<FlipActiveSubMonthlyPdsVersion, ProductInfo> = {
    [FlipActiveSubMonthlyPdsVersion.V1]: flipActiveSubMonthlyV1,
    [FlipActiveSubMonthlyPdsVersion.V1_KIDS]: flipActiveSubMonthlyV1,
    [FlipActiveSubMonthlyPdsVersion.FLIP_2_0]: flipActiveSubMonthlyV1,
};

export default flipActiveSubMonthlyProductInfo;
