import { FC, FormEvent, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { CartErrorDisplay } from '../../../../business-logic/models/Cart';
import CoverSelection from '../../../../business-logic/models/CoverSelection';
import Button from '../../../../components/button/Button';
import CartError from '../../../../components/cart-error/CartError';
import CoverSummaryCard from '../../../../components/cover-summary-card/CoverSummaryCard';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import ReviewCoverFinePrint from '../../../../components/review-cover-fine-print/ReviewCoverFinePrint';
import Sticky from '../../../../components/sticky/Sticky';
import guestCartContent from '../../../../content/ui/screens/guest-cart/guestCart';
import withContent from '../../../../hoc/with-content/withContent';
import useCart from '../../../../hooks/use-cart/useCart';
import Cover from '../../../../utils/constants/Cover';
import formatPrice from '../../../../utils/formatPrice';
import getProductInfo from '../../../../utils/getProductInfo';

import './ReviewCoverStep.scss';

const contentMap = {
    benefitsCTA: 'ui.reviewCoverStep.benefitsCTA',
    exclusionsCTA: 'ui.reviewCoverStep.exclusionsCTA',
    ctaLabel: 'ui.reviewCoverStep.ctaLabel',
    coverSummaryTitle: 'ui.reviewCoverStep.coverSummaryTitle',
    subtotal: 'ui.reviewCoverStep.subtotal',
    total: 'ui.reviewCoverStep.total',
};

interface ReviewCoverStepProps {
    content: Record<keyof typeof contentMap, string>;
    coverSelections: CoverSelection[];
    onContinueToPayment: () => void;
}

const ReviewCoverStep: FC<ReviewCoverStepProps> = ({ content, coverSelections, onContinueToPayment }) => {
    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onContinueToPayment();
    };

    const coverSelectionsGroupedByCover = coverSelections.reduce<Record<Cover, CoverSelection[]>>(
        (acc, cur) => {
            acc[cur.selectedCover].push(cur);
            return acc;
        },
        {
            [Cover.BOOST]: [],
            [Cover.DAY_PASS]: [],
            [Cover.ALWAYS_ON]: [],
            [Cover.JAPAN_PASS]: [],
            [Cover.FLIP_ACTIVE_DAILY]: [],
            [Cover.FLIP_ACTIVE_WEEKLY]: [],
            [Cover.FLIP_ACTIVE_SUB_MONTHLY]: [],
            [Cover.FLIP_KIDS_DAILY]: [],
            [Cover.FLIP_KIDS_WEEKLY]: [],
            [Cover.FLIP_KIDS_SUB_MONTHLY]: [],
            [Cover.FLIP_ACTIVE_BOOST]: [],
            [Cover.FLIP_ROAMING_WEEKLY]: [],
            [Cover.FLIP_ROAMING_KIDS_WEEKLY]: [],
        },
    );

    const total = useMemo(
        () =>
            Object.entries(coverSelectionsGroupedByCover)
                .filter(([, selections]) => selections.length > 0)
                .reduce((acc, [cover, selections]) => {
                    const selectedCoverInformation = getProductInfo(cover as Cover);
                    const numericPrice = parseInt(selectedCoverInformation.price.match(/\$(\d*)/)![1], 10) * 100;

                    return acc + selections.length * numericPrice;
                }, 0),
        [coverSelectionsGroupedByCover],
    );

    const { createGuestCart } = useCart();
    const history = useHistory();

    useEffect(() => {
        createGuestCart.mutate(coverSelections);
    }, []);

    const cartError = createGuestCart.isError ? (createGuestCart.error as Error).cause : null;

    if (createGuestCart.isLoading || createGuestCart.isPaused) {
        return <LoadingSpinner />;
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <div>
                {Object.values(coverSelectionsGroupedByCover)
                    .filter((selections) => selections.length > 0)
                    .map((selections) => (
                        <div key={selections[0].selectedCover} className="review-cover-step__cover-summary-card">
                            <CoverSummaryCard coverSelections={selections} />
                        </div>
                    ))}
                <p className="review-cover-step__total">
                    {content.total}
                    <span className="review-cover-step__total-amount">{formatPrice(total)}</span>
                </p>
            </div>
            <ReviewCoverFinePrint ctaLabel={content.ctaLabel} />
            <Sticky>
                {!!cartError && (
                    <CartError
                        cartError={cartError as CartErrorDisplay}
                        tryAgainEvent={() => createGuestCart.mutate(coverSelections)}
                        prevStepEvent={() => history.goBack()}
                    />
                )}
                <div>{!!cartError === false && <Button type="submit" width="full" label={content.ctaLabel} />}</div>
            </Sticky>
        </form>
    );
};

export default withContent(ReviewCoverStep, contentMap, guestCartContent);
