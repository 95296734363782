import { CoverInfo, CoverInfoV1, CoverInfoV3 } from './types';

import greenCheck from '../../../assets/images/deep-green-check.svg';
import alcoholDrugs from '../../../assets/images/not-covered/alcohol-drugs.svg';
import dental from '../../../assets/images/not-covered/dental.svg';
import illegalActGrogu from '../../../assets/images/not-covered/illegal-act-grogu.svg';
import injuryChild from '../../../assets/images/not-covered/injury-child.svg';
import medical from '../../../assets/images/not-covered/medical.svg';
import motorcycling from '../../../assets/images/not-covered/motorcycling.svg';
import nonSportsWork from '../../../assets/images/not-covered/non-sports-work.svg';
import overuseGrogu from '../../../assets/images/not-covered/overuse-grogu.svg';
import preExisting from '../../../assets/images/not-covered/pre-existing.svg';
import suicide from '../../../assets/images/not-covered/suicide.svg';
import travelOverseas from '../../../assets/images/not-covered/travel-overseas.svg';
import war from '../../../assets/images/not-covered/war.svg';

import dentalIcon from '../../../assets/images/injuries/dental-with-pattern.svg';
import dislocationIcon from '../../../assets/images/injuries/dislocation-with-pattern.svg';
import paraplegiaIcon from '../../../assets/images/injuries/paraplegia-with-pattern.svg';
import { AlertTypes } from '../../../components/alert/Alert';
import { FlipKidsDailyPdsVersion } from '../../../utils/constants/PdsVersion';

const flipKidsV1Terms: CoverInfoV1 = {
    coverTermsVersion: '1',
    whatsCovered: {
        description:
            "If you have an accident and suffer a specified injury as a result while your insurance is active, here's what you get.",
        coverTiers: [
            {
                title: 'Injuries that don’t need surgery',
                icon: dislocationIcon,
                payoutAmount: '$200',
                boostPayoutAmount: '$400',
                description: 'For things like sprains, strains and minor dental injuries.',
                descriptionLines: [
                    '<strong>Injuries</strong> to muscles, joints & bones that happen suddenly.',
                    '<strong>Wounds requiring stitches.</strong>',
                    '<strong>Minor dental</strong> injuries.',
                ],
                conditionsDescription: 'Minor injuries include:',
                includedConditions: [
                    {
                        description:
                            'Sprains and strains to muscles, ligaments, tendons, or cartilage that happen suddenly (not from overuse or wear and tear)',
                    },
                    { description: 'Wounds requiring stitches or ongoing care' },
                    {
                        description: 'Minor dental injuries, which means chipped or knocked (concussed) tooth',
                    },
                    {
                        description: 'Broken bones and dislocated joints that don’t require surgery.',
                    },
                ],
            },
            {
                title: 'Injuries that do need surgery',
                icon: dentalIcon,
                payoutAmount: '$2,000',
                boostPayoutAmount: '$4,000',
                description: 'For things like surgery or major dental.',
                conditionsDescription: 'Our surgery or major dental cover includes:',
                descriptionLines: [
                    'Like a <strong>torn ACL</strong> that needs reconstruction or a <strong>broken bone</strong> that needs pins or rods.',
                    '<strong>Major dental</strong> from a cracked, knocked out or displaced tooth:',
                ],
                includedConditions: [
                    { description: 'Surgery in a hospital for any physical injury' },
                    {
                        description:
                            'The following dental treatments because of a cracked, knocked out or displaced tooth:',
                        items: ['Crown work', 'Bridge work', 'Implants', 'Dentures', 'Splints', 'Root canal'],
                    },
                ],
            },
            {
                title: 'Significant loss or disability',
                icon: paraplegiaIcon,
                payoutAmount: '$20,000',
                boostPayoutAmount: '$40,000',
                description: 'For really serious injuries like significant loss or disability.',
                conditionsDescription: 'Our significant loss or disability cover is for:',
                descriptionLines: [
                    'Like <strong>traumatic brain</strong> or <strong>spinal injuries.</strong>',
                    "The loss of <strong>limbs, sight or hearing that can't be corrected.</strong>",
                ],
                includedConditions: [
                    {
                        description:
                            "Traumatic brain injury that results in the inability to move two or more limbs (arms or legs) that's persistent 1 month after the injury",
                    },
                    {
                        description:
                            "Traumatic spinal cord injury that results in the inability to move two or more limbs (arms or legs) that's persistent 1 month after the injury",
                    },
                    {
                        description: 'An injury that results in the removal of a hand, arm, foot or leg',
                    },
                    {
                        description:
                            "An injury that has caused a reduction in eyesight to below the Australian legal driving limit, that can't be corrected even with glasses",
                    },
                    {
                        description:
                            "An injury that has caused severe hearing loss in both ears that can't be corrected even with hearing aids",
                    },
                ],
            },
        ],
        additionalNotes: [
            'You get one payout per accident (if you get multiple injuries in the one accident, you get the highest payout, once).',
            'Accident prone? No stress. You can keep claiming for separate accidents.',
            "For all Specified Injuries, you'll need to see the relevant Health Professional no later than 14 days after your Accident. If your injury needs any of the surgery or dental treatment listed above, you'll also need to have this done within 6 months after your Accident.",
        ],
    },
    whatsNotCovered: {
        description: "We won't pay claims that are a result of",
        exclusions: [
            {
                name: 'Motorcycling',
                icon: motorcycling,
                description: "If you're riding a motorbike when your injury happens.",
            },
            {
                name: 'Non sports related work',
                icon: nonSportsWork,
                description:
                    "Paid work or employment isn't covered (e.g. food delivery rider), except when the paid work or employment is a sports related activity (e.g. tennis coaching).",
            },
            {
                name: 'Overuse injuries',
                icon: overuseGrogu,
                description:
                    "We only cover acute injuries caused by accidents, i.e. they happen all of a sudden. Any overuse injury, like shin splints, runners knee and tennis elbow aren't covered.",
            },
            {
                name: 'Pre-existing injuries or conditions',
                icon: preExisting,
                description:
                    "If you have an accident that's caused by an existing condition or injury, or if you're claiming for an injury you got before your insurance was turned on.",
            },
            {
                name: 'Dental injury when eating or drinking',
                icon: dental,
                description:
                    'Dental injuries caused by food or drink including any foreign object in it while being consumed.',
            },
            {
                name: 'Travel outside of Aus. or N.Z.',
                icon: travelOverseas,
                description: "If you're not in Australia or New Zealand when your injury happens.",
            },
            {
                name: 'Medical stuff',
                icon: medical,
                description:
                    'Injuries resulting from accidents caused by medical conditions or medical episodes,  eg. you suffer a specified injury that occurs as a result of fainting, or a dislocated shoulder caused by chronic shoulder instability or a joint injury caused by osteoarthritis.',
            },
            {
                name: 'Booze and drugs',
                icon: alcoholDrugs,
                description:
                    'Being under the influence of alcohol, taking illicit drugs, or taking medication in a way not as directed. ',
            },
            {
                name: 'Intentional self-injury',
                icon: suicide,
                description:
                    "If you hurt yourself on purpose, that's not covered. For example, popping your own shoulder out to prove to your mates you can.",
            },
            {
                name: 'Injury to a child caused by a parent',
                icon: injuryChild,
                description: 'Injury to a child caused by a parent, guardian or someone living with the child.',
            },
            {
                name: 'Breaking the law',
                icon: illegalActGrogu,
                description:
                    "If you're injured while involved in an illegal act, you're not covered.\nInvolvement in an illegal act e.g. riding an e-scooter in public spaces in NSW, assaulting someone, solo paragliding without the relevant certification or flying within a restricted airspace.",
            },
            {
                name: 'War or civil commotion',
                icon: war,
                description:
                    "If you're hurt during an act of war, whether declared or not, hostilities, civil commotion, terrorism or insurrection.",
            },
        ],
    },
};

const flipKidsFlip2_0Terms: CoverInfoV3 = {
    coverTermsVersion: '3',
    mainMenu: {
        id: 'mainMenu',
        title: 'Flip Kids',
        description:
            'Accidental injury insurance for 5-17 year olds. Parents or legal guardians can buy cover on behalf of their kids, for peace of mind for the kids&apos; most active days.',
        pricePerTimePeriod: '$7 a day',
        groupPrice: '$7 a day',
        links: [
            {
                target: 'whatsCovered',
                label: 'Up to A$50,000 cash payouts on approved claims. Check the benefits.',
            },
            {
                target: 'whatsNotCovered',
                label: `Cover while doing almost any activity. Check the list of what's not covered.`,
            },
            {
                target: 'howItWorks',
                label: 'Get injured. Get cash. Get better. See how it works.',
            },
        ],
    },
    howItWorks: {
        title: 'How it works?',
        id: 'howItWorks',
        description:
            '<h2>Get Flip. Get injured. Get cash. Get better.</h2>' +
            'We wanted to create insurance products that are easy to understand and simple to use. Here&apos;s how to get the most out of Flip.',
        workingSteps: {
            steps: [
                '<h6>Buy a Flip product</h6>',
                '<h6>Get injured</h6><p>Have an Injury that&apos;s caused by an Accident, while your insurance is active.</p>',
                '<h6>Gather evidence</h6><p>See a Health Professional (e.g. a doctor) within 14 days to diagnose your injury.</p><p>Get them to confirm you meet one or more covered benefits within 6 months.</p><p>Get evidence of the accident e.g. date, time and location.</p>',
                '<h6>Submit a digital claim</h6><p>Login to your Flip account.</p><p>Follow the prompts and upload your evidence.</p><p>We approve most claims within 1 business day.</p>',
                '<h6>Get cash</h6><p>Cash straight to your bank account on approved claims to spend how you choose</p>',
            ],
        },
        additionalNotes: {
            title: 'Get the most out of your claim',
            icon: greenCheck,
            notes: [
                'For one Accident, you can qualify for multiple benefit types.',
                'You can claim each benefit type once per Accident.',
                'You can receive up to A$50,000 in payouts.',
            ],
        },
        alert: {
            type: AlertTypes.INFO,
            message:
                '<span>Any reference to a <b>Registered Doctor</b> means a medical practitioner or dentist relevant to the Accidental Injury you have suffered, that is registered or licensed to practise medicine or dentistry by the relevant licensing body in the country where they are practising.</span>',
        },
    },
    whatsCovered: {
        title: 'Cash to help you recover',
        description:
            '<strong>Flip Kids</strong> is accidental injury insurance.\n\n' +
            'We pay you cash directly to your bank account on approved claims, to spend however you want.' +
            '<h2>Here is what you get...</h2>' +
            'If a Health Professional confirms that your accidental injury is of a severity that requires any of the following:',
        payoutCategories: [
            {
                payouts: [
                    {
                        injurySeverity: 'Diagnosis by an X-ray or ultrasound',
                        payoutAmount: '$100',
                    },
                    {
                        injurySeverity: 'Diagnosis by an MRI or CT scan',
                        payoutAmount: '$100',
                    },
                    {
                        injurySeverity: 'At least 2 physio* sessions',
                        payoutAmount: '$150',
                    },
                    {
                        injurySeverity: 'At least 4 physio* sessions (Paid in addition to the A$150 benefit above)',
                        payoutAmount: '$150',
                    },
                    {
                        injurySeverity: 'Specialist care',
                        payoutAmount: '$100',
                    },
                    {
                        injurySeverity: 'A cast, brace or splint',
                        payoutAmount: '$100',
                    },
                    {
                        injurySeverity: 'Urgent treatment in a hospital emergency department',
                        payoutAmount: '$200',
                    },
                    {
                        injurySeverity: 'Inpatient hospital care for 2 or more nights',
                        payoutAmount: '$1,000',
                    },
                    {
                        injurySeverity: 'Surgery in hospital',
                        payoutAmount: '$2,000',
                    },
                    {
                        injurySeverity: 'Major dental (root canal, crown or bridge work, implants, dentures or splint)',
                        payoutAmount: '$2,000',
                    },
                    {
                        injurySeverity: 'Care in an intensive care unit (ICU)',
                        payoutAmount: '$5,000',
                    },
                    {
                        injurySeverity: 'Continuous ventilatory support in a hospital for 3 or more nights',
                        payoutAmount: '$15,000',
                    },
                ],
            },
            {
                categoryName: 'Additional specific injury payouts',
                payouts: [
                    {
                        injurySeverity:
                            'A traumatic brain or spinal cord injury requiring care in an intensive care unit (ICU) for 5 or more nights',
                        payoutAmount: '$25,000',
                    },
                    {
                        injurySeverity:
                            'A spinal cord injury that results in an inability to move two or more limbs that is persistent 1 month after the injury',
                        payoutAmount: '$50,000',
                    },
                    {
                        injurySeverity: 'The removal of a hand, arm, foot or leg',
                        payoutAmount: '$50,000',
                    },
                ],
            },
        ],
        subtext: [
            'For one Accident, you can qualify for multiple benefit types.',
            'You can claim each benefit type once per Accident and you can receive up to $50,000 in payouts.',
            '<small><strong>*Reminder:</strong> A registered medical practitioner in Aus or NZ (e.g. a doctor) must confirm that your injury is of a severity that requires a certain amount of physiotherapy.</small>',
        ],
        triggerDrawer: {
            id: 'howItWorks',
            label: 'More info about how it works',
        },
    },
    whatsNotCovered: {
        title: 'We cover you while doing most activities',
        description:
            "We're all for having fun and leaving comfort zones. That's why we cover you while doing nearly any activity, from skiing to chess playing." +
            "<h2>What's the catch?</h2>" +
            "There are some basic things we don't cover, like illegal activities or accidents that happen before your insurance starts.<br /><br />" +
            "But if your kids' activity doesn't match anything on this list, that means it's covered.",
        exclusions: [
            {
                name: 'Motorcycling',
                icon: motorcycling,
                description: "If you're injured as a result of riding a motorbike.",
            },
            {
                name: 'Non sports related work',
                icon: nonSportsWork,
                description:
                    "Paid work or employment isn't covered (e.g. food delivery rider), except when the paid work or employment is a sports related activity (e.g. tennis coaching).",
            },
            {
                name: 'Overuse injuries',
                icon: overuseGrogu,
                description:
                    "We only cover acute injuries caused by accidents, i.e. they happen all of a sudden and are unexpected. Any overuse injury, like shin splints, runners knee and tennis elbow aren't covered.",
            },
            {
                name: "Accidents that happen while your insurance isn't active",
                icon: preExisting,
                description:
                    "If you have an accident before your insurance starts or after it ends, that's not covered.",
            },
            {
                name: 'Dental injury when eating or drinking',
                icon: dental,
                description:
                    'Dental injuries caused by food or drink including any foreign object in it while being consumed.',
            },
            {
                name: 'Travel outside of Aus. or N.Z.',
                icon: travelOverseas,
                description: "If you're not in Australia or New Zealand when your injury happens.",
            },
            {
                name: 'Medical stuff',
                icon: medical,
                description:
                    'Accidents or injuries caused by medical conditions or episodes e.g. an injury caused by osteoarthritis, a seizure or a heart attack, or by medical procedures or related complications.',
            },
            {
                name: 'Booze and drugs',
                icon: alcoholDrugs,
                description:
                    'Being under the influence of alcohol, taking illicit drugs, or taking medication in a way not as directed. ',
            },
            {
                name: 'Intentional self-injury',
                icon: suicide,
                description:
                    "If you hurt yourself on purpose, that's not covered. For example, popping your own shoulder out to prove to your mates you can.",
            },
            {
                name: 'Injury to a child caused by a parent',
                icon: injuryChild,
                description: 'Injury to a child caused by a parent, guardian or someone living with the child.',
            },
            {
                name: 'Breaking the law',
                icon: illegalActGrogu,
                description:
                    "If you're injured while involved in an illegal act e.g. assaulting someone or solo paragliding without the relevant certification.",
            },
            {
                name: 'War or civil commotion',
                icon: war,
                description:
                    "If you're hurt during an act of war, whether declared or not, hostilities, civil commotion, terrorism or insurrection.",
            },
        ],
    },
};

const flipKidsDailyCoverTerms: Record<FlipKidsDailyPdsVersion, CoverInfo> = {
    [FlipKidsDailyPdsVersion.V1]: flipKidsV1Terms,
    [FlipKidsDailyPdsVersion.FLIP_2_0]: flipKidsFlip2_0Terms,
};

export default flipKidsDailyCoverTerms;
