import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { Modifier } from 'react-day-picker';
import { assign } from 'xstate';
import { MainCover } from '../../../../utils/constants/Cover';
import Limits from '../../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../../utils/getLastDayToSchedule';
import { RoamingDatesGuestMachineContextTypes } from '../context/roamingDatesGuestMachineContext';
import { Typegen0 } from '../roamingDatesGuestMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setDaysToSchedule = assign<
    RoamingDatesGuestMachineContextTypes,
    { type: EventsCausingActions['setDaysToSchedule']; data: Date[] }
>({
    daysToSchedule: (ctx, event) => event.data,
});

export const evaluateDisabledDays = assign<
    RoamingDatesGuestMachineContextTypes,
    { type: EventsCausingActions['evaluateDisabledDays']; data: MainCover } | { type: '' }
>({
    disabledDays: (ctx) => {
        const zonedToday = utcToZonedTime(new Date(), ctx.timezone);
        const lastDayToGetCover = getLastDayToSchedule(zonedToday, Limits.GUEST_SCHEDULE_LIMIT_IN_HOURS);

        const dates: Modifier[] = [
            {
                before: zonedToday,
                after: lastDayToGetCover,
            },
        ];

        return dates;
    },
});
