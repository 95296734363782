import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Layout } from '../../../components/layout/Layout';
import { Button } from '../../../components/button/Button';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { TextField } from '../../../components/text-field/TextField';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Routes from '../../../utils/Routes';
import Regex from '../../../utils/RegexCollection';
import Constants from '../../../utils/Constants';
import InputType from '../../../utils/constants/InputType';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Analytics from '../../../analytics/Analytics';
import isValidAccountName from '../../../validation/isValidAccountName';
import isValidAccountNumber from '../../../validation/isValidAccountNumber';
import isValidBsb from '../../../validation/isValidBsb';

import './BankDetails.scss';

const BankDetails: React.FC = () => {
    const { claimDetails, setClaimPayeeBankDetails } = useClaim();

    const { payeeBankDetails } = claimDetails;

    const {
        loading: isAppLoading,
        userDetails: { bankDetails },
    } = useUser();

    const { bankDetails: bankDetailsStrings } = claimsFlowStrings;

    const userHasBankDetailsAlreadySaved = bankDetails !== undefined;

    const currentAccountName = bankDetails?.accountName ?? payeeBankDetails.accountName;
    const currentAccountNumber = bankDetails?.accountNumber ?? payeeBankDetails.accountNumber;
    const currentBsb = bankDetails?.bsb ?? payeeBankDetails.bsb;

    const [accountName, setAccountName] = useState(currentAccountName);
    const [accountNumber, setAccountNumber] = useState(currentAccountNumber);
    const [bsb, setBsb] = useState(currentBsb);
    const [accountNameError, setAccountNameError] = useState('');
    const [accountNumberError, setAccountNumberError] = useState('');
    const [bsbError, setBsbError] = useState('');
    const history = useHistory();

    const formIsNotEmpty = accountName.length > 0 && accountNumber.length > 0 && bsb.length > 0;
    const isAccountNameValid = isValidAccountName(accountName);
    const isAccountNumberValid = isValidAccountNumber(accountNumber);
    const isBsbValid = isValidBsb(bsb);
    const isFormValid = formIsNotEmpty && isAccountNameValid && isAccountNumberValid && isBsbValid;

    useEffect(() => {
        // set values if they already exist
        if (bankDetails !== undefined && !accountName) {
            setAccountName(currentAccountName);
            setAccountNumber(currentAccountNumber);
            setBsb(currentBsb);
        }
    }, [bankDetails]);

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_BANK_DETAILS, claimDetails);
    }, []);

    const handleAccountNameValidation = () => {
        if (accountName.length === 0) return;
        if (!isAccountNameValid) setAccountNameError(bankDetailsStrings.errorInvalidFormat);
    };

    const handleAccountNumberValidation = () => {
        if (accountNumber.length === 0) return;
        if (!isAccountNumberValid) setAccountNumberError(bankDetailsStrings.errorInvalidNumber);
    };

    const handleBsbValidation = () => {
        if (bsb.length === 0) return;
        if (!isBsbValid) setBsbError(bankDetailsStrings.errorInvalidNumber);
    };

    const handleAccountNameChange = (value: string) => {
        const allowedText = Regex.bankAccountName;
        setAccountNameError('');
        if (value === '' || allowedText.test(value)) setAccountName(value);
    };

    const handleAccountNumberChange = (value: string) => {
        const allowedText = Regex.numbers;
        setAccountNumberError('');
        if (value === '' || allowedText.test(value)) setAccountNumber(value);
    };

    const handleBsbChange = (value: string) => {
        const allowedText = Regex.numbers;
        setBsbError('');
        if (value === '' || allowedText.test(value)) setBsb(value);
    };

    const handleContinueClick = () => {
        setClaimPayeeBankDetails(accountName, accountNumber, bsb);

        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_BANK_DETAILS, claimDetails);

        history.push(Routes.REVIEW_CLAIM);
    };

    return (
        <Layout>
            <ProgressBar completed={(Constants.CLAIMS_STEP_BANK_DETAILS / Constants.CLAIMS_NO_OF_STEPS) * 100} />
            {!isAppLoading && (
                <>
                    <h1 className="bank-details__header">
                        {userHasBankDetailsAlreadySaved
                            ? bankDetailsStrings.titleBankDetailsAlreadySet
                            : bankDetailsStrings.titleBankDetailsNotSet}
                    </h1>
                    <form>
                        <TextField
                            name="account-name"
                            label={commonStrings.accountName}
                            className="bank-details__input"
                            maxLength={100}
                            value={accountName}
                            onChange={(e) => handleAccountNameChange(e.target.value)}
                            onBlur={handleAccountNameValidation}
                            error={accountNameError}
                            isError={accountNameError !== ''}
                        />
                        <TextField
                            name="bsb"
                            label={commonStrings.bsb}
                            className="bank-details__input"
                            maxLength={6}
                            value={bsb}
                            onChange={(e) => handleBsbChange(e.target.value)}
                            onBlur={handleBsbValidation}
                            error={bsbError}
                            isError={bsbError !== ''}
                            type={InputType.NUMBER}
                            inputMode="numeric"
                        />
                        <TextField
                            name="account-number"
                            label={commonStrings.accountNumber}
                            className="bank-details__input"
                            maxLength={9}
                            value={accountNumber}
                            onChange={(e) => handleAccountNumberChange(e.target.value)}
                            onBlur={handleAccountNumberValidation}
                            error={accountNumberError}
                            isError={accountNumberError !== ''}
                            type={InputType.NUMBER}
                            inputMode="numeric"
                        />
                        <Button
                            className="bank-details__btn-continue"
                            label={commonStrings.continue}
                            width="full"
                            disabled={!isFormValid}
                            onClick={handleContinueClick}
                        />
                    </form>
                </>
            )}
        </Layout>
    );
};

export default BankDetails;
