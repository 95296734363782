import { useOktaAuth } from '@okta/okta-react';
import { useMachine } from '@xstate/react';
import differenceInYears from 'date-fns/differenceInYears';
import parse from 'date-fns/parse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import close from '../../assets/images/close.svg';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import Alert, { AlertTypes } from '../../components/alert/Alert';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import KidDetailsForm from '../../components/kid-details-form/KidDetailsForm';
import Layout from '../../components/layout/Layout';
import LoadingButton from '../../components/loading-button/LoadingButton';
import NumberCounter from '../../components/number-counter/NumberCounter';
import Tooltip from '../../components/tooltip/Tooltip';
import personsInsuredContent from '../../content/ui/screens/persons-insured/personsInsured';
import withContent from '../../hoc/with-content/withContent';
import useOnboarding from '../../hooks/useOnboarding';
import Routes from '../../utils/Routes';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import personsInsuredMachine from './persons-insured-machine/personsInsuredMachine';

import './PersonsInsured.scss';

const KIDS_MAX_AGE = 17;
const MIN_NUM_GUEST_KIDS = 1;
const MAX_NUM_GUEST_KIDS = 15;

const contentMap = {
    heading: 'ui.heading',
    myselfLabel: 'ui.myselfLabel',
    kidsLabel: 'ui.kidsLabel',
    kidsInfo: 'ui.kidsInfo',
    addAKidCta: 'ui.addAKidCta',
    kidDetailsFormHeading: 'ui.kidDetailsFormHeading',
    kidDetailsFormCta: 'ui.kidDetailsFormCta',
    kidDetailsFormCancel: 'ui.kidDetailsFormCancel',
    cta: 'ui.cta',
    kidOver18: 'ui.kidOver18',
    kidHasSubscription: 'ui.kidHasSubscription',
    kidHasSubscriptionDescription: 'ui.kidHasSubscriptionDescription',
};

const personsInsuredFormId = 'persons-insured-form';
const addAKidFormId = 'add-a-kid-form';

interface PersonsInsuredProps {
    content: Record<keyof typeof contentMap, string>;
}

const PersonsInsured: FC<PersonsInsuredProps> = ({ content }) => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const history = useHistory();
    const { dependants, addDependants, accessToken } = useUser();
    const { policies } = useOpenCovers();
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const { setRoamingData, selectedCover: selectedRoamingCover, clearRoamingState } = useRoaming();
    const { authState } = useOktaAuth();
    const { enforceOverlappingRules } = useFlags();

    const dependantsCovers = policies.filter(
        (x) =>
            !!dependants.find((y) => y.personId === x.mainCover.insuredPersonId) && x.mainCover.status !== 'Canceled',
    );
    const [state, send] = useMachine(personsInsuredMachine, {
        context: {
            accessToken,
            isAuthenticated: authState?.isAuthenticated,
        },
        actions: {
            redirectToScheduleCoverForAuthenticatedKids: (ctx) => {
                setRoamingData('coverFor', 'dependant');
                setRoamingData('selectedKidId', ctx.selectedKidId);
                history.push({
                    pathname: Routes.SELECT_COVER,
                    state: {
                        coverFor: 'dependant',
                        dependantToCover: ctx.selectedKidId,
                    },
                });
            },
            redirectToScheduleCoverForGuestKids: (ctx) => {
                setRoamingData('coverFor', 'dependant');
                setRoamingData('numberOfGuestKids', ctx.numberOfGuestKids);
                history.push({
                    pathname: Routes.SELECT_COVER_GUEST,
                    state: {
                        coverFor: 'dependant',
                        numberOfGuestKids: ctx.numberOfGuestKids,
                    },
                });
            },
            redirectToScheduleCoverForAuthenticatedSelf: () => {
                setRoamingData('coverFor', 'self');
                history.push({
                    pathname: Routes.SELECT_COVER,
                    state: {
                        coverFor: 'self',
                    },
                });
            },
            redirectToScheduleCoverForGuestSelf: () => {
                setRoamingData('coverFor', 'self');
                history.push({
                    pathname: Routes.SELECT_COVER_GUEST,
                    state: {
                        coverFor: 'self',
                    },
                });
            },
            updateUserContext: (ctx, event) => {
                addDependants(event.data);
            },
        },
    });

    const coverForMyself = state.matches('myself');
    const coverForAuthenticatedKids = state.matches('kids.authenticatedKids');
    const coverForGuestKids = state.matches('kids.guestKids');
    const showKidDetailsForm =
        state.matches('kids.authenticatedKids.addAKid') &&
        !state.matches('kids.authenticatedKids.addAKid.spawnKidDetailsFormMachine');
    const showAddAKidButton = state.can('ADD_A_KID');
    const isCreatingDependants = state.matches('kids.authenticatedKids.addAKid.createDependant');
    const showCreateDependantsError = state.matches('kids.authenticatedKids.addAKid.displayCreateDependantError');
    const isPersonsInsuredFormInvalid = !state.can('CONTINUE');
    const isAddAKidFormInvalid = state.context.kidDetailsFormMachineRef
        ? state.context.kidDetailsFormMachineRef.getSnapshot()?.hasTag('INVALID')
        : true;
    const isAbleToToggleKids = state.can({ type: 'SELECT_KID', data: { id: '' } });

    const handlePersonsInsuredFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('CONTINUE');
    };

    const handleAddAKidFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('SAVE_KID');
    };

    const renderDependantsRadioButtons = () =>
        dependants.map((d) => {
            const dependantIsAbove18 =
                differenceInYears(new Date(), parse(d.dob!, DateFormat.DEFAULT, new Date())) > KIDS_MAX_AGE;

            const hasSubscription = !!dependantsCovers.find(
                (x) =>
                    x.mainCover.insuredPersonId === d.personId! &&
                    x.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1,
            );

            return (
                <div className="persons-insured__radio-with-tooltip" key={d.personId}>
                    <RadioButton
                        form={personsInsuredFormId}
                        id={d.personId!}
                        key={d.personId}
                        name="kids"
                        className="persons-insured__kids-radio-button"
                        label={`${d.firstName} ${d.lastName}`}
                        onChange={() => send({ type: 'SELECT_KID', data: { id: d.personId! } })}
                        checked={state.context.selectedKidId === d.personId}
                        disabled={
                            !isAbleToToggleKids || dependantIsAbove18 || (hasSubscription && enforceOverlappingRules)
                        }
                        variant="secondary"
                    />
                    {dependantIsAbove18 && (
                        <div className="persons-insured__tooltip">
                            <Tooltip
                                modalProps={{
                                    textContent: (
                                        <p className="persons-insured__tooltip-heading">{content.kidOver18}</p>
                                    ),
                                }}
                            />
                        </div>
                    )}
                    {hasSubscription && enforceOverlappingRules && (
                        <div className="persons-insured__tooltip">
                            <Tooltip
                                modalProps={{
                                    textContent: (
                                        <>
                                            <p className="persons-insured__tooltip-heading">
                                                {content.kidHasSubscription}
                                            </p>
                                            <p className="persons-insured__tooltip-description">
                                                {content.kidHasSubscriptionDescription}
                                            </p>
                                        </>
                                    ),
                                }}
                            />
                        </div>
                    )}
                </div>
            );
        });
    useEffect(() => {
        if (selectedRoamingCover) {
            clearRoamingState();
        }
    }, []);

    useEffect(() => {
        const currentPath = location.pathname;
        if (authState?.isAuthenticated && currentPath === Routes.PERSONS_INSURED_GUEST) {
            history.push(Routes.HOME);
        }
    }, [location, authState, history]);

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            <form id={personsInsuredFormId} onSubmit={handlePersonsInsuredFormSubmit} />
            <form id={addAKidFormId} onSubmit={handleAddAKidFormSubmit} />
            <div className="persons-insured__form-content">
                <Fieldset
                    legend={content.heading}
                    visuallyHideLegend
                    className="persons-insured__radio-fieldset"
                    form={personsInsuredFormId}
                >
                    <RadioButton
                        id="myself"
                        name="persons-insured"
                        className="persons-insured__radio-button"
                        label={content.myselfLabel}
                        onChange={() => {
                            send('SELECT_MYSELF');
                        }}
                        checked={coverForMyself}
                        form={personsInsuredFormId}
                    />
                    <RadioButton
                        id="kids"
                        name="persons-insured"
                        className="persons-insured__radio-button"
                        label={content.kidsLabel}
                        onChange={() => {
                            send('SELECT_KIDS');
                        }}
                        checked={coverForAuthenticatedKids || coverForGuestKids}
                        form={personsInsuredFormId}
                    />
                </Fieldset>
                {/* Guest flow */}
                {coverForGuestKids && (
                    <>
                        <p>How many kids would you like to cover?</p>
                        <NumberCounter
                            id="guestKidNumber"
                            name="guestKidNumber"
                            value={state.context.numberOfGuestKids}
                            onChange={(e) => {
                                send({
                                    type: 'SET_NUMBER_OF_KIDS',
                                    data: { numberOfGuestKids: parseInt(e.target.value, 10) },
                                });
                            }}
                            minValue={MIN_NUM_GUEST_KIDS}
                            maxValue={MAX_NUM_GUEST_KIDS}
                            minErrorMessage={personsInsuredContent
                                .formatString(personsInsuredContent.minGuestKidsError, {
                                    minGuestKids: MIN_NUM_GUEST_KIDS.toString(),
                                })
                                .toString()}
                            maxErrorMessage={personsInsuredContent
                                .formatString(personsInsuredContent.maxGuestKidsError, {
                                    maxGuestKids: MAX_NUM_GUEST_KIDS.toString(),
                                })
                                .toString()}
                            onIncrement={() => {
                                send({
                                    type: 'SET_NUMBER_OF_KIDS',
                                    data: { numberOfGuestKids: state.context.numberOfGuestKids + 1 },
                                });
                            }}
                            onDecrement={() => {
                                send({
                                    type: 'SET_NUMBER_OF_KIDS',
                                    data: { numberOfGuestKids: state.context.numberOfGuestKids - 1 },
                                });
                            }}
                        />
                    </>
                )}
                {/* Authenticated flow */}
                {coverForAuthenticatedKids && (
                    <>
                        <Alert
                            type={AlertTypes.INFO}
                            message={content.kidsInfo}
                            className="persons-insured__kids-info"
                        />
                        <Fieldset
                            className="persons-insured__kids-fieldset"
                            legend="Select which kid"
                            visuallyHideLegend
                            form={personsInsuredFormId}
                        >
                            {renderDependantsRadioButtons()}
                        </Fieldset>
                        {showAddAKidButton && (
                            <Button
                                label={content.addAKidCta}
                                width="full"
                                variant="secondary"
                                onClick={() => send('ADD_A_KID')}
                            />
                        )}

                        {showKidDetailsForm && (
                            <>
                                <div className="persons-insured__kid-details-form-heading-section">
                                    <p className="persons-insured__kid-details-form-heading">
                                        {content.kidDetailsFormHeading}
                                    </p>
                                    <button
                                        type="button"
                                        className="persons-insured__kid-details-form-cancel-button"
                                        onClick={() => send('CANCEL_ADD_A_KID')}
                                    >
                                        <span className="persons-insured__kid-details-form-cancel-button-text">
                                            {content.kidDetailsFormCancel}
                                        </span>
                                        <img src={close} alt="" />
                                    </button>
                                </div>

                                <KidDetailsForm
                                    //  @ts-expect-error xstate typegen wrongly inferring state to be never
                                    kidDetailsFormMachineRef={state.context.kidDetailsFormMachineRef}
                                    form={addAKidFormId}
                                />

                                {showCreateDependantsError && (
                                    <Alert className="persons-insured__kid-details-form-error" />
                                )}

                                <LoadingButton
                                    status={isCreatingDependants ? 'loading' : 'idle'}
                                    label={content.kidDetailsFormCta}
                                    width="full"
                                    variant="secondary"
                                    type="submit"
                                    form={addAKidFormId}
                                    disabled={isAddAKidFormInvalid}
                                />
                            </>
                        )}
                    </>
                )}
            </div>

            <Button
                label={content.cta}
                width="full"
                form={personsInsuredFormId}
                type="submit"
                disabled={isPersonsInsuredFormInvalid}
            />
        </Layout>
    );
};

export default withContent(PersonsInsured, contentMap, personsInsuredContent);
