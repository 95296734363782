import React from 'react';
import { FlipRating } from '../../flip-rating/FlipRating';
import './ClaimUploadExampleItem.scss';

export interface ClaimUploadExampleItemProps {
    id: string;
    title: string;
    description: string;
    image: string;
    rating: number;
}

export const ClaimUploadExampleItem: React.FC<ClaimUploadExampleItemProps> = ({
    id,
    title,
    description,
    image,
    rating,
}) => {
    return (
        <div className="claim-upload-example-item" id={id}>
            <img className="claim-upload-example-item__img" src={image} alt={title} />
            <div className="claim-upload-example-item__info">
                <h2 className="claim-upload-example-item__title">{title}</h2>
                <p className="claim-upload-example-item__description">{description}</p>
                <FlipRating rating={rating} />
            </div>
        </div>
    );
};

export default ClaimUploadExampleItem;
