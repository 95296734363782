import { FC } from 'react';
import classNames from 'classnames';

import './FixedPaymentModelCoverTile.scss';

type TProps = {
    title: string;
    priceTimePeriod: string;
    price: string;
};

const FixedPaymentModelCoverTile: FC<TProps> = ({ price, priceTimePeriod, title }: TProps) => {
    return (
        <div className={classNames('fixed-payment-model-cover-tile')}>
            <p className="fixed-payment-model-cover-tile__title">{title}</p>
            <div className="fixed-payment-model-cover-tile__price-section">
                <p className={classNames('fixed-payment-model-cover-tile__price')}>
                    {price} <span className="fixed-payment-model-cover-tile__description">{priceTimePeriod}</span>
                </p>
            </div>
        </div>
    );
};

export default FixedPaymentModelCoverTile;
