import format from 'date-fns-tz/format';
import parse from 'date-fns/parse';
import DateFormat from '../constants/DateFormat';

const toTime = (time: string): { time: string; timePeriod: string } => {
    if (time.length === 0) return { time: '', timePeriod: '' };
    const timeValue = parse(time, DateFormat.TIME, new Date());

    return { time: format(timeValue, 'h:mm'), timePeriod: format(timeValue, 'a') };
};

export default toTime;
