import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import toHeaders from './utils/toHeaders';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const cancelSingleCover = async (
    accessToken: string | undefined,
    insuranceCoverId: string,
    cancelCustomerInsight: string[] = [],
) => {
    try {
        const response = await axios.delete(`${baseApiPath}/api/v1/insurance-cover/type/single-v1`, {
            data: { insuranceCoverId, cancelCustomerInsight },
            ...toHeaders(accessToken),
        });
        return response;
    } catch (e: any) {
        datadogRum.addError(new Error(e), {
            serviceName: 'cancelSingleCover',
            description: 'Failed to cancel single cover',
            data: {
                insuranceCoverId,
            },
        });
        throw new Error(e);
    }
};

const cancelSubscriptionCover = async (
    accessToken: string | undefined,
    insuranceCoverId: string,
    cancelCustomerInsight: string[] = [],
) => {
    try {
        const response = await axios.delete(`${baseApiPath}/api/v1/insurance-cover/type/subscription-v1`, {
            data: { insuranceCoverId, cancelCustomerInsight },
            ...toHeaders(accessToken),
        });

        return response;
    } catch (e: any) {
        datadogRum.addError(new Error(e), {
            serviceName: 'cancelSingleCover',
            description: 'Failed to cancel single cover',
            data: {
                insuranceCoverId,
            },
        });
        throw new Error(e);
    }
};

const cancelAllSingleCovers = async (
    accessToken: string | undefined,
    insuranceCoverIds: string[],
    cancelCustomerInsights: string[] = [],
) => {
    try {
        const responses = await Promise.all(
            insuranceCoverIds.map(async (insuranceCoverId) => {
                const response = await axios.delete(`${baseApiPath}/api/v1/insurance-cover/type/single-v1`, {
                    data: { insuranceCoverId, cancelCustomerInsights },
                    ...toHeaders(accessToken),
                });

                return response;
            }),
        );

        return responses;
    } catch (e: any) {
        datadogRum.addError(new Error(e), {
            serviceName: 'cancelAllSingleCovers',
            description: 'Failed to cancel all single covers',
            data: {
                insuranceCoverIds,
            },
        });
        throw new Error(e);
    }
};

export default {
    cancelSingleCover,
    cancelSubscriptionCover,
    cancelAllSingleCovers,
};
