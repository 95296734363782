import { ReactElement } from 'react';
import claimsFlowStrings from '../../strings/claimsFlow';
import './SpeedUpYourClaim.scss';

const SpeedUpYourClaim = (): ReactElement => {
    const { speedUpYourClaim } = claimsFlowStrings;
    return (
        <div className="speed-up-your-claim-pill">
            <div className="speed-up-your-claim-pill__success-icon" />
            <p className="speed-up-your-claim-pill__text">{speedUpYourClaim}</p>
        </div>
    );
};

export default SpeedUpYourClaim;
