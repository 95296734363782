import LocalizedStrings from 'react-localization';

const claimType = new LocalizedStrings({
    en: {
        heading: 'Type of claim',
        description: 'Is this a claim for an accidental injury you sustained in Australia / New Zealand, or overseas?',
        localRadioButtonLabel: 'Australia & New Zealand',
        overseasRadioButtonLabel: 'Overseas',
    },
});

export default claimType;
