import { createMachine } from 'xstate';
import {
    setKidSelection,
    spawnKidDetailsFormMachine,
    setNewDependantAsSelectedKid,
    setGuestKidsNumbers,
} from '../actions/personsInsuredMachineActions';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';
import { isAuthenticated, isInvalidNumberGuestKids, hasSelectedKid } from '../guards/personsInsuredMachineGuards';
import { PersonsInsuredMachineServices, createDependant } from '../services/personsInsuredMachineServices';

const PersonsInsuredMachine = createMachine(
    {
        id: 'PersonsInsuredMachine',
        initial: 'idle',
        tsTypes: {} as import('./personsInsuredMachine.typegen').Typegen0,
        schema: {
            context: {} as PersonsInsuredMachineContextTypes,
            events: {} as
                | { type: 'SELECT_MYSELF' }
                | { type: 'SELECT_KIDS' }
                | { type: 'CONTINUE' }
                | { type: 'ADD_A_KID' }
                | { type: 'SET_NUMBER_OF_KIDS'; data: { numberOfGuestKids: number } }
                | { type: 'SAVE_KID' }
                | { type: 'CANCEL_ADD_A_KID' }
                | { type: 'SELECT_KID'; data: { id: string } }
                | { type: 'GUEST_KIDS_ERROR' },
            services: {} as PersonsInsuredMachineServices,
        },
        context: {
            selectedKidId: '',
            kidDetailsFormMachineRef: null,
            accessToken: '',
            isAuthenticated: false,
            numberOfGuestKids: 1,
        },
        preserveActionOrder: true,
        states: {
            idle: {
                on: {
                    SELECT_MYSELF: {
                        target: 'myself',
                    },
                    SELECT_KIDS: {
                        target: 'kids',
                    },
                },
            },
            myself: {
                initial: 'isAuthenticatedSelf',
                states: {
                    isAuthenticatedSelf: {
                        always: [{ cond: 'isAuthenticated', target: 'authenticatedSelf' }, { target: 'guestSelf' }],
                    },
                    authenticatedSelf: {
                        on: {
                            CONTINUE: {
                                actions: 'redirectToScheduleCoverForAuthenticatedSelf',
                            },
                            SELECT_KIDS: {
                                target: '#PersonsInsuredMachine.kids',
                            },
                        },
                    },
                    guestSelf: {
                        on: {
                            CONTINUE: {
                                actions: 'redirectToScheduleCoverForGuestSelf',
                            },
                            SELECT_KIDS: {
                                target: '#PersonsInsuredMachine.kids',
                            },
                        },
                    },
                },
            },
            kids: {
                on: {
                    SELECT_MYSELF: {
                        target: '#PersonsInsuredMachine.myself',
                    },
                },
                initial: 'determineIfAuthenticated',
                states: {
                    determineIfAuthenticated: {
                        always: [{ cond: 'isAuthenticated', target: 'authenticatedKids' }, { target: 'guestKids' }],
                    },
                    authenticatedKids: {
                        initial: 'determineIfSelectionIsValid',
                        states: {
                            determineIfSelectionIsValid: {
                                always: [{ cond: 'hasSelectedKid', target: 'readyToContinue' }, { target: 'idle' }],
                            },
                            idle: {
                                on: {
                                    ADD_A_KID: {
                                        target: 'addAKid',
                                    },
                                    SELECT_KID: {
                                        actions: 'setKidSelection',
                                        target: 'determineIfSelectionIsValid',
                                    },
                                },
                            },
                            readyToContinue: {
                                on: {
                                    ADD_A_KID: {
                                        target: 'addAKid',
                                    },
                                    SELECT_KID: {
                                        actions: 'setKidSelection',
                                        target: 'determineIfSelectionIsValid',
                                    },
                                    CONTINUE: {
                                        actions: 'redirectToScheduleCoverForAuthenticatedKids',
                                    },
                                },
                            },
                            addAKid: {
                                initial: 'spawnKidDetailsFormMachine',
                                states: {
                                    spawnKidDetailsFormMachine: {
                                        always: {
                                            actions: 'spawnKidDetailsFormMachine',
                                            target: 'ready',
                                        },
                                    },
                                    ready: {
                                        on: {
                                            SAVE_KID: {
                                                target: 'createDependant',
                                            },
                                            CANCEL_ADD_A_KID: {
                                                target: '#PersonsInsuredMachine.kids.determineIfAuthenticated',
                                            },
                                        },
                                    },
                                    createDependant: {
                                        invoke: {
                                            src: 'createDependant',
                                            onDone: {
                                                actions: ['updateUserContext', 'setNewDependantAsSelectedKid'],
                                                target: '#PersonsInsuredMachine.kids.determineIfAuthenticated',
                                            },
                                            onError: {
                                                target: 'displayCreateDependantError',
                                            },
                                        },
                                    },
                                    displayCreateDependantError: {
                                        on: {
                                            SAVE_KID: {
                                                target: 'createDependant',
                                            },
                                            CANCEL_ADD_A_KID: {
                                                target: '#PersonsInsuredMachine.kids.determineIfAuthenticated',
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    guestKids: {
                        initial: 'evaluateGuestKidsNumber',
                        states: {
                            idle: {
                                on: {
                                    SET_NUMBER_OF_KIDS: {
                                        actions: 'setGuestKidsNumbers',
                                        target: 'evaluateGuestKidsNumber',
                                    },
                                    CONTINUE: {
                                        actions: 'redirectToScheduleCoverForGuestKids',
                                    },
                                },
                            },
                            guestKidsError: {
                                on: {
                                    SET_NUMBER_OF_KIDS: {
                                        actions: 'setGuestKidsNumbers',
                                        target: 'evaluateGuestKidsNumber',
                                    },
                                },
                            },
                            evaluateGuestKidsNumber: {
                                always: [
                                    { cond: 'isInvalidNumberGuestKids', target: 'guestKidsError' },
                                    { target: 'idle' },
                                ],
                            },
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            setKidSelection,
            setGuestKidsNumbers,
            spawnKidDetailsFormMachine,
            setNewDependantAsSelectedKid,
        },
        guards: {
            isInvalidNumberGuestKids,
            isAuthenticated,
            hasSelectedKid,
        },
        services: {
            createDependant,
        },
    },
);

export default PersonsInsuredMachine;
