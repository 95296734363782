import { FC } from 'react';

import close from '../../assets/images/close.svg';
import { MainCover } from '../../utils/constants/Cover';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import CoveredTab from '../covered-tab/CoveredTab';
import Modal from '../modal/Modal';
import NotCoveredTab from '../not-covered-tab/NotCoveredTab';
import { Tabs } from '../tabs/Tabs';

import './SlideInBenefits.scss';

interface SlideInBenefitsProps {
    content: {
        injuriesCoveredTab: string;
        injuriesNotCoveredTab: string;
    };
    selectedCover: MainCover;
    pdsVersion: MainCoverPdsVersion;
    isOpen: boolean;
    onClose: () => void;
}

const SlideInBenefits: FC<SlideInBenefitsProps> = ({ content, selectedCover, pdsVersion, isOpen, onClose }) => {
    const tabs = [
        {
            id: 'whats-covered',
            label: content.injuriesCoveredTab,
            content: <CoveredTab selectedCover={selectedCover} pdsVersion={pdsVersion} />,
        },
        {
            id: 'whats-not-covered',
            label: content.injuriesNotCoveredTab,
            content: <NotCoveredTab selectedCover={selectedCover} pdsVersion={pdsVersion} showExamples={false} />,
        },
    ];

    return (
        <>
            <Modal isOpen={isOpen} parentClass="#root" onClose={onClose} variant="slide-in">
                <div className="slide-in-benefits__modal-close-button-wrapper">
                    <button
                        type="button"
                        className="slide-in-benefits__modal-close-button"
                        aria-label="Close"
                        onClick={onClose}
                    >
                        <img src={close} alt="Close" />
                    </button>
                </div>
                <Tabs tabs={tabs} className="slide-in-benefits__tabs" />
            </Modal>
        </>
    );
};

export default SlideInBenefits;
