import Routes from './Routes';

export default (isFirstPurchase: boolean): string => {
    let nextPath: string = Routes.HOME;

    if (isFirstPurchase) {
        nextPath = Routes.YOUR_ACTIVITIES;
    }

    return nextPath;
};
