import capitalize from 'lodash/capitalize';
import { FC } from 'react';

import isEmpty from 'lodash/isEmpty';
import airplane from '../../assets/images/airplane-blue.svg';
import { useUser } from '../../business-logic/context-provider/user-context';
import CoverSelection from '../../business-logic/models/CoverSelection';
import coverSummaryCardContent from '../../content/ui/components/cover-summary-card/coverSummaryCard';
import withContent from '../../hoc/with-content/withContent';
import Cover from '../../utils/constants/Cover';
import CoverInformation from '../../utils/constants/CoverInformation';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import formatPrice from '../../utils/formatPrice';
import getProductInfo from '../../utils/getProductInfo';
import getTimezone from '../../utils/getTimezone';
import isRoamingCover from '../../utils/isRoamingCover';
import { Card } from '../card/Card';

import './CoverSummaryCard.scss';

const contentMap = {
    unitPrice: 'ui.unitPrice',
    singleLineItemLabel: 'ui.singleLineItemLabel',
    singleLineItemDate: 'ui.singleLineItemDate',
    subscriptionLineItemDescription: 'ui.subscriptionLineItemDescription',
    subtotal: 'ui.subtotal',
};

interface CoverSummaryCardProps {
    coverSelections: CoverSelection[];
    content: Record<keyof typeof contentMap, string>;
}

const CoverSummaryCard: FC<CoverSummaryCardProps> = ({ coverSelections, content }) => {
    const {
        dependants,
        userDetails: { personId: userPersonId, firstName, lastName },
        userTimeZone,
    } = useUser();

    const selfCoverHolderName = `${firstName} ${lastName}`;

    const selectedCoverInformation = getProductInfo(coverSelections[0].selectedCover);
    const { coverType } = CoverInformation[coverSelections[0].selectedCover];

    const numericPrice = parseInt(selectedCoverInformation.price.match(/\$(\d*)/)![1], 10) * 100;

    const renderCoverDate = (day: string, index: number, timezone: string | null) => (
        <>
            <div className="cover-summary-card__week" key={day}>
                <div className="cover-summary-card__week-label">
                    <strong className="cover-summary-card__week-label__count">
                        {coverSummaryCardContent.formatString(
                            coverType === CoverTypeId.SINGLE_V1
                                ? content.singleLineItemLabel
                                : content.subscriptionLineItemDescription,
                            {
                                priceTimePeriod: capitalize(selectedCoverInformation.priceTimePeriod),
                                index: index + 1,
                            },
                        )}
                    </strong>
                    <div className="cover-summary-card__week-time">
                        {coverSummaryCardContent.formatString(content.singleLineItemDate, {
                            date:
                                formatDateToString(day, DateFormat.VERBOSE) +
                                ` (${getTimezone(timezone || userTimeZone, day)})`,
                        })}
                    </div>
                </div>

                <div className="cover-summary-card__week-price">{formatPrice(numericPrice)}</div>
            </div>
        </>
    );

    const renderDestination = (selection: CoverSelection[]): JSX.Element | string => {
        if (!selection.length) return '';
        const startingDestination = Array.from(
            new Set(
                coverSelections.map((item) => {
                    return {
                        coverStartDate: item.coverStartDate,
                        selectedCover: item.selectedCover,
                        region: item.destination?.startingRegion.region,
                        destinations: item.destination?.destinations,
                        timezone: item.timezone,
                    };
                }),
            ),
        )[0];
        if (isEmpty(startingDestination)) return '';
        return (
            <div className="cover-summary-card__destination">
                <img src={airplane} alt="" className="cover-summary-card__destination__icon" />
                <>{startingDestination.destinations!.sort((a, b) => a.localeCompare(b)).join(', ')}</>
            </div>
        );
    };

    const renderCoverDates = () => {
        const selfCoverSelections = coverSelections.filter((c) => c.personId === null || c.personId === userPersonId);
        const groupedDependantCoverSelections = coverSelections
            .filter((c) => c.personId !== null && c.personId !== userPersonId)
            .reduce<Record<string, CoverSelection[]>>((acc, cur) => {
                if (acc[cur.personId!]) {
                    acc[cur.personId!].push(cur);
                } else {
                    acc[cur.personId!] = [cur];
                }

                return acc;
            }, {});

        return (
            <>
                {selfCoverSelections.length > 0 && (
                    <div className="cover-summary-card__cover-dates">
                        <p className="cover-summary-card__name">{selfCoverHolderName}</p>
                        {selfCoverSelections.map((c, index) => renderCoverDate(c.coverStartDate, index, c.timezone))}
                        {selfCoverSelections.every((s) => isRoamingCover(s.selectedCover as Cover)) &&
                            renderDestination(selfCoverSelections)}
                    </div>
                )}
                {Object.entries(groupedDependantCoverSelections).map(([personId, selections]) => {
                    const dependant = dependants.find((d) => d.personId === personId);

                    return (
                        <div className="cover-summary-card__cover-dates" key={personId}>
                            <p className="cover-summary-card__name">
                                {dependant?.firstName} {dependant?.lastName}
                            </p>
                            {selections.map((s, i) => renderCoverDate(s.coverStartDate, i, s.timezone))}
                            {selections.every((s) => isRoamingCover(s.selectedCover as Cover)) &&
                                renderDestination(selections)}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <Card className="cover-summary-card">
            <div className="cover-summary-card__header">
                <div className="cover-summary-card__title">{selectedCoverInformation.group}</div>
            </div>
            <div className="cover-summary-card__details">
                <div className="cover-summary-card__label">{selectedCoverInformation.paymentModel}</div>
                {renderCoverDates()}
            </div>
            <div className="cover-summary-card__subtotal-row">
                <span className="cover-summary-card__payment-model-description">
                    {selectedCoverInformation.paymentModelDescription}
                </span>
                <span className="cover-summary-card__total-price">
                    {content.subtotal}
                    <span>{formatPrice(numericPrice * coverSelections.length)}</span>
                </span>
            </div>
        </Card>
    );
};

export default withContent(CoverSummaryCard, contentMap, coverSummaryCardContent);
