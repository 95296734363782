import { createMachine } from 'xstate';
import { KidsDetailsMachineContext } from './context/kidsDetailsMachineContext';
import { setGenericError, clearGenericError } from './actions/kidsDetailsMachineActions';
import { KidsDetailsMachineServices, createGuestDependants } from './services/kidsDetailsMachineServices';

const kidsDetailsMachine = createMachine(
    {
        id: 'KidsDetailsMachine',
        initial: 'spawnKidDetailsFormMachines',
        tsTypes: {} as import('./kidsDetailsMachine.typegen').Typegen0,
        schema: {
            services: {} as KidsDetailsMachineServices,
            context: {} as KidsDetailsMachineContext,
            events: { type: 'CONTINUE' },
        },
        states: {
            spawnKidDetailsFormMachines: {
                always: {
                    actions: 'spawnKidDetailsFormMachines',
                    target: 'ready',
                },
            },
            ready: {
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            CONTINUE: {
                                target: 'createGuestDependants',
                            },
                        },
                    },
                    createGuestDependants: {
                        invoke: {
                            src: 'createGuestDependants',
                            onDone: {
                                actions: ['updateUserContext', 'notifyDependantsCreationComplete'],
                                target: 'idle',
                            },
                            onError: {
                                actions: 'setGenericError',
                                target: 'displayGenericError',
                            },
                        },
                    },
                    displayGenericError: {
                        exit: 'clearGenericError',
                        on: {
                            CONTINUE: {
                                target: 'createGuestDependants',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            setGenericError,
            clearGenericError,
        },
        services: {
            createGuestDependants,
        },
    },
);

export default kidsDetailsMachine;
