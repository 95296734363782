import Cover from '../../../utils/constants/Cover';
import AlwaysOnIcon from '../../../assets/images/cover/always-on.svg';
import { ProductInfo } from './types';
import { AlwaysOnPdsVersion } from '../../../utils/constants/PdsVersion';

const alphaProductInfo: ProductInfo = {
    id: Cover.ALWAYS_ON,
    icon: AlwaysOnIcon,
    name: 'Subscription',
    group: 'Always On',
    price: '$9',
    priceTimePeriod: 'week',
    description: 'Ongoing cover for peace of mind, cancel anytime.',
    primaryFeatures: [
        'Subscribe for full-time cover',
        'Boost to double your payout for the day, for just $6 extra per day',
        'Cancel any time',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Subscription',
    coverUnitDuration: 'Subscription',
    paymentModelDescription: 'Weekly subscription, cancel anytime.',
    benefitDescription: 'Up to <strong>$20k</strong> depending on your injury',
};

const betaProductInfo: ProductInfo = {
    id: Cover.ALWAYS_ON,
    icon: AlwaysOnIcon,
    name: 'Always On',
    group: 'Always On',
    price: '$9',
    priceTimePeriod: 'week',
    description: 'Ongoing cover for peace of mind.',
    primaryFeatures: [
        'Subscribe for full-time cover',
        'Boost to double your payout for the day, for just $6 extra per day',
        'Cancel any time',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Subscription',
    coverUnitDuration: 'Subscription',
    paymentModelDescription: 'Weekly subscription, cancel anytime.',
    benefitDescription: 'Up to <strong>$20k</strong> depending on your injury',
};

const groguProductInfo: ProductInfo = {
    id: Cover.ALWAYS_ON,
    icon: AlwaysOnIcon,
    name: 'Always On',
    group: 'Always On',
    price: '$9',
    priceTimePeriod: 'week',
    description: 'Flexible full-time cover for peace of mind',
    primaryFeatures: ['Weekly subscription, cancel anytime', 'Boost for $6 per day to double your payout for the day'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Subscription',
    coverUnitDuration: 'Subscription',
    paymentModelDescription: 'Weekly subscription, cancel anytime',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const alwaysOnProductInfo: Record<AlwaysOnPdsVersion, ProductInfo> = {
    [AlwaysOnPdsVersion.ALPHA_V1]: alphaProductInfo,
    [AlwaysOnPdsVersion.ALPHA_V2]: alphaProductInfo,
    [AlwaysOnPdsVersion.BETA]: betaProductInfo,
    [AlwaysOnPdsVersion.GROGU]: groguProductInfo,
    [AlwaysOnPdsVersion.GROGU_SCHEDULE]: groguProductInfo,
    [AlwaysOnPdsVersion.GROGU_SEPT_UPDATE]: groguProductInfo,
    [AlwaysOnPdsVersion.GROGU_SCHEDULING_LIMIT_UPDATE]: groguProductInfo,
};

export default alwaysOnProductInfo;
