import { useMachine } from '@xstate/react';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import { useHistory, useLocation } from 'react-router';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import ProductList from '../../analytics/ProductList';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import MultiProductEncodedCart from '../../business-logic/models/MultiProductEncodedCart';
import requireFlags from '../../hoc/require-flags/requireFlags';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import RoamingDatesBody from './components/RoamingDatesBody';
import roamingDatesGuestMachine from './roaming-dates-guest-machine/roamingDatesGuestMachine';

import './RoamingDates.scss';

const RoamingDatesGuest = () => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const history = useHistory();

    const {
        destinations,
        startingRegion,
        timezone,
        coverFor,
        selectedCover: selectedRoamingCover,
        startingDestination,
        numberOfGuestKids,
    } = useRoaming();

    const [state, send] = useMachine(roamingDatesGuestMachine, {
        context: {
            timezone,
            destinations,
            startingRegion,
            startingDestination,
            coverFor,
            selectedCover: selectedRoamingCover,
        },
        actions: {
            goBack: () => {
                history.goBack();
            },
            goToCheckout: (ctx) => {
                const zonedToday = utcToZonedTime(new Date(), ctx.timezone);
                ctx.daysToSchedule.forEach((date) => {
                    Analytics.trackProductAdded({
                        ...ProductList[selectedRoamingCover!],
                        quantity: 1,
                        variant: formatDateToString(date, DateFormat.ANALYTICS),
                        scheduledFor: isSameDay(zonedToday, date) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                        startingInDays: differenceInCalendarDays(date, zonedToday),
                    });
                });
                const cartCover = ProductList[selectedRoamingCover!];
                const guestCartSelection: MultiProductEncodedCart = {
                    items: [
                        {
                            id: cartCover.name,
                            productId: cartCover.product_id,
                            sku: cartCover.product_id,
                            position: cartCover.position!,
                            quantity: numberOfGuestKids !== undefined && numberOfGuestKids > 0 ? numberOfGuestKids : 1,
                            name: cartCover.name,
                            price: cartCover.price,
                            paymentOption: cartCover.name,
                            destinations,
                            startingRegion: {
                                region: startingRegion,
                                timezone,
                            },
                            variant: [
                                ...ctx.daysToSchedule.map((date) => formatDateToString(date, DateFormat.DEFAULT)),
                            ],
                        },
                    ],
                    total: numberOfGuestKids !== undefined && numberOfGuestKids > 0 ? numberOfGuestKids : 1,
                };
                const encodedCartString = btoa(JSON.stringify(guestCartSelection));
                history.push({
                    pathname: Routes.CART,
                    search: `c=${encodedCartString}`,
                    state: { isOnboarding: isOnboardingFlow },
                });
            },
        },
    });

    const { datePickerMode, daysToSchedule, disabledDays } = state.context;

    const handleCalendarOpen = () => {
        Analytics.trackCalendarOpened(ProductList[selectedRoamingCover!]);
    };

    const handleDateSubmission = (dates: Date[]) => {
        send({ type: 'SELECT_DATES', data: [...dates] });
    };

    return (
        <>
            <RoamingDatesBody
                selectedRoamingCover={selectedRoamingCover as Cover}
                datePickerMode={datePickerMode}
                daysToSchedule={daysToSchedule}
                disabledDays={disabledDays}
                goBack={() => send({ type: 'GO_BACK' })}
                fixedPaymentModelMode={false}
                cartLoading={false}
                handleDateSubmission={handleDateSubmission}
                canSelectDates={state.can({ type: 'SELECT_DATES', data: [] })}
                proceedToCheckout={() => send('CHECKOUT')}
                handleCalendarOpen={handleCalendarOpen}
                isOnboardingFlow={location.state ? location.state.isOnboarding : false}
                startingRegion={startingRegion}
                destinations={destinations}
                timezone={timezone}
                coverFor={coverFor}
                openRoamingProductModal={state.matches('showRoamingModal')}
                agreeToConditions={() => send({ type: 'AGREE_CONDITIONS' })}
                canAgreeToConditions={state.can({ type: 'AGREE_CONDITIONS' })}
            />
        </>
    );
};

export default requireFlags(RoamingDatesGuest);
