import toHeaders from '../utils/toHeaders';
import CheckoutResponse from '../../business-logic/models/PayCheckoutResponse';
import asService, { ServiceArgs } from '../utils/asService';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const payCheckout = async ({
    accessToken,
    paymentId,
    paymentMethodId,
    setAsDefault,
    axios,
}: {
    accessToken: string;
    paymentId: string;
    paymentMethodId?: string;
    setAsDefault?: boolean;
} & ServiceArgs): Promise<CheckoutResponse> => {
    const body = paymentMethodId ? { stripePaymentMethodId: paymentMethodId, setAsDefault } : undefined;

    const { data } = await axios.put<CheckoutResponse>(
        `${baseApiPath}/api/v1/billing/stripe/checkout/${paymentId}`,
        body,
        toHeaders(accessToken),
    );

    return data;
};

export default asService(payCheckout);
