import LocalizedStrings from 'react-localization';

const manageCoverItemCardsContent = new LocalizedStrings({
    en: {
        yourCover: 'Your cover{numberOfCovers}',
        firstNameCover: "{firstName}'s cover{numberOfCovers}",
    },
});

export default manageCoverItemCardsContent;
