import { FC } from 'react';
import { AddressFields } from '../../../../business-logic/models/Address';
import Dropdown, { OptionType } from '../../../../components/dropdown/Dropdown';
import TextField from '../../../../components/text-field/TextField';
import claimsFlow from '../../../../strings/claimsFlow';
import InputType from '../../../../utils/constants/InputType';

interface AustralianAddressFieldsProps {
    addressFields: AddressFields;
    updateAddressFieldValues: (key: keyof AddressFields, value: string | OptionType | null) => void;
}

const AustralianAddressFields: FC<AustralianAddressFieldsProps> = ({ addressFields, updateAddressFieldValues }) => {
    const { claimAccidentLocation: contentStrings } = claimsFlow;

    return (
        <>
            <TextField
                id="address-line-1"
                name={contentStrings.labels.addressLine1}
                label={contentStrings.labels.addressLine1}
                value={addressFields.address1}
                onChange={(e) => updateAddressFieldValues('address1', e.target.value)}
            />
            <TextField
                id="address-line-2"
                name={contentStrings.labels.addressLine2}
                label={contentStrings.labels.addressLine2}
                value={addressFields.address2}
                onChange={(e) => updateAddressFieldValues('address2', e.target.value)}
            />
            <TextField
                id="suburb"
                name={contentStrings.labels.suburb}
                label={contentStrings.labels.suburb}
                value={addressFields.suburb}
                onChange={(e) => updateAddressFieldValues('suburb', e.target.value)}
            />
            <div className="claim-accident-location__state-postcode">
                <Dropdown
                    options={contentStrings.stateOptions}
                    label={contentStrings.labels.state}
                    value={addressFields.state}
                    onChange={(value) => updateAddressFieldValues('state', value)}
                />
                <TextField
                    id="postcode"
                    name={contentStrings.labels.postcode}
                    label={contentStrings.labels.postcode}
                    value={addressFields.postcode}
                    type={InputType.NUMBER}
                    maxLength={4}
                    onChange={(e) => updateAddressFieldValues('postcode', e.target.value)}
                />
            </div>
        </>
    );
};

export default AustralianAddressFields;
