import React, { FC } from 'react';
import notCoveredImg from '../../../../assets/images/not-covered/illegal-act.svg';
import coveredImg from '../../../../assets/images/product/icon-covered-green.svg';
import './ExampleCard.scss';

type TProps = {
    covered: string;
    notCovered: string;
    notCoveredTitle: string;
    coveredTitle: string;
};

const ExampleCard: FC<TProps> = ({ covered, notCovered, coveredTitle, notCoveredTitle }) => {
    return (
        <div className="example-card">
            <div className="example-card__wrapper example-card__not-covered">
                <img src={notCoveredImg} alt="illegal-act" />
                <p className="example-card__title">{notCoveredTitle}</p>
                <p className="example-card__content">{notCovered}</p>
            </div>
            <div className="example-card__wrapper example-card__covered">
                <img src={coveredImg} alt="icon-covered-green" />
                <p className="example-card__title">{coveredTitle}</p>
                <p className="example-card__content">{covered}</p>
            </div>
        </div>
    );
};

export default ExampleCard;
