import { LoadingButtonProps } from '../../../loading-button/LoadingButton';
import { PAYMENT_ELEMENT_MACHINE_TAGS } from '../payment-element-machine/paymentElementMachine';

export default (state: { hasTag: (s: string) => boolean }): LoadingButtonProps['status'] => {
    if (state.hasTag(PAYMENT_ELEMENT_MACHINE_TAGS.CHECKOUT_SUCCESS)) {
        return 'success';
    }
    if (state.hasTag(PAYMENT_ELEMENT_MACHINE_TAGS.CHECKOUT_LOADING)) {
        return 'loading';
    }
    return 'idle';
};
