import React, { useEffect } from 'react';
import { Layout } from '../../../components/layout/Layout';
import quizFlow from '../../../strings/quizFlow';
import QuizRadio from '../components/quiz-radio/QuizRadio';
import { useUser } from '../../../business-logic/context-provider/user-context';
import Routes from '../../../utils/Routes';
import Analytics from '../../../analytics/Analytics';
import useCustomHistory from '../hooks/useCustomHistory';

const WorkAccidents: React.FC = () => {
    const { navigate } = useCustomHistory();
    const { trackOnboardingQuizAnswer } = Analytics;

    const {
        setQuizAnswer,
        onboardingQuizAnswers: { existingInjury, workAccidents },
    } = useUser();

    useEffect(() => {
        if (typeof existingInjury === 'undefined') {
            navigate(Routes.ONBOARDING_QUIZ);
        }
    }, [existingInjury, navigate]);

    const onChangeHandler = (value: boolean) => {
        setQuizAnswer({ workAccidents: value });
        trackOnboardingQuizAnswer('work-accidents', value ? 'yes' : 'no');
        setTimeout(() => {
            navigate(Routes.ONBOARDING_QUIZ_MOTORCYCLING);
        }, 300);
    };

    return (
        <Layout
            isContentCentered
            bgPattern={false}
            showPdsVersion={false}
            totalStepCount={3}
            currentStepCount={2}
            title={quizFlow.workAccidents.title}
        >
            <QuizRadio onChange={onChangeHandler} value={workAccidents} />
        </Layout>
    );
};

export default WorkAccidents;
