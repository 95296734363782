import compareAsc from 'date-fns/compareAsc';
import parse from 'date-fns/parse';
import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import CoverSelection from '../../../../business-logic/models/CoverSelection';
import MultiProductEncodedCart from '../../../../business-logic/models/MultiProductEncodedCart';
import UserDetails from '../../../../business-logic/models/UserDetails';
import Cover from '../../../../utils/constants/Cover';
import CoverInformation from '../../../../utils/constants/CoverInformation';
import DateFormat from '../../../../utils/constants/DateFormat';
import isKidsCover from '../../../../utils/isKidsCover';
import GuestCartStep, { GuestCartStepsWithKids, GuestCartStepsWithoutKids } from '../../constants/GuestCartStep';
import { GuestCartMachineContextTypes } from '../context/guestCartMachineContext';
import { Typegen0 } from '../guestCartMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

const sortVariantByDate = (a: string, b: string) =>
    compareAsc(parse(a, DateFormat.DEFAULT, new Date()), parse(b, DateFormat.DEFAULT, new Date()));

export const setMultiProductEncodedCart = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setMultiProductEncodedCart']; data: MultiProductEncodedCart | null }
>({
    multiProductEncodedCart: (ctx, event) => event.data,
});

export const setMultiProductNumKids = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setMultiProductNumKids'] }
>({
    numKids: (ctx) =>
        ctx.multiProductEncodedCart?.items.find((i) => {
            const cover = Object.entries(CoverInformation).find(
                ([, info]) => info.coverCode === i.productId,
            )![0] as Cover;

            return isKidsCover(cover);
        })!.quantity as number,
});

export const setGuestCartStepsWithoutKids = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setGuestCartStepsWithoutKids'] }
>({
    steps: () => GuestCartStepsWithoutKids,
});

export const setGuestCartStepsWithKids = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setGuestCartStepsWithKids'] }
>({
    steps: () => GuestCartStepsWithKids,
});

export const setProfileStep = assign<
    GuestCartMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['setProfileStep'] }
>({
    currentStep: () => GuestCartStep.PROFILE,
});

export const setKidsDetailsStep = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setKidsDetailsStep'] }
>({
    currentStep: () => GuestCartStep.KIDS_DETAILS,
});

export const setReviewCoverStep = assign<
    GuestCartMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'KIDS_DETAILS_COMPLETE'> }
    | { type: 'KIDS_DETAILS_COMPLETE'; data: { dependants: UserDetails[] } }
>({
    currentStep: () => GuestCartStep.REVIEW_COVER,
});

export const setPaymentStep = assign<GuestCartMachineContextTypes, { type: EventsCausingActions['setPaymentStep'] }>({
    currentStep: () => GuestCartStep.PAYMENT,
});

export const showProfileStep = assign<
    GuestCartMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['showProfileStep'] }
>({
    showStep: () => GuestCartStep.PROFILE,
});

export const showKidsDetailsStep = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['showKidsDetailsStep'] }
>({
    showStep: () => GuestCartStep.KIDS_DETAILS,
});

export const showPaymentStep = assign<GuestCartMachineContextTypes, { type: EventsCausingActions['showPaymentStep'] }>({
    showStep: () => {
        return GuestCartStep.PAYMENT;
    },
});

export const showReviewCoverStep = assign<
    GuestCartMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'KIDS_DETAILS_COMPLETE'> }
    | { type: 'KIDS_DETAILS_COMPLETE'; data: { dependants: UserDetails[] } }
>({
    showStep: () => GuestCartStep.REVIEW_COVER,
});

export const setReferralCodeValidated = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setReferralCodeValidated']; data: boolean | null }
>({
    referralCodeValidated: (ctx, event) => event.data,
});

export const setMultiProductCoverSelections = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['setMultiProductCoverSelections']; data: MultiProductEncodedCart | null }
>({
    coverSelections: (ctx, event) =>
        event.data!.items.reduce<CoverSelection[]>((acc, cur) => {
            return [
                ...acc,
                ...cur.variant.sort(sortVariantByDate).map((date) => ({
                    selectedCover: Object.entries(CoverInformation).find(
                        ([, info]) => info.coverCode === cur.productId,
                    )![0] as Cover,
                    coverStartDate: date,
                    personId: null,
                    timezone: cur.startingRegion?.timezone || null,
                    ...(!!cur.startingRegion &&
                        !!cur.destinations && {
                            destination: {
                                destinations: [...cur.destinations],
                                startingRegion: {
                                    region: cur.startingRegion.region,
                                    timeZone: cur.startingRegion.timezone,
                                },
                            },
                        }),
                })),
            ];
        }, []),
});

export const updateCoverSelectionWithDependants = assign<
    GuestCartMachineContextTypes,
    { type: EventsCausingActions['updateCoverSelectionWithDependants']; data: { dependants: UserDetails[] } }
>({
    coverSelections: (ctx, event) =>
        ctx.coverSelections.reduce<CoverSelection[]>((acc, cur) => {
            if (isKidsCover(cur.selectedCover)) {
                return [
                    ...acc,
                    ...event.data.dependants.map((d) => ({
                        ...cur,
                        personId: d.personId,
                    })),
                ];
            }

            return [...acc, cur];
        }, []),
});

// // Analytics

export const trackProfileStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(GuestCartStep.PROFILE + 1, 'Your Details');
};

export const trackKidsDetailsStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(GuestCartStep.KIDS_DETAILS + 1, 'Kids Details');
};

export const trackReviewCoverStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(GuestCartStep.REVIEW_COVER + 1, 'Review Cover');
};

export const trackPaymentStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(GuestCartStep.PAYMENT + 1, 'Payment Details');
};

export const trackProfileStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(GuestCartStep.PROFILE + 1, 'Your Details');
};

export const trackKidsDetailsStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(GuestCartStep.KIDS_DETAILS + 1, 'Kids Details');
};

export const trackReviewCoverStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(GuestCartStep.REVIEW_COVER + 1, 'Review Cover');
};

export const trackPaymentStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(GuestCartStep.PAYMENT + 1, 'Payment Details');
};

export const trackCheckoutCompleted = (): void => {
    Analytics.trackCheckoutCompleted();
};
