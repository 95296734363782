import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useUser } from '../../business-logic/context-provider/user-context';
import Address from '../../business-logic/models/Address';
import ClaimDetails from '../../business-logic/models/ClaimDetails';
import FileUploadTokens from '../../business-logic/models/FileUploadTokens';
import claimsFlowStrings from '../../strings/claimsFlow';
import commonStrings from '../../strings/common';
import { addressToString } from '../../utils/addressUtils';
import booleanToYesOrNo from '../../utils/booleanToYesOrNo';
import filesToReviewString from '../../utils/claims/filesToReviewString';
import getClaimType from '../../utils/claims/getClaimType';
import ClaimStatuses from '../../utils/constants/ClaimStatuses';
import { List } from '../list/List';
import ClaimFileUploadItem from './ClaimFileUploadItem/ClaimFileUploadItem';
import WitnessesItem from './WitnessesItem/WitnessesItem';

import './ClaimDetailsList.scss';

/**
 * This component is currently used on the Review Claim & Claim Details screen
 * Both display the same information, except that the Claim details screen shows some additional
 * info and allows more files to be uploaded ONLY when the claim is not in draft or approved
 */

export type ClaimDetailsListTypes = ClaimDetails & {
    accidentLocation: Address | string;
    injuriesById?: string[];
    status?: string;
    number?: number;
};

export interface ClaimDetailsListProps {
    claimDetails: ClaimDetailsListTypes;
    isClaimDetailsScreen?: boolean;
    fileUploadTokens: FileUploadTokens;
    isAustraliaNewZealandClaim: boolean;
}

const ClaimDetailsList: React.FC<ClaimDetailsListProps> = ({
    claimDetails,
    isClaimDetailsScreen = false,
    fileUploadTokens,
    isAustraliaNewZealandClaim,
}) => {
    const {
        claimDetailsList,
        claimDetailsList: { overViewListHeaders, whatHappenedListHeaders, theEvidenceListHeaders },
    } = claimsFlowStrings;

    const {
        insuredPersonId,
        date,
        time,
        accidentDescription,
        accidentLocation,
        firstTreatmentDate,
        injuries,
        injuriesById,
        wasMajorSurgeryRequired,
        wasEmergencyServicesRequired,
        medicalReport,
        receipts,
        proofOfAccident,
        proofOfActivity,
        witnesses,
        payeeBankDetails: { accountName, accountNumber, bsb },
        status,
        number,
        thirdPartyConsent,
        healthInfoAccessAuthOneConsent,
        healthInfoAccessAuthTwoConsent,
    } = claimDetails;

    const dashSymbol = '-';

    const formattedInjuries = isClaimDetailsScreen
        ? getClaimType(injuriesById || [])
        : injuries.map((injury: any) => injury.label).join(', ');

    const { dependants, userDetails } = useUser();
    const nameOfInjuredPerson = useMemo(() => {
        if (insuredPersonId === userDetails.personId) {
            return `${userDetails.firstName} ${userDetails.lastName}`;
        }

        const dependant = dependants.find((d) => d.personId === insuredPersonId);
        if (dependant) {
            return `${dependant.firstName} ${dependant.lastName}`;
        }

        return `${userDetails.firstName} ${userDetails.lastName}`;
    }, [dependants, insuredPersonId, userDetails.firstName, userDetails.lastName, userDetails.personId]);

    const overViewList = [
        {
            id: 'injury-status',
            header: overViewListHeaders.status,
            customDataElement: (
                <h3
                    className={classNames(
                        'claim-details-list__status',
                        status === ClaimStatuses.APPROVED && 'claim-details-list__status--approved',
                    )}
                >
                    {status}
                </h3>
            ),
        },
        {
            id: 'claim-number',
            header: overViewListHeaders.claimNumber,
            customDataElement: <h3 className="claim-details-list__number">{number}</h3>,
        },
    ];

    const whatHappenedList = [
        {
            id: 'who',
            header: whatHappenedListHeaders.who,
            data: nameOfInjuredPerson,
        },
        {
            id: 'where',
            header: whatHappenedListHeaders.where,
            data:
                (typeof accidentLocation === 'string' ? accidentLocation : addressToString(accidentLocation)) ||
                dashSymbol,
        },
        {
            id: 'injury-type',
            header: whatHappenedListHeaders.injuryType,
            data: formattedInjuries === 'Injury' ? dashSymbol : formattedInjuries,
        },
        {
            id: 'accident',
            header: whatHappenedListHeaders.accident,
            data: accidentDescription || dashSymbol,
        },
        {
            id: 'when',
            header: whatHappenedListHeaders.when,
            data: (time && date && `${date}, ${time?.toUpperCase()}`) || dashSymbol,
        },
        {
            id: 'treatment-date',
            header: whatHappenedListHeaders.treatmentDate,
            data: firstTreatmentDate || dashSymbol,
        },
        {
            id: 'major-surgery',
            header: whatHappenedListHeaders.majorSurgery,
            data: booleanToYesOrNo(wasMajorSurgeryRequired),
        },
        {
            id: 'emergency-services',
            header: whatHappenedListHeaders.emergencyServices,
            data: booleanToYesOrNo(wasEmergencyServicesRequired),
        },
        {
            id: 'third-party-consent',
            header: whatHappenedListHeaders.thirdPartyConsent,
            data: booleanToYesOrNo(thirdPartyConsent),
        },
        {
            id: 'medical-consent',
            header: whatHappenedListHeaders.medicalConsent,
            data: booleanToYesOrNo(healthInfoAccessAuthOneConsent && healthInfoAccessAuthTwoConsent),
        },
    ];

    const evidenceList = [
        {
            id: 'medical-report',
            header: theEvidenceListHeaders.medicalReport,
            data: filesToReviewString(medicalReport),
        },
        {
            id: 'witness-details',
            header: theEvidenceListHeaders.witnessDetails,
            customDataElement: <WitnessesItem witnesses={witnesses} />,
        },
        {
            id: 'photo-of-accident',
            header: theEvidenceListHeaders.photoOfAccident,
            data: filesToReviewString(proofOfAccident),
        },
        {
            id: 'proof-of-activity',
            header: theEvidenceListHeaders.proofOfActivity,
            data: filesToReviewString(proofOfActivity),
        },
    ];

    const evidenceListWithFileUploading = [
        {
            id: 'medical-report',
            header: theEvidenceListHeaders.medicalReport,
            customDataElement: (
                <ClaimFileUploadItem
                    uploadButtonLabel={claimDetailsList.addMoreMedicalReports}
                    newUploadsSuccessLabel={theEvidenceListHeaders.newMedicalReportUploaded}
                    claimStatus={status || ''}
                    accessToken={fileUploadTokens.accessToken}
                    folderId={fileUploadTokens.folderIds.medicalReport}
                />
            ),
        },
        {
            id: 'witness-details',
            header: theEvidenceListHeaders.witnessDetails,
            customDataElement: <WitnessesItem witnesses={witnesses} />,
        },
    ];

    // TODO: revise this implementation upon Claims 2.0
    if (!isAustraliaNewZealandClaim) {
        evidenceListWithFileUploading.splice(1, 0, {
            id: 'overseas-receipts',
            header: theEvidenceListHeaders.receipts,
            customDataElement: (
                <ClaimFileUploadItem
                    uploadButtonLabel={claimDetailsList.addMoreOverseasReceipts}
                    newUploadsSuccessLabel={theEvidenceListHeaders.newReceiptsUploaded}
                    claimStatus={status || ''}
                    accessToken={fileUploadTokens.accessToken}
                    folderId={fileUploadTokens.folderIds.receipts}
                />
            ),
        });
        evidenceList.splice(1, 0, {
            id: 'overseas-cost',
            header: theEvidenceListHeaders.receipts,
            data: filesToReviewString(receipts),
        });
    }

    const bankDetailsList = [
        {
            id: 'acc-name',
            header: commonStrings.accountName,
            data: accountName || dashSymbol,
        },
        { id: 'bsb', header: commonStrings.bsb, data: bsb || dashSymbol },
        {
            id: 'acc-num',
            header: commonStrings.accountNumber,
            data: accountNumber || dashSymbol,
        },
    ];

    return (
        <div className="claim-details-list">
            {isClaimDetailsScreen && <List header={claimDetailsList.overview} items={overViewList} />}
            <List header={claimDetailsList.whatHappened} items={whatHappenedList} />
            <List
                header={claimDetailsList.theEvidence}
                items={isClaimDetailsScreen ? evidenceListWithFileUploading : evidenceList}
            />
            <List header={claimDetailsList.bankDetails} items={bankDetailsList} />
        </div>
    );
};

export default ClaimDetailsList;
