import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosPromise } from 'axios';
import getUnixTime from 'date-fns/getUnixTime';
import parse from 'date-fns/parse';
import ClaimDetails from '../business-logic/models/ClaimDetails';
import { addressToString } from '../utils/addressUtils';
import DateFormat from '../utils/constants/DateFormat';
import toHeaders from './utils/toHeaders';

// TODO: Transition to async/await pattern

const baseUrl = process.env.REACT_APP_BASE_API_PATH;

const toClaimsUrl = (query = '') => `${baseUrl}/api/claim${query}`;

const createClaim = (accessToken: string | undefined): AxiosPromise =>
    axios
        .post(toClaimsUrl(), {}, toHeaders(accessToken))
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'createClaim',
                description: 'Failed to create claim',
            });
            throw new Error(error);
        });

const submitClaim = (accessToken: string | undefined, claimDetails: ClaimDetails): AxiosPromise => {
    const {
        id,
        insuredPersonId,
        accidentDescription,
        accidentLocation,
        injuries,
        date,
        time,
        firstTreatmentDate,
        didAccidentHappenInAustralia,
        wasMajorSurgeryRequired,
        wasEmergencyServicesRequired,
        witnesses,
        hasSigned,
        payeeBankDetails: { accountName, accountNumber, bsb },
        thirdPartyConsent,
        thirdPartyConsentVersion,
        healthInfoAccessAuthOneConsent,
        healthInfoAccessAuthOneConsentVersion,
        healthInfoAccessAuthTwoConsent,
        healthInfoAccessAuthTwoConsentVersion,
    } = claimDetails;

    const fullDate = getUnixTime(parse(`${date} ${time}`, DateFormat.DATE_AND_TIME, new Date()));
    const fullFirstTreatmentDate = getUnixTime(parse(firstTreatmentDate, DateFormat.DEFAULT, new Date()));

    const body = {
        description: accidentDescription,
        domestic: didAccidentHappenInAustralia,
        emergency: wasEmergencyServicesRequired,
        injuryDate: fullDate,
        injuryLocation: addressToString(accidentLocation),
        injuryTypes: injuries.map((injury) => injury.id),
        surgery: wasMajorSurgeryRequired,
        treatedAt: fullFirstTreatmentDate,
        witnesses,
        hasSigned,
        payeeBankDetails: { accountName, accountNumber, bsb },
        thirdPartyConsent,
        thirdPartyConsentVersion,
        healthInfoAccessAuthOneConsent,
        healthInfoAccessAuthOneConsentVersion,
        healthInfoAccessAuthTwoConsent,
        healthInfoAccessAuthTwoConsentVersion,
        insuredPersonId,
    };

    return axios
        .put(toClaimsUrl(`/${encodeURI(id)}`), body, toHeaders(accessToken))
        .then((response) => response)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'submitClaim',
                description: 'Failed to submit claim',
                data: { id, body },
            });
            throw new Error(error);
        });
};

const getClaimsStatus = (accessToken: string | undefined): AxiosPromise =>
    axios
        .get(toClaimsUrl('/status'), toHeaders(accessToken))
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'getClaimsStatus',
                description: 'Failed to get claims status',
            });
            throw new Error(error);
        });

const getClaimBoxUploadToken = (id: string, accessToken: string | undefined): AxiosPromise =>
    axios
        .get(toClaimsUrl(`/${id}`), toHeaders(accessToken))
        .then((response) => response.data)
        .catch((error) => {
            datadogRum.addError(new Error(error), {
                serviceName: 'getClaimBoxUploadToken',
                description: 'Failed to get box upload token for claims',
            });
            throw new Error(error);
        });

export default { createClaim, submitClaim, getClaimsStatus, getClaimBoxUploadToken };
