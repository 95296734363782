import classNames from 'classnames';
import { FC, ReactNode } from 'react';

const PriceLineItem: FC<{
    description: string | ReactNode;
    amount: string;
    withTopSpacing?: boolean;
    className?: string;
}> = ({ description, amount, withTopSpacing, className }) => (
    <div
        className={classNames(
            'checkout-summary__price-line-item',
            {
                'checkout-summary__price-line-item--top-spacing': withTopSpacing,
            },
            className,
        )}
    >
        <span>{description}</span>
        <span>
            <strong>{amount}</strong>
        </span>
    </div>
);

PriceLineItem.defaultProps = {
    withTopSpacing: false,
};

export default PriceLineItem;
