import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MultipleInjuriesIconWithPattern from '../../../assets/images/injuries/multiple-injuries-with-pattern.svg';
import OtherIconWithPattern from '../../../assets/images/injuries/other-with-pattern.svg';
import SearchingIllustration from '../../../assets/images/searching-illustration.svg';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import { useUser } from '../../../business-logic/context-provider/user-context';
import ClaimStatusResponse from '../../../business-logic/models/ClaimStatusResponse';
import ActionList from '../../../components/action-list/ActionList';
import { ActionItemProps } from '../../../components/action-list/action-item/ActionItem';
import { Button } from '../../../components/button/Button';
import { Dashboard, DashboardCard } from '../../../components/dashboard/Dashboard';
import { EmptyState } from '../../../components/empty-state/EmptyState';
import requireFlags from '../../../hoc/require-flags/requireFlags';
import useLazyDependency from '../../../hooks/lazy-dependency/useLazyDependency';
import ClaimsService from '../../../services/ClaimsService';
import claimsFlowStrings from '../../../strings/claimsFlow';
import Routes from '../../../utils/Routes';
import getClaimType from '../../../utils/claims/getClaimType';
import ClaimStatuses from '../../../utils/constants/ClaimStatuses';
import Injuries from '../../../utils/constants/Injuries';
import HowToMakeAClaim from './how-to-make-a-claim/HowToMakeAClaim';

import './Claims.scss';

const Claims: React.FC = () => {
    const { accessToken, totalPurchases: tpLazyDepObj, dependants } = useUser();
    const totalPurchases = useLazyDependency(tpLazyDepObj);
    const { setExistingClaims, clearCurrentClaimDetails, setClaimDetailsByAttr } = useClaim();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const { claims } = claimsFlowStrings;

    const [errorGettingExistingClaims, setErrorGettingExistingClaims] = useState(false);
    const [existingClaimsRaw, setExistingClaimsRaw] = useState<ClaimStatusResponse[]>([]);

    useEffect(() => {
        // don't go forward if accessToken not there yet or claims already fetched
        if (accessToken === undefined || existingClaimsRaw.length > 0) return;

        try {
            setIsLoading(true);

            ClaimsService.getClaimsStatus(accessToken).then((existingClaims: any) => {
                const claimsList = existingClaims || [];
                setExistingClaimsRaw(claimsList);
                setExistingClaims(claimsList);
            });

            // TODO: This isn't ideal, have some ideas on how we can do better at
            // invalidating data on purchase, logging this as an optimisation
            totalPurchases.fetch();

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            setErrorGettingExistingClaims(true);
        }
    }, [accessToken]);

    const handleMakeClaimClick = () => {
        // Clear any previous claim states that may be hanging around

        clearCurrentClaimDetails();
        setClaimDetailsByAttr('currentClaimStarted', true);
        history.push(dependants.length > 0 ? Routes.CLAIM_PERSONS_INSURED : Routes.CLAIM_TYPE);
    };

    const getClaimIcon = (injury: string[]) => {
        // no injuries
        if (injury.length === 0) return OtherIconWithPattern;
        // multiple injuries
        if (injury.length > 1) return MultipleInjuriesIconWithPattern;
        // icon from injuries list
        return Injuries[injury[0]]?.iconWithPattern;
    };

    const buildExistingClaimsList = (): WithReactKey<ActionItemProps>[] => {
        if (existingClaimsRaw === null) return [];

        return existingClaimsRaw.map((claim) => {
            const injury = claim.injuryTypes;

            return {
                key: claim.id,
                header: getClaimType(injury),
                leftIcon: getClaimIcon(injury),
                customDataElement: (
                    <p className="claims__item">
                        {claims.status}
                        <span
                            className={classNames(claim.status === ClaimStatuses.APPROVED && 'claims__item--approved')}
                        >
                            {claim.status}
                        </span>
                    </p>
                ),
                onClick: () => {
                    history.push(Routes.CLAIM_DETAILS.replace(':claimId', claim.id));
                },
                ariaLabel: `View claim ${getClaimType(injury)}`,
            };
        });
    };

    const existingClaimsList = buildExistingClaimsList();
    const hasExistingClaims = existingClaimsList.length > 0;
    const shouldShowExistingClaims = hasExistingClaims && !errorGettingExistingClaims;
    // can claim if total purchases is more than 0
    const canMakeClaim = totalPurchases.value && totalPurchases.value > 0;

    return (
        <Dashboard title={claims.title} loading={isLoading}>
            <DashboardCard
                className={classNames(
                    'claims__card',
                    !hasExistingClaims && 'claims__card--empty',
                    !canMakeClaim && 'claims__card--cant-make-claim',
                )}
            >
                {!!canMakeClaim && (
                    <div className="claims__title-container">
                        <Button
                            label={claims.buttonLabel}
                            className="claims__btn--desktop"
                            onClick={handleMakeClaimClick}
                            disabled={!canMakeClaim}
                        />
                    </div>
                )}
                {!hasExistingClaims && (
                    <>
                        <EmptyState
                            className="claims__empty-state--mobile"
                            icon={SearchingIllustration}
                            title={claims.emptyState.title}
                            description={
                                canMakeClaim ? claims.emptyState.mobileDescription : claims.noPurchaseDescription
                            }
                        />
                        <EmptyState
                            className="claims__empty-state--desktop"
                            icon={SearchingIllustration}
                            title={claims.emptyState.title}
                            description={
                                canMakeClaim ? claims.emptyState.desktopDescription : claims.noPurchaseDescription
                            }
                        />
                    </>
                )}
                {!!canMakeClaim && (
                    <Button
                        label={claims.buttonLabel}
                        width="full"
                        className="claims__btn--mobile"
                        onClick={handleMakeClaimClick}
                        disabled={!canMakeClaim}
                    />
                )}
                {shouldShowExistingClaims && <ActionList className="claims__list" items={existingClaimsList} />}
            </DashboardCard>
            <HowToMakeAClaim />
        </Dashboard>
    );
};

export default requireFlags(Claims);
