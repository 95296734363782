import coverTerms from '../content/by-product/cover-terms/coverTerms';
import { CoverInfo } from '../content/by-product/cover-terms/types';
import Cover from './constants/Cover';
import {
    AlwaysOnPdsVersion,
    DayPassPdsVersion,
    FlipActiveDailyPdsVersion,
    FlipActiveSubMonthlyPdsVersion,
    FlipActiveWeeklyPdsVersion,
    FlipKidsDailyPdsVersion,
    FlipKidsSubMonthlyPdsVersion,
    FlipKidsWeeklyPdsVersion,
    JapanPassPdsVersion,
    FlipRoamingPdsVersion,
    FlipRoamingKidsPdsVersion,
    LatestAlwaysOnPdsVersion,
    LatestDayPassPdsVersion,
    LatestFlipActiveDailyPdsVersion,
    LatestFlipActiveSubMonthlyPdsVersion,
    LatestFlipActiveWeeklyPdsVersion,
    LatestFlipKidsDailyPdsVersion,
    LatestFlipKidsSubMonthlyPdsVersion,
    LatestFlipKidsWeeklyPdsVersion,
    LatestJapanPassPdsVersion,
    LatestFlipRoamingPdsVersion,
    LatestFlipRoamingKidsPdsVersion,
} from './constants/PdsVersion';

const getCoverTerms = (
    cover: Cover,
    pdsVersion?:
        | AlwaysOnPdsVersion
        | DayPassPdsVersion
        | JapanPassPdsVersion
        | FlipActiveDailyPdsVersion
        | FlipActiveSubMonthlyPdsVersion
        | FlipActiveWeeklyPdsVersion
        | FlipKidsDailyPdsVersion
        | FlipKidsSubMonthlyPdsVersion
        | FlipKidsWeeklyPdsVersion
        | FlipRoamingPdsVersion
        | FlipRoamingKidsPdsVersion,
): CoverInfo => {
    if (cover === Cover.DAY_PASS) {
        return pdsVersion
            ? coverTerms.dayPass[pdsVersion as DayPassPdsVersion]
            : coverTerms.dayPass[LatestDayPassPdsVersion];
    }

    if (cover === Cover.JAPAN_PASS) {
        return pdsVersion
            ? coverTerms.japanPass[pdsVersion as JapanPassPdsVersion]
            : coverTerms.japanPass[LatestJapanPassPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_DAILY) {
        return pdsVersion
            ? coverTerms.flipActiveDaily[pdsVersion as FlipActiveDailyPdsVersion]
            : coverTerms.flipActiveDaily[LatestFlipActiveDailyPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_WEEKLY) {
        return pdsVersion
            ? coverTerms.flipActiveWeekly[pdsVersion as FlipActiveWeeklyPdsVersion]
            : coverTerms.flipActiveWeekly[LatestFlipActiveWeeklyPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_SUB_MONTHLY) {
        return pdsVersion
            ? coverTerms.flipActiveSubMonthly[pdsVersion as FlipActiveSubMonthlyPdsVersion]
            : coverTerms.flipActiveSubMonthly[LatestFlipActiveSubMonthlyPdsVersion];
    }

    if (cover === Cover.FLIP_KIDS_DAILY) {
        return pdsVersion
            ? coverTerms.flipKidsDaily[pdsVersion as FlipKidsDailyPdsVersion]
            : coverTerms.flipKidsDaily[LatestFlipKidsDailyPdsVersion];
    }

    if (cover === Cover.FLIP_KIDS_WEEKLY) {
        return pdsVersion
            ? coverTerms.flipKidsWeekly[pdsVersion as FlipKidsWeeklyPdsVersion]
            : coverTerms.flipKidsWeekly[LatestFlipKidsWeeklyPdsVersion];
    }

    if (cover === Cover.FLIP_KIDS_SUB_MONTHLY) {
        return pdsVersion
            ? coverTerms.flipKidsSubMonthly[pdsVersion as FlipKidsSubMonthlyPdsVersion]
            : coverTerms.flipKidsSubMonthly[LatestFlipKidsSubMonthlyPdsVersion];
    }

    if (cover === Cover.FLIP_ROAMING_WEEKLY) {
        return pdsVersion
            ? coverTerms.flipRoaming[pdsVersion as FlipRoamingPdsVersion]
            : coverTerms.flipRoaming[LatestFlipRoamingPdsVersion];
    }

    if (cover === Cover.FLIP_ROAMING_KIDS_WEEKLY) {
        return pdsVersion
            ? coverTerms.flipRoamingKids[pdsVersion as FlipRoamingKidsPdsVersion]
            : coverTerms.flipRoamingKids[LatestFlipRoamingKidsPdsVersion];
    }

    return pdsVersion
        ? coverTerms.alwaysOn[pdsVersion as AlwaysOnPdsVersion]
        : coverTerms.alwaysOn[LatestAlwaysOnPdsVersion];
};

export default getCoverTerms;
