import { useMachine } from '@xstate/react';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import ProductList from '../../analytics/ProductList';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { CartErrorDisplay } from '../../business-logic/models/Cart';
import checkRoamingAgeEligibility from '../../hoc/check-roaming-age-eligibility/checkRoamingAgeEligibility';
import requireFlags from '../../hoc/require-flags/requireFlags';
import useCart from '../../hooks/use-cart/useCart';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import RoamingDatesBody from './components/RoamingDatesBody';
import roamingDatesMachine from './roaming-dates-authenticated-machine/roamingDatesMachine';

import './RoamingDates.scss';

const RoamingDates = () => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const history = useHistory();
    const { enforceOverlappingRules } = useFlags();

    const { initialised: userContextInitialised, userDetails, accessToken, dependants } = useUser();

    const { policies, openCoversInitialised, setOpenCoversSelections } = useOpenCovers();

    const dependantsCovers = policies.filter(
        (x) => !!dependants.find((y) => y.personId === x.mainCover.insuredPersonId),
    );

    const { initialised: productContextInitialised, latestProductsByPdsVersion } = useProduct();
    const {
        destinations,
        startingRegion,
        timezone,
        coverFor,
        selectedCover: selectedRoamingCover,
        startingDestination,
        selectedKidId,
    } = useRoaming();
    const { createCart } = useCart();

    const [state, send] = useMachine(roamingDatesMachine, {
        context: {
            timezone,
            destinations,
            startingRegion,
            startingDestination,
            coverFor,
            selectedCover: selectedRoamingCover,
            dependantToCover: selectedKidId,
            accessToken,
            enforceOverlappingRules,
        },
        actions: {
            goBack: () => {
                history.goBack();
            },
            goToCheckout: (ctx) => {
                const zonedToday = utcToZonedTime(new Date(), ctx.timezone);
                ctx.daysToSchedule.forEach((date) => {
                    Analytics.trackProductAdded({
                        ...ProductList[ctx.selectedCover!],
                        quantity: 1,
                        variant: formatDateToString(date, DateFormat.ANALYTICS),
                        scheduledFor: isSameDay(zonedToday, date) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                        startingInDays: differenceInCalendarDays(date, zonedToday),
                    });
                });
                history.push({
                    pathname: Routes.CHECKOUT,
                    state: { isOnboarding: isOnboardingFlow },
                });
            },
            redirectToPersonsInsuredScreen: () => history.push(Routes.PERSONS_INSURED),
        },
    });
    useEffect(() => {
        if (userContextInitialised && openCoversInitialised && productContextInitialised) {
            const contextState = {
                startingRegion,
                startingDestination,
                destinations,
                selectedCover: selectedRoamingCover!,
                policies,
                timezone,
                productSpecs: latestProductsByPdsVersion,
            };
            send({
                type: 'CONTEXT_INITIALISED',
                data: contextState,
            });
        }
    }, [
        selectedRoamingCover,
        destinations,
        startingDestination,
        startingRegion,
        send,
        timezone,
        userContextInitialised,
        productContextInitialised,
        latestProductsByPdsVersion,
        coverFor,
        selectedKidId,
        openCoversInitialised,
        policies,
        dependantsCovers,
        userDetails.id,
    ]);

    const { datePickerMode, daysToSchedule, disabledDays } = state.context;

    const handleCalendarOpen = () => {
        Analytics.trackCalendarOpened(ProductList[selectedRoamingCover!]);
    };

    const handleDateSubmission = (dates: Date[]) => {
        const coversToAdd = dates.map((d) => ({
            selectedCover: selectedRoamingCover! as Cover,
            coverStartDate: formatDateToString(d),
            timezone,
            personId: state.context.coverFor === 'dependant' ? state.context.dependantToCover! : userDetails.personId!,
            destination: {
                destinations,
                startingRegion: {
                    region: startingRegion,
                    timeZone: timezone,
                },
            },
        }));

        send({ type: 'SELECT_DATES', data: [...dates] });

        createCart.mutate([...coversToAdd], {
            onError: (err) => {
                send({
                    type: 'CART_CREATE_ERROR',
                    data: (err as Error).cause as CartErrorDisplay,
                });
            },
            onSuccess: () => {
                setOpenCoversSelections([...coversToAdd]);
                send({ type: 'CART_CREATE_SUCCESS' });
            },
        });
    };

    const cartError = createCart.isError ? (createCart.error as Error).cause : null;

    return (
        <RoamingDatesBody
            selectedRoamingCover={selectedRoamingCover as Cover}
            datePickerMode={datePickerMode}
            daysToSchedule={daysToSchedule}
            disabledDays={disabledDays}
            cartError={cartError}
            goBack={() => send({ type: 'GO_BACK' })}
            fixedPaymentModelMode={false}
            cartLoading={createCart.isPaused || createCart.isLoading}
            handleDateSubmission={handleDateSubmission}
            canSelectDates={state.can({ type: 'SELECT_DATES', data: [] })}
            proceedToCheckout={() => send('CHECKOUT')}
            handleCalendarOpen={handleCalendarOpen}
            isOnboardingFlow={location.state ? location.state.isOnboarding : false}
            startingRegion={startingRegion}
            destinations={destinations}
            timezone={timezone}
            coverFor={coverFor}
            openRoamingProductModal={state.matches('showRoamingModal')}
            agreeToConditions={() => send({ type: 'AGREE_CONDITIONS' })}
            canAgreeToConditions={state.can({ type: 'AGREE_CONDITIONS' })}
        />
    );
};

export default requireFlags(checkRoamingAgeEligibility(RoamingDates));
