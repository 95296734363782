import React, { RefObject } from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import DayPicker from 'react-day-picker';
import { DayModifiers, Modifier, Modifiers } from 'react-day-picker/types/Modifiers';
import { Button } from '../button/Button';
import common from '../../strings/common';
import Alert, { AlertTypes } from '../alert/Alert';

import './DatePicker.scss';

export interface DatePickerProps {
    id?: string;
    innerRef?: RefObject<any>;
    onDayClick: (day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
    onSelectClick?: (days: Modifier | Modifier[]) => void;
    isSelectDaysButtonDisabled?: boolean;
    selectedDays?: Modifier | Modifier[];
    monthToShow?: Date;
    fromMonth?: Date;
    toMonth?: Date;
    disabledDays?: Modifier | Modifier[];
    modifiers?: Partial<Modifiers>;
    showYearInCaption?: boolean;
    className?: string;
    error?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
    id,
    innerRef,
    onDayClick,
    onFocus,
    onBlur,
    onSelectClick,
    selectedDays,
    monthToShow,
    fromMonth,
    toMonth,
    disabledDays,
    modifiers,
    showYearInCaption = false,
    isSelectDaysButtonDisabled,
    className,
    error,
}) => {
    return (
        <div
            id={id}
            ref={innerRef}
            className={classNames(onSelectClick !== undefined && 'date-picker--with-select-button', className)}
        >
            <DayPicker
                className={className}
                onFocus={onFocus}
                onBlur={onBlur}
                selectedDays={selectedDays}
                onDayClick={onDayClick}
                month={monthToShow}
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={disabledDays}
                modifiers={modifiers}
                fixedWeeks
                captionElement={({ date }) => (
                    // class name from daypicker needs to be here for
                    // for it to be controlled by parent
                    <div className="DayPicker-Caption">
                        <p>{`${format(date, 'MMMM').toUpperCase()} ${showYearInCaption ? format(date, 'Y') : ''}`}</p>
                    </div>
                )}
            />
            {error && <Alert className="date-picker__alert" message={error} type={AlertTypes.INFO} />}
            {onSelectClick && (
                <Button
                    label={common.select}
                    className={classNames('date-picker__select-btn', error && 'date-picker__select-btn--with-error')}
                    onClick={() => onSelectClick(selectedDays)}
                    disabled={isSelectDaysButtonDisabled}
                />
            )}
        </div>
    );
};
