import React, { useEffect } from 'react';
import { Layout } from '../../../../components/layout/Layout';
import quizFlow from '../../../../strings/quizFlow';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import Button from '../../../../components/button/Button';
import PreExisting from '../../../../assets/images/not-covered/pre-existing.svg';
import NonSportsWork from '../../../../assets/images/not-covered/non-sports-work.svg';
import Motorcycling from '../../../../assets/images/not-covered/motorcycling.svg';
import Routes from '../../../../utils/Routes';
import StorageKeys from '../../../../utils/constants/StorageKeys';
import Analytics from '../../../../analytics/Analytics';
import useCustomHistory from '../../hooks/useCustomHistory';
import './Exclusions.scss';

const Exclusions: React.FC = () => {
    const { title, description, backBtnText, understandBtnText, list } = quizFlow.exclusions;
    const { navigate } = useCustomHistory();

    const {
        onboardingQuizAnswers: { workAccidents, existingInjury, motorcycling },
    } = useUser();

    useEffect(() => {
        if (
            typeof workAccidents === 'undefined' ||
            typeof existingInjury === 'undefined' ||
            typeof motorcycling === 'undefined'
        ) {
            navigate(Routes.ONBOARDING_QUIZ);
        } else {
            sessionStorage.setItem(StorageKeys.IS_QUIZ_COMPLETED, 'true');
        }
    }, [workAccidents, existingInjury, motorcycling, navigate]);

    useEffect(() => {
        Analytics.trackOnboardingQuizCompleted();
    }, []);

    const onUnderstandClickHandler = () => {
        navigate('/signin/register', {
            completedQuiz: 'true',
        });
    };

    const onBackClickHandler = () => {
        navigate('/');
    };

    return (
        <Layout isContentCentered bgPattern={false} showPdsVersion={false} title={title}>
            <ul className="exclusions__list">
                <li className="exclusions__list-item">
                    <img className="exclusions__list-icon" src={PreExisting} alt="" />
                    <p className="exclusions__item-content">{list.injuries}</p>
                </li>
                <li className="exclusions__list-item">
                    <img className="exclusions__list-icon" src={NonSportsWork} alt="" />
                    <p className="exclusions__item-content">{list.accidents}</p>
                </li>
                <li className="exclusions__list-item">
                    <img className="exclusions__list-icon" src={Motorcycling} alt="" />
                    <p className="exclusions__item-content">{list.motorcycling}</p>
                </li>
            </ul>
            <p className="exclusions__description">{description}</p>
            <Button variant="secondary" label={understandBtnText} width="full" onClick={onUnderstandClickHandler} />
            <Button
                className="exclusions__back-btn"
                variant="primary"
                label={backBtnText}
                width="full"
                onClick={onBackClickHandler}
            />
        </Layout>
    );
};

export default Exclusions;
