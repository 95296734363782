import { assign, spawn } from 'xstate';
import kidDetailsFormMachine from '../../../components/kid-details-form/kid-details-form-machine/kidDetailsFormMachine';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';
import { Typegen0 } from '../persons-insured-machine/personsInsuredMachine.typegen';
import UserDetails from '../../../business-logic/models/UserDetails';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setKidSelection = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['setKidSelection']; data: { id: string } }
>({
    selectedKidId: (ctx, event) => event.data.id,
});

export const spawnKidDetailsFormMachine = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['spawnKidDetailsFormMachine'] }
>({
    kidDetailsFormMachineRef: () => spawn(kidDetailsFormMachine, { sync: true }),
});

export const setNewDependantAsSelectedKid = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['setNewDependantAsSelectedKid']; data: UserDetails }
>({
    selectedKidId: (ctx, event) => event.data.personId!,
});

export const setGuestKidsNumbers = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['setGuestKidsNumbers']; data: { numberOfGuestKids: number } }
>({
    numberOfGuestKids: (ctx, event) => event.data.numberOfGuestKids,
});
