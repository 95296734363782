import { FC } from 'react';
import alertIcon from '../../../assets/images/alert-covered.svg';
import { WhatsNotCoveredV1 } from '../../../content/by-product/cover-terms/types';
import notCoveredTabV1 from '../../../content/ui/components/not-covered-tab-v1/notCoveredTabV1';
import withContent from '../../../hoc/with-content/withContent';
import stringToReactKey from '../../../utils/stringToReactKey';
import BulletPointList from '../../bullet-point-list/BulletPointList';
import Card from '../../card/Card';
import { ListItemProps } from '../../list-item/ListItem';
import List from '../../list/List';
import Examples from '../components/examples/Examples';

import './NotCoveredTabV1.scss';

const contentMap = {
    promise: 'ui.promise',
    example: 'ui.example',
};

interface NotCoveredTabV1Props {
    content: Record<keyof typeof contentMap, string>;
    whatsNotCovered: WhatsNotCoveredV1;
    showExamples?: boolean;
}

const NotCoveredTabV1: FC<NotCoveredTabV1Props> = ({ content, whatsNotCovered, showExamples = true }) => {
    const notCoveredList: ListItemProps[] = whatsNotCovered.exclusions.map((exclusion) => ({
        id: stringToReactKey(exclusion.name),
        icon: exclusion.icon,
        data: exclusion.name,
        dataExpanded: <span className="not-covered-tab-v1__item-data-expanded">{exclusion.description}</span>,
    }));

    return (
        <div>
            <Card className="not-covered-tab-v1">
                <img src={alertIcon} alt="alert icon" />
                <p className="not-covered-tab-v1__heading">{whatsNotCovered.description}</p>
                <List items={notCoveredList} className="not-covered-tab-v1__list" />
                {whatsNotCovered.claimLimits && (
                    <>
                        <p>{whatsNotCovered.claimLimits.description}</p>
                        <BulletPointList list={whatsNotCovered.claimLimits.items!} />
                    </>
                )}
                <p className="not-covered-tab-v1__promise">{content.promise}</p>
            </Card>

            {showExamples && <Examples />}
        </div>
    );
};

export default withContent(NotCoveredTabV1, contentMap, notCoveredTabV1);
