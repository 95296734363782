import classNames from 'classnames';
import { FC } from 'react';
import { ProductOption } from '../../../../../business-logic/models/ProductGroupsResponse';

import './CoverTile.scss';

type TProps = {
    value: ProductOption;
    onChange: (value: ProductOption) => void;
    isActive: boolean;
    title: string;
    name?: string | null;
    price: number;
};

const CoverTile: FC<TProps> = ({ onChange, isActive, price, name, value, title }: TProps) => {
    return (
        <div className={classNames('cover-tile')}>
            <label
                htmlFor={value.representedByCoverCode}
                className={classNames('cover-tile__content', {
                    'cover-tile__content--active': isActive,
                })}
            >
                <p className="cover-tile__title">{title}</p>
                <div className="cover-tile__price-section">
                    <p
                        className={classNames('cover-tile__price', {
                            'cover-tile__price--active': isActive,
                        })}
                    >
                        ${price}
                    </p>

                    {name && <p className="cover-tile__description">{name}</p>}

                    <input
                        checked={isActive}
                        name="product_model"
                        type="radio"
                        className="cover-tile__input"
                        id={value.representedByCoverCode}
                        onChange={() => onChange(value)}
                    />
                </div>
            </label>
        </div>
    );
};

export default CoverTile;
