import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const validateReferralCode = async ({
    referralCode,
    axios,
}: { referralCode: string } & ServiceArgs): Promise<boolean> => {
    try {
        const response = await axios.get(`${baseApiPath}/api/v1/pub/referral/code/${encodeURIComponent(referralCode)}`);
        if (response.status === 200) {
            return true;
        }
        return false;
    } catch (error) {
        if (isAxios404Error(error)) {
            return false;
        }
        throw error;
    }
};

export default asService(validateReferralCode, false);
