import Cover from '../../../utils/constants/Cover';
import BoostIcon from '../../../assets/images/boost/boost.svg';
import { ProductInfo } from './types';
import { AlwaysOnPdsVersion } from '../../../utils/constants/PdsVersion';

const alphaProductInfo: ProductInfo = {
    id: Cover.BOOST,
    icon: BoostIcon,
    name: 'Boost',
    group: 'Always On',
    price: '$6',
    priceTimePeriod: 'day',
    description: 'One time payment.',
    primaryFeatures: [
        'Subscribe for full-time cover',
        'Boost to double your payout for the day, for just $6 extra per day',
        'Cancel any time',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Up to <strong>$40k</strong> depending on your injury',
};

const betaProductInfo: ProductInfo = {
    id: Cover.BOOST,
    icon: BoostIcon,
    name: 'Boost',
    group: 'Always On',
    price: '$6',
    priceTimePeriod: 'day',
    description: 'One time payment.',
    primaryFeatures: [
        'Subscribe for full-time cover',
        'Boost to double your payout for the day, for just $6 extra per day',
        'Cancel any time',
    ],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Up to <strong>$40k</strong> depending on your injury',
};

const groguProductInfo: ProductInfo = {
    id: Cover.BOOST,
    icon: BoostIcon,
    name: 'Boost',
    group: 'Always On',
    price: '$6',
    priceTimePeriod: 'day',
    description: 'One time payment.',
    primaryFeatures: ['Weekly subscription, cancel anytime', 'Boost for $6 per day to double your payout for the day'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    highlight: 'Most Popular',
    paymentModel: 'Pay as you go',
    coverUnitDuration: 'Pay as you go',
    paymentModelDescription: 'One time payment',
    benefitDescription: 'Up to <strong>$40k</strong> depending on your injury',
};

const boostProductInfo: Record<AlwaysOnPdsVersion, ProductInfo> = {
    [AlwaysOnPdsVersion.ALPHA_V1]: alphaProductInfo,
    [AlwaysOnPdsVersion.ALPHA_V2]: alphaProductInfo,
    [AlwaysOnPdsVersion.BETA]: betaProductInfo,
    [AlwaysOnPdsVersion.GROGU]: groguProductInfo,
    [AlwaysOnPdsVersion.GROGU_SCHEDULE]: groguProductInfo,
    [AlwaysOnPdsVersion.GROGU_SEPT_UPDATE]: groguProductInfo,
    [AlwaysOnPdsVersion.GROGU_SCHEDULING_LIMIT_UPDATE]: groguProductInfo,
};

export default boostProductInfo;
