import CoverTypeId from '../../../../utils/constants/CoverTypeId';
import { ScheduleCoverAuthenticatedMachineContextTypes } from '../context/scheduleCoverAuthenticatedMachineContext';

export const isPersonsInsuredInfoValid = (ctx: ScheduleCoverAuthenticatedMachineContextTypes): boolean => {
    if (ctx.coverFor === 'self') {
        return true;
    }

    if (ctx.coverFor === 'dependant' && ctx.dependantToCover && ctx.dependantToCover.length > 0) {
        return true;
    }

    return false;
};

export const isSchedulingCoverForDependant = (ctx: ScheduleCoverAuthenticatedMachineContextTypes): boolean => {
    return ctx.coverFor === 'dependant';
};

export const disableOverlappingCovers = (ctx: ScheduleCoverAuthenticatedMachineContextTypes): boolean => {
    return (
        !!(
            ctx.policies.filter(
                (x) =>
                    x.mainCover.coverTypeId === CoverTypeId.SINGLE_V1 &&
                    x.mainCover.status !== 'Canceled' &&
                    (ctx.coverFor === 'dependant'
                        ? x.mainCover.insuredPersonId === ctx.dependantToCover
                        : x.mainCover.insuredPersonId === null),
            ).length > 0
        ) && !!ctx.enforceOverlappingRules
    );
};
