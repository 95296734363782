import Gender from '../../../../../../utils/constants/Gender';
import ResidencyStatusTypes from '../../../../../../utils/constants/ResidencyStatusTypes';
import State from '../../../../../../utils/constants/State';

export interface ProfileMachineContext {
    email: string;
    emailError: string;
    firstName: string;
    lastName: string;
    dob: {
        day: string;
        month: string;
        year: string;
        error: string;
    };
    state: State | 'NOT_IN_AUS' | null;
    residencyStatus: ResidencyStatusTypes | 'TOURIST' | null;
    gender: Gender | null;
    config: {
        nameMinLength: number;
        minAge: number;
        maxAge: number;
    };
    genericError: string;
    isPurchasingKidsCoverOnly: boolean;
}

export const initialContext: ProfileMachineContext = {
    email: '',
    emailError: '',
    firstName: '',
    lastName: '',
    dob: {
        day: '',
        month: '',
        year: '',
        error: '',
    },
    state: null,
    residencyStatus: null,
    gender: null,
    config: {
        nameMinLength: 1,
        minAge: 18,
        maxAge: 64,
    },
    genericError: '',
    isPurchasingKidsCoverOnly: false,
};
