import LocalizedStrings from 'react-localization';

const claimPersonsInsured = new LocalizedStrings({
    en: {
        heading: 'Who do you want to claim for?',
        radioButtonMyselfLabel: 'Myself',
        radioButtonDependantLabel: '{firstName}',
    },
});

export default claimPersonsInsured;
