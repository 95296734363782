import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { Layout } from '../../../components/layout/Layout';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import claimsFlowStrings from '../../../strings/claimsFlow';
import commonStrings from '../../../strings/common';
import Analytics from '../../../analytics/Analytics';
import { ReactComponent as ProofOfActivityQuestionnaireImage } from '../../../assets/images/claims/proof-of-activity-questionnaire.svg';
import SpeedUpYourClaim from '../../../components/speed-up-your-claim/SpeedUpYourClaim';
import { ClaimUploadExampleModal } from '../../../components/claim-upload-example-modal/ClaimUploadExampleModal';
import ClaimExamples from '../../../utils/constants/ClaimExamples';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';

import './ProofOfActivityQuestionnaire.scss';

const ProofOfActivityQuestionnaire: React.FC = () => {
    const { proofOfActivityQuestionnaire: contentStrings } = claimsFlowStrings;

    const history = useHistory();
    const { claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_PROOF_OF_ACTIVITY_QUESTIONNAIRE, claimDetails);
    }, []);

    const handleAnswerClick = (hasProofOfActivity: boolean) => {
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_PROOF_OF_ACTIVITY_QUESTIONNAIRE, claimDetails);

        history.push(hasProofOfActivity ? Routes.PROOF_OF_ACTIVITY : Routes.BANK_DETAILS);
    };

    return (
        <Layout>
            <ProgressBar
                completed={(Constants.CLAIMS_STEP_PROOF_OF_ACTIVITY_QUESTIONNAIRE / Constants.CLAIMS_NO_OF_STEPS) * 100}
            />
            <h1 className="proof-of-activity-questionnaire__header">{contentStrings.title}</h1>
            <SpeedUpYourClaim />
            <div className="proof-of-activity-questionnaire__image-wrapper">
                <ProofOfActivityQuestionnaireImage />
            </div>
            <p className="proof-of-activity-questionnaire__example-description">{contentStrings.description}</p>
            <ClaimUploadExampleModal claimUploadExamples={ClaimExamples.proofOfActivity} />
            <div className="proof-of-activity-questionnaire__btn-group">
                <Button label={commonStrings.no} onClick={() => handleAnswerClick(false)} />
                <Button label={commonStrings.yes} onClick={() => handleAnswerClick(true)} />
            </div>
        </Layout>
    );
};

export default ProofOfActivityQuestionnaire;
