import { SetupIntent, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import toHeaders from '../utils/toHeaders';
import asService, { ServiceArgs } from '../utils/asService';
import SetupIntentAPIResponse from '../../business-logic/models/SetupIntent';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const setupCard = async ({
    accessToken,
    stripe,
    cardNumberElement,
    cardName,
    axios,
}: {
    accessToken: string;
    stripe: Stripe;
    cardNumberElement: StripeCardNumberElement;
    cardName: string;
} & ServiceArgs): Promise<SetupIntent | undefined> => {
    const {
        data: { stripeClientSecret },
    } = await axios.post<SetupIntentAPIResponse>(
        `${baseApiPath}/api/v1/billing/stripe/setup-intent`,
        {},
        toHeaders(accessToken),
    );

    const { setupIntent, error } = await stripe.confirmCardSetup(stripeClientSecret, {
        payment_method: {
            card: cardNumberElement,
            billing_details: {
                name: cardName,
            },
        },
    });

    if (error) {
        throw new Error(error.message);
    }

    return setupIntent;
};

export default asService(setupCard);
