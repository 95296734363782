import { FC } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router';
import Analytics from '../../analytics/Analytics';
import appStoreButton from '../../assets/images/app-store-button.svg';
import playStoreButton from '../../assets/images/play-store-button.svg';
import successIcon from '../../assets/images/success.svg';
import SuccessBgDesktop from '../../assets/lotties/success-page-bg-desktop.json';
import SuccessBgMobile from '../../assets/lotties/success-page-bg-mobile.json';
import SuccessBgTabletLandscape from '../../assets/lotties/success-page-bg-tablet-landscape.json';
import SuccessBgTabletPortrait from '../../assets/lotties/success-page-bg-tablet-portrait.json';
import { useUser } from '../../business-logic/context-provider/user-context';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import LinkButton from '../../components/link-button/LinkButton';
import cartBaymaxSuccessContent from '../../content/ui/screens/cart-baymax-success/cartBaymaxSuccess';
import withContent from '../../hoc/with-content/withContent';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import parseString from '../../utils/parseString';
import Routes from '../../utils/Routes';

import './CartBaymaxSuccess.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
    accessAccountCTA: 'ui.accessAccountCTA',
    accountCreation: 'ui.accountCreation',
    accessAccountCTADescription: 'ui.accessAccountCTADescription',
    downloadTheAppCta: 'ui.downloadTheAppCta',
    appStoreActionText: 'ui.appStoreActionText',
    appStoreStoreName: 'ui.appStoreStoreName',
    productsCta: 'ui.productsCta',
    playStoreActionText: 'ui.playStoreActionText',
    playStoreStoreName: 'ui.playStoreStoreName',
};

interface GuestCartSuccessProps {
    content: Record<keyof typeof contentMap, string>;
}

const CartBaymaxSuccess: FC<GuestCartSuccessProps> = ({ content }) => {
    const {
        userDetails: { firstName },
    } = useUser();
    const history = useHistory();

    const renderDownloadAppButton = (imageSrc: string, accessibleText: string) => {
        return (
            <>
                <img src={imageSrc} alt="" />
                <span className="cart-baymax-success__download-button-hidden-text">{accessibleText}</span>
            </>
        );
    };

    const trackAppstoreRedirect = (platform: string) => {
        Analytics.trackAppstoreRedirect(platform);
    };

    return (
        <Layout className="cart-baymax-success" bgPattern showPdsVersion={false}>
            <div className="cart-baymax__animation-sm">
                <Lottie options={{ animationData: SuccessBgMobile, loop: false }} />
            </div>
            <div className="cart-baymax__animation-md">
                <Lottie options={{ animationData: SuccessBgTabletPortrait, loop: false }} />
            </div>
            <div className="cart-baymax__animation-lg">
                <Lottie options={{ animationData: SuccessBgTabletLandscape, loop: false }} />
            </div>
            <div className="cart-baymax__animation-xl">
                <Lottie options={{ animationData: SuccessBgDesktop, loop: false }} />
            </div>
            <div className="cart-baymax-success__container">
                <img className="cart-baymax-success__success-icon" src={successIcon} alt="" />
                <h1 className="cart-baymax-success__heading">
                    {cartBaymaxSuccessContent.formatString(content.heading, { firstName: firstName || '' })}
                </h1>
                <p className="cart-baymax-success__description">{content.description}</p>
                <div className="cart-baymax-success__acc-create">{parseString(content.accountCreation)}</div>
                <Button
                    className="cart-baymax-success__access-acc-cta"
                    width="full"
                    label={content.accessAccountCTA}
                    onClick={() => history.push(Routes.HOME)}
                />
                <Button
                    className="cart-baymax-success-access-acc-cta cart-baymax-success__product-cta"
                    width="full"
                    variant="secondary"
                    label={content.productsCta}
                    onClick={() => {
                        window.location.href = ExternalLinks.products;
                    }}
                />
                <h2 className="cart-baymax-success__download-app-heading">{content.downloadTheAppCta}</h2>
                <div className="cart-baymax-success__download-app-button-row">
                    <LinkButton
                        label={renderDownloadAppButton(
                            playStoreButton,
                            `${content.playStoreActionText} ${content.playStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppGooglePlayStore}
                        target="_blank"
                        variant="secondary"
                        onClick={() => trackAppstoreRedirect(content.playStoreStoreName)}
                    />
                    <LinkButton
                        label={renderDownloadAppButton(
                            appStoreButton,
                            `${content.appStoreActionText} ${content.appStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppAppleAppStore}
                        target="_blank"
                        variant="secondary"
                        onClick={() => trackAppstoreRedirect(content.appStoreStoreName)}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default withContent(CartBaymaxSuccess, contentMap, cartBaymaxSuccessContent);
