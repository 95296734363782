import LocalizedStrings from 'react-localization';

const medicalReportExamples = new LocalizedStrings({
    en: {
        heading: 'Examples of what to upload',
        items: [
            'Reports from any x-rays, CT scans, ultrasounds or MRIs',
            'Surgery booking confirmation',
            'Hospital discharge papers',
        ],
    },
});

export default medicalReportExamples;
