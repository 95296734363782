import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Analytics from '../../../analytics/Analytics';
import { useClaim } from '../../../business-logic/context-provider/ClaimContext';
import Button from '../../../components/button/Button';
import Layout from '../../../components/layout/Layout';
import ModalWithCTA from '../../../components/modal-with-cta/ModalWithCTA';
import { ModalAlert } from '../../../components/modal-with-cta/utils/ModalWithCTAAlertTypes';
import claimsFlow from '../../../strings/claimsFlow';
import common from '../../../strings/common';
import Constants from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import ThirdPartyConsentVersion from '../../../utils/constants/ThirdPartyConsentVersion';
import formatDateToString from '../../../utils/formatDateToString';

import './ThirdPartyConsent.scss';

const ThirdPartyConsent: FC = () => {
    const { thirdPartyConsent: contentStrings } = claimsFlow;
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setClaimDetailsByAttr, claimDetails } = useClaim();

    useEffect(() => {
        Analytics.trackClaimStepViewed(Constants.CLAIMS_STEP_THIRD_PARTY_CONSENT, claimDetails);
    }, []);

    const handleNoClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmNoClick = () => {
        setClaimDetailsByAttr('thirdPartyConsent', false);
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_THIRD_PARTY_CONSENT, claimDetails);
        history.push(Routes.ACCIDENT_DATE);
    };

    const handleYesClick = () => {
        setClaimDetailsByAttr('thirdPartyConsent', true);
        Analytics.trackClaimStepCompleted(Constants.CLAIMS_STEP_THIRD_PARTY_CONSENT, claimDetails);
        history.push(Routes.ACCIDENT_DATE);
    };

    return (
        <Layout
            title={contentStrings.title}
            showProgressBar
            currentProgress={(Constants.CLAIMS_STEP_THIRD_PARTY_CONSENT / Constants.CLAIMS_NO_OF_STEPS) * 100}
        >
            <p>
                <strong>{contentStrings.description}</strong>
            </p>
            <p>{contentStrings.declaration[ThirdPartyConsentVersion.CURRENT]}</p>
            <p>
                <strong>
                    {claimsFlow.formatString(contentStrings.date, {
                        date: formatDateToString(new Date()),
                    })}
                </strong>
            </p>
            <p>{contentStrings.authorisation}</p>
            <div className="third-party-consent__btn-group">
                <Button label={common.no} variant="secondary" onClick={handleNoClick} />
                <Button label={common.yes} onClick={handleYesClick} />
            </div>
            <ModalWithCTA
                onClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
                icon={ModalAlert.INFO}
                title={contentStrings.modalDescription}
                textContent={contentStrings.modalAlert}
                primaryCTA={{ onClick: handleConfirmNoClick, label: common.next }}
                secondaryCTA={{ onClick: () => setIsModalOpen(false), label: common.back }}
            />
        </Layout>
    );
};

export default ThirdPartyConsent;
