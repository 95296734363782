import classNames from 'classnames';
import format from 'date-fns/format';
import { FC } from 'react';
import { Modifier } from 'react-day-picker';
import { useHistory } from 'react-router-dom';
import { CartErrorDisplay } from '../../../business-logic/models/Cart';
import Button from '../../../components/button/Button';
import CartError from '../../../components/cart-error/CartError';
import DatePickerModal from '../../../components/date-picker-modal/DatePickerModal';
import Layout from '../../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import { NewDatePickerModes } from '../../../components/new-date-picker/types';
import PdsTmdDisclaimer from '../../../components/pds-tmd-disclaimer/PdsTmdDisclaimer';
import RoamingProductModal from '../../../components/roaming-product-modal/RoamingProductModal';
import roamingDatesContent from '../../../content/ui/screens/roaming-dates/roamingDates';
import withContent from '../../../hoc/with-content/withContent';
import useOnboarding from '../../../hooks/useOnboarding';
import Routes from '../../../utils/Routes';
import Cover from '../../../utils/constants/Cover';
import DateFormat from '../../../utils/constants/DateFormat';
import getPdsVersion from '../../../utils/getPdsVersion';
import getProductInfo from '../../../utils/getProductInfo';
import getTimezone from '../../../utils/getTimezone';
import RoamingPriceCoverTile from './roaming-price-cover-tile/RoamingPriceCoverTile';

interface RoamingDatesBodyProps {
    content: Record<keyof typeof contentMap, string>;
    selectedRoamingCover: Cover | null;
    coverFor: 'self' | 'dependant' | undefined;
    startingRegion: string;
    timezone: string;
    destinations: string[];
    fixedPaymentModelMode: boolean;
    datePickerMode: NewDatePickerModes;
    daysToSchedule: Date[];
    disabledDays: Modifier[];
    cartLoading: boolean;
    openRoamingProductModal: boolean;
    cartError?: unknown;
    canSelectDates: boolean;
    isOnboardingFlow?: boolean;
    canAgreeToConditions?: boolean;
    handleDateSubmission: (dates: Date[]) => void;
    proceedToCheckout: () => void;
    goBack: () => void;
    tryAgainCart?: () => void;
    handleCalendarOpen: () => void;
    agreeToConditions: () => void;
}

const contentMap = {
    heading: 'ui.heading',
    paymentOptionLegend: 'ui.paymentOptionLegend',
    continueCTA: 'ui.continueCTA',
    selectCoverDates: 'ui.selectCoverDates',
};

const RoamingDatesBody: FC<RoamingDatesBodyProps> = ({
    content,
    selectedRoamingCover,
    isOnboardingFlow,
    daysToSchedule,
    coverFor,
    destinations,
    startingRegion,
    timezone,
    cartError,
    disabledDays,
    datePickerMode,
    cartLoading,
    openRoamingProductModal,
    canSelectDates,
    proceedToCheckout,
    handleCalendarOpen,
    handleDateSubmission,
    goBack,
    tryAgainCart,
    agreeToConditions,
    canAgreeToConditions,
}) => {
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const history = useHistory();
    const productInfo = getProductInfo(selectedRoamingCover!, getPdsVersion(selectedRoamingCover!));

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            {!cartError && cartLoading && <LoadingSpinnerOverlay />}
            <h2 className="roaming-dates__subheading">Flip Roaming Cover</h2>
            <div className="roaming-price__cover-tile__wrapper">
                <RoamingPriceCoverTile key={productInfo.id} {...productInfo} title={productInfo.coverUnitDuration} />
            </div>
            <ul
                className={classNames('roaming__dates__details', {
                    'roaming__dates__details--dates-loaded': !!daysToSchedule.length,
                })}
            >
                <li>
                    <b>Cover for: </b>
                    {coverFor === 'self' ? 'Myself' : 'Kids'}
                </li>
                <li>
                    <b>Dest: </b>
                    {destinations.join(', ')}
                </li>
                <li>
                    <b>Start: </b>
                    <>{startingRegion}</> {`(${getTimezone(timezone, format(new Date(), DateFormat.DEFAULT))})`}
                </li>
            </ul>
            <p
                className={classNames('roaming-price__select-cover-dates', {
                    'roaming-price__select-cover-dates--dates-loaded': !!daysToSchedule.length === false,
                })}
            >
                {content.selectCoverDates}
            </p>
            <div className="roaming-price__date-picker-modal-wrapper">
                <DatePickerModal
                    value={daysToSchedule}
                    onChange={(dates) => handleDateSubmission(dates)}
                    mode={datePickerMode}
                    disabled={!canSelectDates}
                    disabledDays={disabledDays}
                    onCalendarOpen={handleCalendarOpen}
                    changeButtonOnDateSelected
                />
            </div>
            {!!cartError && (
                <CartError
                    cartError={cartError as CartErrorDisplay}
                    tryAgainEvent={() => {
                        if (tryAgainCart) {
                            tryAgainCart();
                        }
                    }}
                    prevStepEvent={goBack}
                    showAction={false}
                />
            )}
            <RoamingProductModal
                isOpen={openRoamingProductModal}
                onCancelYesClick={proceedToCheckout}
                onExitClick={() => history.push(Routes.HOME)}
            />
            {!!cartError === false && canAgreeToConditions && (
                <>
                    <Button
                        label={content.continueCTA}
                        width="full"
                        className="roaming-price__continue-cta"
                        onClick={agreeToConditions}
                    />
                    <PdsTmdDisclaimer preText="* If your claim is approved." />
                </>
            )}
        </Layout>
    );
};

export default withContent(RoamingDatesBody, contentMap, roamingDatesContent);
