import { useOktaAuth } from '@okta/okta-react';
import { useMachine } from '@xstate/react';
import { ChangeEvent, FC, FormEvent } from 'react';
import close from '../../../../assets/images/close.svg';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import Alert, { AlertTypes } from '../../../../components/alert/Alert';
import Button from '../../../../components/button/Button';
import CheckboxList from '../../../../components/checkbox-list/CheckboxList';
import Fieldset from '../../../../components/form/fieldset/Fieldset';
import KidDetailsForm from '../../../../components/kid-details-form/KidDetailsForm';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import Sticky from '../../../../components/sticky/Sticky';
import personsInsuredContent from '../../../../content/ui/screens/persons-insured/personsInsured';
import withContent from '../../../../hoc/with-content/withContent';
import personsInsuredBaymaxMachine from './persons-insured-baymax-machine/personsInsuredBaymaxMachine';

import './PersonsInsuredBaymax.scss';

const contentMap = {
    heading: 'ui.heading',
    myselfLabel: 'ui.myselfLabel',
    baymaxMyself: 'ui.baymaxMyself',
    kidsLabel: 'ui.kidsLabel',
    kidsInfo: 'ui.kidsInfo',
    addAKidCta: 'ui.addAKidCta',
    kidDetailsFormHeading: 'ui.kidDetailsFormHeading',
    kidDetailsFormCta: 'ui.kidDetailsFormCta',
    kidDetailsFormCancel: 'ui.kidDetailsFormCancel',
    cta: 'ui.cta',
    kidOver18: 'ui.kidOver18',
    kidHasSubscription: 'ui.kidHasSubscription',
    kidHasSubscriptionDescription: 'ui.kidHasSubscriptionDescription',
    baymaxCta: 'ui.baymaxCta',
};

const personsInsuredFormId = 'persons-insured-form';
const addAKidFormId = 'add-a-kid-form';

interface PersonsInsuredBaymaxProps {
    selectedPersons: {
        accountHolder: boolean;
        dependants: string[];
    };
    content: Record<keyof typeof contentMap, string>;
    showAccountHolder: boolean;
    onPersonsUpdateComplete: (personsInsuredDetails: string[], isAccountHolderInsured: boolean) => void;
}

const PersonsInsuredBaymax: FC<PersonsInsuredBaymaxProps> = ({
    selectedPersons,
    content,
    showAccountHolder,
    onPersonsUpdateComplete,
}) => {
    const { dependants, addDependants, accessToken } = useUser();
    const { authState } = useOktaAuth();

    const [state, send] = useMachine(personsInsuredBaymaxMachine, {
        context: {
            dependants,
            accessToken,
            isAuthenticated: authState?.isAuthenticated,
            selectedPersons: [...(selectedPersons.accountHolder ? ['myself'] : []), ...selectedPersons.dependants],
            isAccountHolderInsured: selectedPersons.accountHolder,
        },
        actions: {
            notifyDependantsCreationComplete: (ctx) =>
                onPersonsUpdateComplete(
                    ctx.selectedPersons.filter((x) => x.toLowerCase() !== content.baymaxMyself.toLowerCase()),
                    ctx.isAccountHolderInsured,
                ),
            updateUserContext: (ctx, event) => {
                addDependants(event.data);
            },
        },
    });

    const showKidDetailsForm = state.matches('addAKid') && !state.matches('addAKid.spawnKidDetailsFormMachine');
    const isCreatingDependants = state.matches('addAKid.createDependant');
    const showCreateDependantsError = state.matches('addAKid.displayCreateDependantError');
    const isPersonsInsuredFormInvalid = !state.can('CONTINUE');
    const isAddAKidFormInvalid = state.context.kidDetailsFormMachineRef
        ? state.context.kidDetailsFormMachineRef.getSnapshot()?.hasTag('INVALID')
        : true;

    const handlePersonsInsuredFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('CONTINUE');
    };

    const handleAddAKidFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('SAVE_KID');
    };
    const dependantCheckboxes = dependants.map((dependant) => {
        return {
            id: dependant.personId!,
            name: `${dependant.firstName} ${dependant.lastName}}`,
            label: `${dependant.firstName} ${dependant.lastName}`,
            checked: state.context.selectedPersons.includes(dependant.personId!),
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    send({
                        type: 'SET_SELECTED_PERSONS',
                        data: { selectedPersons: [...state.context.selectedPersons, dependant.personId!] },
                    });
                } else {
                    send({
                        type: 'SET_SELECTED_PERSONS',
                        data: {
                            selectedPersons: state.context.selectedPersons.filter((id) => id !== dependant.personId),
                        },
                    });
                }
            },
            disabled: state.matches('addAKid'),
        };
    });

    if (showAccountHolder) {
        dependantCheckboxes.unshift({
            id: content.baymaxMyself.toLowerCase(),
            name: content.baymaxMyself,
            label: content.baymaxMyself,
            checked: state.context.selectedPersons.includes(content.baymaxMyself.toLowerCase()),
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    send({
                        type: 'SET_MYSELF_INSURED',
                        data: {
                            selectedPersons: [...state.context.selectedPersons, content.baymaxMyself.toLowerCase()],
                        },
                    });
                } else {
                    send({
                        type: 'SET_MYSELF_INSURED',
                        data: {
                            selectedPersons: state.context.selectedPersons.filter(
                                (id) => id !== content.baymaxMyself.toLowerCase(),
                            ),
                        },
                    });
                }
            },
            disabled: state.matches('addAKid'),
        });
    }

    return (
        <>
            <form id={personsInsuredFormId} onSubmit={handlePersonsInsuredFormSubmit} />
            <form id={addAKidFormId} onSubmit={handleAddAKidFormSubmit} />
            <div className="persons-insured__form-content">
                <>
                    <Alert type={AlertTypes.INFO} message={content.kidsInfo} className="persons-insured__kids-info" />
                    <Fieldset
                        className="persons-insured__kids-fieldset"
                        legend="Select which kid"
                        visuallyHideLegend
                        form={personsInsuredFormId}
                    >
                        <CheckboxList list={dependantCheckboxes} />
                    </Fieldset>
                    {showKidDetailsForm && (
                        <>
                            <div className="persons-insured__kid-details-form-heading-section">
                                <p className="persons-insured__kid-details-form-heading">
                                    {content.kidDetailsFormHeading}
                                </p>
                                <button
                                    type="button"
                                    className="persons-insured__kid-details-form-cancel-button"
                                    onClick={() => send('CANCEL_ADD_A_KID')}
                                >
                                    <span className="persons-insured__kid-details-form-cancel-button-text">
                                        {content.kidDetailsFormCancel}
                                    </span>
                                    <img src={close} alt="" />
                                </button>
                            </div>
                            <KidDetailsForm
                                kidDetailsFormMachineRef={state.context.kidDetailsFormMachineRef!}
                                form={addAKidFormId}
                            />

                            {showCreateDependantsError && <Alert className="persons-insured__kid-details-form-error" />}

                            <LoadingButton
                                status={isCreatingDependants ? 'loading' : 'idle'}
                                label={content.kidDetailsFormCta}
                                width="full"
                                variant="secondary"
                                type="submit"
                                form={addAKidFormId}
                                disabled={isAddAKidFormInvalid}
                            />
                        </>
                    )}
                    {!showKidDetailsForm && (
                        <Button
                            label={content.addAKidCta}
                            width="full"
                            variant="secondary"
                            onClick={() => send('ADD_A_KID')}
                        />
                    )}
                </>
            </div>
            <Sticky>
                <Button
                    label={content.baymaxCta}
                    width="full"
                    form={personsInsuredFormId}
                    type="submit"
                    disabled={isPersonsInsuredFormInvalid}
                />
            </Sticky>
        </>
    );
};

export default withContent(PersonsInsuredBaymax, contentMap, personsInsuredContent);
