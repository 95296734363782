import Cover from './constants/Cover';

const isRoamingCover = (cover: Cover): boolean => {
    switch (cover) {
        case Cover.FLIP_ROAMING_WEEKLY:
            return true;
        case Cover.FLIP_ROAMING_KIDS_WEEKLY:
            return true;
        default:
            return false;
    }
};

export default isRoamingCover;
