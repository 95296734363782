import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import { CheckoutDetailsResponse } from '../../business-logic/models/CheckoutDetails';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const applyGuestPromoCode = async ({
    accessToken,
    paymentId,
    promoCode,
    axios,
}: {
    accessToken: string;
    paymentId: string;
    promoCode: string;
} & ServiceArgs): Promise<CheckoutDetailsResponse> => {
    const { data } = await axios.put<CheckoutDetailsResponse>(
        `${baseApiPath}/api/v1/guest/billing/stripe/checkout/${paymentId}/promo-code`,
        { stripePromotionCode: promoCode },
        toHeaders(accessToken),
    );

    return data;
};

export default asService(applyGuestPromoCode);
