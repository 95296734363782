import blindnessIcon from '../../assets/images/injuries/blindness.svg';
import brokenBoneIcon from '../../assets/images/injuries/broken-bone.svg';
import deafnessIcon from '../../assets/images/injuries/deafness.svg';
import dentalIcon from '../../assets/images/injuries/dental.svg';
import dislocationIcon from '../../assets/images/injuries/dislocation.svg';
import muscleIcon from '../../assets/images/injuries/muscle.svg';
import paraplegiaIcon from '../../assets/images/injuries/paraplegia.svg';
import tendonIcon from '../../assets/images/injuries/tendon.svg';
import woundIcon from '../../assets/images/injuries/wound.svg';
import otherIcon from '../../assets/images/injuries/other.svg';
import brokenBoneIconWithPattern from '../../assets/images/injuries/broken-bone-with-pattern.svg';
import muscleIconWithPattern from '../../assets/images/injuries/muscle-with-pattern.svg';
import tendonIconWithPattern from '../../assets/images/injuries/tendon-with-pattern.svg';
import dislocationIconWithPattern from '../../assets/images/injuries/dislocation-with-pattern.svg';
import woundIconWithPattern from '../../assets/images/injuries/wound-with-pattern.svg';
import dentalIconWithPattern from '../../assets/images/injuries/dental-with-pattern.svg';
import blindnessIconWithPattern from '../../assets/images/injuries/blindness-with-pattern.svg';
import deafnessIconWithPattern from '../../assets/images/injuries/deafness-with-pattern.svg';
import paraplegiaIconWithPattern from '../../assets/images/injuries/paraplegia-with-pattern.svg';
import otherIconWithPattern from '../../assets/images/injuries/other-with-pattern.svg';
import claimsFlowStrings from '../../strings/claimsFlow';

interface Injury {
    label: string;
    value: string;
    icon: string;
    iconWithPattern?: string;
}

interface Injuries {
    [key: string]: Injury;
}

const {
    selectInjury: { injuryOptions },
} = claimsFlowStrings;

const Injuries: Injuries = {
    'broken-bone': {
        label: injuryOptions.brokenBone,
        value: 'broken-bone',
        icon: brokenBoneIcon,
        iconWithPattern: brokenBoneIconWithPattern,
    },
    muscle: {
        label: injuryOptions.muscle,
        value: 'muscle',
        icon: muscleIcon,
        iconWithPattern: muscleIconWithPattern,
    },
    tendon: {
        label: injuryOptions.tendon,
        value: 'tendon',
        icon: tendonIcon,
        iconWithPattern: tendonIconWithPattern,
    },
    dislocation: {
        label: injuryOptions.dislocation,
        value: 'dislocation',
        icon: dislocationIcon,
        iconWithPattern: dislocationIconWithPattern,
    },
    wound: {
        label: injuryOptions.wound,
        value: 'wound',
        icon: woundIcon,
        iconWithPattern: woundIconWithPattern,
    },
    dental: {
        label: injuryOptions.dental,
        value: 'dental',
        icon: dentalIcon,
        iconWithPattern: dentalIconWithPattern,
    },
    blindness: {
        label: injuryOptions.blindness,
        value: 'blindness',
        icon: blindnessIcon,
        iconWithPattern: blindnessIconWithPattern,
    },
    deafness: {
        label: injuryOptions.deafness,
        value: 'deafness',
        icon: deafnessIcon,
        iconWithPattern: deafnessIconWithPattern,
    },
    paraplegia: {
        label: injuryOptions.paraplegia,
        value: 'paraplegia',
        icon: paraplegiaIcon,
        iconWithPattern: paraplegiaIconWithPattern,
    },
    other: {
        label: injuryOptions.other,
        value: 'other',
        icon: otherIcon,
        iconWithPattern: otherIconWithPattern,
    },
};

export default Injuries;
