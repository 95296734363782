import { assign } from 'xstate';
import { Destination } from '../../../business-logic/models/RoamingDestinations';
import { RoamingDestinationsMachineContextTypes } from '../context/roamingDestinationsMachineContext';
import { Typegen0 } from '../roaming-destinations-machine/roamingDestinationsMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setChosenDestinations = assign<
    RoamingDestinationsMachineContextTypes,
    { type: EventsCausingActions['setChosenDestinations']; data: string[] }
>({
    chosenDestinations: (_, event) => event.data,
});

export const setAllDestinations = assign<
    RoamingDestinationsMachineContextTypes,
    { type: EventsCausingActions['setAllDestinations']; data: Destination[] }
>({
    availableDestinations: (_, event: any) => event.data,
});
