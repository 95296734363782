import { useMachine } from '@xstate/react';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import Layout from '../../components/layout/Layout';
import BaseBanner from '../../components/layout/banners/base-banner/BaseBanner';
import CreditBanner from '../../components/layout/banners/credit-banner/CreditBanner';
import guestCartContent from '../../content/ui/screens/guest-cart/guestCart';
import withContent from '../../hoc/with-content/withContent';
import useLazyDependency from '../../hooks/lazy-dependency/useLazyDependency';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import getEncodedCart from '../../utils/getEncodedCart';
import getReferralCodeFromQueryString from '../registration/utils/getReferralCodeFromQueryString';
import StepItem from './components/step-item/StepItem';
import StepProgressBar from './components/step-progress-bar/StepProgressBar';
import GuestCartStep from './constants/GuestCartStep';
import guestCartMachine from './guest-cart-machine/guestCartMachine';
import KidsDetailsStep from './steps/kids-details/KidsDetailsStep';
import PaymentStep from './steps/payment/PaymentStep';
import ProfileStep from './steps/profile/ProfileStep';
import ReviewCoverStep from './steps/review-cover/ReviewCoverStep';

import './GuestCart.scss';

const contentMap = {
    header: 'ui.header',
    selectProductStepTitle: 'ui.selectProductStep.title',
    selectProductStepDescription: 'ui.selectProductStep.description',
    selectProductStepFlipActiveDescription: 'ui.selectProductStep.descriptionFlipActive',
    registerStepTitle: 'ui.registerStep.title',
    registerStepDescription: 'ui.registerStep.description',
    profileStepTitle: 'ui.profileStep.title',
    profileStepDescription: 'ui.profileStep.description',
    kidsDetailsStepTitle: 'ui.kidsDetailsStep.title',
    kidsDetailsStepDescription: 'ui.kidsDetailsStep.description',
    reviewCoverStepTitle: 'ui.reviewCoverStep.title',
    reviewCoverStepDescription: 'ui.reviewCoverStep.description',
    paymentStepTitle: 'ui.paymentStep.title',
    referralVerificationSuccess: 'ui.referral.referralVerificationSuccess',
    referralVerificationError: 'ui.referral.referralVerificationError',
};

interface GuestCartProps {
    content: Record<keyof typeof contentMap, string>;
}

const GuestCart: FC<GuestCartProps> = ({ content }) => {
    const { creditBalance: creditBalanceLazyDependency } = useUser();
    const location = useLocation();
    const creditBalance = useLazyDependency(creditBalanceLazyDependency);
    const { clearRoamingState } = useRoaming();

    const [state, send] = useMachine(guestCartMachine, {
        actions: {
            redirectToWebflow: () => {
                window.location.href = ExternalLinks.productPage;
            },
            clearData: () => {
                clearRoamingState();
            },
        },
        context: {
            encodedCartString: getEncodedCart(location) as string | null,
            referralCode: getReferralCodeFromQueryString(location),
        },
    });

    const { currentStep, showStep, referralCodeValidated, coverSelections, steps, numKids } = state.context;
    const hasKids = !!steps.find((s) => s === GuestCartStep.KIDS_DETAILS);

    const renderReferralBanner = () => {
        if (typeof referralCodeValidated === 'boolean') {
            return referralCodeValidated ? (
                <BaseBanner text={content.referralVerificationSuccess} variant="success" />
            ) : (
                <BaseBanner text={content.referralVerificationError} variant="error" />
            );
        }
        return <CreditBanner />;
    };

    const layoutHasBanner = !!creditBalance.value || typeof referralCodeValidated === 'boolean';
    const renderStepItem = (step: GuestCartStep, index: number) => {
        switch (step) {
            case GuestCartStep.PROFILE:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.profileStepTitle}
                        description={content.profileStepDescription}
                        open={showStep === GuestCartStep.PROFILE}
                        onToggle={() => send('TOGGLE_PROFILE')}
                        completed={currentStep > GuestCartStep.PROFILE}
                        disabled={!state.can('TOGGLE_PROFILE')}
                        scrollToTopOnOpen
                    >
                        <ProfileStep
                            showKidsInfo={hasKids}
                            // We don't support mixing of Flip Active and Flip Kids in cart right now
                            // So if there is kids in the cart it means it's only kids
                            isPurchasingKidsCoverOnly={hasKids}
                            onProfileUpdateComplete={() => send('PROFILE_UPDATE_COMPLETE')}
                        />
                    </StepItem>
                );

            case GuestCartStep.KIDS_DETAILS:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.kidsDetailsStepTitle}
                        description={content.kidsDetailsStepDescription}
                        open={showStep === GuestCartStep.KIDS_DETAILS}
                        onToggle={() => send('TOGGLE_KIDS_DETAILS')}
                        completed={currentStep > GuestCartStep.KIDS_DETAILS}
                        disabled={!state.can('TOGGLE_KIDS_DETAILS')}
                        scrollToTopOnOpen
                    >
                        <KidsDetailsStep
                            numKids={numKids}
                            onDependantsUpdateComplete={(dependants) =>
                                send({ type: 'KIDS_DETAILS_COMPLETE', data: { dependants } })
                            }
                        />
                    </StepItem>
                );

            case GuestCartStep.REVIEW_COVER:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.reviewCoverStepTitle}
                        description={content.reviewCoverStepDescription}
                        open={showStep === GuestCartStep.REVIEW_COVER}
                        onToggle={() => send('TOGGLE_REVIEW_COVER')}
                        completed={currentStep > GuestCartStep.REVIEW_COVER}
                        disabled={!state.can('TOGGLE_REVIEW_COVER')}
                        scrollToTopOnOpen
                    >
                        <ReviewCoverStep
                            coverSelections={coverSelections}
                            onContinueToPayment={() => send('CONTINUE_TO_PAYMENT')}
                        />
                    </StepItem>
                );

            case GuestCartStep.PAYMENT:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.paymentStepTitle}
                        open={showStep === GuestCartStep.PAYMENT}
                        onToggle={() => send('TOGGLE_PAYMENT')}
                        completed={currentStep > GuestCartStep.PAYMENT}
                        disabled={!state.can('TOGGLE_PAYMENT')}
                        scrollToTopOnOpen
                    >
                        <PaymentStep
                            coverSelections={coverSelections}
                            onInvalidCoverSelection={() => send('TOGGLE_PROFILE')}
                            onPaymentComplete={() => send('PAYMENT_COMPLETE')}
                            onGoToPrevStep={() => send('TOGGLE_REVIEW_COVER')}
                        />
                    </StepItem>
                );

            default:
                return null;
        }
    };

    return (
        <Layout banner={renderReferralBanner()}>
            <StepProgressBar steps={steps} currentStep={currentStep} layoutHasBanner={layoutHasBanner} />

            <h1 className="guest-cart__header">{content.header}</h1>
            {steps.map(renderStepItem)}
        </Layout>
    );
};

export default withContent(GuestCart, contentMap, guestCartContent);
