import flipActiveImage from '../../assets/images/cover/flip-active-category-image.png';
import flipKidsImage from '../../assets/images/cover/flip-kids-category-image.jpg';
import flipRoaming from '../../assets/images/cover/flip-roaming-category-image.png';
import Cover from '../../utils/constants/Cover';

export interface IProductCategory {
    title: string;
    coverFor: 'self' | 'dependant';
    position: number;
    coverCodes: string[];
    image: string;
    description: string;
    pricePerPeriod: string;
    highlightText: string;
    highlightStyle: 'primary' | 'secondary';
}

export const ProductCategories: Record<string, IProductCategory> = {
    FLIP_ACTIVE: {
        title: 'Flip Active',
        coverFor: 'self',
        position: 1,
        coverCodes: [Cover.FLIP_ACTIVE_DAILY, Cover.FLIP_ACTIVE_SUB_MONTHLY, Cover.FLIP_ACTIVE_WEEKLY],
        image: flipActiveImage,
        description:
            'Accidental injury insurance for Australian residents aged 18-64. Get cover for a day, a week or a monthly subscription so you can enjoy your active lifestyle stress-free.',
        pricePerPeriod: '<small>From</small>$7/day',
        highlightText: 'AU/NZ cover',
        highlightStyle: 'primary',
    },
    FLIP_KIDS: {
        title: 'Flip Kids',
        coverFor: 'dependant',
        position: 1,
        coverCodes: [Cover.FLIP_KIDS_DAILY, Cover.FLIP_KIDS_SUB_MONTHLY, Cover.FLIP_KIDS_WEEKLY],
        image: flipKidsImage,
        description:
            'Accidental injury insurance for 5-17 year olds. Parents or legal guardians can buy cover on behalf of their kids, for peace of mind for the kids&apos; most active days.',
        pricePerPeriod: '<small>From</small>$7/day',
        highlightText: 'AU/NZ cover',
        highlightStyle: 'primary',
    },
    FLIP_ROAMING: {
        title: 'Flip Roaming',
        coverFor: 'self',
        position: 0,
        coverCodes: [Cover.FLIP_ROAMING_WEEKLY],
        image: flipRoaming,
        description:
            'Accidental injury insurance while at a covered destination outside Aus/NZ. Up to A$70,000 in cash payouts.',
        pricePerPeriod: '$80/week',
        highlightText: 'Overseas cover',
        highlightStyle: 'secondary',
    },
    FLIP_ROAMING_KIDS: {
        title: 'Flip Roaming',
        coverFor: 'dependant',
        position: 0,
        coverCodes: [Cover.FLIP_ROAMING_KIDS_WEEKLY],
        image: flipRoaming,
        description:
            'Accidental injury insurance while at a covered destination outside Aus/NZ. Up to A$70,000 in cash payouts.',
        pricePerPeriod: '$80/week',
        highlightText: 'Overseas cover',
        highlightStyle: 'secondary',
    },
};
