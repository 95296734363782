import { assign } from 'xstate';
import { Typegen0 } from '../kidsDetailsMachine.typegen';
import { KidsDetailsMachineContext } from '../context/kidsDetailsMachineContext';
import errors from '../../../../../../content/common/errors';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setGenericError = assign<
    KidsDetailsMachineContext,
    { type: EventsCausingActions['setGenericError']; data: unknown }
>({
    genericError: () => errors.errorSomethingWentWrongTryAgain,
});

export const clearGenericError = assign<KidsDetailsMachineContext, { type: 'xstate.stop' }>({
    genericError: () => '',
});
