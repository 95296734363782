import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '../../components/button/Button';
import { Layout } from '../../components/layout/Layout';
import Routes from '../../utils/Routes';
import { useUser } from '../../business-logic/context-provider/user-context';
import toOption from '../../utils/toOption';
import onBoardingStrings from '../../strings/onboardingFlow';
import commonStrings from '../../strings/common';
import Analytics from '../../analytics/Analytics';
import Alert, { AlertSizes } from '../../components/alert/Alert';
import parseString from '../../utils/parseString';
import withContent from '../../hoc/with-content/withContent';
import stateContent from '../../content/ui/screens/state/state';
import Dropdown from '../../components/dropdown/Dropdown';
import CustomerService from '../../services/customer-service/CustomerService';
import useOnboarding from '../../hooks/useOnboarding';

import './ResidencyState.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface ResidencyStateProps {
    content: Record<keyof typeof contentMap, string>;
}

const ResidencyState: React.FC<ResidencyStateProps> = ({ content }) => {
    const { userDetails, setUserDetailsByAttr, accessToken } = useUser();
    const { residency: contentStrings } = onBoardingStrings;
    const history = useHistory();

    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();

    const states = contentStrings.stateOptions;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedState, setSelectedState] = useState(toOption(states, userDetails.state));
    const [hasError, setError] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setUserDetailsByAttr('state', selectedState?.value);

        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepCompleted(onboardingStepNumber);
        }

        setIsLoading(true);

        try {
            await CustomerService.updateCustomerProfile({
                accessToken: accessToken!,
                profile: { state: selectedState?.value },
            });

            history.push({
                pathname: Routes.RESIDENCY_STATUS,
                state: { isOnboarding: isOnboardingFlow },
            });
        } catch (err) {
            console.log(err);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleExitClick = () => {
        history.push(Routes.HOME);
    };

    useEffect(() => {
        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepViewed(onboardingStepNumber);
        }
    }, []);

    return (
        <Layout
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            title={content.heading}
            showBackButton={!isOnboardingFlow}
            showLoading={isLoading}
        >
            <form onSubmit={handleSubmit}>
                <Dropdown
                    options={[...states, contentStrings.stateNotInAusOption]}
                    label={commonStrings.state}
                    placeholder={contentStrings.statePlaceholder}
                    value={selectedState}
                    onChange={setSelectedState}
                    searchable={false}
                    className="residency-state__dropdown"
                />

                {selectedState?.value === contentStrings.stateNotInAusOption.value && (
                    <>
                        <Alert size={AlertSizes.LARGE} message={parseString(contentStrings.stateError)} />
                        <Button
                            label={commonStrings.exit}
                            width="full"
                            className="residency-state__exit-cta"
                            onClick={handleExitClick}
                        />
                    </>
                )}

                {selectedState?.value !== contentStrings.stateNotInAusOption.value && (
                    <Button
                        type="submit"
                        label={commonStrings.continue}
                        disabled={selectedState == null}
                        width="full"
                    />
                )}
            </form>
            {hasError && <p className="error">{commonStrings.errorSomethingWentWrong}</p>}
        </Layout>
    );
};

export default withContent(ResidencyState, contentMap, stateContent);
