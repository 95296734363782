import { createMachine } from 'xstate';
import UserDetails from '../../../business-logic/models/UserDetails';
import { GuestCartStepsWithoutKids } from '../constants/GuestCartStep';
import {
    setGuestCartStepsWithKids,
    setGuestCartStepsWithoutKids,
    setKidsDetailsStep,
    setMultiProductCoverSelections,
    setMultiProductEncodedCart,
    setMultiProductNumKids,
    setPaymentStep,
    setProfileStep,
    setReferralCodeValidated,
    setReviewCoverStep,
    showKidsDetailsStep,
    showPaymentStep,
    showProfileStep,
    showReviewCoverStep,
    trackCheckoutCompleted,
    trackKidsDetailsStepCompleted,
    trackKidsDetailsStepViewed,
    trackPaymentStepCompleted,
    trackPaymentStepViewed,
    trackProfileStepCompleted,
    trackProfileStepViewed,
    trackReviewCoverStepCompleted,
    trackReviewCoverStepViewed,
    updateCoverSelectionWithDependants,
} from './actions/guestCartMachineActions';
import { GuestCartMachineContextTypes } from './context/guestCartMachineContext';
import { isKidsDetailsRequired, areMultiProductCoverSelectionsValid } from './guards/cartMachineGuards';
import {
    GuestCartMachineServicesTypes,
    parseMultiProductCartString,
    validateReferralCode,
} from './services/guestCartMachineServices';

const guestCartMachine = createMachine(
    {
        id: 'GuestCartMachine',
        initial: 'init',
        tsTypes: {} as import('./guestCartMachine.typegen').Typegen0,
        schema: {
            context: {} as GuestCartMachineContextTypes,
            events: {} as
                | { type: 'TOGGLE_PROFILE' }
                | { type: 'PROFILE_UPDATE_COMPLETE' }
                | { type: 'TOGGLE_KIDS_DETAILS' }
                | { type: 'KIDS_DETAILS_COMPLETE'; data: { dependants: UserDetails[] } }
                | { type: 'TOGGLE_REVIEW_COVER' }
                | { type: 'TOGGLE_PAYMENT' }
                | { type: 'CHANGE_COVER' }
                | { type: 'CONTINUE_TO_PAYMENT' }
                | { type: 'PAYMENT_COMPLETE' },
            services: {} as GuestCartMachineServicesTypes,
        },
        context: {
            coverSelections: [],
            encodedCartString: '',
            multiProductEncodedCart: null,
            currentStep: 0,
            numKids: 0,
            showStep: null,
            referralCode: null,
            referralCodeValidated: null,
            steps: GuestCartStepsWithoutKids,
        },
        preserveActionOrder: true,
        invoke: {
            src: 'validateReferralCode',
            onDone: {
                actions: 'setReferralCodeValidated',
            },
        },
        states: {
            init: {
                initial: 'parseMultiProductCartString',
                states: {
                    parseMultiProductCartString: {
                        invoke: {
                            src: 'parseMultiProductCartString',
                            onDone: [
                                {
                                    cond: 'areMultiProductCoverSelectionsValid',
                                    actions: ['setMultiProductEncodedCart', 'setMultiProductCoverSelections'],
                                    target: 'setSteps',
                                },
                                {
                                    target: 'redirectToWebflow',
                                },
                            ],
                            onError: {
                                target: 'redirectToWebflow',
                            },
                        },
                    },
                    setSteps: {
                        always: [
                            {
                                cond: 'isKidsDetailsRequired',
                                actions: ['setGuestCartStepsWithKids', 'setMultiProductNumKids'],
                                target: '#GuestCartMachine.profileStep',
                            },
                            {
                                actions: 'setGuestCartStepsWithoutKids',
                                target: '#GuestCartMachine.profileStep',
                            },
                        ],
                    },
                    redirectToWebflow: {
                        type: 'final',
                        entry: 'redirectToWebflow',
                    },
                },
            },
            profileStep: {
                entry: ['setProfileStep', 'showProfileStep', 'trackProfileStepViewed'],
                exit: 'trackProfileStepCompleted',
                on: {
                    PROFILE_UPDATE_COMPLETE: [
                        {
                            cond: 'isKidsDetailsRequired',
                            target: 'kidsDetailsStep',
                        },
                        {
                            target: 'reviewCoverStep',
                        },
                    ],
                },
            },
            kidsDetailsStep: {
                entry: ['setKidsDetailsStep', 'showKidsDetailsStep', 'trackKidsDetailsStepViewed'],
                exit: 'trackKidsDetailsStepCompleted',
                on: {
                    KIDS_DETAILS_COMPLETE: {
                        actions: ['updateCoverSelectionWithDependants'],
                        target: 'reviewCoverStep',
                    },
                    TOGGLE_PROFILE: {
                        target: 'profileStep',
                    },
                },
            },
            reviewCoverStep: {
                entry: ['setReviewCoverStep', 'showReviewCoverStep', 'trackReviewCoverStepViewed'],
                exit: 'trackReviewCoverStepCompleted',
                on: {
                    CHANGE_COVER: [
                        {
                            actions: 'redirectToWebflow',
                        },
                    ],
                    CONTINUE_TO_PAYMENT: {
                        target: 'paymentStep',
                    },
                    TOGGLE_PROFILE: {
                        target: 'profileStep',
                    },
                    TOGGLE_KIDS_DETAILS: {
                        target: 'kidsDetailsStep',
                    },
                },
            },
            paymentStep: {
                entry: ['setPaymentStep', 'showPaymentStep', 'trackPaymentStepViewed'],
                on: {
                    PAYMENT_COMPLETE: {
                        actions: ['trackPaymentStepCompleted', 'trackCheckoutCompleted', 'clearData'],
                    },
                    TOGGLE_PROFILE: {
                        target: 'profileStep',
                    },
                    TOGGLE_KIDS_DETAILS: {
                        target: 'kidsDetailsStep',
                    },
                    TOGGLE_REVIEW_COVER: {
                        target: 'reviewCoverStep',
                    },
                },
            },
        },
    },
    {
        guards: {
            isKidsDetailsRequired,
            areMultiProductCoverSelectionsValid,
        },
        actions: {
            setMultiProductNumKids,
            setMultiProductEncodedCart,
            setMultiProductCoverSelections,
            setGuestCartStepsWithKids,
            setGuestCartStepsWithoutKids,
            setReferralCodeValidated,
            setProfileStep,
            setKidsDetailsStep,
            setReviewCoverStep,
            setPaymentStep,
            showProfileStep,
            showKidsDetailsStep,
            showReviewCoverStep,
            showPaymentStep,
            updateCoverSelectionWithDependants,
            trackProfileStepViewed,
            trackKidsDetailsStepViewed,
            trackReviewCoverStepViewed,
            trackPaymentStepViewed,
            trackProfileStepCompleted,
            trackKidsDetailsStepCompleted,
            trackReviewCoverStepCompleted,
            trackPaymentStepCompleted,
            trackCheckoutCompleted,
        },
        services: {
            validateReferralCode,
            parseMultiProductCartString,
        },
    },
);

export default guestCartMachine;
