import { FC } from 'react';

import { MainCover } from '../../utils/constants/Cover';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import getCoverTerms from '../../utils/getCoverTerms';
import SlideInBenefits from '../slide-in-benefits/SlideInBenefits';
import SlideInCoverTerms from '../slide-in-cover-terms/SlideInCoverTerms';

import './SlideInActiveTerms.scss';

interface SlideInActiveTermsProps {
    selectedCover: MainCover;
    isOpen: boolean;
    pdsVersion: MainCoverPdsVersion;
    onOpen: () => void;
    onClose: () => void;
    content?: {
        benefitsCTA?: string;
        injuriesCoveredTab: string;
        injuriesNotCoveredTab: string;
    };
}

const SlideInActiveTerms: FC<SlideInActiveTermsProps> = ({
    pdsVersion,
    content,
    selectedCover,
    isOpen,
    onOpen,
    onClose,
}) => {
    const coverTerms = getCoverTerms(selectedCover, pdsVersion);

    return (
        <>
            {parseFloat(coverTerms.coverTermsVersion) < 3 ? (
                <SlideInBenefits
                    content={{
                        injuriesNotCoveredTab: content!.injuriesNotCoveredTab,
                        injuriesCoveredTab: content!.injuriesCoveredTab,
                    }}
                    selectedCover={selectedCover}
                    pdsVersion={pdsVersion}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            ) : (
                <SlideInCoverTerms
                    selectedCover={selectedCover}
                    pdsVersion={pdsVersion}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                />
            )}
        </>
    );
};

export default SlideInActiveTerms;
