import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button } from '../../components/button/Button';
import { Layout } from '../../components/layout/Layout';
import Routes from '../../utils/Routes';
import { useUser } from '../../business-logic/context-provider/user-context';
import onBoardingStrings from '../../strings/onboardingFlow';
import commonStrings from '../../strings/common';
import Analytics from '../../analytics/Analytics';
import RadioButton from '../../components/form/radio-button/RadioButton';
import ResidencyStatusTypes from '../../utils/constants/ResidencyStatusTypes';
import Alert, { AlertSizes } from '../../components/alert/Alert';
import parseString from '../../utils/parseString';
import withContent from '../../hoc/with-content/withContent';
import residencyStatusContent from '../../content/ui/screens/residency-status/ResidencyStatus';
import Fieldset from '../../components/form/fieldset/Fieldset';
import CustomerService from '../../services/customer-service/CustomerService';
import useOnboarding from '../../hooks/useOnboarding';

import './ResidencyStatus.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface ResidencyStatusProps {
    content: Record<keyof typeof contentMap, string>;
}

const ResidencyStatus: React.FC<ResidencyStatusProps> = ({ content }) => {
    const { userDetails, setUserDetailsByAttr, accessToken } = useUser();
    const { residency: contentStrings } = onBoardingStrings;
    const history = useHistory();
    const { baymax } = useFlags();
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setError] = useState(false);

    const [selectedResidency, setSelectedResidency] = useState<ResidencyStatusTypes | undefined | null>(() => {
        const { residencyStatusType } = userDetails;

        if (!residencyStatusType) {
            return undefined;
        }

        if (residencyStatusType.includes(ResidencyStatusTypes.AUS_CITIZEN)) {
            return ResidencyStatusTypes.AUS_CITIZEN;
        }
        if (residencyStatusType.includes(ResidencyStatusTypes.NZ_CITIZEN)) {
            return ResidencyStatusTypes.NZ_CITIZEN;
        }
        if (residencyStatusType.includes(ResidencyStatusTypes.AUS_PR)) {
            return ResidencyStatusTypes.AUS_PR;
        }

        return undefined;
    });

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setUserDetailsByAttr('residencyStatusType', [selectedResidency!]);

        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepCompleted(onboardingStepNumber);
        }

        setIsLoading(true);

        try {
            await CustomerService.updateCustomerProfile({
                accessToken: accessToken!,
                profile: { residencyStatusType: [selectedResidency!] },
            });

            history.push({
                pathname: baymax ? Routes.HOME : Routes.PERSONS_INSURED,
                state: baymax ? null : { isOnboarding: isOnboardingFlow },
            });
        } catch (err) {
            console.log(err);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleExitClick = () => {
        history.push(Routes.HOME);
    };

    useEffect(() => {
        if (isOnboardingFlow) {
            Analytics.trackOnboardingStepViewed(onboardingStepNumber);
        }
    }, []);

    return (
        <Layout
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            title={content.heading}
            showBackButton={!isOnboardingFlow}
            showLoading={isLoading}
        >
            <form onSubmit={handleSubmit}>
                <Fieldset legend={content.heading} visuallyHideLegend>
                    <RadioButton
                        id={ResidencyStatusTypes.AUS_CITIZEN}
                        name="residency"
                        className="residency-status__radio-button"
                        label={contentStrings.residencyOptions.ausCitizen}
                        checked={selectedResidency === ResidencyStatusTypes.AUS_CITIZEN}
                        onChange={() => setSelectedResidency(ResidencyStatusTypes.AUS_CITIZEN)}
                    />
                    <RadioButton
                        id={ResidencyStatusTypes.NZ_CITIZEN}
                        name="residency"
                        className="residency-status__radio-button"
                        label={contentStrings.residencyOptions.nzCitizen}
                        checked={selectedResidency === ResidencyStatusTypes.NZ_CITIZEN}
                        onChange={() => setSelectedResidency(ResidencyStatusTypes.NZ_CITIZEN)}
                    />
                    <RadioButton
                        id={ResidencyStatusTypes.AUS_PR}
                        name="residency"
                        className="residency-status__radio-button"
                        label={contentStrings.residencyOptions.ausPR}
                        checked={selectedResidency === ResidencyStatusTypes.AUS_PR}
                        onChange={() => setSelectedResidency(ResidencyStatusTypes.AUS_PR)}
                    />
                    <RadioButton
                        id={ResidencyStatusTypes.AUS_OTHER_VISA}
                        name="residency"
                        className="residency-status__radio-button"
                        label={contentStrings.residencyOptions.otherVisa}
                        checked={selectedResidency === ResidencyStatusTypes.AUS_OTHER_VISA}
                        onChange={() => setSelectedResidency(ResidencyStatusTypes.AUS_OTHER_VISA)}
                    />
                    <RadioButton
                        id="tourist"
                        name="residency"
                        className="residency-status__radio-button"
                        label={contentStrings.residencyOptions.tourist}
                        checked={selectedResidency === null}
                        onChange={() => setSelectedResidency(null)}
                    />
                </Fieldset>
                {selectedResidency === null ? (
                    <>
                        <Alert size={AlertSizes.LARGE} message={parseString(contentStrings.residencyError)} />
                        <Button
                            label={commonStrings.exit}
                            width="full"
                            className="residency-status__exit-cta"
                            onClick={handleExitClick}
                        />
                    </>
                ) : (
                    <Button
                        type="submit"
                        label={commonStrings.continue}
                        disabled={selectedResidency == null}
                        width="full"
                    />
                )}
            </form>
            {hasError && <p className="error">{commonStrings.errorSomethingWentWrong}</p>}
        </Layout>
    );
};

export default withContent(ResidencyStatus, contentMap, residencyStatusContent);
