import '@okta/okta-auth-js/polyfill';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RoamingBaymaxProvider } from '../../business-logic/context-provider/RoamingBaymaxContext';
import requireRoamingBaymaxContext from '../../hoc/require-roaming-baymax-context/requireRoamingBaymaxContext';
import Routes from '../../utils/Routes';
import RoamingDatesBaymax from '../roaming-dates/RoamingDatesBaymax';
import RoamingDestinationsBaymax from '../roaming-destinations/RoamingDestinationsBaymax';
import RoamingStartDestinationBaymax from '../roaming-start-destination/RoamingStartDestinationBaymax';
import RoamingStartingRegionBaymax from '../roaming-starting-region/RoamingStartingRegionBaymax';

const RoamingBaymaxRouter: React.FC = () => {
    return (
        <RoamingBaymaxProvider>
            <Switch>
                <Route
                    exact
                    path={Routes.ROAMING_DESTINATIONS_BAYMAX}
                    component={requireRoamingBaymaxContext(RoamingDestinationsBaymax)}
                />
                <Route
                    exact
                    path={Routes.ROAMING_START_BAYMAX}
                    component={requireRoamingBaymaxContext(RoamingStartDestinationBaymax)}
                />
                <Route
                    exact
                    path={Routes.ROAMING_REGION_BAYMAX}
                    component={requireRoamingBaymaxContext(RoamingStartingRegionBaymax)}
                />
                <Route
                    exact
                    path={Routes.ROAMING_DATES_BAYMAX}
                    component={requireRoamingBaymaxContext(RoamingDatesBaymax)}
                />
                <Redirect to={Routes.NOT_FOUND} />
            </Switch>
        </RoamingBaymaxProvider>
    );
};

export default RoamingBaymaxRouter;
