import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from '@tanstack/react-query';
import { useMachine } from '@xstate/react';
import classNames from 'classnames';
import { ChangeEvent, ChangeEventHandler, FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { Destination, Region } from '../../business-logic/models/RoamingDestinations';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Checkbox from '../../components/form/checkbox/Checkbox';
import Fieldset from '../../components/form/fieldset/Fieldset';
import Layout from '../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import roamingDestinationsContent from '../../content/ui/screens/roaming-destinations/roamingDestinations';
import withContent from '../../hoc/with-content/withContent';
import useOnboarding from '../../hooks/useOnboarding';
import CdnService from '../../services/cdn-service/CdnService';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import getPdsVersion from '../../utils/getPdsVersion';
import { RoamingDestinationsMachineContextTypes } from './context/roamingDestinationsMachineContext';
import RoamingDestinationsMachine from './roaming-destinations-machine/roamingDestinationsMachine';

import './RoamingDestinations.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface RoamingDestinationsProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingDestinations: FC<RoamingDestinationsProps> = ({ content }) => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const history = useHistory();
    const { setRoamingData, selectedCover, destinations } = useRoaming();
    const { authState } = useOktaAuth();

    const {
        isLoading: fetchingDestinationsLoading,
        isError: fetchingDestinationsError,
        data: fetchedDestinations,
        isSuccess: fetchingDestinationsSucceeded,
    } = useQuery({
        queryKey: ['destinations'],
        queryFn: () => {
            return CdnService.getCoverConfig({
                coverCode: selectedCover as Cover,
                PDSVersion: getPdsVersion(selectedCover as Cover),
            });
        },
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, send] = useMachine(RoamingDestinationsMachine, {
        context: {
            chosenDestinations: destinations,
        },
        actions: {
            redirectToSelectDates: (ctx: RoamingDestinationsMachineContextTypes) => {
                // one destination chosen + only one region present
                // and region name === destination name
                const { timezone, region } = (fetchedDestinations! as Destination[])
                    .find((item: Destination) => item.destination === ctx.chosenDestinations[0])!
                    .regions.find((item: Region) => item.region === ctx.chosenDestinations[0])!;

                setRoamingData('destinations', state.context.chosenDestinations);
                setRoamingData('startingDestination', state.context.chosenDestinations[0]);
                setRoamingData('startingRegion', region);
                setRoamingData('timezone', timezone);
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.ROAMING_DATES : Routes.ROAMING_DATES_GUEST,
                });
            },
            redirectToChooseStartingRegion: () => {
                setRoamingData('destinations', state.context.chosenDestinations);
                setRoamingData('startingDestination', state.context.chosenDestinations[0]);
                // Reset starting region on destination select
                setRoamingData('startingRegion', '');
                setRoamingData('timezone', '');
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.ROAMING_REGION : Routes.ROAMING_REGION_GUEST,
                });
            },
            redirectToChooseStartingDestination: () => {
                setRoamingData('destinations', state.context.chosenDestinations);
                // Reset starting destination and region on destination select
                setRoamingData('startingDestination', '');
                setRoamingData('startingRegion', '');
                setRoamingData('timezone', '');
                history.push({
                    pathname: authState?.isAuthenticated ? Routes.ROAMING_START : Routes.ROAMING_START_GUEST,
                });
            },
        },
    });

    const selectDestinations = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setRoamingData('destinations', state.context.chosenDestinations);
        send({ type: 'CONTINUE' });
    };

    useEffect(() => {
        if (fetchingDestinationsSucceeded) {
            send({ type: 'LOAD_DESTINATIONS', data: fetchedDestinations as Destination[] });
        }
    }, [fetchingDestinationsSucceeded, fetchedDestinations, send]);

    useEffect(() => {
        const currentPath = location.pathname;
        if (authState?.isAuthenticated && currentPath === Routes.ROAMING_DESTINATIONS_GUEST) {
            history.push(Routes.HOME);
        }
    }, [location, authState, history]);

    const renderCheckbox = (destination: string, onChange: ChangeEventHandler<HTMLInputElement>, checked: boolean) => {
        return (
            <Checkbox
                id={destination}
                key={destination}
                name="covers"
                className={classNames('checkbox--button-style', checked && 'checked')}
                label={<span className="roaming-destinations__option__label">{destination}</span>}
                onChange={onChange}
                checked={checked}
            />
        );
    };

    const renderDestinationCheckbox = (chosenLocation: Destination) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                send({
                    type: 'SELECTED_DESTINATIONS',
                    data: [...state.context.chosenDestinations, chosenLocation.destination],
                });
            } else {
                send({
                    type: 'SELECTED_DESTINATIONS',
                    data: state.context.chosenDestinations.filter((item: any) => item !== chosenLocation.destination),
                });
            }
        };

        const checked = state.context.chosenDestinations.some((item: string) => item === chosenLocation.destination);

        return renderCheckbox(chosenLocation.destination, onChange, checked);
    };
    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
        >
            {(state.hasTag('loading') || fetchingDestinationsLoading) && <LoadingSpinnerOverlay />}

            {fetchingDestinationsError && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}

            <form onSubmit={selectDestinations}>
                <Fieldset legend={content.heading} visuallyHideLegend className="roaming-destinations__list">
                    {(fetchedDestinations! as Destination[])?.map((destination: Destination) => (
                        <div key={destination.destination}>{renderDestinationCheckbox(destination)}</div>
                    ))}
                </Fieldset>
                <Button
                    width="full"
                    type="submit"
                    variant="primary"
                    label={common.continue}
                    className="roaming-destinations__cta"
                    disabled={!!state.context.chosenDestinations.length === false}
                />
            </form>
        </Layout>
    );
};

export default withContent(RoamingDestinations, contentMap, roamingDestinationsContent);
