import CustomerService from '../../../../../../services/customer-service/CustomerService';
import ResidencyStatusTypes from '../../../../../../utils/constants/ResidencyStatusTypes';
import formatDateToString from '../../../../../../utils/formatDateToString';
import { ProfileBaymaxMachineContext } from '../context/profileBaymaxMachineContext';
import { ProfileMachineContext } from '../context/profileMachineContext';

export type ProfileMachineServices = {
    updateCustomerProfile: {
        data: { accessToken: string; isNewCustomer: boolean };
    };
};

export const updateCustomerProfile = async (
    ctx: ProfileMachineContext,
): Promise<{ accessToken: string; isNewCustomer: boolean }> => {
    const { accessToken, isNewCustomer } = await CustomerService.initialiseGuestCustomer({
        email: ctx.email,
    });
    await CustomerService.updateGuestCustomerProfile({
        accessToken,
        profile: {
            firstName: ctx.firstName,
            lastName: ctx.lastName,
            dateOfBirth: formatDateToString(new Date(`${ctx.dob.year}/${ctx.dob.month}/${ctx.dob.day}`)),
            state: ctx.state,
            residencyStatusType: [ctx.residencyStatus as ResidencyStatusTypes],
            gender: ctx.gender,
        },
    });

    return { accessToken, isNewCustomer };
};

export const updateCustomerProfileBaymax = async (ctx: ProfileBaymaxMachineContext) => {
    await CustomerService.updateCustomerProfile({
        accessToken: ctx.accessToken,
        profile: {
            firstName: ctx.firstName,
            lastName: ctx.lastName,
            dateOfBirth: formatDateToString(new Date(`${ctx.dob.year}/${ctx.dob.month}/${ctx.dob.day}`)),
            state: ctx.state,
            residencyStatusType: [ctx.residencyStatus as ResidencyStatusTypes],
            gender: ctx.gender,
        },
    });
};
