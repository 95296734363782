import { datadogRum } from '@datadog/browser-rum';

const applicationId = process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID || '';
const clientToken = process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || '';
const service = process.env.REACT_APP_DATADOG_RUM_SERVICE;
const env = process.env.REACT_APP_DATADOG_RUM_ENV;
const version = process.env.REACT_APP_VERSION;

declare global {
    interface Window {
        DD_RUM?: {
            getInternalContext: () => unknown;
        };
    }
}

export default (): void => {
    try {
        // If not initialised
        if (!window.DD_RUM?.getInternalContext()) {
            datadogRum.init({
                applicationId,
                clientToken,
                site: 'datadoghq.eu',
                service,
                env,
                version,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
                trackFrustrations: true,
                defaultPrivacyLevel: 'mask-user-input',
            });
            datadogRum.startSessionReplayRecording();
        }
    } catch (e) {
        console.error(e);
    }
};
