import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import Analytics from '../../analytics/Analytics';
import { useUser } from '../../business-logic/context-provider/user-context';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import ImageButtonInput, { ImageButtonInputProps } from '../../components/form/image-button-input/ImageButtonInput';
import Layout from '../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import ModalWithCTA from '../../components/modal-with-cta/ModalWithCTA';
import TextField from '../../components/text-field/TextField';
import useOnboarding from '../../hooks/useOnboarding';
import CustomerService from '../../services/customer-service/CustomerService';
import commonStrings from '../../strings/common';
import onboardingFlow from '../../strings/onboardingFlow';
import Routes from '../../utils/Routes';
import Activities, { ActivityId } from '../../utils/constants/Activities';

import './YourActivities.scss';

const YourActivities: FC = () => {
    const history = useHistory();
    const { yourActivities: contentStrings } = onboardingFlow;

    const location = useLocation<LocationState>();
    const isOnboarding = location.state ? location.state.isOnboarding : false;

    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();

    const { userDetails, setUserDetailsByAttr, accessToken } = useUser();
    const [activities, setActivities] = useState<ActivityId[]>([]);
    const [otherActivity, setOtherActivity] = useState('');
    const [isUpdatingUserInfo, setIsUpdatingUserInfo] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [hasShownMotorcyclingModal, setHasShownMotorcyclingModal] = useState(false);
    const [isMotorcyclingModalOpen, setIsMotorcyclingModalOpen] = useState(false);

    useEffect(() => {
        const activityKeys = Object.keys(Activities);

        if (userDetails.activities) {
            const initActivities: ActivityId[] = [];
            userDetails.activities.forEach((activity) => {
                if (activityKeys.includes(activity)) {
                    initActivities.push(activity as ActivityId);
                } else {
                    setOtherActivity(activity);
                }
            });

            setActivities(initActivities);
        }

        if (isOnboarding) {
            Analytics.trackOnboardingStepViewed(onboardingStepNumber);
        }
    }, []);

    const items: ImageButtonInputProps[] = useMemo(
        () =>
            Object.entries(Activities).map(([key, value]) => {
                const checked = activities.some((activity: ActivityId) => activity === key);
                return {
                    id: key,
                    label: value.label,
                    icon: value.icon,
                    name: 'activities',
                    checked,
                    onChange: () => {
                        setActivities(
                            checked
                                ? activities.filter((i) => i !== (key as ActivityId))
                                : [...activities, key as ActivityId],
                        );

                        if (key === 'motorcycling' && !hasShownMotorcyclingModal) {
                            setIsMotorcyclingModalOpen(true);
                            setHasShownMotorcyclingModal(true);
                        }
                    },
                    className: 'your-activities__image-button',
                    type: 'checkbox',
                    variant: 'horizontal',
                };
            }),
        [activities],
    );

    const showOtherTextbox = useMemo(() => activities.some((activity) => activity === 'other'), [activities]);

    const continueDisabled = useMemo(() => {
        if (isUpdatingUserInfo) {
            return true;
        }

        if (activities.length === 0) {
            return true;
        }

        if (showOtherTextbox && otherActivity.trim().length === 0) {
            return true;
        }

        return false;
    }, [isUpdatingUserInfo, activities, otherActivity, showOtherTextbox]);

    const handleContinue = async () => {
        const activitiesToSave: string[] = [...activities];

        if (showOtherTextbox) {
            activitiesToSave.push(otherActivity.trim());
        }

        setUserDetailsByAttr('activities', activitiesToSave);

        Analytics.trackYourActivitiesClickContinue(activitiesToSave);

        setIsUpdatingUserInfo(true);

        try {
            await CustomerService.updateCustomerProfile({
                accessToken: accessToken!,
                profile: { activities: activitiesToSave },
            });

            if (isOnboarding) {
                Analytics.trackOnboardingStepCompleted(onboardingStepNumber, undefined, activitiesToSave);
            }

            history.push({
                pathname: Routes.HOME,
            });
        } catch (err) {
            console.log(err);
            setHasError(true);
        } finally {
            setIsUpdatingUserInfo(false);
        }
    };

    const handleSkip = () => {
        if (isOnboarding) {
            Analytics.trackOnboardingStepCompleted(onboardingStepNumber);
        }

        history.push({
            pathname: Routes.HOME,
        });
    };

    return (
        <Layout
            title={contentStrings.title}
            showProgressBar={isOnboarding}
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
        >
            {isUpdatingUserInfo && <LoadingSpinnerOverlay />}
            <form onSubmit={(e) => e.preventDefault()}>
                <Fieldset legend={<span className="your-activities__description">{contentStrings.description}</span>}>
                    {items.map((item) => (
                        <ImageButtonInput {...item} className="your-activities__options" />
                    ))}
                </Fieldset>
                {showOtherTextbox && (
                    <TextField
                        name={contentStrings.labels.otherTextbox}
                        value={otherActivity}
                        onChange={(e) => setOtherActivity(e.target.value)}
                        label={contentStrings.labels.otherTextbox}
                        className="your-activities__other"
                    />
                )}
                <Button
                    label={commonStrings.done}
                    width="full"
                    disabled={continueDisabled}
                    className="your-activities__continue"
                    type="submit"
                    onClick={handleContinue}
                />
                <Button
                    label={commonStrings.skip}
                    width="full"
                    className="your-activities__skip"
                    onClick={handleSkip}
                    variant="secondary"
                />
                {hasError && <p className="error">{commonStrings.errorSomethingWentWrong}</p>}
            </form>
            <ModalWithCTA
                isOpen={isMotorcyclingModalOpen}
                textContent={contentStrings.motorcyclingModal.title}
                onClose={() => setIsMotorcyclingModalOpen(false)}
                primaryCTA={{
                    label: commonStrings.ok,
                    onClick: () => setIsMotorcyclingModalOpen(false),
                }}
                hasError
                errorMessage={contentStrings.motorcyclingModal.alertText}
            />
        </Layout>
    );
};

export default YourActivities;
