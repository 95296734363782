enum DateFormat {
    DEFAULT = 'dd/MM/yyyy',
    DEFAULT_AND_TIMEZONE = 'dd/MM/yyyy z',
    LONG = 'dd MMMM, yyyy',
    DAY = 'EEEE',
    TIME = 'h:mma',
    TIME_AND_TIMEZONE = 'h:mma z',
    DATE_AND_TIME = 'dd/MM/yyyy h:mma',
    DAY_AND_DATE = 'EEEE, dd/MM/yyyy',
    ANALYTICS = 'yyyy-MM-dd',
    TIMEZONE = 'z',
    DATE = 'dd MMM yyyy',
    VERBOSE = 'do MMM yyyy',
    VERBOSE_WITH_TIMEZONE = 'do MMM yyyy (z)',
}

export default DateFormat;
