import { useOktaAuth } from '@okta/okta-react';
import React, { useContext, createContext, useCallback, useEffect, useState, useMemo } from 'react';

import ProductService from '../../services/product-service/ProductService';
import { useUser } from './user-context/UserContext';
import Cover, { MainCover } from '../../utils/constants/Cover';
import ProductResponse from '../models/ProductResponse';
import CoverInformation from '../../utils/constants/CoverInformation';

export type IProductContext = {
    initialised: boolean;
    products: Record<MainCover, ProductResponse[]>;
    latestProductsByPdsVersion: Record<MainCover, ProductResponse>;
};

export const ProductContext = createContext<any>({});

export const useProduct = (): IProductContext => {
    const context: IProductContext = useContext(ProductContext);
    if (typeof context === 'undefined') {
        throw new Error('Product Context must be used within the ProductContext');
    }
    return context;
};

export const ProductProvider: React.FC = (props) => {
    const { authState } = useOktaAuth();
    const { accessToken } = useUser();

    const [initialised, setInitialised] = useState(false);
    const [products, setProducts] = useState<Record<MainCover, ProductResponse[]>>({
        [Cover.ALWAYS_ON]: [],
        [Cover.DAY_PASS]: [],
        [Cover.JAPAN_PASS]: [],
        [Cover.FLIP_ACTIVE_DAILY]: [],
        [Cover.FLIP_ACTIVE_WEEKLY]: [],
        [Cover.FLIP_ACTIVE_SUB_MONTHLY]: [],
        [Cover.FLIP_KIDS_DAILY]: [],
        [Cover.FLIP_KIDS_WEEKLY]: [],
        [Cover.FLIP_KIDS_SUB_MONTHLY]: [],
        [Cover.FLIP_ROAMING_WEEKLY]: [],
        [Cover.FLIP_ROAMING_KIDS_WEEKLY]: [],
    });

    // ***************** Fetchers *****************

    const fetchAllProducts = useCallback(async () => {
        const response = await ProductService.getProducts({
            fetchAllProducts: true,
            filterTestProducts: true,
        });

        return response;
    }, []);

    // ***************** Fetch and set *****************

    const fetchAndSetAllProduct = useCallback(async () => {
        const rawProducts = await fetchAllProducts();

        const parsedProducts: Record<MainCover, ProductResponse[]> = {
            [Cover.ALWAYS_ON]: [],
            [Cover.DAY_PASS]: [],
            [Cover.JAPAN_PASS]: [],
            [Cover.FLIP_ACTIVE_DAILY]: [],
            [Cover.FLIP_ACTIVE_WEEKLY]: [],
            [Cover.FLIP_ACTIVE_SUB_MONTHLY]: [],
            [Cover.FLIP_KIDS_DAILY]: [],
            [Cover.FLIP_KIDS_WEEKLY]: [],
            [Cover.FLIP_KIDS_SUB_MONTHLY]: [],
            [Cover.FLIP_ROAMING_WEEKLY]: [],
            [Cover.FLIP_ROAMING_KIDS_WEEKLY]: [],
        };

        Object.entries(CoverInformation)
            .filter(([, coverInfo]) => coverInfo.isMainCover)
            .forEach(([cover, coverInfo]) => {
                parsedProducts[cover as MainCover] = rawProducts
                    .filter((product) => product.productSpec.mainCoverType.coverCode === coverInfo.coverCode)
                    .sort((a, b) => {
                        if (a.pdsVersionMajor === b.pdsVersionMajor) {
                            return a.pdsVersionMinor - b.pdsVersionMinor;
                        }
                        return a.pdsVersionMajor - b.pdsVersionMajor;
                    });
            });

        setProducts(parsedProducts);
        setInitialised(true);
    }, [fetchAllProducts]);

    // ***************** Derived *****************

    const latestProductsByPdsVersion = useMemo(
        () =>
            Object.entries(products).reduce((acc, [cover, coverProducts]) => {
                acc[cover as MainCover] = coverProducts[coverProducts.length - 1];

                return acc;
            }, {} as Record<MainCover, ProductResponse>),
        [products],
    );

    // ***************** Initialise *****************

    useEffect(() => {
        if (accessToken && authState?.isAuthenticated) {
            fetchAndSetAllProduct();
        }
    }, [accessToken, authState?.isAuthenticated, fetchAndSetAllProduct]);

    // ***************** Render *****************

    const value: IProductContext = useMemo(
        () => ({
            initialised,
            products,
            latestProductsByPdsVersion,
        }),
        [initialised, latestProductsByPdsVersion, products],
    );

    return <ProductContext.Provider value={value} {...props} />;
};
