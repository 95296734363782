import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import CartBaymaxStep, { CartBaymaxStepsWithKids, CartBaymaxStepsWithoutKids } from '../../constants/CartBaymaxStep';
import { Typegen0 } from '../cartBaymaxMachine.typegen';
import { CartBaymaxMachineContextTypes, InsuredPersonType } from '../context/cartBaymaxCartMachineContext';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    CartBaymaxMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            products: ProductResponse[];
            productGroups: ProductGroupsResponse | null;
        };
    }
>({
    productGroups: (ctx, event) => event.data.productGroups,
    products: (ctx, event) => {
        return event.data.products;
    },
});

export const setDependantsAsAvailableInsuredType = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['setDependantsAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types = [...ctx.availableInsuredPersonsTypes, 'dependant' as InsuredPersonType];
        return types;
    },
});

export const setAccountHolderAsAvailableInsuredType = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['setAccountHolderAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types = [...ctx.availableInsuredPersonsTypes, 'accountholder' as InsuredPersonType];
        return types;
    },
});

export const markAccountHolderAsInsuredPerson = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['markAccountHolderAsInsuredPerson'] }
>({
    insuredPersons: (ctx) => {
        return {
            accountHolder: true,
            dependants: ctx.insuredPersons.dependants,
        };
    },
});

export const setPersonsInsured = assign<
    CartBaymaxMachineContextTypes,
    { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    insuredPersons: (ctx, event) => {
        return {
            accountHolder: event.data.isAccountHolderInsured,
            dependants: event.data.dependants,
        };
    },
});

export const setCartBaymaxStepsWithoutKids = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['setCartBaymaxStepsWithoutKids'] }
>({
    steps: () => {
        return CartBaymaxStepsWithoutKids;
    },
});

export const setCartBaymaxStepsWithKids = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['setCartBaymaxStepsWithKids'] }
>({
    steps: () => {
        return CartBaymaxStepsWithKids;
    },
});

export const setProfileStep = assign<
    CartBaymaxMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['setProfileStep'] }
>({
    currentStep: () => CartBaymaxStep.PROFILE,
});

export const setPersonsInsuredStep = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['setPersonsInsuredStep'] }
>({
    currentStep: () => {
        return CartBaymaxStep.WHOS_COVERED;
    },
});

export const setReviewCoverStep = assign<
    CartBaymaxMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'PERSONS_INSURED_COMPLETE'> }
    | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    currentStep: () => CartBaymaxStep.REVIEW_COVER,
});

export const setPaymentStep = assign<CartBaymaxMachineContextTypes, { type: EventsCausingActions['setPaymentStep'] }>({
    currentStep: () => CartBaymaxStep.PAYMENT,
});

export const showProfileStep = assign<
    CartBaymaxMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['showProfileStep'] }
>({
    showStep: () => CartBaymaxStep.PROFILE,
});

export const showPersonsInsuredStep = assign<
    CartBaymaxMachineContextTypes,
    { type: EventsCausingActions['showPersonsInsuredStep'] }
>({
    showStep: () => {
        return CartBaymaxStep.WHOS_COVERED;
    },
});

export const showPaymentStep = assign<CartBaymaxMachineContextTypes, { type: EventsCausingActions['showPaymentStep'] }>(
    {
        showStep: () => {
            return CartBaymaxStep.PAYMENT;
        },
    },
);

export const showReviewCoverStep = assign<
    CartBaymaxMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'PERSONS_INSURED_COMPLETE'> }
    | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    showStep: () => CartBaymaxStep.REVIEW_COVER,
});

// // Analytics

export const trackProfileStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartBaymaxStep.PROFILE + 1, 'Your Details');
};

export const trackPersonsInsuredStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartBaymaxStep.WHOS_COVERED + 1, 'Who is covered');
};

export const trackReviewCoverStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartBaymaxStep.REVIEW_COVER + 1, 'Review Cover');
};

export const trackPaymentStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartBaymaxStep.PAYMENT + 1, 'Payment Details');
};

export const trackProfileStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartBaymaxStep.PROFILE + 1, 'Your Details');
};

export const trackSelectedPersonsToInsure = (ctx: CartBaymaxMachineContextTypes): void => {
    Analytics.trackSelectedPersonsToInsure(ctx.insuredPersons.accountHolder, ctx.insuredPersons.dependants.length);
};

export const trackPersonsInsuredStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartBaymaxStep.WHOS_COVERED + 1, 'Who is covered');
};

export const trackReviewCoverStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartBaymaxStep.REVIEW_COVER + 1, 'Review Cover');
};

export const trackPaymentStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartBaymaxStep.PAYMENT + 1, 'Payment Details');
};

export const trackCheckoutCompleted = (): void => {
    Analytics.trackCheckoutCompleted();
};
