import classNames from 'classnames';
import { FC } from 'react';

import './RoamingPriceCoverTile.scss';

type TProps = {
    title: string;
    price: string;
};

const RoamingPriceCoverTile: FC<TProps> = ({ price, title }: TProps) => {
    return (
        <div className={classNames('roaming-price-cover-tile')}>
            <p className="roaming-price-cover-tile__title">{title}</p>
            <div className="roaming-price-cover-tile__price-section">
                <p className={classNames('roaming-price-cover-tile__price')}>
                    <span className="roaming-price-cover-tile__price__currency">A$</span>
                    {parseInt(price.match(/\$(\d*)/)![1], 10)}
                </p>
            </div>
        </div>
    );
};

export default RoamingPriceCoverTile;
