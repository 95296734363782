import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRoaming } from '../../business-logic/context-provider/RoamingContext';
import { Destination, Region } from '../../business-logic/models/RoamingDestinations';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import Layout from '../../components/layout/Layout';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import Sticky from '../../components/sticky/Sticky';
import roamingStartingRegionContent from '../../content/ui/screens/roaming-starting-region/roamingStartingRegion';
import withContent from '../../hoc/with-content/withContent';
import useOnboarding from '../../hooks/useOnboarding';
import CdnService from '../../services/cdn-service/CdnService';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import Cover from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import getPdsVersion from '../../utils/getPdsVersion';
import getTimezone from '../../utils/getTimezone';

import './RoamingStartingRegion.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
};

interface RoamingStartingRegionProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingStartingRegion: FC<RoamingStartingRegionProps> = ({ content }) => {
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;
    const { onboardingStepNumber, onboardingTotalSteps } = useOnboarding();
    const history = useHistory();
    const { setRoamingData, startingDestination, selectedCover, startingRegion } = useRoaming();
    const { authState } = useOktaAuth();

    const selectTimezone = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        history.push({
            pathname: authState?.isAuthenticated ? Routes.ROAMING_DATES : Routes.ROAMING_DATES_GUEST,
        });
    };

    const {
        isLoading: fetchingDestinationsLoading,
        isError: fetchingDestinationsError,
        data: fetchedDestinations,
        isSuccess: fetchingDestinationsSucceeded,
    } = useQuery({
        queryKey: ['destinations'],
        queryFn: () => {
            return CdnService.getCoverConfig({
                coverCode: selectedCover as Cover,
                PDSVersion: getPdsVersion(selectedCover as Cover),
            });
        },
    });

    const displayRegions = () => {
        if (fetchingDestinationsError || !!fetchedDestinations === false) return [];
        return (fetchedDestinations as Destination[]).find(
            (item: Destination) => item.destination === startingDestination,
        )?.regions;
    };

    const selectRegion = (selectedRegion: Region): void => {
        setRoamingData('timezone', selectedRegion.timezone);
        setRoamingData('startingRegion', selectedRegion.region);
    };

    useEffect(() => {
        const currentPath = location.pathname;
        if (authState?.isAuthenticated && currentPath === Routes.ROAMING_REGION_GUEST) {
            history.push(Routes.HOME);
        }
    }, [location, authState, history]);

    return (
        <Layout
            title={content.heading}
            showProgressBar={isOnboardingFlow}
            isContentCentered
            currentProgress={(onboardingStepNumber / onboardingTotalSteps) * 100}
            showBackButton={!isOnboardingFlow}
            className="roaming-start-region__layout"
        >
            <p>{content.description}</p>
            {fetchingDestinationsLoading && <LoadingSpinnerOverlay />}
            <form onSubmit={selectTimezone}>
                <Fieldset legend={content.heading} visuallyHideLegend className="roaming-start-destinations__list">
                    {fetchingDestinationsSucceeded &&
                        !!displayRegions() &&
                        displayRegions()?.map((item: Region) => (
                            <div className="roaming__start__region-wrapper" key={item.region}>
                                <RadioButton
                                    id={item.region}
                                    name="destination__option"
                                    className="roaming__start__region"
                                    label={`${item.region} (${getTimezone(
                                        item.timezone,
                                        format(new Date(), DateFormat.DEFAULT),
                                    )})`}
                                    onChange={() => selectRegion(item)}
                                    checked={startingRegion === item.region}
                                />
                                {Object.hasOwn(item, 'description') && (
                                    <p className="roaming-starting-region__description">{item.description}</p>
                                )}
                            </div>
                        ))}
                </Fieldset>
                <Sticky>
                    <Button
                        width="full"
                        type="submit"
                        variant="primary"
                        label={common.continue}
                        disabled={!!startingRegion === false}
                        className="roaming-destinations__cta"
                    />
                </Sticky>
            </form>
        </Layout>
    );
};

export default withContent(RoamingStartingRegion, contentMap, roamingStartingRegionContent);
