import '@okta/okta-auth-js/polyfill';
import { SecureRoute } from '@okta/okta-react';
import React from 'react';
import { Redirect, Switch } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClaimProvider } from '../../business-logic/context-provider/ClaimContext';
import Routes from '../../utils/Routes';

import requireClaimsContext from '../../hoc/require-claims-context/requireClaimsContext';
import AccidentDate from '../claims/accident-date/AccidentDate';
import AccidentDescription from '../claims/accident-description/AccidentDescription';
import BankDetails from '../claims/bank-details/BankDetails';
import ClaimAccidentLocation from '../claims/claim-accident-location/ClaimAccidentLocation';
import ClaimAutograph from '../claims/claim-autograph/ClaimAutograph';
import ClaimDetails from '../claims/claim-details/ClaimDetails';
import ClaimPersonsInsured from '../claims/claim-persons-insured/ClaimPersonsInsured';
import ClaimRequirements from '../claims/claim-requirments/ClaimRequirements';
import ClaimSubmitted from '../claims/claim-submitted/ClaimSubmitted';
import ClaimsType from '../claims/claim-type/ClaimType';
import ClaimsChecklist from '../claims/claims-checklist/ClaimsChecklist';
import ClaimsDeprecation from '../claims/claims-deprecation/ClaimsDeprecation';
import Claims from '../claims/claims/Claims';
import EmergencyServicesRequired from '../claims/emergency-services-required/EmergencyServicesRequired';
import FirstTreatmentDate from '../claims/first-treatment-date/FirstTreatmentDate';
import MedicalContactConsent from '../claims/medical-contact-consent/MedicalContactConsent';
import MedicalContactInformation from '../claims/medical-contact-information/MedicalContactInformation';
import MedicalReport from '../claims/medical-report/MedicalReport';
import OverseasReceipts from '../claims/overseas-receipts/OverseasReceipts';
import PhotoOfAccidentQuestionnaire from '../claims/photo-of-accident-questionnaire/PhotoOfAccidentQuestionnaire';
import PhotoOfAccident from '../claims/photo-of-accident/PhotoOfAccident';
import ProofOfActivityQuestionnaire from '../claims/proof-of-activity-questionnaire/ProofOfActivityQuestionnaire';
import ProofOfActivity from '../claims/proof-of-activity/ProofOfActivity';
import ReviewClaim from '../claims/review-claim/ReviewClaim';
import SelectInjury from '../claims/select-injury/SelectInjury';
import SurgeryRequired from '../claims/surgery-required/SurgeryRequired';
import ThirdPartyConsent from '../claims/third-party-consent/ThirdPartyConsent';
import WitnessInfo from '../claims/witness-info/WitnessInfo';
import WitnessQuestionnaire from '../claims/witness-questionnaire/WitnessQuestionnaire';

const ClaimsRouter: React.FC = () => {
    const { claims20 } = useFlags();
    return (
        <ClaimProvider>
            <Switch>
                {claims20 && <SecureRoute path="*" component={ClaimsDeprecation} />}
                <SecureRoute exact path={Routes.CLAIMS} component={Claims} />
                <SecureRoute exact path={Routes.CLAIM_PERSONS_INSURED} component={ClaimPersonsInsured} />
                <SecureRoute exact path={Routes.CLAIM_CHECKLIST} component={requireClaimsContext(ClaimsChecklist)} />
                <SecureRoute exact path={Routes.CLAIM_TYPE} component={requireClaimsContext(ClaimsType)} />
                <SecureRoute path={Routes.CLAIM_REQUIREMENTS} component={requireClaimsContext(ClaimRequirements)} />
                <SecureRoute path={Routes.CLAIM_AUTOGRAPH} component={requireClaimsContext(ClaimAutograph)} />
                <SecureRoute
                    path={Routes.CLAIM_ACCIDENT_LOCATION}
                    component={requireClaimsContext(ClaimAccidentLocation)}
                />
                <SecureRoute path={Routes.SELECT_INJURY} component={requireClaimsContext(SelectInjury)} />
                <SecureRoute path={Routes.ACCIDENT_DESCRIPTION} component={requireClaimsContext(AccidentDescription)} />
                <SecureRoute path={Routes.THIRD_PARTY_CONSENT} component={requireClaimsContext(ThirdPartyConsent)} />
                <SecureRoute path={Routes.ACCIDENT_DATE} component={requireClaimsContext(AccidentDate)} />
                <SecureRoute path={Routes.FIRST_TREATMENT_DATE} component={requireClaimsContext(FirstTreatmentDate)} />
                <SecureRoute path={Routes.SURGERY_REQUIRED} component={requireClaimsContext(SurgeryRequired)} />
                <SecureRoute path={Routes.OVERSEAS_RECEIPTS} component={requireClaimsContext(OverseasReceipts)} />
                <SecureRoute path={Routes.MEDICAL_REPORT} component={requireClaimsContext(MedicalReport)} />
                <SecureRoute
                    path={Routes.MEDICAL_CONTACT_CONSENT}
                    component={requireClaimsContext(MedicalContactConsent)}
                />
                <SecureRoute
                    path={Routes.MEDICAL_CONTACT_INFORMATION}
                    component={requireClaimsContext(MedicalContactInformation)}
                />
                <SecureRoute
                    path={Routes.EMERGENCY_SERVICES_REQUIRED}
                    component={requireClaimsContext(EmergencyServicesRequired)}
                />
                <SecureRoute
                    path={Routes.WITNESS_QUESTIONNAIRE}
                    component={requireClaimsContext(WitnessQuestionnaire)}
                />
                <SecureRoute path={Routes.WITNESS_INFO} component={requireClaimsContext(WitnessInfo)} />
                <SecureRoute
                    path={Routes.PHOTO_OF_ACCIDENT_QUESTIONNAIRE}
                    component={requireClaimsContext(PhotoOfAccidentQuestionnaire)}
                />
                <SecureRoute path={Routes.PHOTO_OF_ACCIDENT} component={requireClaimsContext(PhotoOfAccident)} />
                <SecureRoute
                    path={Routes.PROOF_OF_ACTIVITY_QUESTIONNAIRE}
                    component={requireClaimsContext(ProofOfActivityQuestionnaire)}
                />
                <SecureRoute path={Routes.PROOF_OF_ACTIVITY} component={requireClaimsContext(ProofOfActivity)} />
                <SecureRoute path={Routes.BANK_DETAILS} component={requireClaimsContext(BankDetails)} />
                <SecureRoute path={Routes.REVIEW_CLAIM} component={requireClaimsContext(ReviewClaim)} />
                <SecureRoute path={Routes.CLAIM_SUBMITTED} component={ClaimSubmitted} />
                <SecureRoute path={Routes.CLAIM_DETAILS} component={ClaimDetails} />
                <Redirect to={Routes.NOT_FOUND} />
            </Switch>
        </ClaimProvider>
    );
};

// other options available for a set up: https://launchdarkly.github.io/react-client-sdk/interfaces/providerconfig.html
export default ClaimsRouter;
