import differenceInYears from 'date-fns/differenceInYears';
import isValidDate from '../../../../../../validation/isValidDate';
import isValidEmail from '../../../../../../validation/isValidEmail';
import getDate from '../../../../../../validation/getDate';
import { ProfileMachineContext } from '../context/profileMachineContext';
import isAxios403Error from '../../../../../../services/utils/isAxios403Error';

export const isEmailFilledIn = (ctx: ProfileMachineContext): boolean => ctx.email.length > 0;

export const isEmailValid = (ctx: ProfileMachineContext): boolean => isValidEmail(ctx.email);

export const isFirstNameValid = (ctx: ProfileMachineContext): boolean =>
    ctx.firstName.length >= ctx.config.nameMinLength;

export const isLastNameValid = (ctx: ProfileMachineContext): boolean => ctx.lastName.length >= ctx.config.nameMinLength;

export const isDateFilledIn = (ctx: ProfileMachineContext): boolean =>
    ctx.dob.day.length > 0 && ctx.dob.month.length > 0 && ctx.dob.year.length === 4;

export const isValidDateFormat = (ctx: ProfileMachineContext): boolean =>
    ctx.dob.year.length === 4 && isValidDate(ctx.dob.day, ctx.dob.month, ctx.dob.year);

export const isPurchasingKidsCoverOnly = (ctx: ProfileMachineContext): boolean => ctx.isPurchasingKidsCoverOnly;

export const isBelowMinAge = (ctx: ProfileMachineContext): boolean =>
    differenceInYears(new Date(), getDate(ctx.dob.day, ctx.dob.month, ctx.dob.year)) < ctx.config.minAge;

export const isAboveMaxAge = (ctx: ProfileMachineContext): boolean =>
    differenceInYears(new Date(), getDate(ctx.dob.day, ctx.dob.month, ctx.dob.year)) > ctx.config.maxAge;

export const isEligibleState = (ctx: ProfileMachineContext): boolean => !!ctx.state && ctx.state !== 'NOT_IN_AUS';

export const isInvalidState = (ctx: ProfileMachineContext): boolean => !!ctx.state && ctx.state === 'NOT_IN_AUS';

export const isEligibleResidencyStatus = (ctx: ProfileMachineContext): boolean =>
    !!ctx.residencyStatus && ctx.residencyStatus !== 'TOURIST';

export const isInvalidResidencyStatus = (ctx: ProfileMachineContext): boolean =>
    !!ctx.residencyStatus && ctx.residencyStatus === 'TOURIST';

export const isGenderSelected = (ctx: ProfileMachineContext): boolean => {
    return ctx.gender !== null;
};

export const isExistingActiveUser = (ctx: ProfileMachineContext, event: { data: unknown }): boolean =>
    isAxios403Error(event.data);

export const isNewCustomer = (
    ctx: ProfileMachineContext,
    event: { data: { accessToken: string; isNewCustomer: boolean } },
): boolean => event.data.isNewCustomer;
