import { createMachine } from 'xstate';
import { Destination } from '../../../business-logic/models/RoamingDestinations';
import { setAllDestinations, setStartingDestination } from '../actions/roamingStartDestinationMachineActions';
import { RoamingStartDestinationMachineContextTypes } from '../context/roamingStartDestinationMachineContext';
import hasMultipleRegions from '../guards/roamingStartDestinationMachineGuards';

const RoamingStartDestinationMachine = createMachine(
    {
        id: 'RoamingStartDestinationMachine',
        initial: 'initialise',
        schema: {
            context: {} as RoamingStartDestinationMachineContextTypes,
            events: {} as
                | { type: 'SELECT_START_DESTINATION'; data: string }
                | { type: 'LOAD_DESTINATIONS'; data: Destination[] },
        },
        tsTypes: {} as import('./roamingStartDestinationMachine.typegen').Typegen0,
        context: {
            availableDestinations: [],
            startDestination: '',
        },
        preserveActionOrder: true,
        states: {
            initialise: {
                tags: ['loading'],
                on: {
                    LOAD_DESTINATIONS: {
                        actions: 'setAllDestinations',
                        target: 'idle',
                    },
                },
            },
            idle: {
                on: {
                    SELECT_START_DESTINATION: {
                        actions: 'setStartingDestination',
                        target: 'determineHowManyRegions',
                    },
                },
            },
            determineHowManyRegions: {
                tags: ['loading'],
                always: [
                    {
                        cond: 'hasMultipleRegions',
                        target: 'chooseStartingRegion',
                    },
                    {
                        target: 'selectRoamingDates',
                    },
                ],
            },
            selectRoamingDates: {
                entry: 'redirectToSelectDates',
                tags: ['loading'],
                type: 'final',
            },
            chooseStartingRegion: {
                entry: 'redirectToChooseStartingRegion',
                tags: ['loading'],
                type: 'final',
            },
        },
    },
    {
        actions: {
            setStartingDestination,
            setAllDestinations,
        },
        guards: {
            hasMultipleRegions,
        },
    },
);

export default RoamingStartDestinationMachine;
