import React from 'react';
import classNames from 'classnames';
import claimsFlowStrings from '../../strings/claimsFlow';
import './FlipRating.scss';

export interface FlipRatingProps {
    rating: number;
}

interface RatingBarProps {
    barNumber: number;
    rating: number;
}

const RatingBar: React.FC<RatingBarProps> = ({ barNumber, rating }) => (
    <div className={classNames('flip-rating__bar', rating >= barNumber && 'flip-rating__bar--active')} />
);

const { claimUploadExampleModal } = claimsFlowStrings;

export const FlipRating: React.FC<FlipRatingProps> = ({ rating }) => {
    return (
        <div className="flip-rating">
            <p className="flip-rating__label">
                {claimUploadExampleModal.flipRatingLabel}: {rating}/10
            </p>
            <div className="flip-rating__bars">
                <RatingBar key="bar-4" barNumber={4} rating={rating} />
                <RatingBar key="bar-6" barNumber={6} rating={rating} />
                <RatingBar key="bar-8" barNumber={8} rating={rating} />
                <RatingBar key="bar-10" barNumber={10} rating={rating} />
            </div>
        </div>
    );
};

export default FlipRating;
