import LocalizedStrings from 'react-localization';

const roamingSelectedContent = new LocalizedStrings({
    en: {
        heading: 'Flip Roaming Cover',
        description:
            'Get cash back with our Overseas Medical Cash, plus Extra Recovery Cash to help you back on your feet.',
        infolink: 'Check all the details, exclusions and the countries we cover',
        roamingcta: 'Select travel destinations',
    },
});

export default roamingSelectedContent;
