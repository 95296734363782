import productInfo from '../content/by-product/product-info/productInfo';
import { ProductInfo } from '../content/by-product/product-info/types';
import Cover from './constants/Cover';
import {
    AlwaysOnPdsVersion,
    DayPassPdsVersion,
    FlipActiveDailyPdsVersion,
    FlipActiveSubMonthlyPdsVersion,
    FlipActiveWeeklyPdsVersion,
    FlipKidsDailyPdsVersion,
    FlipKidsSubMonthlyPdsVersion,
    FlipKidsWeeklyPdsVersion,
    FlipRoamingPdsVersion,
    FlipRoamingKidsPdsVersion,
    JapanPassPdsVersion,
    LatestAlwaysOnPdsVersion,
    LatestDayPassPdsVersion,
    LatestFlipActiveDailyPdsVersion,
    LatestFlipActiveSubMonthlyPdsVersion,
    LatestFlipActiveWeeklyPdsVersion,
    LatestFlipKidsDailyPdsVersion,
    LatestFlipKidsSubMonthlyPdsVersion,
    LatestJapanPassPdsVersion,
    LatestFlipKidsWeeklyPdsVersion,
    LatestFlipRoamingPdsVersion,
    LatestFlipRoamingKidsPdsVersion,
} from './constants/PdsVersion';

const getProductInfo = (
    cover: Cover,
    pdsVersion?:
        | AlwaysOnPdsVersion
        | DayPassPdsVersion
        | FlipActiveDailyPdsVersion
        | FlipActiveWeeklyPdsVersion
        | FlipActiveSubMonthlyPdsVersion
        | FlipKidsDailyPdsVersion
        | FlipKidsWeeklyPdsVersion
        | FlipKidsSubMonthlyPdsVersion
        | JapanPassPdsVersion
        | FlipRoamingPdsVersion
        | FlipRoamingKidsPdsVersion,
): ProductInfo => {
    if (cover === Cover.DAY_PASS) {
        return pdsVersion
            ? productInfo.dayPass[pdsVersion as DayPassPdsVersion]
            : productInfo.dayPass[LatestDayPassPdsVersion];
    }

    if (cover === Cover.JAPAN_PASS) {
        return pdsVersion
            ? productInfo.japanPass[pdsVersion as JapanPassPdsVersion]
            : productInfo.japanPass[LatestJapanPassPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_DAILY) {
        return pdsVersion
            ? productInfo.flipActiveDaily[pdsVersion as FlipActiveDailyPdsVersion]
            : productInfo.flipActiveDaily[LatestFlipActiveDailyPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_WEEKLY) {
        return pdsVersion
            ? productInfo.flipActiveWeekly[pdsVersion as FlipActiveWeeklyPdsVersion]
            : productInfo.flipActiveWeekly[LatestFlipActiveWeeklyPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_SUB_MONTHLY) {
        return pdsVersion
            ? productInfo.flipActiveSubMonthly[pdsVersion as FlipActiveSubMonthlyPdsVersion]
            : productInfo.flipActiveSubMonthly[LatestFlipActiveSubMonthlyPdsVersion];
    }

    if (cover === Cover.FLIP_KIDS_DAILY) {
        return pdsVersion
            ? productInfo.flipKidsDaily[pdsVersion as FlipKidsDailyPdsVersion]
            : productInfo.flipKidsDaily[LatestFlipKidsDailyPdsVersion];
    }

    if (cover === Cover.FLIP_KIDS_WEEKLY) {
        return pdsVersion
            ? productInfo.flipKidsWeekly[pdsVersion as FlipKidsWeeklyPdsVersion]
            : productInfo.flipKidsWeekly[LatestFlipKidsWeeklyPdsVersion];
    }

    if (cover === Cover.FLIP_KIDS_SUB_MONTHLY) {
        return pdsVersion
            ? productInfo.flipKidsSubMonthly[pdsVersion as FlipKidsSubMonthlyPdsVersion]
            : productInfo.flipKidsSubMonthly[LatestFlipKidsSubMonthlyPdsVersion];
    }

    if (cover === Cover.BOOST) {
        return pdsVersion
            ? productInfo.boost[pdsVersion as AlwaysOnPdsVersion]
            : productInfo.boost[LatestAlwaysOnPdsVersion];
    }

    if (cover === Cover.FLIP_ACTIVE_BOOST) {
        return pdsVersion
            ? productInfo.flipActiveBoost[
                  pdsVersion as FlipActiveSubMonthlyPdsVersion.V1 | FlipActiveSubMonthlyPdsVersion.V1_KIDS
              ]
            : productInfo.flipActiveBoost[FlipActiveSubMonthlyPdsVersion.V1_KIDS];
    }

    if (cover === Cover.FLIP_ROAMING_WEEKLY) {
        return pdsVersion
            ? productInfo.flipRoaming[pdsVersion as FlipRoamingPdsVersion]
            : productInfo.flipRoaming[LatestFlipRoamingPdsVersion];
    }

    if (cover === Cover.FLIP_ROAMING_KIDS_WEEKLY) {
        return pdsVersion
            ? productInfo.flipRoamingKids[pdsVersion as FlipRoamingKidsPdsVersion]
            : productInfo.flipRoamingKids[LatestFlipRoamingKidsPdsVersion];
    }

    return pdsVersion
        ? productInfo.alwaysOn[pdsVersion as AlwaysOnPdsVersion]
        : productInfo.alwaysOn[LatestAlwaysOnPdsVersion];
};

export default getProductInfo;
