import asService, { ServiceArgs } from '../utils/asService';
import toHeaders from '../utils/toHeaders';
import CustomerResponse, { CustomerResponseProfile } from '../../business-logic/models/CustomerResponse';
import transformCustomerResponse, { CustomerDetails } from './transformCustomerResponse';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const updateCustomerProfile = async ({
    accessToken,
    axios,
    profile,
}: {
    accessToken: string;
    profile: Partial<CustomerResponseProfile>;
} & ServiceArgs): Promise<CustomerDetails> => {
    const { data } = await axios.put<CustomerResponse>(
        `${baseApiPath}/api/v1/customer/profile`,
        profile,
        toHeaders(accessToken),
    );

    return transformCustomerResponse(data);
};

export default asService(updateCustomerProfile);
