import MultiProductEncodedCart from '../../../../business-logic/models/MultiProductEncodedCart';
import isKidsCover from '../../../../utils/isKidsCover';
import { GuestCartMachineContextTypes } from '../context/guestCartMachineContext';

export const areMultiProductCoverSelectionsValid = (
    ctx: GuestCartMachineContextTypes,
    event: { data: MultiProductEncodedCart | null },
): boolean => {
    return !!event.data && event.data.items.length > 0 && !event.data.items.find((item) => item.variant.length === 0);
};

export const isKidsDetailsRequired = (ctx: GuestCartMachineContextTypes): boolean => {
    return ctx.coverSelections.some((c) => isKidsCover(c.selectedCover));
};
