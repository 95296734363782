import LocalizedStrings from 'react-localization';

const whatsCoveredExamples = new LocalizedStrings({
    en: {
        heading: 'For example',
        coveredTitle: 'Covered',
        notCoveredTitle: 'Not covered',
        examples: [
            {
                covered: 'A nasty fall on an early morning run twisted my knee and put me on crutches',
                notCovered:
                    'During months of training for a 10K run, I felt increasing pain and discomfort in my knee.',
            },
            {
                covered: 'A car crashed into me and I had to have surgery for a fractured leg.',
                notCovered:
                    'I was driving while over the legal limit of alcohol, crashed my car and had to have surgery for a fractured leg.',
            },
            {
                covered: 'I crashed my mountain bike, and had to have stitches for the gash on my shin.',
                notCovered:
                    'I fainted from dehydration, hit my head on the way down, and had to have stitches for a gash on my head.',
            },
            {
                covered: 'I tore my ACL during a basketball game.',
                notCovered: 'I was playing basketball and ended up with shin splints at the end of the season.',
            },
            {
                covered:
                    'I came off my e-scooter on a public footpath dislocating my shoulder, in a state where e-scooters are legal.',
                notCovered:
                    'I came off my e-scooter on a public footpath dislocating my shoulder, in a state where e-scooters are illegal.',
            },
            {
                covered: 'I sprained my wrist while working as a tennis coach.',
                notCovered: 'I sprained my wrist while working as a furniture removalist.',
            },
            {
                covered: 'I bought a Flip subscription, tore my ACL and then had surgery.',
                notCovered: 'I tore my ACL, bought a Flip subscription and then had surgery.',
            },
        ],
    },
});

export default whatsCoveredExamples;
