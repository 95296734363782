import { useOktaAuth } from '@okta/okta-react';
import { useMachine } from '@xstate/react';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import ProductList from '../../analytics/ProductList';
import MultiProductEncodedCart from '../../business-logic/models/MultiProductEncodedCart';
import requireFlags from '../../hoc/require-flags/requireFlags';
import Routes from '../../utils/Routes';
import { MainCover } from '../../utils/constants/Cover';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import ScheduleCoverBody from './schedule-cover-body/ScheduleCoverBody';
import scheduleCoverGuestMachine from './schedule-cover-guest-machine/scheduleCoverGuestMachine';

const ScheduleCoverGuest = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const { authState } = useOktaAuth();

    const [state, send] = useMachine(scheduleCoverGuestMachine, {
        context: {
            coverFor: location.state?.coverFor,
            dependantToCover: location.state?.dependantToCover,
            coversAvailable: location.state?.guestSelectedCovers as MainCover[],
        },
        actions: {
            goBack: () => {
                history.goBack();
            },
            goToCheckout: (ctx) => {
                const zonedToday = utcToZonedTime(new Date(), ctx.userTimeZone);
                ctx.daysToSchedule.forEach((date) => {
                    Analytics.trackProductAdded({
                        ...ProductList[ctx.selectedCover!],
                        quantity: 1,
                        variant: formatDateToString(date, DateFormat.ANALYTICS),
                        scheduledFor: isSameDay(zonedToday, date) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                        startingInDays: differenceInCalendarDays(date, zonedToday),
                    });
                });

                const cartCover = ProductList[ctx.selectedCover!];
                const guestCartSelection: MultiProductEncodedCart = {
                    items: [
                        {
                            id: 'Flip Active',
                            productId: cartCover.product_id,
                            sku: cartCover.product_id,
                            position: cartCover.position!,
                            quantity:
                                location.state.numberOfGuestKids !== undefined && location.state.numberOfGuestKids > 0
                                    ? location.state.numberOfGuestKids
                                    : 1,
                            name: 'Flip Active',
                            price: cartCover.price,
                            paymentOption: cartCover.name,
                            variant: [
                                ...ctx.daysToSchedule.map((date) => formatDateToString(date, DateFormat.DEFAULT)),
                            ],
                        },
                    ],
                    total:
                        location.state.numberOfGuestKids !== undefined && location.state.numberOfGuestKids > 0
                            ? location.state.numberOfGuestKids
                            : 1,
                };
                const encodedCartString = btoa(JSON.stringify(guestCartSelection));
                history.push({
                    pathname: Routes.CART,
                    search: `c=${encodedCartString}`,
                });
            },
        },
    });
    const { coversAvailable, selectedCover, datePickerMode, daysToSchedule, disabledDays } = state.context;

    const handleCalendarOpen = () => {
        Analytics.trackCalendarOpened(ProductList[selectedCover!]);
    };

    const handleDateSubmission = (dates: Date[]) => {
        send({ type: 'SELECT_DATES', data: [...dates] });
    };

    useEffect(() => {
        if (authState?.isAuthenticated) {
            history.push(Routes.HOME);
        }
    }, [authState, history]);

    return (
        <>
            <ScheduleCoverBody
                selectedCover={selectedCover}
                datePickerMode={datePickerMode}
                coversAvailable={coversAvailable}
                daysToSchedule={daysToSchedule}
                disabledDays={disabledDays}
                goBack={() => send({ type: 'GO_BACK' })}
                fixedPaymentModelMode={false}
                cartLoading={false}
                selectCover={(value) => send({ type: 'SELECT_COVER', data: value as MainCover })}
                handleDateSubmission={handleDateSubmission}
                canSelectDates={state.can({ type: 'SELECT_DATES', data: [] })}
                canCheckout={state.can({ type: 'CHECKOUT' })}
                proceedToCheckout={() => send('CHECKOUT')}
                handleCalendarOpen={handleCalendarOpen}
                isOnboardingFlow={location.state ? location.state.isOnboarding : false}
            />
        </>
    );
};

export default requireFlags(ScheduleCoverGuest);
