import axios from 'axios';
import { CartError, CartInstanceResponse, CartRequestItem } from '../../business-logic/models/Cart';
import { cartErrorCodesToActions } from '../../hooks/use-cart/constants/CartErrorMessages';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import toHeaders from '../utils/toHeaders';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = process.env.REACT_APP_BASE_API_PATH;

const createBaymaxCart = async ({
    accessToken,
    cartItems,
}: {
    accessToken: string | undefined;
    cartItems: CartRequestItem[];
} & ServiceArgs): Promise<CartInstanceResponse | CartError | null> => {
    try {
        assertAccessTokenValidity(accessToken);
        const { data } = await axios.post<CartInstanceResponse>(
            `${baseApiPath}/api/v1/cart`,
            cartItems,
            toHeaders(accessToken),
        );
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            return null; // handle error silently
        }
        if (axios.isAxiosError(error) && !!error && !!error.response) {
            const cartServerError = error.response.data as CartError;
            throw new Error('Create Baymax Cart server error', {
                cause: cartErrorCodesToActions.find(
                    (item) => item.errorCode === cartServerError.errorCode || item.errorCode === 'CATCH_ALL',
                ),
            });
        }
        throw new Error('Create Baymax Cart default error', {
            cause: cartErrorCodesToActions.find((item) => item.errorCode === 'CATCH_ALL'),
        });
    }
};

export default asService(createBaymaxCart);
